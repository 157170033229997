import { BrowserStorageKeys } from '../types';

interface Args {
    type: 'local' | 'session';
    key: BrowserStorageKeys;
}

export default function useBrowserStorage({
    type,
    key,
}: Args): { setInStorage: () => void; clearFromStorage: () => void; itemExistsInStorage: boolean } {
    const storage = type === 'local' ? localStorage : sessionStorage;

    return {
        setInStorage: () => storage.setItem(key, key),
        clearFromStorage: () => storage.removeItem(key),
        itemExistsInStorage: !!storage.getItem(key),
    };
}
