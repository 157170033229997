import React, { Component, ReactNode } from 'react';
import { createPortal } from 'react-dom';

export interface Props {
    isOpen: boolean;
    closeModal: () => void;
    children: ReactNode;
}

export default function Modal(props: Props) {
    return (
        <Portal>
            {props.isOpen && (
                <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
                    <div className="absolute w-full h-full bg-gray-900 opacity-50 z-30" />
                    <div
                        className="w-11/12 sm:w-3/4 md:w-2/5 lg:w-1/4 mx-auto bg-gray-100 rounded shadow-lg z-50 overflow-auto md:pb-5 md:pt-3"
                        style={{ maxHeight: '1000px' }}
                    >
                        <div className="px-5 py-1">
                            <div className="flex justify-end items-center">
                                <button className="cursor-pointer z-40" onClick={props.closeModal}>
                                    <svg
                                        className="fill-current text-gray-600"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                    >
                                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                                    </svg>
                                </button>
                            </div>
                            {props.children}
                        </div>
                    </div>
                </div>
            )}
        </Portal>
    );
}

class Portal extends Component {
    private readonly el: HTMLDivElement;
    constructor(props: any) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount = () => {
        const modalRoot = document.getElementById('modal');
        modalRoot?.appendChild(this.el);
    };

    componentWillUnmount = () => {
        const modalRoot = document.getElementById('modal');
        modalRoot?.removeChild(this.el);
    };

    render() {
        const { children } = this.props;
        return createPortal(children, this.el);
    }
}
