import DatabaseManager from '../../../database/DatabaseManager';
import { AlertDocument } from '../../../database/documents/AlertDocument';
import { PatientDocument } from '../../../database/documents/PatientDocument';
import { AlertDashboardData, AlertTableRow, CurrentUserPatientsData } from './PatientsList';
import { AlertTier } from '../../../database/schemas/Patient';
import { OrganizationDocument } from '../../../database/documents/OrganizationDocument';
import { DeliveryType } from '../../../database/schemas/Medication';

export const noAccountTablePlaceholder = 'No account created';
export const tablePlaceholder = '---';

export async function deriveAndFormatTierThreeAndInfoAlerts({
    selectedOrganization,
    onlyForCurrentUserPatients,
    currentUserIdAndDelegatorsIds,
    isDefaultSort,
}: CurrentUserPatientsData & {
    selectedOrganization: OrganizationDocument;
}): Promise<{ patientsWithAlerts: PatientDocument[]; alertTableRows: AlertTableRow[] }> {
    const aboveTierTwoAlerts = await selectedOrganization.getActiveTierThreeAndInfoAlerts({
        onlyForCurrentUserPatients,
        currentUserIdAndDelegatorsIds,
        isDefaultSort,
    });

    const formattedAlerts: {
        tierThree: {
            [patientId: string]: AlertTableRow;
        };
        informational: {
            [patientId: string]: AlertTableRow;
        };
    } = { tierThree: {}, informational: {} };

    const generateAlertTableRow = (rowData: {
        alert: AlertDocument;
        patient: PatientDocument;
    }): AlertTableRow => ({
        acknowledgedAt: null,
        assignedPrescriberId: rowData.patient.data.assignedPrescriber,
        patientPhoneNumber: rowData.patient.data.emergencyPhoneNumber,
        createdAt: rowData.alert.data.createdAt,
        patientName: derivePatientName(rowData.patient),
        alerts: [rowData.alert],
        patientId: rowData.patient.id,
        alertId: rowData.alert.id,
    });

    const uniquePatientIds = Array.from(new Set(aboveTierTwoAlerts.map(({ patientId }) => patientId)));
    const patientsWithAlerts = await DatabaseManager.PatientModel.getByIds(uniquePatientIds);
    const patientDictionary: { [patientId: string]: PatientDocument } = patientsWithAlerts.reduce(
        (dictionary, patient) => {
            dictionary[patient.id] = patient;
            return dictionary;
        },
        {}
    );

    aboveTierTwoAlerts.forEach(alert => {
        const patient = patientDictionary[alert.patientId];

        if (alert.data.tier === AlertTier.tierThree) {
            if (formattedAlerts.tierThree[patient.id]) {
                formattedAlerts.tierThree[patient.id].alerts.push(alert);
            } else {
                formattedAlerts.tierThree[patient.id] = generateAlertTableRow({ patient, alert });
            }
        } else {
            if (formattedAlerts.informational[patient.id]) {
                formattedAlerts.informational[patient.id].alerts.push(alert);
            } else {
                formattedAlerts.informational[patient.id] = generateAlertTableRow({ patient, alert });
            }
        }
    });

    const sortAlerts = (aTableRow: AlertTableRow, bTableRow: AlertTableRow) => {
        if (aTableRow.alerts.length !== bTableRow.alerts.length) {
            return bTableRow.alerts.length - aTableRow.alerts.length;
        }
        return bTableRow.createdAt.seconds - aTableRow.createdAt.seconds;
    };

    const alertTableRows = [
        ...Object.values(formattedAlerts.tierThree).sort(sortAlerts),
        ...Object.values(formattedAlerts.informational).sort(sortAlerts),
    ];

    return { patientsWithAlerts, alertTableRows };
}

function derivePatientName(patientDocument: PatientDocument): string {
    return `${patientDocument.data.lastName}, ${patientDocument.data.firstName}`;
}

export function deriveTableRowDataFromPatients(patients: PatientDocument[]) {
    return patients.map(({ tableRowData }) => tableRowData);
}

export function getMedLabel({ count, delivery }: { count: number | null; delivery: DeliveryType }) {
    if (count === 1) {
        switch (delivery) {
            case DeliveryType.patch:
                return 'patch';
            case DeliveryType.liquid:
                return 'mL';
            default:
                return 'pill';
        }
    } else {
        switch (delivery) {
            case DeliveryType.patch:
                return 'patches';
            case DeliveryType.liquid:
                return 'mL';
            default:
                return 'pills';
        }
    }
}
