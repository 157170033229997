import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import { RouteProps } from '../types';

export default function Unauthorized(_: RouteProps) {
    return (
        <Container>
            <div className="h-screen w-screen bg-blue-600 flex justify-center content-center items-center flex-wrap absolute top-0">
                <p className="font-sans text-white error-text">401</p>
                <div className="w-screen text-white text-center font-sans text-xl">
                    <p className="text-white text-center mb-2">You are not authorized to view this page</p>
                </div>
                <Link
                    className="bg-white hover:bg-green-500 text-blue-600 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                    to="/"
                >
                    Go back to safety
                </Link>
            </div>
        </Container>
    );
}
const Container = styled.div`
    .error-text {
        font-size: 130px;
    }

    @media (min-width: 768px) {
        .error-text {
            font-size: 220px;
        }
    }

    @media (max-width: 415px) {
        .message {
            margin-bottom: 2em;
        }
    }

    @media (max-width: 320px) {
        .message {
            margin-bottom: auto;
        }
    }
`;
