import React from 'react';
import { FormSubmission, InputChange, RouteProps } from '../../../types';
import ToastAlert from '../../../components/ToastAlert';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux/store';
import { selectToastAlert } from '../../../redux/currentSession/currentSessionSelectors';
import SubmitButton from '../../../components/SubmitButton';
import { useImmer } from 'use-immer';
import { selectSelectedOrganization } from '../../../redux/selectedOrganization/selectedOrganizationSelectors';
import { setToastAlert, setToastError } from '../../../redux/currentSession/currentSessionActions';

interface State {
    ortRequired: boolean;
    submitting?: boolean;
}

export default function OrganizationSettings(_: RouteProps) {
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const [state, updateState] = useImmer<State>({
        ortRequired: !!selectedOrganization?.data.requireOpioidRiskTool,
    });
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const dispatch = useDispatch();

    const toggleOrtRequired = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        if (selectedOrganization) {
            updateState(draft => void (draft.submitting = true));
            try {
                const updatedOrganization = await selectedOrganization.setOpioidRiskToolRequiredStatus(
                    state.ortRequired
                );
                updateState(draft => {
                    draft.ortRequired = updatedOrganization.data.requireOpioidRiskTool;
                });
                dispatch(setToastAlert('Organization settings have been updated'));
            } catch (error) {
                console.log(error);
                dispatch(
                    setToastError("An error occurred while trying to update this organization's settings")
                );
            }
            updateState(draft => void (draft.submitting = false));
        }
    };

    const handleOrtRequiredInput = (e: InputChange) => {
        e.persist();
        updateState(draft => void (draft.ortRequired = !draft.ortRequired));
    };

    return (
        <>
            {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
            <div className="w-full flex flex-row p-2">
                <div className="mt-3 flex ml-5 flex-col w-3/4">
                    <div className="flex flex-row justify-start">
                        <h2 className="text-blue-900 block text-xl font-semibold">Organization Settings</h2>
                    </div>
                    <form
                        className="w-full h-64 flex flex-col items-end mt-3 bg-white rounded-md border border-gray-400 py-3 px-4"
                        onSubmit={toggleOrtRequired}
                    >
                        <label className="text-gray-700 self-start block mt-3 ml-3" htmlFor={''}>
                            <input
                                type="checkbox"
                                className="form-checkbox cursor-pointer bg-gray-100"
                                checked={state.ortRequired}
                                onChange={handleOrtRequiredInput}
                            />
                            <span className="ml-2">
                                Require patients to complete the Opioid Risk Tool (ORT)
                            </span>
                        </label>
                        <div className="mt-auto flex flex-row w-full justify-end items-center">
                            <SubmitButton loading={state.submitting} className="w-1/3 lg:w-1/4">
                                Update
                            </SubmitButton>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
