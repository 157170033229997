import { DocumentConstructor, FirestoreOptions, QueryParams } from '../DatabaseTypes';
import { BaseDocument } from '../documents/BaseDocument';
import { FirestoreAdapter } from '../FirebaseAdapter';
import { Firestore } from '../FirestoreTypes';
import { Timestamps } from '../schemas/Timestamps';
import { firestore } from 'firebase/app';

export abstract class BaseModel<
    Schema extends Timestamps,
    Document extends BaseDocument<Schema>
> extends FirestoreAdapter {
    protected constructor(
        firestore: Firestore,
        documentType: string,
        protected rootCollection: string,
        protected documentConstructor: DocumentConstructor<Schema, Document>
    ) {
        super(firestore, documentType);
    }

    create(data: Omit<Schema, 'createdAt'>): Promise<Document> {
        return this.createDocument(this.documentConstructor, this.rootCollection, data);
    }

    update(id: string, update: Partial<Schema>): Promise<void> {
        return this.updateDocument(`${this.rootCollection}/${id}`, update);
    }

    get(id: string): Promise<Document> {
        return this.getDocument(this.documentConstructor, `${this.rootCollection}/${id}`);
    }

    getAll(options: FirestoreOptions<Schema> = {}): Promise<Document[]> {
        return this.getAllDocuments(this.documentConstructor, this.rootCollection, options);
    }

    async getByIds(idsToGet: string[]): Promise<Document[]> {
        const ids = [...idsToGet];
        if (ids.length == 0) return [];
        let groups: string[][] = [];
        while (ids.length > 0) {
            groups.push(ids.splice(0, ids.length >= 10 ? 10 : ids.length));
        }

        const promises = groups.map(group => {
            return this.query({
                queries: [[firestore.FieldPath.documentId() as any, 'in', group]],
            });
        });

        const results = await Promise.all(promises);
        return results.reduce((acc, curr) => acc.concat(curr), []);
    }

    query(params: QueryParams<Schema>): Promise<Document[]> {
        return this.queryDocuments(this.documentConstructor, this.rootCollection, params);
    }
}
