import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../redux/store';
import {
    selectActiveTab,
    selectLoadingSelectedOrganization,
    selectSelectedOrganization,
} from '../../redux/selectedOrganization/selectedOrganizationSelectors';
import PageContainer from '../../components/PageContainer';
import Tabulation, { Tab } from '../../components/Tabulation';
import { useImmer } from 'use-immer';
import { UserRoles } from '../../database/schemas/User';
import UserList from './tabs/UserList';
import OpioidAgreementList from './tabs/opioidAgreements/OpioidAgreementList';
import OrganizationSettings from './tabs/OrganizationSettings';
import { RouteProps } from '../../types';
import { SelectedOrganizationActions } from '../../redux/selectedOrganization/selectedOrganizationReducer';
import { AuthRedirection } from '../../components/AuthRedirection';
import { useSynchronizeOrgId } from '../../hooks/useSynchronizeOrgId';
import useNavigation from '../../hooks/useNavigation';

export enum OrgAdminTabKeys {
    PROVIDERS = 'PROVIDERS',
    ORG_ADMINS = 'ORG_ADMINS',
    OPIOID_AGREEMENTS = 'OPIOID_AGREEMENTS',
    SETTINGS = 'SETTINGS',
}

interface State {
    tabs: Tab[];
}

export default function OrganizationDetails(props: RouteProps) {
    const navigation = useNavigation();
    const { synchronized } = useSynchronizeOrgId(navigation.getOrganizationDetailsUrl);
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const selectedActiveTab = useSelector((state: ReduxState) => selectActiveTab(state));
    const dispatch = useDispatch();
    const [state, updateState] = useImmer<State>({
        tabs: [
            {
                type: 'component',
                Component: () =>
                    UserList({ userRole: UserRoles.provider, organizationId: selectedOrganization?.id }),
                active: selectedActiveTab === OrgAdminTabKeys.PROVIDERS,
                title: 'Providers',
                key: OrgAdminTabKeys.PROVIDERS,
            },
            {
                type: 'component',
                Component: () =>
                    UserList({ userRole: UserRoles.orgAdmin, organizationId: selectedOrganization?.id }),
                active: selectedActiveTab === OrgAdminTabKeys.ORG_ADMINS,
                title: 'Organization Admins',
                key: OrgAdminTabKeys.ORG_ADMINS,
            },
            {
                type: 'component',
                Component: OpioidAgreementList,
                active: selectedActiveTab === OrgAdminTabKeys.OPIOID_AGREEMENTS,
                title: 'Opioid Agreements',
                key: OrgAdminTabKeys.OPIOID_AGREEMENTS,
            },
            {
                type: 'component',
                Component: OrganizationSettings,
                active: selectedActiveTab === OrgAdminTabKeys.SETTINGS,
                title: 'Settings',
                key: OrgAdminTabKeys.SETTINGS,
            },
        ],
    });

    const loadingSelectedOrganization = useSelector((state: ReduxState) =>
        selectLoadingSelectedOrganization(state)
    );

    const switchTab = useCallback((tabKey: string) => {
        updateState(draft => void draft.tabs.map(tab => (tab.active = tab.key === tabKey)));
        dispatch({ type: SelectedOrganizationActions.SET_ACTIVE_TAB, payload: tabKey });
    }, []);

    return (
        <AuthRedirection requiredRoles={[UserRoles.orgAdmin]} synchronized={synchronized}>
            <PageContainer>
                <div className="py-3 text-left">
                    <h1 className="text-2xl text-blue-900 font-semibold">
                        Organization Admin - {selectedOrganization?.data.name}
                    </h1>
                </div>
                <Tabulation tabs={state.tabs} loading={loadingSelectedOrganization} switchTab={switchTab} />
            </PageContainer>
        </AuthRedirection>
    );
}
