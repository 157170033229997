import React, { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    className?: string;
}

export default function ErrorMessage(props: Props) {
    return <p className={`text-red-500 text-sm text-center ${props.className}`}>{props.children}</p>;
}
