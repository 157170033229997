import React, { useContext } from 'react';
import { AuthRoutes, NonAuthRoutes } from './routes';
import Login from './pages/Login';
import PatientsList from './pages/patients/patientList/PatientsList';
import { Router } from '@reach/router';
import PatientDetails from './pages/patients/PatientDetails';
import Unauthorized from './pages/Unauthorized';
import ResetPassword from './pages/ResetPassword';
import NavBar from './components/NavBar';
import ErrorBoundary from './components/ErrorBoundary';
import isAuthed from './hooks/isAuthed';
import AccountManagement from './pages/account/AccountManagement';
import OrganizationDetails from './pages/orgAdmin/OrganizationDetails';
import ProviderDetails from './pages/orgAdmin/tabs/providers/ProviderDetails';
import AddOpioidAgreement from './pages/orgAdmin/tabs/opioidAgreements/AddOpioidAgreement';
import AddPatient from './pages/patients/AddPatient';
import OpioidAgreementDetails from './pages/orgAdmin/tabs/opioidAgreements/OpioidAgreementDetails';
import OrganizationList from './pages/appAdmin/OrganizationList';
import OrganizationAdminList from './pages/appAdmin/OrganizationAdminList';
import OrganizationAdminDetails from './pages/orgAdmin/tabs/orgAdmins/OrganizationAdminDetails';
import AccountSetup from './pages/account/accountSetup/AccountSetup';
import OpioidAgreementRenewals from './pages/OpioidAgreementRenewals';
import RedirectPatient from './pages/RedirectPatient';
import NotFound from './pages/NotFound';
import ConfirmPasswordReset from './pages/ConfirmPasswordReset';
import Offline from './pages/Offline';
import { CurrentUserContext } from './context/CurrentUserContextProvider';

export default function App() {
    const currentUser = useContext(CurrentUserContext);
    isAuthed();
    return (
        <ErrorBoundary>
            {!!currentUser.claims && !currentUser.loading && <NavBar />}
            <Router>
                <RedirectPatient path={NonAuthRoutes.DOWNLOAD_MOBILE_APP} />
                <Login path={NonAuthRoutes.LOGIN} />
                <ConfirmPasswordReset path={NonAuthRoutes.CONFIRM_PASSWORD_RESET} />
                <AccountSetup path={NonAuthRoutes.ACCOUNT_SETUP} />
                <AccountManagement path={AuthRoutes.ACCOUNT} />
                <ResetPassword path={NonAuthRoutes.RESET_PASSWORD} />
                <OrganizationList path={AuthRoutes.ADMIN_ORGANIZATIONS} />
                <OrganizationAdminList path={AuthRoutes.ADMIN_ORGANIZATIONS_VIEW_ORGANIZATION} />
                <OrganizationAdminDetails path={AuthRoutes.ADMIN_ORGANIZATIONS_VIEW_ADMIN} />
                <OpioidAgreementRenewals path={AuthRoutes.OPIOID_AGREEMENT_RENEWALS} />
                <PatientsList path={AuthRoutes.PATIENTS} />
                <PatientDetails path={AuthRoutes.VIEW_PATIENT} />
                <AddPatient path={AuthRoutes.ADD_PATIENT} />
                <OrganizationDetails path={AuthRoutes.VIEW_ORGANIZATION} />
                <OrganizationAdminDetails path={AuthRoutes.VIEW_ORG_ADMIN} />
                <ProviderDetails path={AuthRoutes.VIEW_PROVIDER} />
                <AddOpioidAgreement path={AuthRoutes.ADD_OPIOID_AGREEMENT} />
                <OpioidAgreementDetails path={AuthRoutes.VIEW_OPIOID_AGREEMENT} />
                <Unauthorized path={NonAuthRoutes.UNAUTHORIZED} />
                <Offline path={NonAuthRoutes.OFFLINE} />
                <NotFound default />
            </Router>
        </ErrorBoundary>
    );
}
