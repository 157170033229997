import { Firestore } from '../FirestoreTypes';
import { ResourceDocument } from '../documents/ResourceDocument';
import { BaseModel } from './BaseModel';
import { ResourceSchema } from '../schemas/Resource';

export class ResourceModel extends BaseModel<ResourceSchema, ResourceDocument> {
    constructor(firestore: Firestore) {
        super(firestore, 'Resource', 'resources', ResourceDocument);
    }
}
