import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import { clearSelectedOrganization } from '../redux/selectedOrganization/selectedOrganizationActions';
import useNavigation from './useNavigation';
import AuthManager from '../AuthManager';
import { useIdleTimer } from 'react-idle-timer';
import { clearToastAlerts } from '../redux/currentSession/currentSessionActions';

export default function isAuthed() {
    const navigation = useNavigation();
    const dispatch = useDispatch();

    const handleOnIdle = async () => {
        console.log('user logged out for stale session');
        await AuthManager.logout();
        await Promise.all([dispatch(clearSelectedOrganization()), dispatch(clearToastAlerts())]);
        await navigate(navigation.loginUrl);
    };

    useIdleTimer({
        timeout: 1000 * 60 * 20,
        onIdle: handleOnIdle,
        eventsThrottle: 1000 * 60 * 5,
    });
}
