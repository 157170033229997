import React from 'react';
import PatientForm from '../../../../pages/patients/tabs/patient/PatientForm';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import { selectToastAlert } from '../../../../redux/currentSession/currentSessionSelectors';
import ToastAlert from '../../../../components/ToastAlert';
import PatientMonitoring from './patientMonitoring/PatientMonitoring';

export default function PatientTab() {
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    return (
        <div className="w-full flex flex-row p-2">
            {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
            <div className="rounded-md bg-white mx-4 border border-gray-300 mt-2 p-4 flex flex-col flex-1">
                <h4 className="text-xl font-semibold px-4 text-blue-900">Information</h4>
                <PatientForm addingPatient={false} />
            </div>
            <div className="rounded-md mx-4 bg-white border border-gray-300 mt-2 p-4 flex flex-col flex-1">
                <h4 className="text-xl font-semibold px-4 text-blue-900">Monitoring</h4>
                <PatientMonitoring />
            </div>
        </div>
    );
}
