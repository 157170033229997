import React, { useContext } from 'react';
import { Redirect } from '@reach/router';
import { NonAuthRoutes } from '../routes';
import { UserRoles } from '../database/schemas/User';
import { CurrentUserContext } from '../context/CurrentUserContextProvider';
import LoadingSpinner from './LoadingSpinner';

interface AuthRedirectProps {
    children: JSX.Element;
    requiredRoles: UserRoles[];
    synchronized: boolean;
}

export function AuthRedirection({ children, requiredRoles, synchronized }: AuthRedirectProps) {
    const currentUser = useContext(CurrentUserContext);
    const userIsPermitted = requiredRoles.find(
        requiredRole =>
            (currentUser.claims?.currentRoles && currentUser.claims?.currentRoles[requiredRole] !== false) ||
            (requiredRole === UserRoles.appAdmin && currentUser.claims?.isAppAdmin)
    );
    if (!synchronized) {
        return <LoadingSpinner type="page" />;
    } else if (!currentUser.claims) {
        return <Redirect to={NonAuthRoutes.LOGIN} />;
    } else if (userIsPermitted === undefined) {
        return <Redirect to={NonAuthRoutes.UNAUTHORIZED} />;
    } else {
        return <>{children}</>;
    }
}
