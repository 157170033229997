import React, { Component, ReactNode } from 'react';

class ErrorBoundary extends Component<{ children: ReactNode }, { error: Error | null }> {
    state = {
        error: null,
    };
    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(`React error info: ${errorInfo.componentStack}`);
    }

    render() {
        if (this.state.error) {
            return (
                <div className="w-full min-h-screen bg-red-200 pt-20 text-center">
                    <div className="border max-w-3xl border-red-500 shadow-md bg-white rounded-md p-5 m-auto">
                        <h3 className="text-xl font-semibold text-red-500">
                            🚫 There has been an uncaught error! 🚫
                        </h3>
                        <div className="text-left mt-3">
                            <ul>
                                <li className="font-semibold text-lg underline text-red-500">
                                    {((this.state.error as unknown) as Error).message}
                                </li>
                                <li>
                                    <code>{((this.state.error as unknown) as Error).stack}</code>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
