import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from './LoadingSpinner';
import { BlueVariants, buttonStyles, Theme } from '../theme';
import { ButtonClick } from '../types';

interface Props {
    loading?: boolean;
    children: string;
    className?: string;
    disabled?: boolean;
    onClick?: (e: ButtonClick) => void;
    type?: 'button' | 'submit';
    color?: BlueVariants;
}

export default function SubmitButton(props: Props) {
    const variant = props.color ?? Theme.darkBlue;
    const buttonClassNames = buttonStyles.classNames({
        variant,
        isDisabled: props.disabled,
        additionalClassNames: `relative ${props.className ?? ''}`,
    });
    return (
        <button
            type={props.type}
            className={buttonClassNames}
            onClick={props.onClick}
            disabled={!props.onClick && props.disabled}
        >
            <LoadingSpinner type="button" loading={props.loading ? 1 : 0} />
            <Text loading={props.loading ? 1 : 0}>{props.children}</Text>
        </button>
    );
}

const Text = styled.p<{ loading: number }>`
    font-weight: bold;
    transition: opacity 200ms;
    transition-delay: ${({ loading }) => (!!loading ? '0ms' : '200ms')};
    width: 100%;
    opacity: ${({ loading }) => (!!loading ? 0 : 1)};
`;
