import React, { RefObject, useContext, useEffect, useRef } from 'react';
import {
    BrowserStorageKeys,
    ButtonClick,
    FormSubmission,
    InputChange,
    ValidatablePassword,
    ValidatableString,
} from '../../types';
import { CurrentUserContext, MultiFactorAuthState } from '../../context/CurrentUserContextProvider';
import { useImmer } from 'use-immer';
import Modal from '../../components/Modal';
import SubmitButton from '../../components/SubmitButton';
import AuthManager, { AuthErrorCodes } from '../../AuthManager';
import { setToastAlert, setToastError } from '../../redux/currentSession/currentSessionActions';
import { useDispatch } from 'react-redux';
import TextInput from '../../components/TextInput';
import ActionButton from '../../components/ActionButton';
import { formatPhoneNumber, isValidEmail, isValidPhoneNumber, unformatPhoneNumber } from '../../utils';
import { ProviderDocument } from '../../database/documents/ProviderDocument';
import { UserFormKeys } from '../../database/schemas/User';
import MaskablePasswordInput from '../../components/MaskablePasswordInput';
import { navigate } from '@reach/router';
import useNavigation from '../../hooks/useNavigation';
import Encouragement from '../../components/Encouragement';
import useBrowserStorage from '../../hooks/useBrowserStorage';
import FunctionsManager from '../../functions/FunctionsManager';
import MFAVerificationForm from '../../components/MFAVerificationForm';
import ConfirmationModal from '../../components/ConfirmationModal';
import { updateUserInSelectedOrganization } from '../../redux/selectedOrganization/selectedOrganizationActions';

interface State {
    modalScenario?: ModalScenarios;
    encourageUserToSetContactNumber?: boolean;
    multiFactorAuth: MultiFactorAuthState;
    userIsChangingMFAAssociatedPhoneNumber?: boolean;
    form: {
        firstName: string;
        lastName: string;
        newEmail: ValidatableString;
        password: string;
        newPassword: ValidatablePassword;
        submissionError: unknown;
        submitting: boolean;
        phoneNumber?: ValidatableString;
    };
}

const initialState = ({
    firstName,
    lastName,
    phoneNumber,
    multiFactorAuthEnabled,
}: {
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    multiFactorAuthEnabled: boolean;
}): State => ({
    form: {
        firstName,
        lastName,
        newEmail: { value: '' },
        password: '',
        newPassword: { value: '' },
        submissionError: null,
        submitting: false,
        phoneNumber: { value: phoneNumber ?? '', isValid: phoneNumber ? true : undefined },
    },
    encourageUserToSetContactNumber: true,
    multiFactorAuth: {
        personalPhoneNumber: { value: '' },
        verificationCode: '',
        enabled: multiFactorAuthEnabled,
        enableMFARequest: { fetching: false, data: undefined },
    },
});

// Start with 1 to avoid falsy bug on switch statement for modal scenarios
enum ModalScenarios {
    CHANGING_EMAIL = 1,
    CHANGING_PASSWORD,
    REAUTHENTICATING_BEFORE_UPDATING_MFA,
    INPUTING_VERIFICATION_CODE,
    DISABLING_MFA,
    CHANGING_MFA_ASSOCIATED_PHONE_NUMBER,
}

const FormKeys = { ...UserFormKeys, newPassword: 'newPassword', newEmail: 'newEmail' };

export default function CurrentUserProfile() {
    const currentUser = useContext(CurrentUserContext);
    const { firstName, lastName, phoneNumber } = (() => {
        if (currentUser.document) {
            //cast as ProviderDocument to access phoneNumber
            const { firstName, lastName, phoneNumber } = (currentUser.document as ProviderDocument).data;
            return {
                firstName,
                lastName,
                phoneNumber: phoneNumber ? formatPhoneNumber(phoneNumber) : '',
            };
        }
        //fall back if current user is undefined for some reason
        return { firstName: '', lastName: '', phoneNumber: '' };
    })();
    const [state, updateState] = useImmer<State>(
        initialState({
            firstName,
            lastName,
            phoneNumber,
            multiFactorAuthEnabled: !!currentUser.multiFactorAuthEnabled,
        })
    );
    const validatableFields = [
        FormKeys.phoneNumber,
        FormKeys.newEmail,
        FormKeys.newPassword,
        FormKeys.personalPhoneNumber,
    ];
    const navigation = useNavigation();
    const encouragementToSetContactNumber = useBrowserStorage({
        type: 'session',
        key: BrowserStorageKeys.PROVIDER_HAS_DISMISSED_CONTACT_NUMBER_ENCOURAGEMENT,
    });

    useEffect(() => {
        updateState(draft => {
            draft.encourageUserToSetContactNumber =
                !phoneNumber && !encouragementToSetContactNumber.itemExistsInStorage;
        });
    }, [phoneNumber]);

    const toggleModal = (scenario?: ModalScenarios) => {
        updateState(draft => {
            draft.modalScenario = scenario;
            draft.form = initialState({
                firstName,
                lastName,
                phoneNumber,
                multiFactorAuthEnabled: !!currentUser.multiFactorAuthEnabled,
            }).form;
        });
    };
    const dispatch = useDispatch();

    const handleInput = (e: InputChange) => {
        e.persist();
        const isValidatableField = validatableFields.includes(e.target.name);
        updateState(draft => {
            draft.multiFactorAuth.submissionError = undefined;
            if (isValidatableField) {
                draft.form[e.target.name] = { isValid: undefined, value: e.target.value };
                e.target.name === FormKeys.newPassword &&
                    (draft.form[e.target.name].confirmedStrong = undefined);
            } else {
                draft.form[e.target.name] = e.target.value;
            }
        });
    };

    const changePassword = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => void (draft.form.submitting = true));
        try {
            let { newPassword, password } = state.form;
            newPassword.value = newPassword.value.trim();

            if (newPassword.value.length < 8) {
                return updateState(draft => void (draft.form.newPassword.isValid = false));
            }
            const passwordConfirmedStrong = await FunctionsManager.user.confirmStrongPassword(
                newPassword.value
            );
            if (passwordConfirmedStrong) {
                await AuthManager.changePassword({
                    email: currentUser.document!.data.email,
                    newPassword: newPassword.value,
                    password,
                });
                updateState(draft => void (draft.modalScenario = undefined));
                dispatch(setToastAlert('Your password has been updated'));
            } else {
                updateState(draft => void (draft.form.newPassword.confirmedStrong = false));
            }
        } catch (error) {
            console.log(error);
            dispatch(setToastError('An error occurred while attempting to update your information'));
        }
        updateState(draft => void (draft.form.submitting = false));
    };

    const changeEmail = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => void (draft.form.submitting = true));
        try {
            const { newEmail, password } = state.form;
            if (isValidEmail(state.form.newEmail.value)) {
                await AuthManager.changeEmail({
                    email: currentUser.document!.data.email,
                    password,
                    newEmail: newEmail.value,
                });
                await AuthManager.logout();
                await navigate(navigation.loginUrl);
                updateState(draft => void (draft.modalScenario = undefined));
                dispatch(setToastAlert("We've sent you a verification email to your new email address"));
            } else {
                updateState(draft => void (draft.form.newEmail.isValid = false));
            }
        } catch (error) {
            console.log(error);
            dispatch(setToastError('An error occurred while attempting to update your information'));
        }
        updateState(draft => void (draft.form.submitting = false));
    };

    const updateName = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        const { firstName, lastName } = state.form;
        if (
            currentUser.document?.data.firstName !== firstName ||
            currentUser.document.data.lastName !== lastName
        ) {
            try {
                currentUser.updateCurrentUserProp({ prop: 'firstName', value: firstName.trim() });
                currentUser.updateCurrentUserProp({ prop: 'lastName', value: lastName.trim() });
                // Must also update in Redux so that if you view the current user from the user list, it's updated
                dispatch(updateUserInSelectedOrganization(currentUser.document!));
                dispatch(setToastAlert('Your name has been successfully updated'));
            } catch (error) {
                console.log(error);
                dispatch(setToastError('An error occurred while attempting to update your name'));
            }
        }
    };

    const changePhoneNumber = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        //at this point, we can be certain that this current user is a provider or else this component would not be rendered
        const providerDocument = currentUser.document as ProviderDocument;
        if (!state.form.phoneNumber?.value || !isValidPhoneNumber(state.form.phoneNumber.value)) {
            return updateState(draft => void (draft.form.phoneNumber!.isValid = false));
        }
        try {
            const unformattedNumber = unformatPhoneNumber(state.form.phoneNumber.value);
            if (
                !providerDocument.data.phoneNumber ||
                unformattedNumber !== providerDocument.data.phoneNumber
            ) {
                currentUser.updateCurrentUserProp({ prop: 'phoneNumber', value: unformattedNumber.trim() });
                updateState(
                    draft =>
                        void (draft.form.phoneNumber!.value = formatPhoneNumber(
                            draft.form.phoneNumber!.value
                        )!)
                );
            }
            // Must also update in Redux so that if you view the current user from the user list, it's updated
            dispatch(updateUserInSelectedOrganization(providerDocument));
            dispatch(setToastAlert('Your phone number has been successfully updated'));
        } catch (error) {
            console.log(error);
            dispatch(setToastError('An error occurred while attempting to update your phone number'));
        }
    };

    const dismissContactNumberEncouragement = (e: ButtonClick) => {
        e.preventDefault();
        updateState(draft => void (draft.encourageUserToSetContactNumber = undefined));
        encouragementToSetContactNumber.setInStorage();
    };

    /*
    MULTIFACTOR AUTH LOGIC
     */
    const sendVerificationCodeToUserDevice = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        if (
            state.multiFactorAuth.personalPhoneNumber.value &&
            state.multiFactorAuth.personalPhoneNumber.isValid !== false
        ) {
            updateState(draft => void (draft.multiFactorAuth.submittingVerificationCode = true));
            //addPhoneNumberAsMFAMethod will be a callback function stored in state that will
            //take the verification code sent via SMs to user & ultimately enroll their phone
            //number as 2nd factor
            const togglePhoneNumberAsMFAMethod = await AuthManager.togglePhoneNumberAsMFA(
                state.multiFactorAuth.personalPhoneNumber.value
            ).catch(error => {
                console.log(`error sending verification code to user device: ${error}`);
            });
            updateState(draft => {
                draft.multiFactorAuth.awaitingCodeConfirmation = true;
                if (togglePhoneNumberAsMFAMethod) {
                    draft.multiFactorAuth.togglePhoneNumberAsMFAMethod = togglePhoneNumberAsMFAMethod;
                }
            });
            updateState(draft => void (draft.multiFactorAuth.submittingVerificationCode = false));
            //modal view will be switch to allow user to input verification code, which will
            //ultimately be passed to the addPhoneNumberAsMFAMethod callback stored in state
            toggleModal(ModalScenarios.INPUTING_VERIFICATION_CODE);
        }
    };

    //could probably be cleaned up
    const submitVerificationCodeToToggleMFA = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        try {
            if (state.multiFactorAuth.enabled && state.multiFactorAuth.resolveSignIn) {
                updateState(draft => {
                    draft.multiFactorAuth.enableMFARequest.fetching = true;
                    draft.multiFactorAuth.submittingVerificationCode = true;
                });
                await state.multiFactorAuth.resolveSignIn(state.multiFactorAuth.verificationCode);
                currentUser.setMultiFactorAuthEnabled(false);
                updateState(draft => {
                    draft.multiFactorAuth.enableMFARequest.fetching = true;
                    draft.multiFactorAuth.submittingVerificationCode = true;
                    draft.multiFactorAuth.verificationCode = '';
                    draft.multiFactorAuth.resolveSignIn = undefined;
                });
                toggleModal(
                    state.userIsChangingMFAAssociatedPhoneNumber
                        ? ModalScenarios.CHANGING_MFA_ASSOCIATED_PHONE_NUMBER
                        : ModalScenarios.DISABLING_MFA
                );
            } else if (state.multiFactorAuth.togglePhoneNumberAsMFAMethod) {
                updateState(draft => {
                    draft.multiFactorAuth.enableMFARequest.fetching = true;
                    draft.multiFactorAuth.submittingVerificationCode = true;
                });
                await state.multiFactorAuth.togglePhoneNumberAsMFAMethod(
                    state.multiFactorAuth.verificationCode
                );
                currentUser.setMultiFactorAuthEnabled(true);
                updateState(draft => {
                    draft.multiFactorAuth.enableMFARequest.fetching = true;
                    draft.multiFactorAuth.submittingVerificationCode = true;
                    draft.multiFactorAuth.verificationCode = '';
                    draft.multiFactorAuth.enabled = true;
                });
                toggleModal();
                dispatch(setToastAlert('Multifactor Authentication has been set up for your account'));
            }
        } catch (error) {
            console.log(`error submitting verification code: ${error}`);
            updateState(draft => {
                draft.multiFactorAuth.submissionError = error;
                draft.multiFactorAuth.submittingVerificationCode = false;
                draft.multiFactorAuth.enableMFARequest.fetching = false;
            });
        }
        updateState(draft => {
            draft.multiFactorAuth.submittingVerificationCode = false;
            draft.multiFactorAuth.enableMFARequest.fetching = false;
        });
    };

    //in order to make any changes to a user's MFA enrollments they must first be re-authenticated
    //and if they already have MFA enabled there will be an error caught during login which will be
    //handled by the AuthManager.login method that will return a callback function (resolveSignInForMFAUser)
    //to properly log the user in
    const reAuthenticateUser = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => void (draft.multiFactorAuth.reAuthenticating = true));
        try {
            const resolveSignInForMFAUser = await AuthManager.login({
                email: currentUser.document!.data.email.trim(),
                password: state.form.password.trim(),
            });
            if (resolveSignInForMFAUser) {
                updateState(draft => {
                    draft.multiFactorAuth.resolveSignIn = resolveSignInForMFAUser;
                    draft.multiFactorAuth.awaitingCodeConfirmation = true;
                });
            }
            toggleModal(ModalScenarios.INPUTING_VERIFICATION_CODE);
        } catch (error) {
            console.log(`error during login not relating to MFA: ${error}`);
            updateState(draft => {
                draft.multiFactorAuth.submissionError = error;
                if (error.code !== AuthErrorCodes.wrongPassword) {
                    dispatch(setToastError('An unknown error occurred while trying to re-authenticate you'));
                    toggleModal();
                }
            });
        }
        updateState(draft => void (draft.multiFactorAuth.reAuthenticating = false));
    };

    const disableMFA = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        try {
            await AuthManager.removePhoneNumberAsMultiFactorAuth();
            currentUser.setMultiFactorAuthEnabled(false);
            updateState(draft => {
                draft.multiFactorAuth.awaitingCodeConfirmation = false;
                draft.multiFactorAuth.verificationCode = '';
                draft.userIsChangingMFAAssociatedPhoneNumber = false;
                draft.multiFactorAuth.personalPhoneNumber = { value: '' };
                draft.multiFactorAuth.enabled = false;
            });
        } catch (error) {
            console.log(`error disabling MFA: ${error}`);
            dispatch(
                setToastError('An error occurred while updating your multifactor authentication settings')
            );
        }
    };

    const handleVerificationCodeInput = (e: InputChange) => {
        e.persist();
        if (e.target.value.length <= 6) {
            updateState(draft => {
                draft.multiFactorAuth.verificationCode = e.target.value;
                draft.multiFactorAuth.submissionError = undefined;
            });
        }
    };

    const handlePersonalPhoneNumberBlur = () => {
        updateState(draft => {
            draft.multiFactorAuth.personalPhoneNumber.showError =
                draft.multiFactorAuth.personalPhoneNumber.isValid === false;
        });
    };

    const handlePersonalPhoneNumberInput = (e: InputChange) => {
        e.persist();
        updateState(draft => {
            draft.multiFactorAuth.submissionError = undefined;
            draft.multiFactorAuth.personalPhoneNumber.showError = false;
            draft.multiFactorAuth.personalPhoneNumber.value = e.target.value;
            draft.multiFactorAuth.personalPhoneNumber.isValid = isValidPhoneNumber(
                unformatPhoneNumber(e.target.value)
            );
        });
    };

    const contactNumberInfo =
        'This will be the number your patients will be prompted to contact in the case of an emergency';

    return (
        <>
            <div className="p-3 my-2">
                <form className="flex flex-row justify-start items-end w-full lg:w-2/3" onSubmit={updateName}>
                    <div className="flex flex-col w-full">
                        <label className="w-full mb-2 block font-semibold" htmlFor={UserFormKeys.firstName}>
                            <span className="text-gray-700">First Name</span>
                            <TextInput
                                className="py-1"
                                name={UserFormKeys.firstName}
                                value={state.form.firstName}
                                onChange={handleInput}
                            />
                        </label>
                        <label className="w-full block font-semibold" htmlFor={UserFormKeys.lastName}>
                            <span className="text-gray-700">Last Name</span>
                            <TextInput
                                className="py-1"
                                name={UserFormKeys.lastName}
                                value={state.form.lastName}
                                onChange={handleInput}
                            />
                        </label>
                    </div>
                    <ActionButton
                        type="submit"
                        className="ml-2 mt-5 self-center"
                        disabled={
                            state.form.firstName === currentUser.document?.data.firstName &&
                            state.form.lastName === currentUser.document.data.lastName
                        }
                    >
                        Update
                    </ActionButton>
                </form>
                {currentUser.claims?.isProviderInCurrentOrganization() && (
                    <form
                        className="text-center flex flex-row justify-start items-start mt-3 lg:w-2/3 w-full"
                        onSubmit={changePhoneNumber}
                    >
                        <label
                            className="block flex flex-col justify-start items-start w-full"
                            htmlFor={FormKeys.phoneNumber}
                        >
                            <span className="font-semibold text-gray-700">Phone Number</span>
                            <div className="flex flex-col w-full items-start">
                                <TextInput
                                    value={state.form.phoneNumber!.value}
                                    name={FormKeys.phoneNumber}
                                    onChange={handleInput}
                                    className={`py-1 ${
                                        state.form.phoneNumber!.isValid === false
                                            ? 'border border-red-500'
                                            : state.encourageUserToSetContactNumber
                                            ? 'border border-blue-500'
                                            : ''
                                    }`}
                                />
                                {state.encourageUserToSetContactNumber ? (
                                    <Encouragement
                                        className="mt-3"
                                        dismiss={dismissContactNumberEncouragement}
                                        title="Set Contact Number"
                                        message={contactNumberInfo}
                                    />
                                ) : (
                                    <p
                                        className={`text-xs text-left text-${
                                            state.form.phoneNumber!.isValid === false ? 'red-500' : 'gray-600'
                                        } block ml-1 mt-1`}
                                    >
                                        {state.form.phoneNumber!.isValid === false
                                            ? 'Please enter a valid phone number'
                                            : contactNumberInfo}
                                    </p>
                                )}
                            </div>
                        </label>
                        <ActionButton
                            type="submit"
                            className="ml-2 mt-6"
                            disabled={
                                unformatPhoneNumber(state.form.phoneNumber!.value) ===
                                (currentUser.document as ProviderDocument)?.data.phoneNumber
                            }
                        >
                            Update
                        </ActionButton>
                    </form>
                )}
                <div className="flex flex-col justify-start items-start mt-3 py-3">
                    <ActionButton
                        className="w-full lg:w-1/3 mb-2"
                        onClick={() => toggleModal(ModalScenarios.CHANGING_PASSWORD)}
                    >
                        Change password
                    </ActionButton>
                    <ActionButton
                        className="w-full lg:w-1/3"
                        onClick={() => toggleModal(ModalScenarios.CHANGING_EMAIL)}
                    >
                        Change email
                    </ActionButton>
                </div>
                <div className="w-full flex flex-row justify-start items-center">
                    <div className="flex flex-col justify-center items-center lg:w-1/3 w-full">
                        <button
                            onClick={() => toggleModal(ModalScenarios.REAUTHENTICATING_BEFORE_UPDATING_MFA)}
                            className={`border ${
                                currentUser.multiFactorAuthEnabled
                                    ? 'border-red-500 hover:bg-red-500 text-red-500'
                                    : 'border-blue-500 hover:bg-blue-500 text-blue-500'
                            } w-full hover:text-white font-semibold rounded-md px-2 py-1`}
                        >
                            {currentUser.multiFactorAuthEnabled
                                ? 'Disable 2-factor Auth'
                                : 'Enable 2-factor Auth'}
                        </button>
                        {currentUser.multiFactorAuthEnabled && (
                            <button
                                className="text-xs mt-1 text-blue-500 hover:text-blue-600 flex flex-col justify-center"
                                onClick={() => {
                                    toggleModal(ModalScenarios.REAUTHENTICATING_BEFORE_UPDATING_MFA);
                                    updateState(draft => {
                                        draft.userIsChangingMFAAssociatedPhoneNumber = true;
                                    });
                                }}
                            >
                                Change associated phone number
                                <span className="text-gray-600 block mt-1">
                                    {AuthManager.currentUserPhoneNumberDisplayName}
                                </span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <CurrentUserProfileModal
                isOpen={!!state.modalScenario}
                toggleModal={toggleModal}
                changeEmail={changeEmail}
                changePassword={changePassword}
                changePhoneNumber={changePhoneNumber}
                sendVerificationCodeToUserDevice={sendVerificationCodeToUserDevice}
                handleInput={handleInput}
                handleVerificationCodeInput={handleVerificationCodeInput}
                multiFactorAuth={state.multiFactorAuth}
                form={state.form}
                modalScenario={state.modalScenario}
                handlePersonalPhoneNumberBlur={handlePersonalPhoneNumberBlur}
                handlePersonalPhoneNumberInput={handlePersonalPhoneNumberInput}
                submitVerificationCodeToToggleMFA={submitVerificationCodeToToggleMFA}
                disableMFA={disableMFA}
                reAuthenticateUser={reAuthenticateUser}
                userIsChangingMFAAssociatedPhoneNumber={state.userIsChangingMFAAssociatedPhoneNumber}
            />
        </>
    );
}

interface FormProps {
    submitting: boolean;
    handleInput: (e: InputChange) => void;
}

interface ChangeEmailFormProps extends FormProps {
    password: State['form']['password'];
    newEmail: State['form']['newEmail'];
}

function ChangeEmailForm(props: ChangeEmailFormProps): JSX.Element {
    return (
        <div className="py-4">
            <div className="mb-5">
                <label htmlFor={FormKeys.newEmail}>
                    <span className="block text-gray-700 text-sm font-semibold">New Email</span>
                    <TextInput
                        className="py-1"
                        type="email"
                        name={FormKeys.newEmail}
                        value={props.newEmail.value}
                        onChange={props.handleInput}
                    />
                </label>
                {props.newEmail.isValid === false && (
                    <p className="text-xs text-left block ml-1 mt-1 text-red-500">
                        Please enter a valid email address
                    </p>
                )}
            </div>
            <div className="mb-5">
                <label className="block mt-3" htmlFor={FormKeys.password}>
                    <span className="block text-gray-700 text-sm font-semibold">
                        Please confirm your password to authorize this change
                    </span>
                    <MaskablePasswordInput
                        name={FormKeys.password}
                        value={props.password}
                        onChange={props.handleInput}
                        type="selfControlled"
                    />
                </label>
                <p className="text-xs text-center block text-gray-600 mt-2">
                    You will be logged out after requesting verification. Please check your provided email to
                    verify the new email address before logging back in.
                </p>
            </div>
            <SubmitButton loading={props.submitting} className="w-full mt-3">
                Request Verification
            </SubmitButton>
        </div>
    );
}

interface ChangePasswordFormProps extends FormProps {
    password: State['form']['password'];
    newPassword: State['form']['newPassword'];
}

interface ChangePasswordState {
    passwordVisible: boolean;
    newPasswordVisible: boolean;
}

interface HandleToggleProps {
    e: InputChange;
    ref: RefObject<HTMLInputElement>;
    typeOfInput: 'password' | 'newPassword';
}

const initialChangePasswordState: ChangePasswordState = {
    passwordVisible: false,
    newPasswordVisible: false,
};

function ChangePasswordForm(props: ChangePasswordFormProps): JSX.Element {
    const [state, updateState] = useImmer<ChangePasswordState>(initialChangePasswordState);
    const passwordRef = useRef<HTMLInputElement>(null);
    const newPasswordRef = useRef<HTMLInputElement>(null);

    const handleToggle = ({ e, ref, typeOfInput }: HandleToggleProps) => {
        e.persist();
        if (!ref.current) return;
        ref.current.type = ref.current.type === 'password' ? 'text' : 'password';
        updateState(draft => {
            typeOfInput === 'password'
                ? (draft.passwordVisible = ref.current?.type === 'text')
                : (draft.newPasswordVisible = ref.current?.type === 'text');
        });
        ref.current.focus();
    };

    const invalidPasswordMessage = (() => {
        if (props.newPassword.isValid === false) {
            return 'Your password must be at least 8 characters';
        } else if (props.newPassword.confirmedStrong === false) {
            return 'The password chosen is too common. Please use another.';
        }
        return null;
    })();

    return (
        <div className="py-4">
            <div className="mb-5">
                <label className="block mb-2" htmlFor={FormKeys.password}>
                    <span className="text-gray-700 font-semibold">Current Password</span>
                    <MaskablePasswordInput
                        name={FormKeys.password}
                        value={props.password}
                        onChange={props.handleInput}
                        ref={passwordRef}
                        type="parentControlled"
                        handleToggle={(e: InputChange) =>
                            handleToggle({ e, ref: passwordRef, typeOfInput: 'password' })
                        }
                        visible={state.passwordVisible}
                        id={1}
                    />
                </label>
            </div>
            <div className="mb-5">
                <label className="block mb-2" htmlFor={FormKeys.newPassword}>
                    <span className="text-gray-700 font-semibold">New Password</span>
                    <MaskablePasswordInput
                        name={FormKeys.newPassword}
                        value={props.newPassword.value}
                        onChange={props.handleInput}
                        ref={newPasswordRef}
                        type="parentControlled"
                        handleToggle={(e: InputChange) =>
                            handleToggle({ e, ref: newPasswordRef, typeOfInput: 'newPassword' })
                        }
                        className={invalidPasswordMessage ? 'border border-red-500' : ''}
                        visible={state.newPasswordVisible}
                        id={2}
                    />
                </label>
                {invalidPasswordMessage && (
                    <p className="text-xs text-center w-full block ml-1 mt-1 text-red-500">
                        {invalidPasswordMessage}
                    </p>
                )}
            </div>
            <SubmitButton
                disabled={!!invalidPasswordMessage}
                loading={props.submitting}
                className="w-full mt-3"
            >
                Change Password
            </SubmitButton>
        </div>
    );
}

type AsyncForm = (e: FormSubmission) => Promise<void>;

interface CurrentUserProfileModalProps
    extends Pick<
        State,
        'modalScenario' | 'form' | 'multiFactorAuth' | 'userIsChangingMFAAssociatedPhoneNumber'
    > {
    isOpen: boolean;
    toggleModal: (modalScenario?: ModalScenarios) => void;
    changeEmail: AsyncForm;
    changePassword: AsyncForm;
    changePhoneNumber: AsyncForm;
    submitVerificationCodeToToggleMFA: AsyncForm;
    disableMFA: AsyncForm;
    sendVerificationCodeToUserDevice: AsyncForm;
    handleInput: (e: InputChange) => void;
    handleVerificationCodeInput: (e: InputChange) => void;
    handlePersonalPhoneNumberInput: (e: InputChange) => void;
    handlePersonalPhoneNumberBlur: () => void;
    reAuthenticateUser: AsyncForm;
}

function CurrentUserProfileModal(props: CurrentUserProfileModalProps) {
    const dispatch = useDispatch();
    switch (props.modalScenario) {
        case ModalScenarios.CHANGING_EMAIL:
            return (
                <Modal isOpen={props.isOpen} closeModal={() => props.toggleModal()}>
                    <form className="lg:mx-8" onSubmit={props.changeEmail}>
                        <ChangeEmailForm
                            handleInput={props.handleInput}
                            newEmail={props.form.newEmail}
                            password={props.form.password}
                            submitting={props.form.submitting}
                        />
                    </form>
                </Modal>
            );
        case ModalScenarios.CHANGING_PASSWORD:
            return (
                <Modal isOpen={props.isOpen} closeModal={() => props.toggleModal()}>
                    <form className="lg:mx-8" onSubmit={props.changePassword}>
                        <ChangePasswordForm
                            handleInput={props.handleInput}
                            password={props.form.password}
                            newPassword={props.form.newPassword}
                            submitting={props.form.submitting}
                        />
                    </form>
                </Modal>
            );
        case ModalScenarios.REAUTHENTICATING_BEFORE_UPDATING_MFA:
            return (
                <Modal isOpen={props.isOpen} closeModal={() => props.toggleModal()}>
                    <form className="lg:mx-8" onSubmit={props.reAuthenticateUser}>
                        <div className="mb-5">
                            <label className="block mb-2" htmlFor={FormKeys.password}>
                                <span className="text-gray-700 block font-normal mb-2">
                                    Please enter your password to authorize this change
                                </span>
                                <MaskablePasswordInput
                                    name={FormKeys.password}
                                    value={props.form.password}
                                    onChange={props.handleInput}
                                    type="selfControlled"
                                    className={
                                        props.multiFactorAuth.submissionError &&
                                        (props.multiFactorAuth.submissionError as { code: AuthErrorCodes })
                                            .code === AuthErrorCodes.wrongPassword
                                            ? 'border border-red-500'
                                            : ''
                                    }
                                />
                            </label>
                            {props.multiFactorAuth.submissionError && (
                                <p className="text-xs text-center w-full block ml-1 my-1 text-red-500">
                                    {AuthManager.translateError(props.multiFactorAuth.submissionError)}
                                </p>
                            )}
                        </div>
                        <SubmitButton
                            className={`w-full ${props.multiFactorAuth.submissionError ? '' : 'mt-8'}`}
                            loading={props.multiFactorAuth.reAuthenticating}
                            disabled={props.form.password.length < 8}
                        >
                            Re-authenticate
                        </SubmitButton>
                    </form>
                </Modal>
            );
        case ModalScenarios.INPUTING_VERIFICATION_CODE:
            return (
                <Modal isOpen={props.isOpen} closeModal={() => props.toggleModal()}>
                    <div className="lg:mx-8">
                        <MFAVerificationForm
                            sendVerificationCodeToUserDevice={props.sendVerificationCodeToUserDevice}
                            submitVerificationCodeToToggleMFA={props.submitVerificationCodeToToggleMFA}
                            handlePersonalPhoneNumberInput={props.handlePersonalPhoneNumberInput}
                            handleVerificationCodeInput={props.handleVerificationCodeInput}
                            handlePersonalPhoneNumberBlur={props.handlePersonalPhoneNumberBlur}
                            multiFactorAuth={props.multiFactorAuth}
                        />
                    </div>
                </Modal>
            );
        case ModalScenarios.DISABLING_MFA:
            return (
                <ConfirmationModal
                    onConfirm={async e => {
                        await props.disableMFA(e);
                        dispatch(
                            setToastAlert('Multifactor authentication has been disabled for your account')
                        );
                        props.toggleModal();
                    }}
                    isOpen={props.isOpen}
                    closeModal={() => props.toggleModal()}
                >
                    <p>Are you sure you want to disable multifactor authentication for your account?</p>
                </ConfirmationModal>
            );
        case ModalScenarios.CHANGING_MFA_ASSOCIATED_PHONE_NUMBER:
            return (
                <ConfirmationModal
                    onConfirm={async e => {
                        await props.disableMFA(e);
                        props.toggleModal(ModalScenarios.INPUTING_VERIFICATION_CODE);
                    }}
                    isOpen={props.isOpen}
                    closeModal={() => props.toggleModal()}
                >
                    <p className="text-left text-sm">
                        Are you sure you want to change the phone number associated with the multifactor
                        authentication for your account? If you confirm without adding a new number,
                        multifactor authentication will be disabled for your account.
                    </p>
                </ConfirmationModal>
            );
    }
    return null;
}
