import React from 'react';
import { Theme } from '../theme';

interface Props {
    onClick: () => void;
    remainingCount: number;
    increment?: number;
}

export default function LoadMoreButton({ increment = 10, ...props }: Props) {
    return (
        <button
            onClick={props.onClick}
            className={`font-semibold text-${Theme.lightBlue} border border-${Theme.lightBlue} hover:text-white rounded-md px-2 py-1 hover:bg-${Theme.lightBlue}`}
        >
            Load {props.remainingCount < 10 ? props.remainingCount : 10} More
        </button>
    );
}
