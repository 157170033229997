import React, { useContext, useEffect } from 'react';
import {
    BrowserStorageKeys,
    ButtonClick,
    FetchRequest,
    FormSubmission,
    InputChange,
    RouteProps,
    ValidatableString,
} from '../../../types';
import SplashPageContainer from '../../../components/SplashPageContainer';
import useURLQueryParams from '../../../hooks/useURLQueryParams';
import { useImmer } from 'use-immer';
import FunctionsManager from '../../../functions/FunctionsManager';
import useNavigation from '../../../hooks/useNavigation';
import { UserFormKeys, UserRoles } from '../../../database/schemas/User';
import DatabaseManager from '../../../database/DatabaseManager';
import AuthManager from '../../../AuthManager';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import { setPendingAccountCreationInfo } from '../../../redux/currentSession/currentSessionActions';
import { AccountCreationInfo } from '../../../redux/currentSession/currentSessionReducer';
import { GetEmailByInviteIdResponse } from '../../../functions/userTypes';
import { isValidPhoneNumber, unformatPhoneNumber } from '../../../utils';
import { UserDocument } from '../../../database/documents/UserDocument';
import { CurrentUserContext, MultiFactorAuthState } from '../../../context/CurrentUserContextProvider';
import SignUpForm, { SignUpFormProps } from './SignUpForm';
import MFAEncouragement, { MFAEncouragementProps } from './MFAEncouragement';
import MFASetup, { MFASetupProps } from './MFASetup';
import firebase from 'firebase';
import { AuthRedirection } from '../../../components/AuthRedirection';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Theme } from '../../../theme';
//@ts-ignore
import Logo from '../../../img/logo-white.png';

enum Pages {
    SIGN_UP_FORM,
    MFA_ENCOURAGEMENT,
    MFA_SETUP,
}

interface Page {
    key: number;
    active: boolean;
    Component: (props?: any) => JSX.Element;
    componentProps?: any;
}

export interface State {
    activePage: number;
    userInfo: FetchRequest<GetEmailByInviteIdResponse | undefined>;
    createAccountError: boolean;
    fetchEmailError: boolean;
    multiFactorAuth: MultiFactorAuthState;
    firebaseUser: firebase.User | null;
    form: {
        firstName: string;
        lastName: string;
        password: ValidatableString & { confirmedStrong?: boolean };
        phoneNumber?: ValidatableString;
        submitting: boolean;
        valid: boolean;
    };
}

const initialFormState: State['form'] = {
    firstName: '',
    lastName: '',
    password: { value: '' },
    phoneNumber: { value: '' },
    submitting: false,
    valid: false,
};

const initialState: State = {
    activePage: Pages.SIGN_UP_FORM,
    firebaseUser: null,
    createAccountError: false,
    fetchEmailError: false,
    multiFactorAuth: {
        enabled: false,
        verificationCode: '',
        enableMFARequest: { fetching: false, data: undefined },
        personalPhoneNumber: { value: '' },
    },
    userInfo: { fetching: true, data: undefined, error: null },
    form: initialFormState,
};

export default function AccountSetup(_: RouteProps) {
    const [state, updateState] = useImmer<State>(initialState);
    const { inviteId } = useURLQueryParams<{ inviteId: string }>();
    const navigation = useNavigation();
    const validatableFields = [
        UserFormKeys.password,
        UserFormKeys.phoneNumber,
        UserFormKeys.personalPhoneNumber,
    ];
    const dispatch = useDispatch();
    const currentUser = useContext(CurrentUserContext);

    useEffect(() => {
        (async () => {
            if (currentUser.document) {
                currentUser.setInAccountCreationFlow(false);
                sessionStorage.removeItem(BrowserStorageKeys.USER_IS_IN_ACCOUNT_CREATION_FLOW);
                return navigateUser(currentUser.document);
            } else {
                if (inviteId && !state.userInfo.data && !currentUser.inAccountCreationFlow) {
                    try {
                        const { email, isProvider } = await FunctionsManager.user.getEmailByInviteId({
                            inviteId,
                        });
                        updateState(draft => {
                            draft.userInfo.data = { email, isProvider };
                        });
                    } catch (error) {
                        console.log(error);
                        updateState(draft => void (draft.fetchEmailError = true));
                    }
                }
                updateState(draft => void (draft.userInfo.fetching = false));

                return () => {
                    sessionStorage.removeItem(BrowserStorageKeys.USER_IS_IN_ACCOUNT_CREATION_FLOW);
                };
            }
        })();
    }, []);

    useEffect(() => {
        updateState(draft => {
            draft.form.valid =
                !!state.form.firstName &&
                !!state.form.lastName &&
                !!state.form.password.value &&
                state.form.password.isValid !== false &&
                (!!state.form.phoneNumber?.value ? state.form.phoneNumber?.isValid !== false : true);
        });
    }, [
        state.form.firstName,
        state.form.lastName,
        state.form.password.value,
        state.form.phoneNumber?.value,
        state.multiFactorAuth.enabled,
    ]);

    const handleInput = (e: InputChange) => {
        e.persist();
        const key = e.target.name as UserFormKeys;
        const isValidatableField = validatableFields.includes(key);
        updateState(draft => {
            if (isValidatableField) {
                let isValid: boolean | undefined = undefined;
                if (key === UserFormKeys.phoneNumber) {
                    const phoneNumber = unformatPhoneNumber(e.target.value);
                    isValid = isValidPhoneNumber(phoneNumber);
                } else if (key === UserFormKeys.password) {
                    isValid = e.target.value.length >= 8;
                }
                draft.form[e.target.name] = {
                    showError: false,
                    value: e.target.value,
                    isValid,
                };
            } else {
                draft.form[e.target.name] = e.target.value;
            }
        });
    };

    const handlePasswordBlur = () => {
        updateState(draft => {
            draft.form.password.showError = draft.form.password.isValid === false;
        });
    };

    const handlePhoneNumberBlur = () => {
        updateState(draft => {
            if (draft.form.phoneNumber) {
                draft.form.phoneNumber.showError =
                    !!draft.form.phoneNumber.value && draft.form.phoneNumber.isValid === false;
            }
        });
    };

    const handlePersonalPhoneNumberInput = (e: InputChange) => {
        e.persist();
        updateState(draft => {
            draft.multiFactorAuth.personalPhoneNumber.showError = false;
            draft.multiFactorAuth.personalPhoneNumber.value = e.target.value;
            draft.multiFactorAuth.personalPhoneNumber.isValid = isValidPhoneNumber(
                unformatPhoneNumber(e.target.value)
            );
        });
    };

    const handlePersonalPhoneNumberBlur = () => {
        updateState(draft => {
            draft.multiFactorAuth.personalPhoneNumber.showError =
                draft.multiFactorAuth.personalPhoneNumber.isValid === false;
        });
    };

    const handleVerificationCodeInput = (e: InputChange) => {
        e.persist();
        if (e.target.value.length <= 6) {
            updateState(draft => {
                draft.multiFactorAuth.verificationCode = e.target.value;
                draft.multiFactorAuth.submissionError = undefined;
            });
        }
    };

    const sendVerificationCodeToUserDevice = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => void (draft.multiFactorAuth.submittingVerificationCode = true));
        //signInWithPhoneNumber will be a callback function stored in state that will
        //take the verification code sent via SMS to user & ultimately sign them in
        const addPhoneNumberAsMultiFactorAuth = await AuthManager.togglePhoneNumberAsMFA(
            state.multiFactorAuth.personalPhoneNumber!.value
        ).catch(err => {
            console.log('error verifyPhoneNumber', err);
        });
        updateState(draft => {
            draft.multiFactorAuth.awaitingCodeConfirmation = true;
            if (addPhoneNumberAsMultiFactorAuth) {
                draft.multiFactorAuth.togglePhoneNumberAsMFAMethod = addPhoneNumberAsMultiFactorAuth;
            }
        });
    };

    const submitVerificationCodeToToggleMFA = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        if (state.multiFactorAuth.togglePhoneNumberAsMFAMethod) {
            updateState(draft => void (draft.multiFactorAuth.enableMFARequest.fetching = true));
            try {
                await state.multiFactorAuth.togglePhoneNumberAsMFAMethod(
                    state.multiFactorAuth.verificationCode
                );
                updateState(draft => {
                    draft.multiFactorAuth.enableMFARequest.fetching = false;
                    draft.multiFactorAuth.enableMFARequest.data = 'success';
                });
            } catch (error) {
                console.log(error);
                updateState(draft => {
                    draft.multiFactorAuth.submissionError = error;
                    draft.multiFactorAuth.enableMFARequest.fetching = false;
                });
            }
        }
    };

    const createAccount = async (awaitingEmailVerification?: boolean): Promise<void> => {
        currentUser.setInAccountCreationFlow(true);
        const { userInfo } = state;
        updateState(draft => void (draft.form.submitting = true));

        const passwordConfirmedStrong = await FunctionsManager.user.confirmStrongPassword(
            state.form.password.value
        );

        if (!passwordConfirmedStrong) {
            updateState(draft => {
                draft.form.password.confirmedStrong = false;
                draft.form.submitting = false;
            });
        } else {
            await updateUserAccountInfo();
            const accountInfo = {
                email: userInfo.data!.email.trim(),
                password: state.form.password.value.trim(),
                awaitingEmailVerification,
            };
            await AuthManager.createUserAccount(accountInfo).catch(err => {
                console.error('error creating account', err);
                updateState(draft => {
                    draft.createAccountError = err;
                    draft.form.submitting = false;
                });
            });
        }
    };

    const skipMultiFactorAuthSetup = async (e: ButtonClick): Promise<void> => {
        e.preventDefault();
        const [user] = await DatabaseManager.UserModel.query({
            queries: [['email', '==', state.userInfo.data!.email]],
        }).catch(err => {
            console.error('error fetching user email', err);
            updateState(draft => void (draft.form.submitting = false));
        });
        await navigateUser(user);
    };

    const setUserOptsToEnableMultifactorAuth = (e: FormSubmission) => {
        e.preventDefault();
        updateState(draft => {
            draft.multiFactorAuth.enabled = true;
            draft.activePage = Pages.MFA_SETUP;
        });
    };

    const updateUserAccountInfo = async (): Promise<void> => {
        const { userInfo, form } = state;
        const userAccountInfo: AccountCreationInfo = {
            firstName: form.firstName,
            lastName: form.lastName,
        };

        if (userInfo.data?.isProvider) {
            userAccountInfo.phoneNumber = state.form.phoneNumber?.value;
        }
        dispatch(setPendingAccountCreationInfo(userAccountInfo));
    };

    const directUserAfterMFASetup = async (): Promise<void> => {
        const [user] = await DatabaseManager.UserModel.query({
            queries: [['email', '==', state.userInfo.data!.email]],
        }).catch(err => {
            console.error('error fetching user email', err);
            updateState(draft => void (draft.form.submitting = false));
        });
        await navigateUser(user);
        currentUser.setMultiFactorAuthEnabled(true);
    };

    const navigateUser = async (user: UserDocument): Promise<void> => {
        setTimeout(async () => {
            if (user.access.isAppAdmin()) {
                await navigate(navigation.adminOrganizationsUrl);
            } else {
                const organizationId = Object.keys(user.data.organizations)[0];
                user.access.isProviderIn(organizationId)
                    ? await navigate(navigation.getPatientsUrl(organizationId))
                    : await navigate(navigation.getOrganizationDetailsUrl(organizationId));
            }
            currentUser.setInAccountCreationFlow(false);
            updateState(draft => void (draft.form.submitting = false));
        }, 1200);
    };

    const createAccountAndContinueToMFAEncouragement = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => void (draft.form.submitting = true));
        const passwordConfirmedStrong = await FunctionsManager.user.confirmStrongPassword(
            state.form.password.value
        );

        if (!passwordConfirmedStrong) {
            updateState(draft => {
                draft.form.password.confirmedStrong = false;
                draft.form.submitting = false;
            });
        } else {
            setActivePage(Pages.MFA_ENCOURAGEMENT);
            await createAccount(true);
        }
    };

    const pages: Page[] = [
        {
            key: Pages.SIGN_UP_FORM,
            active: state.activePage === Pages.SIGN_UP_FORM,
            Component: SignUpForm,
            componentProps: {
                form: state.form,
                createAccountError: state.createAccountError,
                fetchEmailError: state.fetchEmailError,
                isProvider: state.userInfo.data?.isProvider,
                userEmail: state.userInfo.data?.email,
                handleInput,
                handlePasswordBlur,
                handlePhoneNumberBlur,
                createAccountAndContinueToMFAEncouragement,
            } as SignUpFormProps,
        },
        {
            key: Pages.MFA_ENCOURAGEMENT,
            active: state.activePage === Pages.MFA_ENCOURAGEMENT,
            Component: MFAEncouragement,
            componentProps: {
                skipMultiFactorAuthSetup,
                setUserOptsToEnableMultifactorAuth,
            } as MFAEncouragementProps,
        },
        {
            key: Pages.MFA_SETUP,
            active: state.activePage === Pages.MFA_SETUP,
            Component: MFASetup,
            componentProps: {
                multiFactorAuth: state.multiFactorAuth,
                personalPhoneNumber: state.multiFactorAuth.personalPhoneNumber,
                handlePersonalPhoneNumberInput,
                handlePersonalPhoneNumberBlur,
                sendVerificationCodeToUserDevice,
                submitVerificationCodeToToggleMFA,
                handleVerificationCodeInput,
                directUserAfterMFASetup,
            } as MFASetupProps,
        },
    ];

    const setActivePage = (pageKey: Pages) => {
        updateState(draft => void (draft.activePage = pageKey));
    };

    return (
        <SplashPageContainer>
            <div className="mx-auto text-center">
                <img src={Logo} alt="Symmetry logo" height={200} className="mx-auto mb-4" />
            </div>
            <div className={`bg-${Theme.softGray} shadow-lg border rounded px-8 pt-6 pb-8 mb-4`}>
                <h2 className={`text-${Theme.darkBlue} text-xl mb-2 text-center font-semibold`}>
                    Account Setup
                </h2>
                <ErrorMessage
                    createAccountError={state.createAccountError}
                    fetchEmailError={state.fetchEmailError}
                />
                {state.userInfo.fetching ? <LoadingSpinner type="page" /> : <ActivePage pages={pages} />}
            </div>
        </SplashPageContainer>
    );
}

function ActivePage({ pages }: { pages: Page[] }): JSX.Element {
    const activePage = pages.find(({ active }) => active)!;
    return <activePage.Component {...(activePage?.componentProps ?? {})} />;
}

type ErrorMessageProps = Pick<State, 'createAccountError' | 'fetchEmailError'>;

function ErrorMessage(props: ErrorMessageProps): JSX.Element | null {
    const classNames = 'text-red-500 text-sm text-center';
    if (props.createAccountError) {
        return (
            <p className={classNames}>
                An error occurred while attempting to create your account. Please check your internet
                connection & try again.
            </p>
        );
    } else if (props.fetchEmailError) {
        return (
            <p className={classNames}>
                An error occurred while attempting to gather your email. Please ensure the invite is still
                valid.
            </p>
        );
    }
    return null;
}
