export enum ErrorType {
    validation,
    viewContext,
    typeError,
    misc,
    patientQuery,
}

export default class ErrorManager extends Error {
    type: ErrorType | undefined;
    constructor(message: string, type: ErrorType) {
        super(message);
        this.message = message;
        this.type = type;
    }

    log() {
        console.error({
            message: this.message,
            type: this.type,
        });
    }
}
