import React from 'react';
import Asterisk from '../../../../components/Asterisk';
import TextInput from '../../../../components/TextInput';
import TextareaAutosize from 'react-textarea-autosize';
import { FormSubmission } from '../../../../types';
import SubmitButton from '../../../../components/SubmitButton';
import { FormKeys } from './AddOpioidAgreement';
import { FormState, OpioidAgreementInputChange } from './opioidAgreementTypes';
import ActionButton from '../../../../components/ActionButton';
import dateFNSFormat from 'date-fns/format';
import { Timestamp } from '../../../../database/FirestoreTypes';
import { Theme } from '../../../../theme';

type CommonProps = {
    handleSubmit: (e: FormSubmission) => void;
    handleInput: (e: OpioidAgreementInputChange) => void;
    form: FormState;
};

type Props =
    | (CommonProps & {
          scenario: 'viewing';
          isArchived?: boolean;
          timeStamp: Timestamp;
          updateTitle: () => void;
      })
    | (CommonProps & { scenario: 'adding' });

export default function OpioidAgreementForm(props: Props) {
    return (
        <form className="y-3" onSubmit={props.handleSubmit}>
            <div className="w-full mt-2 mb-3 flex flex-row justify-start items-end w-full md:w-2/3">
                <label className="w-full md:w-3/5 block font-semibold" htmlFor={FormKeys.title}>
                    <span className="text-gray-700">Title</span>
                    {props.scenario === 'adding' && <Asterisk />}
                    <TextInput
                        className="py-1"
                        name={FormKeys.title}
                        value={props.form.title}
                        onChange={props.handleInput}
                        inValid={props.form.isValid === false && !props.form.title}
                    />
                </label>
                {props.scenario === 'viewing' && !props.isArchived && (
                    <ActionButton onClick={props.updateTitle} className="ml-2 w-1/2 md:w-1/5">
                        Update
                    </ActionButton>
                )}
            </div>
            <div className="w-full mt-2 mb-3 flex flex-row justify-start items-end w-full">
                <label className="w-full md:w-3/5 block font-semibold" htmlFor={FormKeys.text}>
                    <span className="text-gray-700">Agreement Text</span>
                    {props.scenario === 'adding' && <Asterisk />}
                    <TextareaAutosize
                        type="text"
                        className={`form-input bg-white mt-1 block w-full outline-none rounded ${
                            props.form.isValid === false && !props.form.text
                                ? props.scenario === 'adding'
                                    ? 'border border-red-500'
                                    : 'border-none'
                                : props.scenario === 'adding'
                                ? 'border border-gray-400'
                                : 'border-none'
                        }`}
                        disabled={props.scenario === 'viewing'}
                        name={FormKeys.text}
                        onChange={props.handleInput}
                        minRows={6}
                        value={props.form.text}
                        maxRows={18}
                    />
                </label>
            </div>
            {props.scenario === 'viewing' ? (
                <div className="flex flex-row justify-between w-full md:w-3/5">
                    <p className="text-sm text-gray-600 block">
                        {props.isArchived ? 'Archived' : 'Created'}
                        <span className="ml-2 font-semibold">
                            {dateFNSFormat(props.timeStamp.toDate(), 'MM/dd/yyyy')}
                        </span>
                    </p>
                    <SubmitButton loading={props.form.submitting} color={Theme.lightBlue}>
                        {props.isArchived ? 'Unarchive' : 'Archive'}
                    </SubmitButton>
                </div>
            ) : (
                <SubmitButton className="mt-2 w-1/2 md:w-1/5" loading={props.form.submitting}>
                    Create Agreement
                </SubmitButton>
            )}
        </form>
    );
}
