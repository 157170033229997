import { AlertDocument } from '../../database/documents/AlertDocument';
import { ReduxState } from '../store';
import { SelectedPatientState, UpdatedPatientFields } from './selectedPatientReducer';
import { diffObjects } from '../../utils';

//will select properties from the diff of the selected patient

export function selectSelectedPatientDocument(state: ReduxState): SelectedPatientState['document'] {
    return state.selectedPatient.diff.document ?? null;
}

export function selectSelectedProviderAddedPrescriptions(
    state: ReduxState
): SelectedPatientState['prescriptions'] {
    return state.selectedPatient.diff.prescriptions;
}

export function selectSelectedProviderAddedPrescriptionsArchived(
    state: ReduxState
): SelectedPatientState['archivedPrescriptions'] {
    return state.selectedPatient.diff.archivedPrescriptions;
}

export function selectPatientAddedMedications(state: ReduxState): SelectedPatientState['medications'] {
    return state.selectedPatient.diff.medications;
}

export function selectSelectedPatientForm(state: ReduxState): SelectedPatientState['form'] {
    return state.selectedPatient.diff.form;
}

export function selectSelectedPatientUpdates(state: ReduxState): UpdatedPatientFields {
    const { loading, ...original } = state.selectedPatient.original;
    return diffObjects<SelectedPatientState, UpdatedPatientFields>(original, state.selectedPatient.diff);
}

export function selectUpdatedSelectedPatient(state: ReduxState): SelectedPatientState {
    return state.selectedPatient.diff;
}

export function selectSelectedPatientEntries(state: ReduxState): SelectedPatientState['entries'] {
    return state.selectedPatient.diff.entries;
}

export function selectSelectedPatientActiveAlerts(state: ReduxState): AlertDocument[] {
    return state.selectedPatient.diff.alerts.active;
}

export function selectSelectedPatientResolvedAlerts(state: ReduxState): AlertDocument[] {
    return state.selectedPatient.diff.alerts.resolved;
}

export function selectLoadingSelectedPatient(state: ReduxState): boolean {
    return state.selectedPatient.original.loading;
}
