import { getTimestamp } from '../../utils';
import { DocumentSnapshot } from '../FirestoreTypes';
import { AlertTier, AlertType, DefaultAlertSchema, MedicationAlertSchema } from '../schemas/Patient';
import { BaseDocument } from './BaseDocument';
import { PatientDocument } from './PatientDocument';

export class AlertDocument extends BaseDocument<DefaultAlertSchema | MedicationAlertSchema> {
    get patientId(): string {
        return this.ref.parent.parent!.id;
    }

    constructor(snapshot: DocumentSnapshot<DefaultAlertSchema | MedicationAlertSchema>) {
        super(snapshot, 'Alert');
    }

    async getAssignedPrescriber() {
        return (await this.getPatient()).data.assignedPrescriber;
    }

    getPatient(): Promise<PatientDocument> {
        return this.db.PatientModel.get(this.patientId);
    }

    isActive(): boolean {
        return this.data.acknowledgedAt === null;
    }

    isTierThree(): boolean {
        return this.data.tier === AlertTier.tierThree;
    }

    isDefaultAlert(): boolean {
        return this.data.type == AlertType.default;
    }

    isMedicationAlert(): boolean {
        return this.data.type == AlertType.medication;
    }

    isForMedication(medicationId: string): boolean {
        const data = { ...this.data };
        return data.type == AlertType.medication && data.details.medicationId == medicationId;
    }

    async toggleAcknowledged(): Promise<void> {
        try {
            this.data.acknowledgedAt = this.data.acknowledgedAt ? null : getTimestamp();
            await this.save();
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'AlertDocument.toggleAcknowledged()',
                error,
                path: this.path,
                patientId: this.patientId,
            });
            throw error;
        }
    }
}
