import { firestore } from 'firebase';
import { DocumentSnapshot } from '../FirestoreTypes';
import { BaseDocument } from './BaseDocument';
import { OpioidAgreementSchema } from '../schemas/Organization';
import { UserOpioidAgreement } from '../schemas/Patient';
import { addYears, format as dateFnsFormat } from 'date-fns';

export class OpioidAgreementDocument extends BaseDocument<OpioidAgreementSchema> {
    constructor(snapshot: DocumentSnapshot<OpioidAgreementSchema>) {
        super(snapshot, 'Opioid Agreement');
    }

    toggleArchivedStatus(): Promise<OpioidAgreementDocument> {
        return this.update({ archivedAt: this.data.archivedAt ? null : firestore.Timestamp.now() });
    }

    updateUserAssignedAgreement(
        currentOpioidAgreement: UserOpioidAgreement | undefined
    ): UserOpioidAgreement {
        if (!currentOpioidAgreement) {
            return {
                assignedOpioidAgreementId: this.id === undefined ? null : this.id,
                currentOpioidAgreementId: null,
                expirationDate: dateFnsFormat(addYears(new Date(), 1), 'y-MM-dd'),
                pdf: null,
            };
        } else {
            // we know these fields exist due to the way the updates are dispatched
            return {
                assignedOpioidAgreementId: this.id === undefined ? null : this.id,
                currentOpioidAgreementId: currentOpioidAgreement.currentOpioidAgreementId!,
                expirationDate: currentOpioidAgreement.expirationDate,
                pdf: currentOpioidAgreement.pdf!,
            };
        }
    }
}
