import React, { useEffect, useState } from 'react';
import IconManager, { IconType } from '../../../../components/IconManager';
import { UserMedicationDocument } from '../../../../database/documents/UserMedicationDocument';
import {
    diffObjects,
    generateId,
    getTimestamp,
    logDistinctiveMessage,
    scrollPageTo,
} from '../../../../utils';
import { useImmer } from 'use-immer';
import { LocalUserMedicationSchema } from '../../../../redux/selectedPatient/selectedPatientReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import {
    selectPatientAddedMedications,
    selectSelectedPatientDocument,
    selectSelectedProviderAddedPrescriptions,
    selectSelectedProviderAddedPrescriptionsArchived,
} from '../../../../redux/selectedPatient/selectedPatientSelectors';
import * as reduxActions from '../../../../redux/selectedPatient/selectedPatientActions';
import {
    removePatientAddedMedicationForSelectedPatient,
    removePendingPrescriptionForSelectedPatient,
    removeProviderAddedPrescriptionForSelectedPatient,
    updatePendingPrescriptionForSelectedPatient,
    updateProviderAddedPrescriptionForSelectedPatient,
} from '../../../../redux/selectedPatient/selectedPatientActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { selectToastAlert } from '../../../../redux/currentSession/currentSessionSelectors';
import ToastAlert from '../../../../components/ToastAlert';
import { setToastAlert } from '../../../../redux/currentSession/currentSessionActions';
import ActionButton from '../../../../components/ActionButton';
import ToolTip from '../../../../components/ToolTip';
import PrescriptionForm from './components/PrescriptionForm';
import MedicationForm from './components/MedicationForm';
import { InputChange } from '../../../../types';
import { MedicationFormKeys } from './medicationTypes';
import { Draft } from 'immer';
import set from 'lodash.set';
import { pillsPerDoseValidator } from './medicationFormValidation';
import PendingPrescriptionForm from './components/PendingPrescriptionForm';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { DeliveryType, OpioidType } from '../../../../database/schemas/Medication';
import { getMeasure } from './medicationFormLabels';
import { BasePrescription } from '../../../../database/schemas/Patient';
import ArchivedPrescriptionRow from './components/ArchivedPrescriptionRow';
import TextInput from '../../../../components/TextInput';

export interface State {
    providerAddedPrescriptions: UserMedicationDocument[];
    patientAddedMedications: UserMedicationDocument[];
    providerAddedArchivedPrescriptions: UserMedicationDocument[];
    pendingPrescriptions: LocalUserMedicationSchema[]; //id is purely local
    isAddingPrescription: boolean;
    loading: boolean;
    addingPrescriptionDisabledToolTipVisible: boolean;
    isArchivingPrescriptionOrMedication: boolean;
    archivePrescriptionOrMedication?: (archivedReason: string) => void; //this will be a handler set in state when user clicks "Archive" for each list item
    medicationOrPrescriptionNameBeingArchived?: { name: string; type: 'medication' | 'prescription' }; //will only be used to render removal modal

    //tool tip state
    providerAddedPrescriptionIdBeingUpdated?: string;
    pendingPrescriptionIdBeingUpdated?: string;
    updatingProviderAddedPrescriptionDisabledToolTipVisible: boolean;
    updatingPendingPrescriptionDisabledToolTipVisible: boolean;
}

const initialState: State = {
    providerAddedPrescriptions: [],
    patientAddedMedications: [],
    providerAddedArchivedPrescriptions: [],
    pendingPrescriptions: [],
    isAddingPrescription: false,
    loading: true,
    addingPrescriptionDisabledToolTipVisible: false,
    updatingProviderAddedPrescriptionDisabledToolTipVisible: false,
    updatingPendingPrescriptionDisabledToolTipVisible: false,
    isArchivingPrescriptionOrMedication: false,
};

const initialPendingPrescriptionFormState = {
    archived: false,
    name: '',
};

export default function MedicationTab() {
    const [state, updateState] = useImmer<State>(initialState);
    const [archivedReason, setArchivedReason] = useState<string>('');
    const selectedPatient = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const selectedPatientPrescriptions = useSelector((state: ReduxState) =>
        selectSelectedProviderAddedPrescriptions(state)
    );
    const selectedPatientMedications = useSelector((state: ReduxState) =>
        selectPatientAddedMedications(state)
    );
    const selectedPatientArchivedPrescriptions = useSelector((state: ReduxState) =>
        selectSelectedProviderAddedPrescriptionsArchived(state)
    );
    const dispatch = useDispatch();
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));

    useEffect(() => {
        (async () => {
            try {
                //On mount we want to merge the patient prescriptions & medications with local state for manipulation.
                if (selectedPatientPrescriptions) {
                    updateState(draft => {
                        draft.providerAddedPrescriptions = selectedPatientPrescriptions.providerAdded;
                        draft.patientAddedMedications = selectedPatientMedications;
                        draft.providerAddedArchivedPrescriptions = selectedPatientArchivedPrescriptions;
                    });
                }
            } catch (error) {
                console.log(`Error with DatabaseManager.MedicationModel.getAll(): ${error}`);
            }
            updateState(draft => void (draft.loading = false));
        })();
    }, [
        selectedPatientPrescriptions.providerAdded,
        selectedPatientMedications,
        selectedPatientArchivedPrescriptions,
    ]);

    useEffect(() => {
        //we want to listen for changes for the pending prescriptions separately as a means of not overriding pending
        //prescriptions when a user either archives or updates a previously added prescription
        updateState(draft => {
            draft.pendingPrescriptions = selectedPatientPrescriptions.pending;
        });
    }, [selectedPatientPrescriptions.pending]);

    const setAddingNewPrescription = () => {
        //if the user tries to click the Add Prescription button while already adding one
        //display a tool tip to encourage them to finish the pending one before adding another
        if (state.isAddingPrescription || state.providerAddedPrescriptionIdBeingUpdated) {
            updateState(draft => {
                draft.addingPrescriptionDisabledToolTipVisible = true;
            });
            setTimeout(() => {
                updateState(draft => {
                    draft.addingPrescriptionDisabledToolTipVisible = false;
                });
            }, 2000);
        } else {
            const initialMedicationState = {
                id: generateId(), //generate local id for tracking -- will be removed when saved
                ...initialPendingPrescriptionFormState,
            };
            updateState(draft => {
                draft.isAddingPrescription = true;
                draft.pendingPrescriptions.push(initialMedicationState as LocalUserMedicationSchema);
            });
            // scrollPageTo('bottom');
        }
    };

    const toggleIsArchivingPrescriptionOrMedication = () => {
        updateState(draft => {
            draft.isArchivingPrescriptionOrMedication = !draft.isArchivingPrescriptionOrMedication;
            //if we're closing the modal, make sure the archive handler is cleared
            if (draft.isArchivingPrescriptionOrMedication === false) {
                draft.archivePrescriptionOrMedication = undefined;
                draft.medicationOrPrescriptionNameBeingArchived = undefined;
            }
        });
    };

    const ProviderAddedPrescriptionLogic = {
        updateInRedux: (prescription: UserMedicationDocument) => {
            dispatch(updateProviderAddedPrescriptionForSelectedPatient(prescription));
            updateState(draft => void (draft.providerAddedPrescriptionIdBeingUpdated = undefined));
        },

        handleInput(e: InputChange, prescriptionId: string) {
            e.persist();
            const key = e.target.name as MedicationFormKeys;
            //we want the localPrescription to be a draft so we're able to mutate it; otherwise we could just do one
            //state.find() => call at the top
            const getLocalPrescription = (draft: Draft<Pick<State, 'providerAddedPrescriptions'>>) => {
                return draft.providerAddedPrescriptions.find(
                    ({ id }) => id === prescriptionId
                ) as UserMedicationDocument;
            };

            //if there's already a prescription being updated, show a tool tip encouraging the user to finish updating
            //that prescription before attempting to update another one
            if (
                state.providerAddedPrescriptionIdBeingUpdated &&
                state.providerAddedPrescriptionIdBeingUpdated !== prescriptionId
            ) {
                updateState(draft => {
                    draft.updatingProviderAddedPrescriptionDisabledToolTipVisible = true;
                });
                setTimeout(() => {
                    updateState(draft => {
                        draft.updatingProviderAddedPrescriptionDisabledToolTipVisible = false;
                    });
                }, 2000);
            } else {
                //this input handler will operate under the assumption that prescription is not null because it's pre-existing.
                //the conditional logic to determine the prescriptionIdBeingUpdated might seem repetitive, but immer doesn't like
                //exposing its draft values outside of the scope in which they're defined
                if (key === MedicationFormKeys.dose) {
                    return updateState(draft => {
                        const localPrescription = getLocalPrescription(draft);
                        if (localPrescription) {
                            localPrescription.data.prescription!.dose = pillsPerDoseValidator.convertToMinMax(
                                e.target.value
                            );
                            draft.providerAddedPrescriptionIdBeingUpdated = this.determinePrescriptionIdBeingEdited(
                                localPrescription
                            );
                        }
                    });
                } else {
                    updateState(draft => {
                        const localPrescription = getLocalPrescription(draft);
                        if (localPrescription) {
                            set(localPrescription.data, key, Number(e.target.value));
                            draft.providerAddedPrescriptionIdBeingUpdated = this.determinePrescriptionIdBeingEdited(
                                localPrescription
                            );
                        }
                    });
                }
            }
        },
        async archiveAndRemoveFromRedux({
            prescription,
            archivedReason,
        }: {
            prescription: UserMedicationDocument;
            archivedReason: string;
        }) {
            let updatedPrescription: UserMedicationDocument | null;
            if (archivedReason) {
                updatedPrescription = await prescription.update({
                    archived: true,
                    archivedReason,
                    archivedAt: getTimestamp(new Date()),
                });
            } else {
                updatedPrescription = await prescription.update({
                    archived: true,
                    archivedAt: getTimestamp(new Date()),
                });
            }
            dispatch(removeProviderAddedPrescriptionForSelectedPatient(updatedPrescription));
            dispatch(setToastAlert('Changes saved to patient'));
            updateState(draft => {
                draft.medicationOrPrescriptionNameBeingArchived = undefined;
                draft.isArchivingPrescriptionOrMedication = false;
            });
        },
        cancelUpdatingInRedux(prescription: UserMedicationDocument) {
            const localPrescriptionIndex = state.providerAddedPrescriptions.findIndex(
                ({ id }) => id === prescription.id
            );
            const reduxPrescription = selectedPatientPrescriptions.providerAdded.find(
                ({ id }) => id === prescription.id
            );
            if (localPrescriptionIndex !== -1 && reduxPrescription) {
                updateState(draft => {
                    draft.providerAddedPrescriptions[localPrescriptionIndex] = reduxPrescription;
                });
            } else {
                logDistinctiveMessage(
                    `Unable to find provider added prescription with id ${prescription.id} in cancelUpdatingProviderAddedPrescription()`
                );
            }
        },
        isPrescriptionBeingEdited(prescription: UserMedicationDocument): boolean {
            const reduxPrescription = selectedPatientPrescriptions.providerAdded.find(
                ({ id }) => id === prescription.id
            );
            if (!reduxPrescription) {
                return false;
            }
            return !!(diffObjects(reduxPrescription, prescription) as string[]).length;
        },
        determinePrescriptionIdBeingEdited(prescription: UserMedicationDocument): string | undefined {
            if (this.isPrescriptionBeingEdited(prescription)) {
                return prescription.id;
            }
        },
        toggleArchiveAndRemovalModal(prescription: UserMedicationDocument) {
            toggleIsArchivingPrescriptionOrMedication();
            updateState(draft => {
                draft.medicationOrPrescriptionNameBeingArchived = {
                    name: prescription.data.name,
                    type: 'prescription',
                };
                draft.archivePrescriptionOrMedication = async (archivedReason: string) => {
                    await this.archiveAndRemoveFromRedux({ prescription, archivedReason });
                };
            });
        },
    };

    const PendingPrescriptionLogic = {
        alreadyExistsInRedux(prescriptionId: string): boolean {
            const foundInPending = !!selectedPatientPrescriptions.pending.find(
                ({ id }) => id === prescriptionId
            );
            const wasJustAddedToProviderAdded = !!selectedPatientPrescriptions.providerAdded.find(
                ({ id }) => id === prescriptionId
            );
            return foundInPending || wasJustAddedToProviderAdded;
        },
        handleInput(e: InputChange, prescriptionId: string) {
            e.persist();
            const key = e.target.name as MedicationFormKeys;
            const getLocalPrescription = (draft: Draft<Pick<State, 'pendingPrescriptions'>>) => {
                return draft.pendingPrescriptions.find(({ id }) => id === prescriptionId);
            };

            if (
                state.pendingPrescriptionIdBeingUpdated &&
                state.pendingPrescriptionIdBeingUpdated !== prescriptionId
            ) {
                updateState(draft => {
                    draft.updatingPendingPrescriptionDisabledToolTipVisible = true;
                });
                setTimeout(() => {
                    updateState(draft => {
                        draft.updatingPendingPrescriptionDisabledToolTipVisible = false;
                    });
                }, 2000);
            } else {
                //this input handler will handle updating prescription properties that will initially be null, which is why
                //lodash set method is used
                if (key === MedicationFormKeys.dose) {
                    return updateState(draft => {
                        const localPrescription = getLocalPrescription(draft);
                        if (localPrescription) {
                            const { min, max } = pillsPerDoseValidator.convertToMinMax(e.target.value);
                            set(localPrescription, MedicationFormKeys.doseMin, min);
                            set(localPrescription, MedicationFormKeys.doseMax, max);
                            draft.pendingPrescriptionIdBeingUpdated = this.determinePrescriptionIdBeingEdited(
                                localPrescription
                            );
                        }
                    });
                } else {
                    updateState(draft => {
                        const localPrescription = getLocalPrescription(draft);
                        if (localPrescription) {
                            set(localPrescription, key, !!e.target.value ? e.target.value : '');
                            draft.pendingPrescriptionIdBeingUpdated = this.determinePrescriptionIdBeingEdited(
                                localPrescription
                            );
                        }
                    });
                }
            }
        },
        async addToRedux(prescription: LocalUserMedicationSchema) {
            updateState(draft => void (draft.isAddingPrescription = false));
            dispatch(reduxActions.addPendingPrescriptionForSelectedPatient(prescription));
        },
        updateInRedux(prescription: LocalUserMedicationSchema) {
            dispatch(updatePendingPrescriptionForSelectedPatient(prescription));
        },
        removeFromRedux(prescription: LocalUserMedicationSchema) {
            dispatch(removePendingPrescriptionForSelectedPatient(prescription.id));
            updateState(draft => {
                draft.isArchivingPrescriptionOrMedication = false;
                draft.medicationOrPrescriptionNameBeingArchived = undefined;
            });
        },
        cancelAdding() {
            updateState(draft => {
                draft.isAddingPrescription = false;
                draft.pendingPrescriptions.pop();
            });
        },
        cancelUpdatingInRedux(prescription: LocalUserMedicationSchema) {
            const localPrescriptionIndex = state.pendingPrescriptions.findIndex(
                ({ id }) => id === prescription.id
            );
            const reduxPrescription = selectedPatientPrescriptions.pending.find(
                ({ id }) => id === prescription.id
            );
            if (localPrescriptionIndex !== -1 && reduxPrescription) {
                updateState(draft => {
                    draft.pendingPrescriptions[localPrescriptionIndex] = reduxPrescription;
                });
            } else {
                logDistinctiveMessage(
                    `Unable to find pending prescription with id ${prescription.id} in cancelUpdatingPendingPrescription()`
                );
            }
        },
        isPrescriptionBeingEdited(prescription: LocalUserMedicationSchema): boolean {
            const reduxPrescription = selectedPatientPrescriptions.pending.find(
                ({ id }) => id === prescription.id
            );
            if (!reduxPrescription) {
                return false;
            }
            return !!(diffObjects(reduxPrescription, prescription) as string[]).length;
        },
        determinePrescriptionIdBeingEdited(prescription: LocalUserMedicationSchema): string | undefined {
            if (this.isPrescriptionBeingEdited(prescription)) {
                return prescription.id;
            }
        },
        toggleRemovalModal(prescription: LocalUserMedicationSchema) {
            toggleIsArchivingPrescriptionOrMedication();
            updateState(draft => {
                draft.medicationOrPrescriptionNameBeingArchived = {
                    name: prescription.name,
                    type: 'prescription',
                };
                draft.archivePrescriptionOrMedication = () => {
                    this.removeFromRedux(prescription);
                };
            });
        },
        handleDeliveryTypeSelect({
            deliveryType,
            prescriptionId,
        }: {
            deliveryType: DeliveryType;
            prescriptionId: string;
        }) {
            updateState(draft => {
                const localPrescription = draft.pendingPrescriptions.find(({ id }) => id === prescriptionId);
                if (localPrescription) {
                    localPrescription.delivery = deliveryType;
                    set(localPrescription, MedicationFormKeys.unitMeasure, getMeasure(deliveryType));
                }
            });
        },
        handleOpioidTypeSelect({
            opioidType,
            prescriptionId,
        }: {
            opioidType: OpioidType;
            prescriptionId: string;
        }) {
            updateState(draft => {
                const localPrescription = draft.pendingPrescriptions.find(({ id }) => id === prescriptionId);
                if (localPrescription) {
                    if (opioidType === OpioidType.nonOpioid) {
                        const newPrescription = {
                            dose: localPrescription.prescription?.dose,
                            frequency: localPrescription.prescription?.frequency,
                        };
                        localPrescription.prescription = newPrescription as BasePrescription;
                    } else {
                        set(localPrescription, MedicationFormKeys.prescriptionOpioid, opioidType);
                        set(localPrescription, MedicationFormKeys.prescriptionCountRemaining, null);
                    }
                }
            });
        },
    };

    const PatientAddedMedicationLogic = {
        async archiveAndRemoveFromRedux(medication: UserMedicationDocument) {
            await medication.update({ archived: true, archivedAt: getTimestamp(new Date()) });
            dispatch(removePatientAddedMedicationForSelectedPatient(medication.id));
            dispatch(setToastAlert('Changes saved to patient'));
            updateState(draft => {
                draft.isArchivingPrescriptionOrMedication = false;
                draft.medicationOrPrescriptionNameBeingArchived = undefined;
            });
        },
        toggleArchiveAndRemovalModal(medication: UserMedicationDocument) {
            toggleIsArchivingPrescriptionOrMedication();
            updateState(draft => {
                draft.medicationOrPrescriptionNameBeingArchived = {
                    name: medication.data.name,
                    type: 'medication',
                };
                draft.archivePrescriptionOrMedication = async () => {
                    await this.archiveAndRemoveFromRedux(medication);
                };
            });
        },
    };

    return (
        <>
            <div className="py-8 w-11/12 mx-auto">
                {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
                <div className="py-2 flex flex-row justify-between items-center mb-2 relative">
                    <h3 className="text-lg font-semibold text-blue-900 block mr-2">
                        {selectedPatient?.data.firstName}'s Prescriptions
                    </h3>
                    <ActionButton onClick={setAddingNewPrescription}>
                        <IconManager
                            type={IconType.PLUS}
                            stroke="#fff"
                            size={19}
                            strokeWidth={3}
                            className="mr-1"
                        />
                        <span className="font-semibold">Add prescription</span>
                    </ActionButton>
                    <ToolTip visible={state.addingPrescriptionDisabledToolTipVisible}>
                        <span className="text-sm text-blue-500">
                            Please finish{' '}
                            {!!state.providerAddedPrescriptionIdBeingUpdated ? 'updating' : 'adding'}{' '}
                            prescription below
                        </span>
                    </ToolTip>
                </div>
                <PrescriptionList
                    loading={state.loading}
                    listIsEmpty={
                        !state.providerAddedPrescriptions.length && !state.pendingPrescriptions.length
                    }
                >
                    <>
                        {state.providerAddedPrescriptions.map(prescription => (
                            <div
                                key={prescription.id}
                                className="bg-white rounded-md border border-gray-400 my-2 relative"
                            >
                                <PrescriptionForm
                                    //because PrescriptionForm has local input handlers that deal with formatting/validating
                                    //pillsPerDose, we need to pass an explicit flag preventing those handlers from firing if
                                    //the user is try to edit more than one prescription at a time
                                    preventInput={
                                        !!(
                                            state.providerAddedPrescriptionIdBeingUpdated &&
                                            state.providerAddedPrescriptionIdBeingUpdated !== prescription.id
                                        )
                                    }
                                    handleInput={e => {
                                        ProviderAddedPrescriptionLogic.handleInput(e, prescription.id);
                                    }}
                                    updatePrescriptionInRedux={() =>
                                        ProviderAddedPrescriptionLogic.updateInRedux(prescription)
                                    }
                                    cancelUpdatingPrescription={() =>
                                        ProviderAddedPrescriptionLogic.cancelUpdatingInRedux(prescription)
                                    }
                                    toggleArchivePrescriptionModal={() =>
                                        ProviderAddedPrescriptionLogic.toggleArchiveAndRemovalModal(
                                            prescription
                                        )
                                    }
                                    prescription={prescription}
                                    isEditing={ProviderAddedPrescriptionLogic.isPrescriptionBeingEdited(
                                        prescription
                                    )}
                                />
                                <ToolTip
                                    visible={
                                        state.updatingProviderAddedPrescriptionDisabledToolTipVisible &&
                                        state.providerAddedPrescriptionIdBeingUpdated === prescription.id
                                    }
                                    className="border border-blue-500"
                                >
                                    <span className="text-sm text-blue-500">
                                        Please finish updating this prescription
                                    </span>
                                </ToolTip>
                            </div>
                        ))}
                        {state.pendingPrescriptions.map(prescription => (
                            <div
                                key={prescription.id}
                                className="bg-white rounded-md border border-gray-400 my-2 relative"
                            >
                                <PendingPrescriptionForm
                                    //because PendingPrescriptionForm has local input handlers that deal with formatting/validating
                                    //pillsPerDose & pillSizeOrConcentration, we need to pass an explicit flag preventing those handlers
                                    // from firing if the user is try to edit more than one prescription at a time
                                    preventInput={
                                        !!(
                                            state.pendingPrescriptionIdBeingUpdated &&
                                            state.pendingPrescriptionIdBeingUpdated !== prescription.id
                                        )
                                    }
                                    prescription={prescription}
                                    action={
                                        PendingPrescriptionLogic.alreadyExistsInRedux(prescription.id)
                                            ? () => PendingPrescriptionLogic.updateInRedux(prescription)
                                            : () => PendingPrescriptionLogic.addToRedux(prescription)
                                    }
                                    cancelAction={
                                        PendingPrescriptionLogic.alreadyExistsInRedux(prescription.id)
                                            ? () =>
                                                  PendingPrescriptionLogic.cancelUpdatingInRedux(prescription)
                                            : () => PendingPrescriptionLogic.cancelAdding()
                                    }
                                    isAdding={state.isAddingPrescription}
                                    isEditing={PendingPrescriptionLogic.isPrescriptionBeingEdited(
                                        prescription
                                    )}
                                    handleInput={e =>
                                        PendingPrescriptionLogic.handleInput(e, prescription.id)
                                    }
                                    toggleArchivePrescriptionModal={() =>
                                        PendingPrescriptionLogic.toggleRemovalModal(prescription)
                                    }
                                    alreadyExistsInRedux={PendingPrescriptionLogic.alreadyExistsInRedux(
                                        prescription.id
                                    )}
                                    handleDeliveryTypeSelect={deliveryType =>
                                        PendingPrescriptionLogic.handleDeliveryTypeSelect({
                                            prescriptionId: prescription.id,
                                            deliveryType,
                                        })
                                    }
                                    handleOpioidTypeSelect={opioidType =>
                                        PendingPrescriptionLogic.handleOpioidTypeSelect({
                                            prescriptionId: prescription.id,
                                            opioidType,
                                        })
                                    }
                                />
                                <ToolTip
                                    visible={
                                        state.updatingPendingPrescriptionDisabledToolTipVisible &&
                                        state.pendingPrescriptionIdBeingUpdated === prescription.id
                                    }
                                    className="border border-blue-500"
                                >
                                    <span className="text-sm text-blue-500">
                                        Please finish updating this prescription
                                    </span>
                                </ToolTip>
                            </div>
                        ))}
                    </>
                </PrescriptionList>
                {!state.loading && !!state.patientAddedMedications.length && (
                    <>
                        <div className="py-2 mb-2">
                            <h3 className="text-lg font-semibold text-blue-900">
                                {selectedPatient?.data.firstName}'s Added Medications
                            </h3>
                        </div>
                        <MedicationList listIsEmpty={!state.patientAddedMedications.length}>
                            <>
                                {state.patientAddedMedications.map(medication => (
                                    <div
                                        key={medication.id}
                                        className="bg-white rounded-md border border-gray-400 my-2"
                                    >
                                        <MedicationForm
                                            medication={medication}
                                            toggleArchivedMedicationModal={() =>
                                                PatientAddedMedicationLogic.toggleArchiveAndRemovalModal(
                                                    medication
                                                )
                                            }
                                        />
                                    </div>
                                ))}
                            </>
                        </MedicationList>
                    </>
                )}
                {!state.loading && !!state.providerAddedArchivedPrescriptions.length && (
                    <>
                        <div className="py-2 mb-2">
                            <h3 className="text-lg font-semibold text-blue-900">
                                {selectedPatient?.data.firstName}'s Removed Prescriptions
                            </h3>
                        </div>

                        <ArchivedList listIsEmpty={!state.providerAddedArchivedPrescriptions.length}>
                            <>
                                {state.providerAddedArchivedPrescriptions.map(prescription => (
                                    <div
                                        key={prescription.id}
                                        className="bg-white rounded-md border border-gray-400 my-2"
                                    >
                                        <ArchivedPrescriptionRow prescription={prescription} />
                                    </div>
                                ))}
                            </>
                        </ArchivedList>
                    </>
                )}
            </div>
            <ConfirmationModal
                onConfirm={e => {
                    e.preventDefault();
                    state.archivePrescriptionOrMedication?.(archivedReason);
                }}
                isOpen={state.isArchivingPrescriptionOrMedication}
                closeModal={toggleIsArchivingPrescriptionOrMedication}
            >
                <p>
                    Are you sure you want to remove {selectedPatient?.data.firstName}'s{' '}
                    <span className="font-semibold">
                        {state.medicationOrPrescriptionNameBeingArchived?.name}
                    </span>{' '}
                    {state.medicationOrPrescriptionNameBeingArchived?.type}?
                </p>
                {state.medicationOrPrescriptionNameBeingArchived?.type === 'prescription' && (
                    <div className="my-2">
                        <label>Reason for Removal (optional)</label>
                        <TextInput
                            className={`form-input focus:outline-none border py-1 border-gray-400 w-1/12 block`}
                            multiline
                            autofocus
                            value={archivedReason}
                            onChange={(e: InputChange) => setArchivedReason(e.target.value)}
                        />
                    </div>
                )}
            </ConfirmationModal>
        </>
    );
}

interface ListProps {
    children: JSX.Element;
    loading?: boolean;
    listIsEmpty: boolean;
}

function PrescriptionList({ children, loading, listIsEmpty }: ListProps) {
    if (loading) {
        return <LoadingSpinner type="page" />;
    } else if (listIsEmpty) {
        return (
            <div className="text-left my-2 py-4">
                <h3 className="text-gray-600">
                    This patient does not currently have any active prescriptions.
                </h3>
            </div>
        );
    }
    return children;
}

function MedicationList({ children, listIsEmpty }: ListProps) {
    if (listIsEmpty) {
        return (
            <div className="text-left my-2 py-2">
                <h3 className="text-gray-600">
                    This patient does not currently have any active medications.
                </h3>
            </div>
        );
    }
    return children;
}

function ArchivedList({ children, listIsEmpty }: ListProps) {
    if (listIsEmpty) {
        return (
            <div className="text-left my-2 py-2">
                <h3 className="text-gray-600">
                    This patient does not currently have any archived prescriptions.
                </h3>
            </div>
        );
    }
    return children;
}
