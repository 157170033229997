import React, { useEffect, useRef } from 'react';
import '../../../lib/sparkline.css';
import sparkline from '../../../lib/sparkline';
import { noAccountTablePlaceholder, tablePlaceholder } from './patientTableFormatters';
import { PatientTableRow, SortStatus } from './PatientsList';
import ToolTip from '../../../components/ToolTip';
import IconManager, { IconType } from '../../../components/IconManager';
import { Theme } from '../../../theme';
import { OpioidRiskStatus } from '../../../database/schemas/Patient';
import { getDateFromDateString, renderRelativeDateFromTimeStamp, toPascalCase } from '../../../utils';
import * as dateFNS from 'date-fns';
import LoadingSpinner from '../../../components/LoadingSpinner';

const baseClassNames = 'block w-full text-center mx-auto px-2 py-1';

function Placeholder(): JSX.Element {
    return <span className={`${baseClassNames} text-black rounded-md`}>{tablePlaceholder}</span>;
}

type PainLevelProps =
    | ({
          type: 'average';
      } & Pick<PatientTableRow, 'lastAverageDayPainLevel'>)
    | ({ type: 'worst' } & Pick<PatientTableRow, 'lastWorstDayPainLevel'>);

export function PainLevel(props: PainLevelProps): JSX.Element {
    const pain = props.type === 'average' ? props.lastAverageDayPainLevel : props.lastWorstDayPainLevel;
    if (!pain) {
        return <Placeholder />;
    } else {
        const valueIsHighRisk = pain.painLevel >= 7;
        const timeStamp = getDateFromDateString(pain.date);
        const daysSinceLastRecorded = dateFNS.differenceInCalendarDays(new Date(), timeStamp);
        return (
            <div className="flex flex-col justify-center text-center">
                <span
                    className={`${baseClassNames} block rounded-md text-${
                        valueIsHighRisk ? 'white' : 'black'
                    } bg-${valueIsHighRisk ? 'red-500' : 'transparent'}`}
                >
                    {pain.painLevel}
                </span>
                {pain.date ? (
                    <span className="block text-gray-600 text-xs">
                        {daysSinceLastRecorded === 0
                            ? 'Today'
                            : `${daysSinceLastRecorded} day${daysSinceLastRecorded !== 1 ? 's' : ''} ago`}
                    </span>
                ) : (
                    <Placeholder />
                )}
            </div>
        );
    }
}

export function ConstipationDays(props: {
    lastBowelMovementDate: PatientTableRow['lastBowelMovementDate'];
}): JSX.Element {
    if (!props.lastBowelMovementDate) {
        return <Placeholder />;
    } else {
        const timeStamp = getDateFromDateString(props.lastBowelMovementDate);
        const daysSinceLastBowelMovement = dateFNS.differenceInCalendarDays(new Date(), timeStamp);
        const isHighRisk = daysSinceLastBowelMovement >= 5;
        return (
            <span
                className={`${baseClassNames} rounded-md text-${isHighRisk ? 'white' : 'black'} bg-${
                    isHighRisk ? 'red-500' : 'transparent'
                }`}
            >
                {daysSinceLastBowelMovement} day{daysSinceLastBowelMovement !== 1 ? 's' : ''}
            </span>
        );
    }
}

type PainLevelSparkLineProps =
    | ({ type: 'average' } & Pick<PatientTableRow, 'averageDayPainSparkline'>)
    | ({ type: 'worst' } & Pick<PatientTableRow, 'worstDayPainSparkline'>);

export function PainLevelSparkLine(props: PainLevelSparkLineProps): JSX.Element {
    const sparkLineRef = useRef<SVGSVGElement>(null);
    const painLevels = props.type === 'average' ? props.averageDayPainSparkline : props.worstDayPainSparkline;
    useEffect(() => {
        if (sparkLineRef.current) {
            sparkline(sparkLineRef.current, painLevels ?? []);
        }
    }, []);

    return painLevels ? (
        <div className="flex flex-row justify-between items-center">
            <div
                className="flex flex-1 justify-between items-center m-0 p-0 flex-col"
                style={{ height: '90px' }}
            >
                <span className="block text-gray-600 text-sm">10</span>
                <span className="block text-gray-600 text-sm">0</span>
            </div>
            <svg ref={sparkLineRef} className="sparkline flex-2" width="175" height="75" strokeWidth="3" />
        </div>
    ) : (
        <Placeholder />
    );
}

export function DayOfLastEntry(props: {
    dayOfLastEntry: PatientTableRow['lastEntryDate'];
    accountCreated?: boolean;
}): JSX.Element {
    let classNames = baseClassNames;
    const formattedDayOfLastEntry = (() => {
        if (!props.dayOfLastEntry) {
            return props.accountCreated ? tablePlaceholder : noAccountTablePlaceholder;
        } else {
            const timeStamp = getDateFromDateString(props.dayOfLastEntry);
            return renderRelativeDateFromTimeStamp(timeStamp);
        }
    })();
    if (formattedDayOfLastEntry === noAccountTablePlaceholder || /^\d/i.test(formattedDayOfLastEntry)) {
        classNames += ' bg-yellow-300 text-black rounded-md';
    }
    return <span className={classNames}>{formattedDayOfLastEntry}</span>;
}

export function ShortActingUsage({
    shortActingUsage,
}: {
    shortActingUsage: PatientTableRow['shortActingOpioidUsage'];
}) {
    let tooltip = '';

    switch (shortActingUsage) {
        case OpioidRiskStatus.HIGH:
            tooltip =
                'Yesterday, patient took more than 66% of their prescribed max of short-acting opioids.';
            break;
        case OpioidRiskStatus.MODERATE:
            tooltip =
                'Yesterday, patient took between 33% and 66% of their prescribed max of short-acting opioids.';
            break;
        case OpioidRiskStatus.LOW:
            tooltip =
                'Yesterday, patient took less than 33% of their prescribed max of short-acting opioids.';
            break;
        case OpioidRiskStatus.NONE:
            tooltip = 'Patient took no short-acting opioids yesterday.';
            break;
    }

    return (
        <span
            className={`rounded-md ${baseClassNames} ${
                shortActingUsage === OpioidRiskStatus.HIGH
                    ? 'bg-red-500 text-white'
                    : 'bg-transparent text-black'
            }`}
            title={tooltip}
        >
            {toPascalCase(OpioidRiskStatus[shortActingUsage])}
        </span>
    );
}

export function PatientSearchToolTip({
    visible,
    belowIcon,
}: {
    visible: boolean;
    belowIcon?: boolean;
}): JSX.Element {
    const codeClassNames = 'text-gray-600 rounded-md p-1 bg-gray-300';
    return (
        <ToolTip visible={visible} belowIcon={belowIcon}>
            <div className="flex flex-col justify-start items-start text-left">
                <h6 className="text-base font-semibold">Search by patient name, email or MRN</h6>
                <div className="mt-2 px-3 leading-loose">
                    <span className="text-base leading-none">
                        You can also filter for certain conditions:
                    </span>
                    <ul className="list-disc">
                        <li>
                            <code className={codeClassNames}>constipation:2</code> - Patients with 2 or more
                            days of constipation
                        </li>
                        <li>
                            <code className={codeClassNames}>lastentry:3</code> - Patients with their last
                            entry 3 or more days ago
                        </li>
                        <li>
                            <code className={codeClassNames}>monitoring:none</code>,{' '}
                            <code className={codeClassNames}>monitoring:daily</code> or{' '}
                            <code className={codeClassNames}>monitoring:hourly</code> - Show patients with the
                            corresponding monitoring status
                        </li>
                        <li>
                            <code className={codeClassNames}>pain:6</code> - Patients with average pain score
                            of 6 or greater
                        </li>
                        <li>
                            <code className={codeClassNames}>shortacting:low</code>,{' '}
                            <code className={codeClassNames}>shortacting:moderate</code> or{' '}
                            <code className={codeClassNames}>shortacting:high</code> - Show patients with low
                            (0 - 33% of max dose), moderate (33% to 66% of max dose) or high (66% or more of
                            max dose) consumption of short-acting opioids
                        </li>
                    </ul>
                </div>
            </div>
        </ToolTip>
    );
}

interface SortableColumnProps {
    title: string;
    toggleSortOrder?: () => void;
    sortStatus?: SortStatus;
    width?: string;
    loading?: boolean;
}

export function SortableColumn(props: SortableColumnProps): JSX.Element {
    const isActiveSort = props.sortStatus?.isActive || !!props.sortStatus?.priority;
    return (
        <th className="border relative px-4 py-2" style={{ width: props.width }}>
            <button
                className={`font-semibold text-left flex flex-row justify-between w-full ${
                    props.toggleSortOrder ? 'cursor-pointer' : 'cursor-default'
                }`}
                onClick={props.toggleSortOrder}
            >
                <span className="block flex flex-row items-center justify-end absolute top-0 right-0 mt-2 mr-1">
                    <SortStatusIndicator
                        loading={props.loading}
                        sortStatus={props.sortStatus}
                        isActiveSort={isActiveSort}
                    />
                </span>
                <span
                    className={`block mr-3 ${
                        isActiveSort ? `text-${Theme.lightBlue} hover:text-${Theme.lightBlueHover}` : ''
                    }`}
                    style={{ fontSize: '15px' }}
                >
                    {props.title}
                </span>
            </button>
        </th>
    );
}

type SortStatusIndicatorProps = Pick<SortableColumnProps, 'loading' | 'sortStatus'> & {
    isActiveSort: boolean;
};

function SortStatusIndicator(props: SortStatusIndicatorProps): JSX.Element | null {
    if (props.loading) {
        return (
            <div className="relative w-3/4 p-3 mr-1 mt-1">
                <LoadingSpinner type="button" loading={1} color="#009AD4" />
            </div>
        );
    } else if (props.isActiveSort) {
        return (
            <>
                <IconManager
                    type={props.sortStatus?.order === 'ascending' ? IconType.ARROW_UP : IconType.ARROW_DOWN}
                    stroke="#3B98CF"
                    size={20}
                />
                {props.sortStatus?.priority && (
                    <span className={`text-sm text-${Theme.lightBlue}`}>{props.sortStatus?.priority}</span>
                )}
            </>
        );
    }
    return null;
}
