import { AlertDocument } from '../../database/documents/AlertDocument';
import { PatientDocument } from '../../database/documents/PatientDocument';
import { UserMedicationDocument } from '../../database/documents/UserMedicationDocument';
import produce from 'immer';
import { EntryDocument } from '../../database/documents/EntryDocument';
import set from 'lodash.set';
import { InvitePatientRequest } from '../../functions/inviteTypes';
import { Gender, PatientKeys, UserMedicationSchema } from '../../database/schemas/Patient';

export type LocalUserMedicationSchema = UserMedicationSchema & { id: string };
export const initialPatientFormState: InvitePatientRequest = {
    [PatientKeys.firstName]: '',
    [PatientKeys.lastName]: '',
    [PatientKeys.email]: '',
    [PatientKeys.dob]: '',
    [PatientKeys.mrn]: '',
    [PatientKeys.gender]: Gender.MALE,
    [PatientKeys.emergencyPhoneNumber]: '',
    [PatientKeys.assignedPrescriber]: '',
};

export type GroupedAlerts = { active: AlertDocument[]; resolved: AlertDocument[] };

export interface SelectedPatientState {
    document: PatientDocument | null;
    form: InvitePatientRequest;
    medications: UserMedicationDocument[];
    prescriptions: {
        providerAdded: UserMedicationDocument[];
        pending: LocalUserMedicationSchema[];
    };
    archivedPrescriptions: UserMedicationDocument[];
    entries: EntryDocument[];
    alerts: GroupedAlerts;
}

export enum SelectedPatientActions {
    //DOCUMENT
    UPDATE_SELECTED_PATIENT_DOCUMENT_FIELD = 'UPDATE_SELECTED_PATIENT_DOCUMENT_FIELD',
    //PRESCRIPTIONS/MEDICATIONS
    // --- PROVIDER-ADDED
    UPDATE_PROVIDER_ADDED_PRESCRIPTION_FOR_SELECTED_PATIENT = 'UPDATE_PROVIDER_ADDED_PRESCRIPTION_FOR_SELECTED_PATIENT',
    REMOVE_PROVIDER_ADDED_PRESCRIPTION_FOR_SELECTED_PATIENT = 'REMOVE_PROVIDER_ADDED_PRESCRIPTION_FOR_SELECTED_PATIENT',
    // --- PENDING
    UPDATE_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT = 'UPDATE_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT',
    REMOVE_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT = 'REMOVE_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT',
    ADD_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT = 'ADD_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT',
    // --- PATIENT-ADDED
    REMOVE_PATIENT_ADDED_MEDICATION_FOR_SELECTED_PATIENT = 'REMOVE_PATIENT_ADDED_MEDICATION_FOR_SELECTED_PATIENT',

    SET_SELECTED_PATIENT_MEDICATIONS_AND_PRESCRIPTIONS = 'SET_SELECTED_PATIENT_MEDICATIONS_AND_PRESCRIPTIONS',
    //FORM
    SET_SELECTED_PATIENT_FORM = 'SET_SELECTED_PATIENT_FORM',
    UPDATE_SELECTED_PATIENT_FORM_PROP = 'UPDATE_SELECTED_PATIENT_FORM_PROP',
    CLEAR_SELECTED_PATIENT_FORM = 'CLEAR_SELECTED_PATIENT_FORM',
    //set all patient data
    SET_SELECTED_PATIENT = 'SET_SELECTED_PATIENT',
    CLEAR_SELECTED_PATIENT = 'CLEAR_SELECTED_PATIENT',
    MERGE_UPDATED_PATIENT_FIELDS = 'MERGE_UPDATED_PATIENT_FIELDS',
    MERGE_UPDATED_PATIENT_PRESCRIPTIONS = 'MERGE_UPDATED_PATIENT_PRESCRIPTIONS',
    SET_SELECTED_PATIENT_ARCHIVED_PRESCRIPTIONS = 'SET_SELECTED_PATIENT_ARCHIVED_PRESCRIPTIONS',
    //ENTRIES
    SET_SELECTED_PATIENT_ENTRIES = 'SET_SELECTED_PATIENT_ENTRIES',
    //CANCEL/SAVE
    CANCEL_CHANGES_FOR_PATIENT = 'CANCEL_CHANGES_FOR_PATIENT',
    //ALERTS
    TOGGLE_ALERT_RESOLVED = 'TOGGLE_ALERT_RESOLVED',
    SET_SELECTED_PATIENT_ALERTS = 'SET_SELECTED_PATIENT_ALERTS',
}

const initialSelectedPatientState: SelectedPatientState = {
    document: null,
    form: initialPatientFormState,
    medications: [],
    prescriptions: {
        providerAdded: [],
        pending: [],
    },
    archivedPrescriptions: [],
    entries: [],
    alerts: { active: [], resolved: [] },
};

const initialSelectedPatientOriginalState = {
    ...initialSelectedPatientState,
    loading: true,
};

export interface SelectedPatientReduxState {
    original: SelectedPatientState & { loading: boolean };
    diff: SelectedPatientState;
}

export const initialSelectedPatientReduxState: SelectedPatientReduxState = {
    original: initialSelectedPatientOriginalState,
    diff: initialSelectedPatientState,
};
export type UpdatedPatientFields = (keyof SelectedPatientState)[];
export type UpdatePatientArgs = { path: PatientKeys; value: any };
export type SelectedPatientActionTypes =
    | {
          type: SelectedPatientActions.UPDATE_SELECTED_PATIENT_DOCUMENT_FIELD;
          payload: UpdatePatientArgs;
      }
    | {
          type: SelectedPatientActions.SET_SELECTED_PATIENT_MEDICATIONS_AND_PRESCRIPTIONS;
          payload: SelectedPatientState['medications'];
      }
    | {
          type: SelectedPatientActions.SET_SELECTED_PATIENT_ARCHIVED_PRESCRIPTIONS;
          payload: SelectedPatientState['archivedPrescriptions'];
      }
    | {
          type:
              | SelectedPatientActions.UPDATE_PROVIDER_ADDED_PRESCRIPTION_FOR_SELECTED_PATIENT
              | SelectedPatientActions.REMOVE_PROVIDER_ADDED_PRESCRIPTION_FOR_SELECTED_PATIENT;
          payload: UserMedicationDocument;
      }
    | {
          type: SelectedPatientActions.UPDATE_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT;
          payload: LocalUserMedicationSchema;
      }
    | {
          type:
              | SelectedPatientActions.REMOVE_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT
              | SelectedPatientActions.REMOVE_PATIENT_ADDED_MEDICATION_FOR_SELECTED_PATIENT;
          payload: string;
      }
    | {
          type: SelectedPatientActions.ADD_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT;
          payload: LocalUserMedicationSchema;
      }
    | {
          type: SelectedPatientActions.SET_SELECTED_PATIENT_FORM;
          payload: SelectedPatientState['form'];
      }
    | {
          type: SelectedPatientActions.UPDATE_SELECTED_PATIENT_FORM_PROP;
          payload: UpdatePatientArgs;
      }
    | { type: SelectedPatientActions.SET_SELECTED_PATIENT; payload: SelectedPatientState }
    | { type: SelectedPatientActions.MERGE_UPDATED_PATIENT_PRESCRIPTIONS; payload: UserMedicationDocument[] }
    | {
          type:
              | SelectedPatientActions.MERGE_UPDATED_PATIENT_FIELDS
              | SelectedPatientActions.CLEAR_SELECTED_PATIENT
              | SelectedPatientActions.CANCEL_CHANGES_FOR_PATIENT
              | SelectedPatientActions.CLEAR_SELECTED_PATIENT_FORM;
      }
    | { type: SelectedPatientActions.SET_SELECTED_PATIENT_ALERTS; payload: GroupedAlerts }
    | { type: SelectedPatientActions.SET_SELECTED_PATIENT_ENTRIES; payload: SelectedPatientState['entries'] }
    | { type: SelectedPatientActions.TOGGLE_ALERT_RESOLVED; payload: string };

/*
Note that the medication/prescription removal state management is unique in that the change to the patient record is made
outright instead of when user hits Save button because there's a confirmation modal presented. Submitting that confirmation
modal will merge the diff with the original so there will not be any updated patient fields to enable top-level Save button
 */

export default function selectedPatientReducer(
    state: SelectedPatientReduxState = initialSelectedPatientReduxState,
    action: SelectedPatientActionTypes
): SelectedPatientReduxState {
    switch (action.type) {
        //SETTING OF ORIGINAL PATIENT STATE
        case SelectedPatientActions.SET_SELECTED_PATIENT_MEDICATIONS_AND_PRESCRIPTIONS:
            return produce(state, draft => {
                draft.original.medications = action.payload;
                draft.diff.medications = action.payload;
            });
        case SelectedPatientActions.SET_SELECTED_PATIENT_ARCHIVED_PRESCRIPTIONS:
            return produce(state, draft => {
                draft.original.archivedPrescriptions = action.payload;
                draft.diff.archivedPrescriptions = action.payload;
            });
        case SelectedPatientActions.SET_SELECTED_PATIENT:
            return produce(state, draft => {
                draft.original = { ...action.payload, loading: false };
                draft.diff = action.payload;
            });
        case SelectedPatientActions.MERGE_UPDATED_PATIENT_FIELDS:
            return produce(state, draft => {
                draft.original = { ...draft.diff, loading: false };
            });
        case SelectedPatientActions.MERGE_UPDATED_PATIENT_PRESCRIPTIONS:
            return produce(state, draft => {
                draft.diff.prescriptions.providerAdded.push(...action.payload);
                draft.diff.prescriptions.pending = [];
            });
        case SelectedPatientActions.SET_SELECTED_PATIENT_FORM:
            return produce(state, draft => {
                draft.original.form = action.payload;
                draft.diff.form = action.payload;
            });
        case SelectedPatientActions.SET_SELECTED_PATIENT_ENTRIES:
            return produce(state, draft => {
                draft.original.entries = action.payload;
                draft.diff.entries = action.payload;
            });
        case SelectedPatientActions.SET_SELECTED_PATIENT_ALERTS:
            return produce(state, draft => {
                draft.diff.alerts = action.payload;
                draft.original.alerts = action.payload;
            });
        // UPDATES TO PATIENT DIFF
        case SelectedPatientActions.UPDATE_SELECTED_PATIENT_DOCUMENT_FIELD:
            // Handle if we're unsetting an assigned opioid agreement
            if (action.payload.path === PatientKeys.opioidAgreement && action.payload.value === undefined) {
                return produce(state, draft => {
                    if (draft.diff.document && draft.original.document) {
                        set(draft.diff.document.data, action.payload.path, {
                            ...draft.original.document.data.opioidAgreement,
                            assignedOpioidAgreementId: null,
                        });
                    }
                });
            }
            return produce(state, draft => {
                if (draft.diff.document) {
                    set(draft.diff.document.data, action.payload.path, action.payload.value);
                }
            });
        case SelectedPatientActions.UPDATE_PROVIDER_ADDED_PRESCRIPTION_FOR_SELECTED_PATIENT:
            return produce(state, draft => {
                const { providerAdded } = draft.diff.prescriptions;
                providerAdded[
                    providerAdded.findIndex(prescription => prescription.id === action.payload.id)
                ] = action.payload;
            });
        case SelectedPatientActions.UPDATE_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT:
            return produce(state, draft => {
                const { pending } = draft.diff.prescriptions;
                pending[pending.findIndex(prescription => prescription.id === action.payload.id)] =
                    action.payload;
            });
        case SelectedPatientActions.REMOVE_PROVIDER_ADDED_PRESCRIPTION_FOR_SELECTED_PATIENT:
            return produce(state, draft => {
                const { providerAdded: originalProviderAddedPrescriptions } = draft.original.prescriptions;
                const { providerAdded: diffProviderAddedPrescriptions } = draft.diff.prescriptions;
                diffProviderAddedPrescriptions.splice(
                    diffProviderAddedPrescriptions.findIndex(
                        prescription => prescription.id === action.payload.id
                    ),
                    1
                );
                originalProviderAddedPrescriptions.splice(
                    originalProviderAddedPrescriptions.findIndex(
                        prescription => prescription.id === action.payload.id
                    ),
                    1
                );
                draft.diff.archivedPrescriptions.push(action.payload);
            });
        case SelectedPatientActions.REMOVE_PATIENT_ADDED_MEDICATION_FOR_SELECTED_PATIENT:
            return produce(state, draft => {
                const { medications: originalMedications } = draft.original;
                const { medications: diffMedications } = draft.diff;
                diffMedications.splice(
                    diffMedications.findIndex(medication => medication.id === action.payload),
                    1
                );
                originalMedications.splice(
                    originalMedications.findIndex(medication => medication.id === action.payload, 1)
                );
            });
        case SelectedPatientActions.REMOVE_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT:
            return produce(state, draft => {
                const { pending: originalPendingPrescriptions } = draft.original.prescriptions;
                const { pending: diffPendingPrescriptions } = draft.diff.prescriptions;
                diffPendingPrescriptions.splice(
                    diffPendingPrescriptions.findIndex(prescription => prescription.id === action.payload),
                    1
                );
                originalPendingPrescriptions.splice(
                    originalPendingPrescriptions.findIndex(
                        prescription => prescription.id === action.payload,
                        1
                    )
                );
            });
        case SelectedPatientActions.CLEAR_SELECTED_PATIENT_FORM:
            return produce(state, draft => {
                draft.diff.form = initialPatientFormState;
            });
        case SelectedPatientActions.ADD_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT:
            return produce(state, draft => {
                draft.diff.prescriptions.pending.push(action.payload);
            });
        case SelectedPatientActions.CLEAR_SELECTED_PATIENT:
            return produce(state, draft => {
                draft.original = initialSelectedPatientOriginalState;
                draft.diff = initialSelectedPatientState;
            });
        case SelectedPatientActions.UPDATE_SELECTED_PATIENT_FORM_PROP:
            if (action.payload.path === PatientKeys.opioidAgreementId) {
                if (action.payload.value === '') {
                    return produce(state, draft => {
                        delete draft.diff.form[action.payload.path];
                    });
                } else {
                    return produce(state, draft => {
                        draft.diff.form[action.payload.path] = action.payload.value;
                    });
                }
            } else {
                return produce(state, draft => {
                    draft.diff.form[action.payload.path] = action.payload.value;
                });
            }
        case SelectedPatientActions.CANCEL_CHANGES_FOR_PATIENT:
            return produce(state, draft => {
                const { loading, ...original } = draft.original;
                draft.diff = original;
            });
        case SelectedPatientActions.TOGGLE_ALERT_RESOLVED:
            return produce(state, draft => {
                draft.diff.alerts.active.find(({ id }) => id === action.payload)?.toggleAcknowledged();
            });
        default:
            return state;
    }
}
