import React, { useContext, useEffect } from 'react';
import { InputChange, RouteProps } from '../../../../types';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import { selectSelectedOrganizationOpioidAgreements } from '../../../../redux/selectedOrganization/selectedOrganizationSelectors';
import { Link } from '@reach/router';
import useNavigation from '../../../../hooks/useNavigation';
import { CurrentUserContext } from '../../../../context/CurrentUserContextProvider';
import dateFNSFormat from 'date-fns/format';
import SegmentedControl from '../../../../components/SegmentedControl';
import { OpioidAgreementDocument } from '../../../../database/documents/OpioidAgreementDocument';
import { useImmer } from 'use-immer';
import ActionButton from '../../../../components/ActionButton';
import { Theme } from '../../../../theme';
import { UserRoles } from '../../../../database/schemas/User';
import isAuthed from '../../../../hooks/isAuthed';
import { useSynchronizeOrgId } from '../../../../hooks/useSynchronizeOrgId';

interface State {
    filteredAgreements: OpioidAgreementDocument[];
    filter: Filter;
}

enum Filter {
    active = 'active',
    archived = 'archived',
}
export default function OpioidAgreementList(_: RouteProps) {
    const navigation = useNavigation();
    const selectedOrganizationOpioidAgreements = useSelector((state: ReduxState) =>
        selectSelectedOrganizationOpioidAgreements(state)
    );
    const [state, updateState] = useImmer<State>({
        filteredAgreements: selectedOrganizationOpioidAgreements.active,
        filter: Filter.active,
    });
    const currentUser = useContext(CurrentUserContext);

    const filterOpioidAgreements = (e: InputChange) => {
        e.persist();
        updateState(draft => void (draft.filter = e.target.value as Filter));
    };

    useEffect(() => {
        updateState(
            draft => void (draft.filteredAgreements = selectedOrganizationOpioidAgreements[draft.filter])
        );
    }, [state.filter, selectedOrganizationOpioidAgreements]);

    return (
        <div className="w-full flex flex-row p-2">
            <div className="mt-3 flex ml-5 flex-col w-3/4">
                <div className="flex flex-row justify-between">
                    <h2 className="text-blue-900 block text-xl font-semibold">Opioid Agreements</h2>
                    <SegmentedControl
                        size="xl"
                        options={[
                            {
                                label: 'Current',
                                value: Filter.active,
                                checked: state.filter === Filter.active,
                            },
                            {
                                label: 'Archived',
                                value: Filter.archived,
                                checked: state.filter === Filter.archived,
                            },
                        ]}
                        handleInput={filterOpioidAgreements}
                    />
                </div>
                {!!state.filteredAgreements.length && (
                    <div className="w-full flex flex-row justify-between items-center px-1 mb-1">
                        <h3 className="text-lg block text-blue-700">Name</h3>
                        <h3 className="text-lg block text-blue-700">Date Created</h3>
                    </div>
                )}
                <ul className="list-none w-full">
                    {!state.filteredAgreements.length ? (
                        <div className="py-4 text-gray-600">
                            There aren't any {state.filter.toLocaleLowerCase()} agreements for this
                            organization
                        </div>
                    ) : (
                        state.filteredAgreements.map(agreement => (
                            <Link
                                key={agreement.id}
                                to={
                                    currentUser.claims?.currentOrgId
                                        ? navigation?.getOpioidAgreementDetailsUrl(
                                              currentUser.claims.currentOrgId,
                                              agreement.id
                                          )
                                        : ''
                                }
                            >
                                <div
                                    className={`round-md border bg-white hover:bg-${Theme.offWhite} border-gray-400 px-3 py-4 my-3 rounded-md flex flex-row justify-between items-center`}
                                >
                                    <h5 className="block">{agreement.data.title}</h5>
                                    <h5 className="block">
                                        {dateFNSFormat(agreement.data.createdAt.toDate(), 'MM/dd/yyyy')}
                                    </h5>
                                </div>
                            </Link>
                        ))
                    )}
                </ul>
                {state.filter === Filter.active && (
                    <ActionButton
                        linkTo={
                            currentUser.claims?.currentOrgId
                                ? navigation.getOrganizationOpioidAgreementAddUrl(
                                      currentUser.claims.currentOrgId
                                  )
                                : ''
                        }
                        className="mt-1 w-1/2 lg:w-1/3"
                    >
                        Add Agreement
                    </ActionButton>
                )}
            </div>
        </div>
    );
}
