import { OrganizationDocument } from '../documents/OrganizationDocument';
import { Firestore } from '../FirestoreTypes';
import { OrganizationSchema } from '../schemas/Organization';
import { BaseModel } from './BaseModel';

export class OrganizationModel extends BaseModel<OrganizationSchema, OrganizationDocument> {
    constructor(firestore: Firestore) {
        super(firestore, 'Organization', 'organizations', OrganizationDocument);
    }
}
