import { SearchError, SearchFilters } from './PatientsList';
import { PatientQueryFields, PatientQueryRequest } from '../../../functions/userTypes';
import { QueryItem } from '../../../database/DatabaseTypes';
import dateFNSSubDays from 'date-fns/subDays';
import { getDateString } from '../../../utils';
import { MonitoringLevel, OpioidRiskStatus } from '../../../database/schemas/Patient';

type MonitoringLevelSearch = 'none' | 'daily' | 'hourly';
type ShortActingUsageSearch = 'none' | 'low' | 'moderate' | 'high';
export type ParsedSearchFilter = {
    field: SearchFilters;
    value: number | MonitoringLevelSearch | ShortActingUsageSearch;
};
export type ParsedSearchQuery = {
    queries: PatientQueryRequest['queries'];
    search: PatientQueryRequest['search'];
};

function parseSearchFilter(filter: string): QueryItem<PatientQueryFields> {
    const parsedSearchFilter = validateFilterFieldAndValue(filter);
    return deriveQueryItem(parsedSearchFilter);
}

export function parseSearchQuery(search: string): ParsedSearchQuery {
    const parsedQueries: PatientQueryRequest['queries'] = [];
    const parsedSearch: PatientQueryRequest['search'] = [];
    const token = search.split(' ');
    token.forEach(item => {
        //if item contains : then it's a filter, otherwise it's a search
        if (/:/g.test(item)) {
            const parsedFilter = parseSearchFilter(item);
            //@ts-ignore
            parsedQueries.push(parsedFilter);
        } else {
            parsedSearch.push(item);
        }
    });
    return { queries: parsedQueries, search: parsedSearch };
}

export function deriveQueryItem({ field, value }: ParsedSearchFilter): QueryItem<PatientQueryFields> {
    switch (field) {
        case SearchFilters.constipation:
            const lastBowelMovementDate = dateFNSSubDays(new Date(), Number(value));
            return ['lastBowelMovementDate', '<=', getDateString(lastBowelMovementDate)];
        case SearchFilters.lastentry:
            const lastEntryDate = dateFNSSubDays(new Date(), Number(value));
            return ['lastEntryDate', '<=', getDateString(lastEntryDate)];
        case SearchFilters.pain:
            return ['lastAverageDayPainLevel.painLevel' as any, '>=', Number(value)];
        case SearchFilters.shortacting:
            return [
                'shortActingOpioidUsage',
                '==',
                convertShortActingToQueryValue(value as ShortActingUsageSearch),
            ];
        case SearchFilters.monitoring:
            return ['monitoringLevel', '==', convertMonitoringToQueryValue(value as MonitoringLevelSearch)];
        default:
            throw { type: 'invalidFilterField' } as SearchError;
    }
}

export function validateFilterFieldAndValue(filter: string): ParsedSearchFilter {
    let [field, value] = filter.split(':') as [
        SearchFilters,
        number | MonitoringLevelSearch | ShortActingUsageSearch
    ];
    const valueAsNumber = Number(value);

    if (!(field in SearchFilters)) {
        throw { type: 'invalidFilterField' } as SearchError;
    }
    switch (field) {
        case SearchFilters.monitoring:
            if (!['none', 'daily', 'hourly'].includes(value.toString())) {
                throw { type: 'invalidMonitoringLevel' } as SearchError;
            }
            break;
        case SearchFilters.shortacting:
            if (!['none', 'low', 'moderate', 'high'].includes(value.toString())) {
                throw { type: 'invalidShortActing' } as SearchError;
            }
            break;
        case SearchFilters.constipation:
            if (isNaN(valueAsNumber) || valueAsNumber < 1) {
                throw { type: 'invalidConstipation' } as SearchError;
            }
            break;
        case SearchFilters.lastentry:
            if (isNaN(valueAsNumber) || valueAsNumber < 1) {
                throw { type: 'invalidLastEntry' } as SearchError;
            }
            break;
        default:
            if (isNaN(valueAsNumber) || valueAsNumber < 1 || value > 10) {
                throw { type: 'invalidPain' } as SearchError;
            }
    }

    return { field, value };
}

function convertMonitoringToQueryValue(value: MonitoringLevelSearch) {
    switch (value) {
        case 'none':
            return MonitoringLevel.none;
        case 'daily':
            return MonitoringLevel.daily;
        default:
            return MonitoringLevel.hourly;
    }
}

function convertShortActingToQueryValue(value: ShortActingUsageSearch) {
    switch (value) {
        case 'none':
            return OpioidRiskStatus.NONE;
        case 'low':
            return OpioidRiskStatus.LOW;
        case 'moderate':
            return OpioidRiskStatus.MODERATE;
        default:
            return OpioidRiskStatus.HIGH;
    }
}
