import React, { useEffect, useRef } from 'react';
import { useImmer } from 'use-immer';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import {
    selectSelectedPatientDocument,
    selectSelectedPatientEntries,
    selectSelectedPatientResolvedAlerts,
} from '../../../../redux/selectedPatient/selectedPatientSelectors';
import { ActivityLogFeed, derivePatientActivityLog } from './historyTabFormatters';
import LoadingSpinner from '../../../../components/LoadingSpinner';

interface State {
    activityLog?: ActivityLogFeed;
    loading: boolean;
    error: unknown;
}

export default function PatientActivity() {
    const isMounted = useRef(false);
    const [state, updateState] = useImmer<State>({ loading: true, error: null });
    const selectedPatientEntries = useSelector((state: ReduxState) => selectSelectedPatientEntries(state));
    const selectedPatient = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const selectedPatientResolvedAlerts = useSelector((state: ReduxState) =>
        selectSelectedPatientResolvedAlerts(state)
    );

    useEffect(() => {
        (async () => {
            isMounted.current = true;
            if (
                selectedPatient &&
                selectedPatientEntries &&
                selectedPatientResolvedAlerts &&
                isMounted.current
            ) {
                try {
                    const activityLog = await derivePatientActivityLog({
                        entries: selectedPatientEntries,
                        patient: selectedPatient,
                        alerts: selectedPatientResolvedAlerts,
                    });
                    updateState(draft => void (draft.activityLog = activityLog));
                } catch (error) {
                    console.log(error);
                    updateState(draft => void (draft.error = error));
                }
            }
            updateState(draft => void (draft.loading = false));

            return () => {
                isMounted.current = false;
            };
        })();
    }, [selectedPatient, selectedPatientEntries]);
    if (state.loading) {
        return <LoadingSpinner type="page" />;
    } else if (!state.activityLog?.length) {
        return <div className="text-gray-700 my-2">This patient does not have any activities to log</div>;
    } else {
        return (
            <>
                {state.activityLog.map(([date, log], i) => (
                    <div
                        className="border border-gray-400 rounded-md bg-white text-gray-700 p-3 my-2"
                        key={i}
                    >
                        <h4 className="text-lg block mb-2">{date}</h4>
                        <ul>
                            {log.map((item, i) => (
                                <li key={i} className="flex flex-row justify-start items-start">
                                    <span className="font-semibold block mr-1 w-1/5">{item.timeStamp}</span>
                                    <div className="max-w-lg flex flex-col items-start text-left w-4/5">
                                        {item.activity.map((activity, i) => (
                                            <span key={i} className="block mb-2">
                                                {activity}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </>
        );
    }
}
