import React from 'react';
import { ButtonClick, FormSubmission } from '../../../types';
import SubmitButton from '../../../components/SubmitButton';
import { Theme } from '../../../theme';

export interface MFAEncouragementProps {
    setUserOptsToEnableMultifactorAuth: (e: FormSubmission) => void;
    skipMultiFactorAuthSetup: (e: ButtonClick) => Promise<void>;
}

export default function MFAEncouragement(props: MFAEncouragementProps) {
    return (
        <form className="w-full my-2" onSubmit={props.setUserOptsToEnableMultifactorAuth}>
            <p className="text-gray-600 text-left">
                We strongly encourage all platform users to enable multifactor authentication for account
                security. If you choose to enable it, you'll be asked to add your personal phone number to
                your account to verify your identity when logging in.
            </p>
            <div className="w-full flex pt-10 flex-col justify-center items-center">
                <SubmitButton className="w-full">Enable Multifactor Auth</SubmitButton>
                <button
                    type="button"
                    className={`text-${Theme.darkBlue} hover:text-${Theme.darkBlueHover} mt-2`}
                    onClick={props.skipMultiFactorAuthSetup}
                >
                    No thanks, continue to dashboard
                </button>
            </div>
        </form>
    );
}
