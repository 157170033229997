import React, { useEffect } from 'react';
import { LocalUserMedicationSchema } from '../../../../../redux/selectedPatient/selectedPatientReducer';
import { InputChange, ReactSelectState, SelectOption, ValidatableString } from '../../../../../types';
import { useImmer } from 'use-immer';
import { MedicationFormKeys } from '../medicationTypes';
import { pillsPerDoseValidator } from '../medicationFormValidation';
import TextInput from '../../../../../components/TextInput';
import Select from '../../../../../components/Select';
import { DeliveryType, OpioidType } from '../../../../../database/schemas/Medication';
import { opioidTypeLabels, renderDoseText, renderPillSizeText } from '../medicationFormLabels';
import { Theme } from '../../../../../theme';
import { getMedLabel } from '../../../patientList/patientTableFormatters';
import { isOpioidPrescription } from '../../../../../utils';

//this component will need to handle adding a new prescription to redux, but once it's added
//and not yet created (i.e. the user hasn't hit save yet) it will need to handle the user
//making updates to it -- action & cancelAction logic will be determined in parent MedicationTab

interface Props {
    prescription: LocalUserMedicationSchema;
    action: () => void;
    cancelAction: () => void;
    isEditing: boolean;
    isAdding: boolean;
    preventInput?: boolean;
    handleInput: (e: InputChange) => void;
    toggleArchivePrescriptionModal: () => void;
    alreadyExistsInRedux: boolean;
    handleDeliveryTypeSelect: (deliveryType: DeliveryType) => void;
    handleOpioidTypeSelect: (opioidType: OpioidType) => void;
}

interface State {
    pillsPerDose: ValidatableString & { showError?: boolean }; //intermediary value for storing dose.min & dose.max as string
    pillSizeOrConcentration: ValidatableString;
    formValid: boolean;
}

export default function PendingPrescriptionForm(props: Props) {
    const [state, updateState] = useImmer<State>({
        pillsPerDose: {
            value: '',
        },
        pillSizeOrConcentration: {
            value: '',
        },
        formValid: false,
    });

    useEffect(() => {
        updateState(draft => {
            draft.formValid =
                !!props.prescription.name &&
                !!props.prescription.prescription &&
                Object.values(props.prescription.prescription).every(value => value !== undefined) &&
                state.pillsPerDose.isValid === true &&
                props.prescription.unit.amount > 0 &&
                state.pillSizeOrConcentration.isValid === true &&
                !!props.prescription.unit.measure &&
                props.prescription.delivery !== null &&
                props.prescription.prescription.frequency > 0 &&
                ((props.prescription.prescription &&
                    isOpioidPrescription(props.prescription.prescription) &&
                    props.prescription.prescription?.count &&
                    props.prescription.prescription?.count.prescribed) > 0 ||
                    (props.prescription.prescription &&
                        !isOpioidPrescription(props.prescription.prescription)));
        });
    }, [
        state.pillSizeOrConcentration.isValid,
        state.pillsPerDose.isValid,
        props.prescription.prescription?.dose,
        props.prescription.prescription?.frequency,
        props.prescription.prescription &&
            isOpioidPrescription(props.prescription.prescription) &&
            props.prescription.prescription?.count,
        props.prescription.delivery,
        props.prescription.name,
        props.prescription.unit?.measure,
        props.prescription.unit?.amount,
        props.prescription.prescription &&
            isOpioidPrescription(props.prescription.prescription) &&
            props.prescription.prescription?.count &&
            props.prescription.prescription?.count.prescribed,
        props.prescription.prescription &&
            isOpioidPrescription(props.prescription.prescription) &&
            props.prescription.prescription?.opioid,
    ]);

    const validatePillsPerDoseOnBlur = () => {
        const isValid = pillsPerDoseValidator.isValid({
            value: state.pillsPerDose.value,
            deliveryType: props.prescription.delivery,
        });
        updateState(draft => {
            draft.pillsPerDose.isValid = isValid;
            draft.pillsPerDose.showError = !isValid;
        });
    };

    const handlePillsPerDoseInput = (e: InputChange) => {
        e.persist();
        props.handleInput(e);
        if (props.preventInput) {
            return;
        }
        updateState(draft => {
            draft.pillsPerDose.showError = false;
            draft.pillsPerDose.value = e.target.value;
            draft.pillsPerDose.isValid = pillsPerDoseValidator.isValid({
                value: e.target.value,
                deliveryType: props.prescription.delivery,
            });
        });
    };

    const handlePillSizeOrConcentrationInput = (e: InputChange) => {
        e.persist();
        props.handleInput(e);
        if (props.preventInput) {
            return;
        }
        updateState(draft => {
            draft.pillSizeOrConcentration = { value: e.target.value };
        });
    };

    const validatePillConcentration = () => {
        updateState(draft => {
            draft.pillSizeOrConcentration.isValid = pillsPerDoseValidator.decimalRegex.test(
                draft.pillSizeOrConcentration.value
            );
        });
    };

    const deliveryTypeSelect: ReactSelectState = {
        options: [
            { label: 'Pill', value: String(DeliveryType.pill) },
            { label: 'Liquid', value: String(DeliveryType.liquid) },
            { label: 'Patch', value: String(DeliveryType.patch) },
        ],
        get selectedOption() {
            return this.options.find(({ value }) => Number(value) === props.prescription.delivery);
        },
        handleSelect(option) {
            const deliveryType = Number((option as SelectOption).value) as DeliveryType;
            props.handleDeliveryTypeSelect(deliveryType);
        },
    };

    const opioidTypeSelect: ReactSelectState = {
        options: [
            { label: opioidTypeLabels[OpioidType.shortActing], value: String(OpioidType.shortActing) },
            { label: opioidTypeLabels[OpioidType.longActing], value: String(OpioidType.longActing) },
            { label: opioidTypeLabels[OpioidType.nonOpioid], value: String(OpioidType.nonOpioid) },
        ],
        get selectedOption() {
            return this.options.find(({ value }) => {
                return (
                    (props.prescription.prescription &&
                        isOpioidPrescription(props.prescription.prescription) &&
                        Number(value) === props.prescription.prescription?.opioid) ||
                    !props.prescription.prescription
                );
            });
        },
        handleSelect(option) {
            if ((option as SelectOption).value) {
                const opioidType = Number((option as SelectOption).value) as OpioidType;
                props.handleOpioidTypeSelect(opioidType);
            }
        },
    };

    return (
        <div className="w-full p-3">
            <div className="flex flex-row items-center justify-between">
                <div className="mr-4" style={{ width: '15%' }}>
                    <label htmlFor={MedicationFormKeys.name} className="block">
                        <span className="text-gray-700 font-semibold">Name</span>
                        <TextInput
                            className="py-1"
                            value={props.prescription.name}
                            name={MedicationFormKeys.name}
                            onChange={props.handleInput}
                        />
                    </label>
                </div>
                <div style={{ width: '10%' }} className="mr-2">
                    <label className="block" htmlFor={MedicationFormKeys.delivery}>
                        <span className="text-gray-700 font-semibold">Method</span>
                        <Select
                            onChange={deliveryTypeSelect.handleSelect}
                            options={deliveryTypeSelect.options}
                            selectedOption={deliveryTypeSelect.selectedOption}
                            placeholder={
                                deliveryTypeSelect.selectedOption ? undefined : ' ' //spaced string to sub as empty placeholder
                            }
                        />
                    </label>
                </div>
                <div style={{ width: '12.5%' }} className="mr-4">
                    <span className="text-gray-700 font-semibold">Opioid Type</span>
                    <Select
                        onChange={opioidTypeSelect.handleSelect}
                        options={opioidTypeSelect.options}
                        selectedOption={opioidTypeSelect.selectedOption}
                        placeholder={props.prescription.prescription ? undefined : ' '} //spaced string to sub as empty placeholder
                    />
                </div>
                <div className="mr-4" style={{ width: '11.5%' }}>
                    <label className="block" htmlFor={MedicationFormKeys.unitAmount}>
                        <span className="text-gray-700 font-semibold">
                            {renderPillSizeText(props.prescription.delivery)}
                        </span>
                        <div className="flex flex-row items-stretch w-full relative">
                            <TextInput
                                name={MedicationFormKeys.unitAmount}
                                value={state.pillSizeOrConcentration.value}
                                onChange={handlePillSizeOrConcentrationInput}
                                onBlur={validatePillConcentration}
                                className="py-1"
                            />
                            <span className="flex items-center leading-normal font-semibold p-1 whitespace-no-wrap text-gray-700 text-sm">
                                {props.prescription.unit?.measure}
                            </span>
                            {state.pillSizeOrConcentration.isValid === false && (
                                <span
                                    className="text-xs w-full text-red-500 absolute"
                                    style={{ bottom: '-35px' }}
                                >
                                    Please enter a valid{' '}
                                    {renderPillSizeText(props.prescription.delivery).toLocaleLowerCase()}
                                </span>
                            )}
                        </div>
                    </label>
                </div>
                <div
                    className="relative mr-4"
                    style={{ width: props.prescription.delivery === DeliveryType.patch ? '15%' : '12.5%' }}
                >
                    <label className="block" htmlFor={MedicationFormKeys.dose}>
                        <span className="text-gray-700 font-semibold">
                            {renderDoseText(props.prescription.delivery)}
                        </span>
                        <div
                            className={`flex flex-row items-stretch ${
                                props.prescription.delivery === DeliveryType.patch
                                    ? 'w-5/6 mx-auto'
                                    : 'w-full'
                            } relative`}
                        >
                            <TextInput
                                name={MedicationFormKeys.dose}
                                value={state.pillsPerDose.value}
                                onChange={handlePillsPerDoseInput}
                                onBlur={validatePillsPerDoseOnBlur}
                                inValid={state.pillsPerDose.showError}
                                className="py-1"
                            />
                            {props.prescription.delivery === DeliveryType.liquid && (
                                <span className="flex items-center leading-normal font-semibold p-1 whitespace-no-wrap text-gray-700 text-sm">
                                    mL
                                </span>
                            )}
                        </div>
                    </label>
                    {state.pillsPerDose.showError && (
                        <span
                            className="text-xs w-full text-red-500 absolute text-center bg-white rounded-md shadow-md z-50 p-2 border border-dashed border-red-500"
                            style={{ bottom: '-85px' }}
                        >
                            {props.prescription.delivery === DeliveryType.patch
                                ? 'Please enter a valid range with only whole values'
                                : 'Please enter a valid range with only whole or half values.'}
                        </span>
                    )}
                </div>
                <div className="text-left mr-4" style={{ width: '11.5%' }}>
                    <label htmlFor={MedicationFormKeys.prescriptionFrequency} className="block">
                        <span className="text-gray-700 font-semibold">Interval</span>
                        <div className="w-full flex flex-row items-center justify-start">
                            <TextInput
                                className={`form-input focus:outline-none border py-1 border-gray-400 w-1/12 block`}
                                value={String(props.prescription.prescription?.frequency ?? '') ?? ''}
                                name={MedicationFormKeys.prescriptionFrequency}
                                onChange={props.handleInput}
                            />
                            <span className="text-gray-700 font-semibold text-sm ml-2">hours</span>
                        </div>
                    </label>
                </div>
                <div className="mr-4" style={{ width: '6%' }}>
                    <label className="block" htmlFor={MedicationFormKeys.prescriptionCountPrescribed}>
                        {props.prescription.prescription &&
                            isOpioidPrescription(props.prescription.prescription) && (
                                <span className="text-gray-700 font-semibold">Qty</span>
                            )}
                        {props.prescription.prescription &&
                            isOpioidPrescription(props.prescription.prescription) && (
                                <TextInput
                                    name={MedicationFormKeys.prescriptionCountPrescribed}
                                    value={
                                        String(props.prescription.prescription?.count?.prescribed ?? '') ?? ''
                                    }
                                    onChange={props.handleInput}
                                    className="py-1"
                                    hidden={
                                        !(
                                            props.prescription.prescription &&
                                            isOpioidPrescription(props.prescription.prescription)
                                        )
                                    }
                                />
                            )}
                    </label>
                </div>
                {props.prescription.prescription && isOpioidPrescription(props.prescription.prescription) ? (
                    <div className="mt-6 text-xs" style={{ width: '8%' }}>
                        <label className="block" htmlFor={MedicationFormKeys.prescriptionCountPrescribed}>
                            <span className="py-1 break-words">
                                {props.prescription.prescription?.count?.remaining !== undefined
                                    ? props.prescription.prescription.count.remaining === null
                                        ? `--- ${getMedLabel({
                                              count: props.prescription.prescription.count.remaining,
                                              delivery: props.prescription.delivery,
                                          })} remaining`
                                        : `${props.prescription.prescription.count.remaining} ${getMedLabel({
                                              count: props.prescription.prescription.count.remaining,
                                              delivery: props.prescription.delivery,
                                          })} remaining`
                                    : ''}
                            </span>
                        </label>
                    </div>
                ) : (
                    <div className="mt-6 text-xs" style={{ width: '8%' }}></div>
                )}
            </div>
            <div className="w-full flex flex-row items-center justify-end">
                {props.alreadyExistsInRedux && !props.isEditing ? (
                    <div className={`px-2 py-1 rounded-md font-semibold mt-5 text-gray-600`}>
                        Changes Pending
                    </div>
                ) : props.isEditing || props.isAdding ? (
                    <div className="flex flex-col justify-center pt-5">
                        <button
                            className={`px-2 py-1 rounded-md focus:outline-none bg-${
                                Theme.lightBlue
                            } hover:bg-${Theme.lightBlueHover} text-white ${
                                !state.formValid ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            disabled={!state.formValid}
                            onClick={e => {
                                e.stopPropagation();
                                props.action();
                            }}
                        >
                            {'Add to Changes'}
                        </button>
                        <button
                            className="px-2 py-1 focus:outline-none font-normal text-gray-600 hover:text-gray-600"
                            onClick={e => {
                                e.stopPropagation();
                                props.cancelAction();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                ) : (
                    //this is a bit hacky, but there is a split second when a prescription is being added that the Add/Cancel buttons would flash,
                    //so this serves as a placeholder to make the state update visually smoother
                    <div
                        className={`px-2 py-1 rounded-md focus:outline-none bg-${Theme.darkBlue} font-semibold mt-5 text-white hover:bg=${Theme.darkBlueHover}`}
                    >
                        Changes Pending
                    </div>
                )}
            </div>
        </div>
    );
}
