import React from 'react';
import TextInput from '../../../../../components/TextInput';
import { MedicationFormKeys } from '../medicationTypes';
import { toPascalCase } from '../../../../../utils';
import { DeliveryType } from '../../../../../database/schemas/Medication';
import { renderPillSizeText } from '../medicationFormLabels';
import { Theme } from '../../../../../theme';
import { UserMedicationDocument } from '../../../../../database/documents/UserMedicationDocument';

interface Props {
    toggleArchivedMedicationModal: () => void;
    medication: UserMedicationDocument;
}

export default function MedicationForm(props: Props) {
    return (
        <div className="w-full p-3">
            <div className="flex flex-row items-center justify-start mb-6">
                <div className="mr-1 w-1/6">
                    <label className="block">
                        <span className="text-gray-700 font-semibold">Name</span>
                        <TextInput disabled={true} className="py-1" value={props.medication.data.name} />
                    </label>
                </div>
                <div style={{ width: '8%' }} className="px-2">
                    <label className="block" htmlFor={MedicationFormKeys.delivery}>
                        <span className="text-gray-700 font-semibold">Method</span>
                        <div className="flex flex-row relative">
                            <TextInput
                                name={MedicationFormKeys.delivery}
                                value={toPascalCase(DeliveryType[props.medication.data.delivery]) ?? ''}
                                disabled={true}
                                className="py-1"
                            />
                        </div>
                    </label>
                </div>
                <div className="mr-8 px-2" style={{ width: '10%' }}>
                    <label className="block" htmlFor={MedicationFormKeys.unitAmount}>
                        <span className="text-gray-700 font-semibold">
                            {renderPillSizeText(props.medication.data.delivery)}
                        </span>
                        <span className="block flex flex-1 items-center leading-normal p-1 whitespace-no-wrap">
                            {String(props.medication.data.unit?.amount ?? '')}
                            {props.medication.data.unit?.measure}
                        </span>
                    </label>
                </div>
                <div className="ml-auto">
                    <button
                        onClick={props.toggleArchivedMedicationModal}
                        className={`px-2 py-1 rounded-md focus:outline-none font-semibold mt-5 text-white bg-${Theme.darkBlue} hover:bg-${Theme.darkBlueHover}`}
                    >
                        Remove
                    </button>
                </div>
            </div>
        </div>
    );
}
