import React, { useContext, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Theme } from '../../../../theme';
import {
    FormSubmission,
    InputChange,
    ReactSelectOption,
    ReactSelectState,
    ReactSelectValue,
    SelectOption,
} from '../../../../types';
import TextInput from '../../../../components/TextInput';
import SubmitButton from '../../../../components/SubmitButton';
import { useImmer } from 'use-immer';
import Select from '../../../../components/Select';
import FunctionsManager from '../../../../functions/FunctionsManager';
import {
    clearSelectedPatientForm,
    handlePatientFormInput,
} from '../../../../redux/selectedPatient/selectedPatientActions';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import {
    selectSelectedPatientDocument,
    selectSelectedPatientForm,
} from '../../../../redux/selectedPatient/selectedPatientSelectors';
import {
    selectSelectedOrganizationActiveOpioidAgreements,
    selectSelectedOrganizationProviders,
} from '../../../../redux/selectedOrganization/selectedOrganizationSelectors';
import dateFNSFormat from 'date-fns/format';
import { Gender, PatientKeys } from '../../../../database/schemas/Patient';
import { selectToastAlert } from '../../../../redux/currentSession/currentSessionSelectors';
import ToastAlert from '../../../../components/ToastAlert';
import { setToastError } from '../../../../redux/currentSession/currentSessionActions';
import ErrorMessage from '../../../../components/ErrorMessage';
import { InviteStatus } from '../../../../functions/userTypes';
import {
    getDateFromDateString,
    isValidEmail,
    isValidPhoneNumber,
    unformatPhoneNumber,
} from '../../../../utils';
import { UserRoles } from '../../../../database/schemas/User';
import { navigate } from '@reach/router';
import useNavigation from '../../../../hooks/useNavigation';
import { CurrentUserContext } from '../../../../context/CurrentUserContextProvider';
import { UrlStateKeys } from '../../../../routes';

interface Props {
    loading?: boolean;
    addingPatient: boolean;
}

interface State {
    formValid?: boolean;
    inviteStatus: InviteStatus;
    emailValidation: { isValid?: boolean; showError?: boolean };
    phoneNumberValidation: { isValid?: boolean; showError?: boolean };
}

export default function PatientForm(props: Props) {
    const selectedPatientForm = useSelector((state: ReduxState) => selectSelectedPatientForm(state));
    const selectedPatientDocument = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const selectedOrganizationProviders = useSelector((state: ReduxState) =>
        selectSelectedOrganizationProviders(state)
    );
    const selectedOpioidAgreements = useSelector((state: ReduxState) =>
        selectSelectedOrganizationActiveOpioidAgreements(state)
    );
    const navigation = useNavigation();
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const currentUser = useContext(CurrentUserContext);
    const dispatch = useDispatch();
    const [state, updateState] = useImmer<State>({
        inviteStatus: { fetching: false, data: undefined },
        emailValidation: {},
        phoneNumberValidation: {},
    });
    useEffect(() => {
        const { mrn, ...patientForm } = selectedPatientForm;
        updateState(draft => {
            draft.formValid =
                !!patientForm.emergencyPhoneNumber &&
                draft.phoneNumberValidation.isValid !== false &&
                !!selectedPatientForm.email &&
                draft.emailValidation.isValid !== false &&
                !!selectedPatientForm.firstName &&
                !!selectedPatientForm.lastName &&
                !!selectedPatientForm.dob &&
                !!selectedPatientForm.assignedPrescriber;
        });
    }, [
        selectedPatientForm.firstName,
        selectedPatientForm.lastName,
        selectedPatientForm.assignedPrescriber,
        selectedPatientForm.dob,
        selectedPatientForm.email,
        selectedPatientForm.emergencyPhoneNumber,
        selectedPatientForm.gender,
    ]);

    const handleInput = (e: InputChange) => {
        e.persist();
        updateState(draft => {
            if (e.target.name === PatientKeys.email) {
                draft.emailValidation.showError = false;
                draft.emailValidation.isValid = isValidEmail(e.target.value);
            }
            if (e.target.name === PatientKeys.emergencyPhoneNumber) {
                draft.phoneNumberValidation.showError = false;
                draft.phoneNumberValidation.isValid = isValidPhoneNumber(unformatPhoneNumber(e.target.value));
            }
        });
        dispatch(
            handlePatientFormInput({
                path: e.target.name as PatientKeys,
                value: e.target.value,
            })
        );
    };

    const handlePhoneNumberBlur = () => {
        updateState(draft => {
            draft.phoneNumberValidation.showError = draft.phoneNumberValidation.isValid === false;
        });
    };

    const handleEmailBlur = () => {
        updateState(draft => {
            draft.emailValidation.showError = draft.emailValidation.isValid === false;
        });
    };

    const handleDateOfBirth = (date: Date) => {
        dispatch(handlePatientFormInput({ path: PatientKeys.dob, value: dateFNSFormat(date, 'yyyy-MM-dd') }));
    };

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => void (draft.inviteStatus.fetching = true));
        try {
            const emailIsEligible = await FunctionsManager.invite.isEligible({
                email: selectedPatientForm.email,
                role: UserRoles.patient,
            });
            if (emailIsEligible) {
                await FunctionsManager.invite.patient(selectedPatientForm, currentUser.claims!.currentOrgId!);
                dispatch(clearSelectedPatientForm());
                await navigate(navigation.getPatientsUrl(currentUser.claims!.currentOrgId!), {
                    state: { [UrlStateKeys.patientInviteSuccess]: true },
                });
            } else {
                updateState(draft => void (draft.inviteStatus.error = { emailAlreadyInUse: true }));
            }
        } catch (error) {
            console.log(error);
            dispatch(setToastError('An error occurred while attempting to send the invite'));
        }
        updateState(draft => void (draft.inviteStatus.fetching = false));
    };

    const providerSelect: ReactSelectState = {
        options: selectedOrganizationProviders.map(({ data, id }) => ({
            label: `${data.lastName}, ${data.firstName}`,
            value: id,
        })),
        get selectedOption() {
            return this.options.find(({ value }) => value === selectedPatientForm.assignedPrescriber);
        },
        handleSelect(option: ReactSelectValue) {
            dispatch(
                handlePatientFormInput({
                    path: PatientKeys.assignedPrescriber,
                    value: (option as SelectOption).value,
                })
            );
        },
    };

    const buildOpioidAgreementOptions = () => {
        const selectableAgreementOptions: ReactSelectOption[] = [{ label: 'Not Required', value: '' }];
        selectedOpioidAgreements.forEach(agreement => {
            selectableAgreementOptions.push({
                label: agreement.data.title,
                value: agreement.id,
            });
        });
        return selectableAgreementOptions;
    };

    const opioidAgreementSelect: ReactSelectState = {
        options: buildOpioidAgreementOptions(),
        get selectedOption() {
            return this.options.find(({ value }) => value === selectedPatientForm.opioidAgreementId);
        },
        handleSelect(option: ReactSelectValue) {
            dispatch(
                handlePatientFormInput({
                    path: PatientKeys.opioidAgreementId,
                    value: (option as SelectOption).value,
                })
            );
        },
    };

    return (
        <>
            {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
            {props.addingPatient && (
                <div className="flex flex-row justify-start items-center">
                    <h2 className="text-3xl text-gray-800 ml-3">Invite Patient</h2>
                    {state.inviteStatus.error?.emailAlreadyInUse && (
                        <ErrorMessage className="ml-3">
                            There is already a patient associated with this email address
                        </ErrorMessage>
                    )}
                </div>
            )}
            <form className="w-full px-4 pb-5" onSubmit={handleSubmit}>
                <div className="flex flex-wrap">
                    <div className="w-full my-2">
                        <label className="block font-semibold" htmlFor={PatientKeys.firstName}>
                            <span className="text-gray-700">First Name</span>
                            <TextInput
                                name={PatientKeys.firstName}
                                value={selectedPatientForm.firstName ?? ''}
                                onChange={handleInput}
                                className="py-1"
                            />
                        </label>
                    </div>
                    <div className="w-full my-2">
                        <label className="block font-semibold" htmlFor={PatientKeys.lastName}>
                            <span className="text-gray-700">Last Name</span>
                            <TextInput
                                name={PatientKeys.lastName}
                                value={selectedPatientForm.lastName ?? ''}
                                onChange={handleInput}
                                className="py-1"
                            />
                        </label>
                    </div>
                </div>
                <div className="w-full mb-2 flex flex-row items-center justify-start">
                    <label className="block font-semibold" htmlFor={PatientKeys.dob}>
                        <label className="text-gray-700 block">Date of Birth</label>
                        <ReactDatePicker
                            selected={
                                selectedPatientForm.dob
                                    ? new Date(getDateFromDateString(selectedPatientForm.dob))
                                    : undefined
                            }
                            onChange={handleDateOfBirth}
                            className="form-input mt-1 block w-full border border-gray-400 py-1"
                            showPopperArrow={true}
                        />
                    </label>
                    <div className="mb-2 ml-8 mt-2">
                        <span className="text-gray-700 font-semibold">Gender</span>
                        <div className="mt-3">
                            <label
                                className="inline-flex items-center mr-6 font-semibold"
                                htmlFor={Gender.MALE}
                            >
                                <input
                                    type="radio"
                                    className={`form-radio outline-none text-${Theme.lightBlue} hover:text-${Theme.lightBlueHover}`}
                                    name={PatientKeys.gender}
                                    value={Gender.MALE}
                                    checked={selectedPatientForm.gender === Gender.MALE}
                                    onChange={handleInput}
                                />
                                <span className="ml-2">Male</span>
                            </label>
                            <label className="inline-flex items-center font-semibold" htmlFor={Gender.FEMALE}>
                                <input
                                    type="radio"
                                    className={`form-radio outline-none text-${Theme.lightBlue} hover:text-${Theme.lightBlueHover}`}
                                    name={PatientKeys.gender}
                                    value={Gender.FEMALE}
                                    checked={selectedPatientForm.gender === Gender.FEMALE}
                                    onChange={handleInput}
                                />
                                <span className="ml-2">Female</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap">
                    <div className="w-full my-2">
                        <label className="block font-semibold" htmlFor={PatientKeys.email}>
                            <span className="text-gray-700">Email</span>
                            <TextInput
                                name={PatientKeys.email}
                                value={selectedPatientForm.email ?? ''}
                                onChange={handleInput}
                                className={`py-1 ${
                                    state.inviteStatus.error?.emailAlreadyInUse ||
                                    state.emailValidation.showError
                                        ? 'border border-red-500'
                                        : ''
                                }`}
                                onBlur={handleEmailBlur}
                                disabled={!!selectedPatientDocument?.data.authId}
                            />
                            {state.emailValidation.showError && (
                                <p className="text-red-500 font-normal">Please enter a valid email address</p>
                            )}
                        </label>
                    </div>
                    <div className="w-full my-2">
                        <label className="block font-semibold" htmlFor={PatientKeys.email}>
                            <span className="text-gray-700">Emergency Contact Phone</span>
                            <TextInput
                                name={PatientKeys.emergencyPhoneNumber}
                                value={selectedPatientForm.emergencyPhoneNumber ?? ''}
                                onChange={handleInput}
                                onBlur={handlePhoneNumberBlur}
                                className={`py-1 ${
                                    state.phoneNumberValidation.showError ? 'border border-red-500' : ''
                                }`}
                            />
                            {state.phoneNumberValidation.showError && (
                                <p className="text-red-500 font-normal">Please enter a valid phone number</p>
                            )}
                        </label>
                    </div>
                    <div className="w-full my-2">
                        <label className="block font-semibold" htmlFor={PatientKeys.mrn}>
                            <span className="text-gray-700">MRN #</span>
                            {props.addingPatient && (
                                <span className="text-gray-600 text-xs ml-1 font-normal">(optional)</span>
                            )}
                            <TextInput
                                name={PatientKeys.mrn}
                                value={selectedPatientForm.mrn ?? ''}
                                onChange={handleInput}
                                className="py-1"
                            />
                        </label>
                    </div>
                    <div className="w-full my-2">
                        <Select
                            label="Assigned Prescriber"
                            options={providerSelect.options}
                            selectedOption={providerSelect.selectedOption}
                            placeholder={providerSelect.selectedOption ? undefined : 'Select prescriber'}
                            onChange={providerSelect.handleSelect}
                        />
                    </div>
                    {props.addingPatient && (
                        <div className="w-full my-2">
                            <Select
                                label="Opioid Agreement"
                                options={opioidAgreementSelect.options}
                                selectedOption={opioidAgreementSelect.selectedOption}
                                placeholder={
                                    opioidAgreementSelect.selectedOption ? undefined : 'Select agreement'
                                }
                                onChange={opioidAgreementSelect.handleSelect}
                            />
                        </div>
                    )}
                </div>
                {props.addingPatient && (
                    <div className="flex flex-row justify-end mt-2 items-center">
                        <SubmitButton
                            disabled={!state.formValid}
                            loading={state.inviteStatus.fetching}
                            className={`bg-${Theme.lightBlue} hover:bg-${Theme.lightBlueHover} rounded-md text-white text-lg font-semibold px-2 py-1 border-none focus:outline-none`}
                        >
                            Send Invite
                        </SubmitButton>
                    </div>
                )}
            </form>
        </>
    );
}
