import { OrganizationRolesObject } from './User';

export enum OrganizationRoles {
    orgAdmin,
    provider,
    patient,
}

export const getOrganizationRoles: {
    orgAdmin: (alsoAddProvider?: boolean) => OrganizationRolesObject;
    provider: (alsoAddOrgAdmin?: boolean) => OrganizationRolesObject;
    patient: () => OrganizationRolesObject;
} = {
    orgAdmin: (alsoAddProvider: boolean = false) => getProviderAndOrOrgAdmin(true, alsoAddProvider),
    provider: (alsoAddOrgAdmin: boolean = false) => getProviderAndOrOrgAdmin(alsoAddOrgAdmin, true),
    patient: () => ({
        0: false,
        1: false,
        2: true,
    }),
};

function getProviderAndOrOrgAdmin(orgAdmin: boolean, provider: boolean): OrganizationRolesObject {
    return {
        0: orgAdmin,
        1: provider,
        2: false,
    };
}
