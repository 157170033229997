import React, { ReactNode } from 'react';
import { FormSubmission } from '../types';
import { Theme } from '../theme';

interface Props {
    onSubmit: (e: FormSubmission) => void;
    children: ReactNode;
    className?: string;
}

export default function SplashPageForm(props: Props) {
    return (
        <form
            className={`${props.className} bg-${Theme.softGray} shadow-lg border rounded px-8 pt-6 pb-8 mb-4`}
            onSubmit={props.onSubmit}
        >
            {props.children}
        </form>
    );
}
