import { DocumentConstructor, FirestoreOptions, QueryItem, QueryParams } from './DatabaseTypes';
import { BaseDocument } from './documents/BaseDocument';
import {
    CollectionReference,
    DocumentReference,
    DocumentSnapshot,
    Firestore,
    FirestoreQuery,
    QuerySnapshot,
} from './FirestoreTypes';
import { firestore } from 'firebase/app';
import { Timestamps } from './schemas/Timestamps';

export abstract class FirestoreAdapter {
    protected constructor(protected firestore: Firestore, protected documentType: string) {}

    /**
     * @param documentConstructor
     * @param collectionPath
     * @param data
     * @param id is an optional that will force the document to be created with a known id; used for "provider-access"
     */
    protected async createDocument<S extends Timestamps, D extends BaseDocument<S>>(
        documentConstructor: DocumentConstructor<S, D>,
        collectionPath: string,
        data: Omit<S, 'createdAt'>,
        id?: string
    ): Promise<D> {
        try {
            let ref: DocumentReference<S>;

            if (id) {
                ref = this.firestore.collection(collectionPath).doc(id) as DocumentReference<S>;
            } else {
                ref = this.firestore.collection(collectionPath).doc() as DocumentReference<S>;
            }

            const dataWithCreatedAt = {
                ...data,
                createdAt: firestore.Timestamp.now(),
            } as S;
            await ref.set(dataWithCreatedAt);
            const snapshot = await ref.get();
            return new documentConstructor(snapshot as DocumentSnapshot<S>);
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'FirebaseAdapter.createDocument()',
                error,
                collectionPath,
                dataWithCreatedAt: {
                    ...data,
                    createdAt: firestore.Timestamp.now(),
                },
                id,
            });
            //todo log and throw correct error
            throw error;
        }
    }

    protected async getDocument<S extends Timestamps, D extends BaseDocument<S>>(
        documentConstructor: DocumentConstructor<S, D>,
        documentPath: string
    ): Promise<D> {
        try {
            const snapshot = await this.firestore
                .doc(documentPath)
                .get()
                .catch(error => {
                    console.warn(`ERROR in getDocuments at documentPath: ${documentPath} 👇🏼`);
                    console.error(error);
                    //todo log and throw correct error
                    throw error;
                });

            if (!snapshot.exists) {
                //todo log and throw correct error
                throw 'doc doesn\'t exist';
            }

            return new documentConstructor(snapshot as DocumentSnapshot<S>);
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: `${this.documentType}.getDocument()`,
                error,
            });
            throw error;
        }
    }

    protected async getAllDocuments<S extends Timestamps, D extends BaseDocument<S>>(
        documentConstructor: DocumentConstructor<S, D>,
        collectionPath: string,
        options: FirestoreOptions<S> = {}
    ): Promise<D[]> {
        const ref = this.firestore.collection(collectionPath);

        try {
            const refWithOptions = FirestoreAdapter.applyFirebaseOptions(ref, options);

            const querySnapshot = (await refWithOptions.get()) as QuerySnapshot<S>;

            return querySnapshot.docs.map(snapshot => new documentConstructor(snapshot));
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: `${this.documentType}.getAllDocuments()`,
                error,
            });
            throw error;
        }
    }

    protected async queryDocuments<S extends Timestamps, D extends BaseDocument<S>>(
        documentConstructor: DocumentConstructor<S, D>,
        collectionPath: string,
        params: QueryParams<S>
    ): Promise<D[]> {
        const { queries, ...options } = params;

        if (queries.length === 0) {
            //todo log and throw correct error
            throw 'queries can\'t be empty';
        }

        const query = queries.reduce((combinedQuery: FirestoreQuery<S>, newQuery: QueryItem<S>) => {
            return combinedQuery.where(newQuery[0] as string, newQuery[1], newQuery[2]);
        }, this.firestore.collection(collectionPath) as CollectionReference<S>);

        const queryWithOptions = FirestoreAdapter.applyFirebaseOptions(query, options);
        try {
            const querySnapshot = (await queryWithOptions.get()) as QuerySnapshot<S>;
            return querySnapshot.docs.map(snapshot => new documentConstructor(snapshot));
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: `${this.documentType}.queryDocuments()`,
                params,
                error,
            });
            throw error;
        }
    }

    protected async queryCollectionGroup<S extends Timestamps, D extends BaseDocument<S>>(
        documentConstructor: DocumentConstructor<S, D>,
        collectionGroup: string,
        params: QueryParams<S>
    ): Promise<D[]> {
        const { queries, ...options } = params;

        if (queries.length == 0) {
            //todo log and throw correct error
            throw 'queries can\'t be empty';
        }

        try {
            const query = queries.reduce((combinedQuery: FirestoreQuery, newQuery: QueryItem<S>) => {
                return combinedQuery.where(newQuery[0] as string, newQuery[1], newQuery[2]);
            }, this.firestore.collectionGroup(collectionGroup));

            const queryWithOptions = FirestoreAdapter.applyFirebaseOptions(query, options);

            const querySnaphot = (await queryWithOptions.get()) as QuerySnapshot<S>;

            return querySnaphot.docs.map(snapshot => new documentConstructor(snapshot));
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: `${this.documentType}.queryCollectionGroup()`,
                error,
            });
            throw error;
        }
    }

    protected async updateDocument<S extends Timestamps>(
        documentPath: string,
        update: Partial<S>
    ): Promise<void> {
        try {
            update.updatedAt = firestore.FieldValue.serverTimestamp() as firestore.Timestamp;
            await this.firestore.doc(documentPath).update(update);
        } catch (error) {
            console.warn(`ERROR in updateDocument at documentPath: ${documentPath} 👇🏼`);
            console.error(error);
            //todo log and throw correct error
            throw `${this.documentType} does not exist or you do not have access.`;
        }
    }

    private static applyFirebaseOptions(
        reference: FirestoreQuery | CollectionReference,
        { orderBy, limit, startAfter, startAt, endBefore, endAt }: FirestoreOptions<any>
    ): FirestoreQuery | CollectionReference {
        // orderBy must be present for additional query options to work
        if (orderBy) {
            for (const [field, descending] of orderBy) {
                reference = reference.orderBy(field, descending ? 'desc' : 'asc');
            }

            // add other options
            reference = limit ? reference.limit(limit) : reference;

            // add one or the other of after or at options
            reference = startAfter
                ? reference.startAfter(startAfter)
                : startAt
                ? reference.startAt(startAt)
                : reference;

            reference = endBefore
                ? reference.endBefore(endBefore)
                : endAt
                ? reference.endAt(endAt)
                : reference;
        }

        return reference;
    }
}
