import { DocumentConstructor } from '../DatabaseTypes';
import { Firestore } from '../FirestoreTypes';
import { BaseDocument } from '../documents/BaseDocument';
import { UserDocument } from '../documents/UserDocument';
import { BaseModel } from './BaseModel';
import { UserSchema } from '../schemas/User';

export class UserModel<
    S extends UserSchema = UserSchema,
    D extends BaseDocument<S> = UserDocument<S>
> extends BaseModel<S, D> {
    constructor(firestore: Firestore, document: DocumentConstructor<S, D>, documentType: string = 'User') {
        super(firestore, documentType, 'users', document);
    }
}
