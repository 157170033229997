import React, { useCallback, useContext, useEffect } from 'react';
import { Theme } from '../theme';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import Navigator, { NonAuthRoutes } from '../routes';
import IconManager, { IconType } from '../components/IconManager';
import { CurrentUserContext } from '../context/CurrentUserContextProvider';
import { useImmer } from 'use-immer';
import { useDispatch, useSelector } from 'react-redux';
import { clearToastAlerts } from '../redux/currentSession/currentSessionActions';
import { clearSelectedOrganization } from '../redux/selectedOrganization/selectedOrganizationActions';
import Modal from './Modal';
import OrganizationSelect from '../pages/OrganizationSelect';
import { ReduxState } from '../redux/store';
import { selectSelectedOrganization } from '../redux/selectedOrganization/selectedOrganizationSelectors';
import useNavigation from '../hooks/useNavigation';
import { UserRoles } from '../database/schemas/User';
import AuthManager from '../AuthManager';
import { BrowserStorageKeys, FormSubmission } from '../types';
import ConfirmationModal from './ConfirmationModal';
import {
    selectSelectedPatientDocument,
    selectSelectedPatientUpdates,
} from '../redux/selectedPatient/selectedPatientSelectors';
import PageContainer from './PageContainer';
import { cancelChangesToPatient } from '../redux/selectedPatient/selectedPatientActions';

enum ModalScenarios {
    CHANGING_ORGANIZATION,
    CONFIRMING_SIGNOUT,
    UNSAVED_PATIENT_UPDATES,
}

interface State {
    modalScenario?: ModalScenarios;
    currentLink: string; //use for navigating after canceling patient updates
}

export default function NavBar(): JSX.Element | null {
    const [state, updateState] = useImmer<State>({ currentLink: '' });
    const currentUser = useContext(CurrentUserContext);
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const navigation = useNavigation();
    const selectedPatientUpdates = useSelector((state: ReduxState) => selectSelectedPatientUpdates(state));
    const selectedPatient = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const dispatch = useDispatch();
    const homeLinkForUser = (() => {
        if (
            currentUser.claims?.currentOrgId &&
            (currentUser.claims?.isOrgAdminInCurrentOrganization() ||
                currentUser.claims?.isProviderInCurrentOrganization())
        ) {
            if (currentUser.viewContext !== null) {
                return currentUser.viewContext === UserRoles.orgAdmin
                    ? navigation.getOrganizationDetailsUrl(currentUser.claims.currentOrgId)
                    : navigation.getPatientsUrl(currentUser.claims.currentOrgId);
            } else {
                return currentUser.claims?.isOrgAdminInCurrentOrganization()
                    ? navigation.getOrganizationDetailsUrl(currentUser.claims.currentOrgId)
                    : navigation.getPatientsUrl(currentUser.claims.currentOrgId);
            }
        } else {
            //user is super admin
            return navigation.adminOrganizationsUrl;
        }
    })();

    useEffect(() => {
        return () => {
            toggleModal();
        };
    }, [
        currentUser.loading,
        currentUser.claims,
        // window.location.pathname,
        currentUser.inAccountCreationFlow,
        currentUser.viewContext,
    ]);

    const toggleModal = (scenario?: ModalScenarios, linkToSet?: string) => {
        updateState(draft => {
            draft.modalScenario = scenario;
        });
        if (linkToSet) {
            if (linkToSet === 'changeOrg')
                updateState(draft => {
                    draft.currentLink = linkToSet;
                });
        }
    };

    const handleLogout = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        await AuthManager.logout();
        await Promise.all([dispatch(clearSelectedOrganization()), dispatch(clearToastAlerts())]);
        await navigate(navigation.loginUrl);
    };

    const cancelChangesAndPop = () => {
        dispatch(cancelChangesToPatient());
        toggleModal();
        if (state.currentLink !== 'changeOrg') {
            navigate(state.currentLink);
        } else {
            toggleModal(ModalScenarios.CHANGING_ORGANIZATION);
        }
    };

    // If a user has 2 roles in the current org, then we show view context
    const showViewContextSelection = () => {
        return (
            (currentUser.claims?.isOrgAdminInCurrentOrganization() &&
                currentUser.claims?.isProviderInCurrentOrganization()) ||
            (currentUser.claims?.isOrgAdminInCurrentOrganization() && currentUser.claims?.isAppAdmin) ||
            (currentUser.claims?.isProviderInCurrentOrganization() && currentUser.claims?.isAppAdmin)
        );
    };

    return (
        <>
            <div
                className={`flex flex-row text-white justify-between items-center py-3 px-5 relative bg-${Theme.darkBlue}`}
            >
                <div
                    className="text-3xl font-bold cursor-pointer"
                    onClick={() =>
                        !!selectedPatientUpdates.length
                            ? toggleModal(ModalScenarios.UNSAVED_PATIENT_UPDATES, homeLinkForUser)
                            : navigate(homeLinkForUser)
                    }
                >
                    <img src={require('../img/logo-horizontal.png')} className="h-12" />
                </div>
                {showViewContextSelection() && (
                    <ViewContextSelection
                        navigation={navigation}
                        toggleSavingPatientUpdatesModal={(link: string) =>
                            toggleModal(ModalScenarios.UNSAVED_PATIENT_UPDATES, link)
                        }
                    />
                )}
                <Nav className="font-thin text-xl flex flex-col justify-start items-end">
                    <DropDown
                        isInMultipleOrganizations={currentUser.claims?.isInMultipleOrganizations()}
                        isProvider={currentUser.claims?.isProviderInCurrentOrganization()}
                        navigation={navigation}
                        toggleOrganizationSelectVisible={() =>
                            !selectedPatientUpdates.length
                                ? toggleModal(ModalScenarios.CHANGING_ORGANIZATION)
                                : toggleModal(ModalScenarios.UNSAVED_PATIENT_UPDATES, 'changeOrg')
                        }
                        username={`${currentUser.document?.data.firstName} ${currentUser.document?.data.lastName}`}
                        presentSignOutConfirmation={() => toggleModal(ModalScenarios.CONFIRMING_SIGNOUT)}
                        selectedOrgId={currentUser.claims?.currentOrgId!}
                        toggleSavingPatientUpdatesModal={(link: string) =>
                            toggleModal(ModalScenarios.UNSAVED_PATIENT_UPDATES, link)
                        }
                    />
                    {currentUser.claims?.isInOrganizations() && (
                        <span className="ml-2">{selectedOrganization?.data.name}</span>
                    )}
                </Nav>
            </div>
            {state.modalScenario === ModalScenarios.CHANGING_ORGANIZATION && (
                <Modal isOpen={true} closeModal={() => toggleModal()}>
                    <OrganizationSelect isChangingOrganization={true} closeModal={() => toggleModal()} />
                </Modal>
            )}
            {state.modalScenario === ModalScenarios.CONFIRMING_SIGNOUT && (
                <ConfirmationModal onConfirm={handleLogout} isOpen={true} closeModal={() => toggleModal()}>
                    <p>
                        Are you sure you want to sign out?{' '}
                        {!!selectedPatientUpdates.length && 'Your unsaved changes will be discarded.'}
                    </p>
                </ConfirmationModal>
            )}
            {state.modalScenario === ModalScenarios.UNSAVED_PATIENT_UPDATES && (
                <ConfirmationModal
                    isOpen={true}
                    closeModal={() => toggleModal()}
                    onConfirm={cancelChangesAndPop}
                >
                    <p>
                        Are you sure you want to discard your unsaved change(s) to{' '}
                        {selectedPatient?.data.firstName}'s record?
                    </p>
                </ConfirmationModal>
            )}
        </>
    );
}

interface DropDownProps {
    username?: string;
    presentSignOutConfirmation: () => void;
    toggleOrganizationSelectVisible: () => void;
    navigation: Navigator;
    isInMultipleOrganizations?: boolean;
    isProvider?: boolean;
    selectedOrgId: string;
    toggleSavingPatientUpdatesModal: (link: string) => void;
}

function DropDown(props: DropDownProps): JSX.Element {
    const selectedPatientUpdates = useSelector((state: ReduxState) => selectSelectedPatientUpdates(state));
    const navItemClassNames = `px-3 text-base w-full text-left flex flex-row items-center justify-start py-3 text-${Theme.darkBlue} hover:text-${Theme.darkBlueHover} border-b border-gray-400 font-medium cursor-pointer`;
    return (
        <DropDownContainer>
            <button className="font-semibold flex flex-row items-center">
                {props.username} <IconManager type={IconType.CHEVRON_DOWN} stroke="#fff" className="ml-1" />
            </button>
            <DropDownContent>
                <div
                    onClick={() =>
                        !selectedPatientUpdates.length
                            ? navigate(props.navigation.accountUrl)
                            : props.toggleSavingPatientUpdatesModal(props.navigation.accountUrl)
                    }
                    className={navItemClassNames}
                >
                    <IconManager type={IconType.PROFILE} className="mr-1" /> Account
                </div>
                {props.isProvider && (
                    <>
                        <div
                            onClick={() =>
                                !selectedPatientUpdates.length
                                    ? navigate(props.navigation.getPatientsUrl(props.selectedOrgId))
                                    : props.toggleSavingPatientUpdatesModal(
                                          props.navigation.getPatientsUrl(props.selectedOrgId)
                                      )
                            }
                            className={navItemClassNames}
                        >
                            <IconManager type={IconType.USER} className="mr-1" /> Patients
                        </div>
                        <div
                            onClick={() =>
                                !selectedPatientUpdates.length
                                    ? navigate(
                                          props.navigation.getOpioidAgreementRenewalsUrl(props.selectedOrgId)
                                      )
                                    : props.toggleSavingPatientUpdatesModal(
                                          props.navigation.getOpioidAgreementRenewalsUrl(props.selectedOrgId)
                                      )
                            }
                            className={navItemClassNames}
                        >
                            <IconManager type={IconType.CLOCK} className="mr-1" /> Agreement Renewals
                        </div>
                    </>
                )}

                {props.isInMultipleOrganizations && (
                    <button onClick={props.toggleOrganizationSelectVisible} className={navItemClassNames}>
                        <IconManager type={IconType.BUILDING} className="mr-1" /> Change Organization
                    </button>
                )}
                <a
                    href="https://forms.clickup.com/f/80c80-1862/VRVSUYBZFYC1T5HEPF"
                    className={navItemClassNames}
                    target="_blank"
                    rel="noreferrer"
                >
                    <IconManager type={IconType.REPORT_BUG} className="mr-1" fill="#1A1D67" /> Report a Bug
                </a>
                <button
                    type="button"
                    className={`bg-transparent flex text-base flex-row items-center justify-center w-full px-5 pt-4 pb-2 mx-auto text-${Theme.darkBlue} hover:text-${Theme.darkBlueHover}`}
                    onClick={props.presentSignOutConfirmation}
                >
                    Sign Out <IconManager type={IconType.SIGNOUT} className="ml-1" fill="#1A1D67" size={20} />
                </button>
            </DropDownContent>
        </DropDownContainer>
    );
}

function ViewContextSelection({
    navigation,
    toggleSavingPatientUpdatesModal,
}: {
    navigation: Navigator;
    toggleSavingPatientUpdatesModal: (link: string) => void;
}): JSX.Element | null {
    const currentUser = useContext(CurrentUserContext);
    const selectedPatientUpdates = useSelector((state: ReduxState) => selectSelectedPatientUpdates(state));
    const roles: { label: string; value: number }[] = [];
    for (const role in UserRoles) {
        if (currentUser.claims?.currentRoles?.[role] !== undefined) {
            switch (Number(role)) {
                case 0:
                    roles.push({ label: 'Organization Admin', value: 0 });
                    break;
                case 1:
                    roles.push({ label: 'Provider', value: 1 });
                    break;
                case 3:
                    roles.push({ label: 'Application Admin', value: 3 });
            }
        }
    }
    if (roles.length <= 1) {
        return null;
    }

    const setViewContext = useCallback((viewContext: UserRoles) => {
        currentUser.setViewContext(viewContext);
        localStorage.setItem(BrowserStorageKeys.VIEW_CONTEXT, String(viewContext));
    }, []);

    const routes = {
        [UserRoles.provider]: currentUser.claims?.currentOrgId
            ? navigation.getPatientsUrl(currentUser.claims.currentOrgId)
            : '',
        [UserRoles.orgAdmin]: currentUser.claims?.currentOrgId
            ? navigation.getOrganizationDetailsUrl(currentUser.claims.currentOrgId)
            : '',
    };

    const setContextAndNavigate = value => {
        setViewContext(value);
        navigate(routes[value]);
    };

    return (
        <div className="flex flex-row items-center justify-end ml-auto mr-3 w-1/5">
            {roles.map(({ label, value }) => (
                <div
                    key={value}
                    onClick={() =>
                        !!selectedPatientUpdates.length
                            ? toggleSavingPatientUpdatesModal(routes[value])
                            : setContextAndNavigate(value)
                    }
                    className={`mr-2 font-thin underline cursor-pointer ${
                        currentUser.viewContext === value ? 'font-semibold' : 'font-normal'
                    }`}
                >
                    {label}
                </div>
            ))}
        </div>
    );
}

// z-index above sticky save/cancel bar on patient details
const DropDownContent = styled.div`
    display: none;
    position: absolute;
    border-radius: 4px;
    width: 220px;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 41;
    border: 1px solid lightgray;
    padding: 0;
    right: 5px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
`;

const DropDownContainer = styled.div`
    position: relative;
    display: inline-block;
    &:hover ${DropDownContent} {
        display: flex;
    }
`;

const Nav = styled.nav`
    display: block;
    @media (max-width: 765px) {
        display: none;
    }
`;
