import React, { useContext, useEffect } from 'react';
import { RouteProps } from '../../types';
import ToastAlert from '../../components/ToastAlert';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../redux/store';
import { selectToastAlert } from '../../redux/currentSession/currentSessionSelectors';
import { CurrentUserContext } from '../../context/CurrentUserContextProvider';
import useURLQueryParams from '../../hooks/useURLQueryParams';
import { NonAuthRoutes, QueryParams } from '../../routes';
import { changeCurrentUserSelectedOrganization } from '../../redux/selectedOrganization/selectedOrganizationActions';
import { Redirect } from '@reach/router';
import FunctionsManager from '../../functions/FunctionsManager';
import { AuthRedirection } from '../../components/AuthRedirection';
import { UserRoles } from '../../database/schemas/User';
import CurrentUserProfile from './CurrentUserProfile';
import Delegation from './Delegation';

export default function AccountManagement(_: RouteProps) {
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const currentUser = useContext(CurrentUserContext);
    const { organizationId } = useURLQueryParams<{ organizationId: QueryParams.organizationId }>();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (organizationId && organizationId !== currentUser.claims?.currentOrgId) {
                await FunctionsManager.user.setCurrentOrganization({ organizationId: organizationId });
                dispatch(
                    changeCurrentUserSelectedOrganization({
                        currentUserClaims: currentUser.claims!,
                        newOrganizationId: organizationId,
                    })
                );
            }
        })();
    }, [organizationId, currentUser.claims?.currentOrgId]);

    return !currentUser.claims ? (
        <Redirect to={NonAuthRoutes.LOGIN} />
    ) : (
        <div className="w-full flex flex-row bg-white py-5 px-10 mt-3">
            {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
            <div className="flex flex-col w-1/2">
                <h2 className="text-2xl font-semibold px-4 text-blue-900">Edit Profile</h2>
                <div className="rounded-md bg-gray-100 mx-4 border border-gray-300 mt-2 p-4 flex flex-col flex-1">
                    <CurrentUserProfile />
                </div>
            </div>
            {currentUser.claims.isProviderInCurrentOrganization() && (
                <div className="flex flex-col w-1/2">
                    <h2 className="text-2xl font-semibold px-4 text-blue-900">Delegate Settings</h2>
                    <div className="rounded-md bg-gray-100 mx-4 border border-gray-300 mt-2 p-4 flex flex-col flex-1">
                        <Delegation />
                    </div>
                </div>
            )}
        </div>
    );
}
