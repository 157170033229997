import React from 'react';
import { FormSubmission, InputChange } from '../../../types';
import { State } from './AccountSetup';
import IconManager, { IconType } from '../../../components/IconManager';
import MFAVerificationForm from '../../../components/MFAVerificationForm';

export interface MFASetupProps extends Pick<State, 'multiFactorAuth'> {
    handlePersonalPhoneNumberInput: (e: InputChange) => void;
    handleVerificationCodeInput: (e: InputChange) => void;
    handlePersonalPhoneNumberBlur: () => void;
    sendVerificationCodeToUserDevice: (e: FormSubmission) => Promise<void>;
    submitVerificationCodeToToggleMFA: (e: FormSubmission) => Promise<void>;
    directUserAfterMFASetup: () => Promise<void>;
}

export default function MFASetup(props: MFASetupProps) {
    if (props.multiFactorAuth.enableMFARequest.data === 'success') {
        return (
            <div className="w-full px-4 py-2 flex flex-col mx-auto justify-center">
                <h3 className="text-lg text-center block mb-3">
                    Multifactor authentication has been set up for your account
                </h3>
                <IconManager type={IconType.CHECK_CIRCLE} size={125} stroke="#34AC6F" className="mx-auto" />
                <button
                    onClick={props.directUserAfterMFASetup}
                    className="text-blue-600 hover:text-blue-700 mx-auto mt-2"
                >
                    Continue to dashboard
                </button>
            </div>
        );
    } else {
        return (
            <MFAVerificationForm
                sendVerificationCodeToUserDevice={props.sendVerificationCodeToUserDevice}
                submitVerificationCodeToToggleMFA={props.submitVerificationCodeToToggleMFA}
                handlePersonalPhoneNumberInput={props.handlePersonalPhoneNumberInput}
                handleVerificationCodeInput={props.handleVerificationCodeInput}
                handlePersonalPhoneNumberBlur={props.handlePersonalPhoneNumberBlur}
                multiFactorAuth={props.multiFactorAuth}
            />
        );
    }
}
