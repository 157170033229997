import { Dispatch } from '../../types';
import {
    AccountCreationInfo,
    CurrentSessionActions,
    CurrentSessionActionTypes,
} from './currentSessionReducer';
import { Thunk } from '../reduxTypes';

export type DispatchCurrentUserSessionActions = Dispatch<CurrentSessionActionTypes>;
type CurrentUserSessionThunk = Thunk<CurrentSessionActions>;

export const setToastAlert = (message: string, duration?: number): CurrentUserSessionThunk => async (
    dispatch: DispatchCurrentUserSessionActions
): Promise<void> => {
    dispatch({ type: CurrentSessionActions.SET_TOAST_ALERT, payload: { message } });
    setTimeout(() => {
        dispatch({ type: CurrentSessionActions.DISMISS_TOAST_ALERT });
    }, duration ?? 3500);
};

export const setToastError = (message: string): CurrentUserSessionThunk => async (
    dispatch: DispatchCurrentUserSessionActions
): Promise<void> => {
    dispatch({ type: CurrentSessionActions.SET_TOAST_ALERT, payload: { message, isError: true } });
};

export const clearToastAlerts = (): CurrentUserSessionThunk => async (
    dispatch: DispatchCurrentUserSessionActions
): Promise<void> => {
    dispatch({ type: CurrentSessionActions.CLEAR_TOAST_ALERT });
};

export const setRequestedPathname = (pathname: string): CurrentUserSessionThunk => async (
    dispatch: DispatchCurrentUserSessionActions
): Promise<void> => {
    dispatch({ type: CurrentSessionActions.SET_REQUESTED_PATHNAME, payload: pathname });
};

export const setPendingAccountCreationInfo = (info: AccountCreationInfo): CurrentUserSessionThunk => async (
    dispatch: DispatchCurrentUserSessionActions
): Promise<void> => {
    dispatch({ type: CurrentSessionActions.SET_PENDING_ACCOUNT_CREATION_INFO, payload: info });
};

export const clearPendingAccountCreationInfo = (): CurrentUserSessionThunk => async (
    dispatch: DispatchCurrentUserSessionActions
): Promise<void> => {
    dispatch({ type: CurrentSessionActions.CLEAR_PENDING_ACCOUNT_CREATION_INFO });
};
