import FirebaseApp from '../firestore';
import { storage as FirebaseStorage } from 'firebase/app';
type Storage = FirebaseStorage.Storage;

class StorageManager {
    private storage: Storage;
    private ref: FirebaseStorage.Reference;
    constructor(storage: Storage) {
        this.storage = storage;
        this.ref = this.storage.ref();
    }
    async getSignedOpioidAgreementURL(filePath: string): Promise<string> {
        return await this.ref.child(filePath).getDownloadURL();
    }
}
export default new StorageManager(FirebaseApp.storage());
