import { combineReducers } from 'redux';
import selectedPatientReducer from './selectedPatient/selectedPatientReducer';
import selectedOrganizationReducer from './selectedOrganization/selectedOrganizationReducer';
import currentSessionReducer from './currentSession/currentSessionReducer';

export default combineReducers({
    selectedPatient: selectedPatientReducer,
    selectedOrganization: selectedOrganizationReducer,
    currentSession: currentSessionReducer,
});
