import React, { forwardRef, Ref, useEffect, useRef, useState } from 'react';
import { Theme } from '../theme';
import { InputChange } from '../types';

type CommonProps = {
    disabled?: boolean;
    tooShort?: boolean;
    className?: string;
    name?: string;
    onChange: (e: InputChange) => void;
    onBlur?: () => void;
    value: string;
};

type SelfControlledProps = {
    type: 'selfControlled';
};

type ParentControlledProps = {
    handleToggle: (e: InputChange) => void;
    type: 'parentControlled';
    visible: boolean;
    id: number;
};

type Props = (CommonProps & SelfControlledProps) | (CommonProps & ParentControlledProps);

const MaskablePasswordInput = forwardRef((props: Props, ref: Ref<HTMLInputElement>) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const classNames = (() => {
        const baseClassNames = `block w-full py-1 ${props.className}`;
        return !props.disabled
            ? `${baseClassNames} form-input focus:outline-none border border-${
                  props.tooShort ? 'red-500' : 'gray-400'
              } bg-white`
            : `${baseClassNames} p-0`;
    })();

    useEffect(() => {
        if (props.type === 'parentControlled' && props.visible !== undefined) {
            setIsVisible(props.visible);
        }
    }, [props.type === 'parentControlled' ? props.visible : undefined]);

    const handleToggle = (e: InputChange) => {
        e.persist();
        if (!inputRef.current) return;
        inputRef.current.type = inputRef.current.type === 'password' ? 'text' : 'password';
        setIsVisible(inputRef.current.type === 'text');
        inputRef.current.focus();
    };

    const toggle = props.type === 'selfControlled' ? handleToggle : props.handleToggle;

    const styles = {
        base: 'rounded px-1 cursor-pointer text-sm',
        borderColor: `border border-${Theme.mediumBlueHover}`,
        backgroundColor: isVisible ? `bg-${Theme.mediumBlue}` : 'bg-white',
        backgroundColorHover: isVisible
            ? `hover:bg-${Theme.mediumBlueHover}`
            : `hover:bg-${Theme.mediumBlue}`,
        textColor: isVisible ? 'text-white' : `text-${Theme.mediumBlue}`,
        textColorHover: 'hover:text-white',
    };
    return (
        <div className="relative w-full">
            <div className="absolute inset-y-0 right-0 flex items-center px-2">
                <input
                    onChange={toggle}
                    className="hidden"
                    id={props.type === 'selfControlled' ? 'toggle' : props.id.toString()}
                    type="checkbox"
                />
                <label
                    className={`${styles.base} ${styles.borderColor} ${styles.backgroundColor} ${styles.backgroundColorHover} ${styles.textColor} ${styles.textColorHover}`}
                    htmlFor={props.type === 'selfControlled' ? 'toggle' : props.id.toString()}
                >
                    {isVisible ? 'Hide' : 'Show'}
                </label>
            </div>
            <input
                className={`appearance-none ${classNames} pr-16`}
                type="password"
                name={props.name}
                disabled={props.disabled}
                autoComplete="off"
                ref={props.type === 'selfControlled' ? inputRef : ref}
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={props.value}
            />
        </div>
    );
});

export default MaskablePasswordInput;
