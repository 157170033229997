import React, { createContext, ReactNode, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import firestore from '../../firestore';
import { Claims } from '../Claims';
import DatabaseManager from '../database/DatabaseManager';
import { useImmer } from 'use-immer';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../redux/store';
import { selectPendingAccountCreationInfo } from '../redux/currentSession/currentSessionSelectors';
import { UserRoles, UserSchema } from '../database/schemas/User';
import { UserDocument } from '../database/documents/UserDocument';
import AuthManager from '../AuthManager';
import FunctionsManager from '../functions/FunctionsManager';
import { clearPendingAccountCreationInfo } from '../redux/currentSession/currentSessionActions';
import { BrowserStorageKeys, FetchRequest, ValidatableString } from '../types';
import LoadingSpinner from '../components/LoadingSpinner';
import { navigate } from '@reach/router';
import { NonAuthRoutes } from '../routes';

export interface MultiFactorAuthState {
    enabled: boolean;
    awaitingCodeConfirmation?: boolean;
    submittingVerificationCode?: boolean;
    reAuthenticating?: boolean;
    togglePhoneNumberAsMFAMethod?: (verificationCode: string) => Promise<void>;
    verificationCode: string;
    submissionError?: unknown;
    enableMFARequest: FetchRequest<'success' | 'failure' | undefined>;
    personalPhoneNumber: ValidatableString;
    resolveSignIn?: (verificationCode: string) => Promise<void>;
}

//multiFactorAuthEnabled will be derived by whether or not there is any items in the firebase.User.multiFactor.enrolledFactors[]
//because in current phase we only care about the phone number being one of those factors and if it's not the array will be empty

type CommonProps = {
    isAuthenticated: boolean;
    loading: boolean;
    multiFactorAuthEnabled?: boolean;
    inAccountCreationFlow: boolean;
};
export type CurrentUserState =
    | ({
          document: null;
          claims: null;
          viewContext: null;
      } & CommonProps)
    | ({
          document: UserDocument;
          claims: Claims;
          viewContext: UserRoles | null;
      } & CommonProps);

const initialCurrentUserState: CurrentUserState = {
    isAuthenticated: !!localStorage.getItem(BrowserStorageKeys.USER_IS_AUTHED),
    loading: true,
    document: null,
    claims: null,
    viewContext: null,
    inAccountCreationFlow: !!sessionStorage.getItem(BrowserStorageKeys.USER_IS_IN_ACCOUNT_CREATION_FLOW),
};

export const CurrentUserContext = createContext<
    CurrentUserState & {
        setViewContext: (viewContext: CurrentUserState['viewContext']) => void;
        setInAccountCreationFlow: (isInCreationFlow: boolean) => void;
        setMultiFactorAuthEnabled: (enabled: boolean) => void;
        updateCurrentUserProp: ({
            prop,
            value,
        }: {
            prop: keyof UserSchema | 'phoneNumber' | 'multiFactorAuthEnabled';
            value: any;
        }) => void;
    }
>({
    ...initialCurrentUserState,
    setViewContext: () => {},
    updateCurrentUserProp: () => {},
    setInAccountCreationFlow: () => {},
    setMultiFactorAuthEnabled: () => {},
});

export default function CurrentUserContextProvider({ children }: { children: ReactNode }) {
    const [firebaseUserIdTokenResult, setFirebaseUserIdTokenResult] = useState<
        firebase.auth.IdTokenResult | undefined
    >();
    const [haveRefreshedClaims, setHaveRefreshedClaims] = useState<boolean | null>(false);
    const [state, updateState] = useImmer<CurrentUserState>(initialCurrentUserState);
    const pendingAccountCreationInfo = useSelector((state: ReduxState) =>
        selectPendingAccountCreationInfo(state)
    );
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            try {
                if (firebaseUserIdTokenResult) {
                    let claims = firebaseUserIdTokenResult.claims as Claims & firebase.auth.IdTokenResult;
                    //if user was logged out after changing their email, make sure the firestore document is updated
                    if (AuthManager.currentUserEmail !== state.document?.data.email) {
                        const updatedUser = await state.document?.update({
                            email: AuthManager.currentUserEmail!,
                        });
                        updateState(draft => void (draft.document = updatedUser!));
                    }
                    // if firestoreId not on claims and we haven't already refreshed, then it's a new user - update custom claims
                    if (!claims.firestoreId && haveRefreshedClaims === false) {
                        try {
                            await FunctionsManager.user.updateCustomClaims();
                            await firebase.auth().currentUser?.getIdTokenResult(true);
                            setHaveRefreshedClaims(true);
                        } catch (error) {
                            console.log('updateCustomClaims', error);
                            updateState(draft => void (draft.loading = false));
                            throw error;
                        }
                        // if we've already tried to refresh, then we know the user doesn't exist
                    } else if (!claims.firestoreId && haveRefreshedClaims) {
                        updateState(draft => void (draft.loading = false));
                        throw new Error("User doesn't exist in Firestore");
                    } else {
                        const finalClaims = new Claims(firebaseUserIdTokenResult);
                        const currentUser = finalClaims.isProviderInCurrentOrganization()
                            ? await DatabaseManager.ProviderModel.get(finalClaims.firestoreId!)
                            : await DatabaseManager.UserModel.get(finalClaims.firestoreId!);
                        if (pendingAccountCreationInfo) {
                            const updatedUser = await currentUser.update({ ...pendingAccountCreationInfo });
                            updateState(draft => void (draft.document = updatedUser!));
                            dispatch(clearPendingAccountCreationInfo());
                        }
                        updateState(draft => {
                            draft.document = currentUser;
                            draft.claims = finalClaims;
                            draft.viewContext = deriveViewContextFromClaims(finalClaims);
                        });
                        setTimeout(() => {
                            updateState(draft => void (draft.loading = false));
                        }, 2000);
                    }
                } else if (!state.isAuthenticated) {
                    updateState(() => ({ ...initialCurrentUserState, loading: false }));
                }
            } catch (err) {
                if (err.code === 'unavailable') {
                    updateState(draft => void (draft.loading = false));
                    await navigate(NonAuthRoutes.OFFLINE);
                }
            }
        })();
    }, [firebaseUserIdTokenResult?.token]);

    useEffect(
        () =>
            firestore.auth().onIdTokenChanged(async user => {
                if (!user) {
                    updateState(draft => {
                        draft.isAuthenticated = false;
                        localStorage.removeItem(BrowserStorageKeys.USER_IS_AUTHED);
                    });
                }
                updateState(draft => {
                    draft.multiFactorAuthEnabled = !!user?.multiFactor.enrolledFactors.length;
                });
                setFirebaseUserIdTokenResult(await user?.getIdTokenResult());
            }),
        []
    );

    useEffect(
        () =>
            firestore.auth().onAuthStateChanged(async user => {
                if (!user) {
                    updateState(draft => {
                        draft.isAuthenticated = false;
                        localStorage.removeItem(BrowserStorageKeys.USER_IS_AUTHED);
                    });
                }
                updateState(draft => {
                    draft.multiFactorAuthEnabled = !!user?.multiFactor.enrolledFactors.length;
                });
                setFirebaseUserIdTokenResult(await user?.getIdTokenResult());
            }),
        []
    );
    return (
        <CurrentUserContext.Provider
            value={{
                ...state,
                setViewContext: viewContext => {
                    updateState(draft => void (draft.viewContext = viewContext));
                },
                setMultiFactorAuthEnabled: enabled => {
                    updateState(draft => void (draft.multiFactorAuthEnabled = enabled));
                },
                setInAccountCreationFlow: isInCreationFlow => {
                    if (isInCreationFlow) {
                        sessionStorage.setItem(
                            BrowserStorageKeys.USER_IS_IN_ACCOUNT_CREATION_FLOW,
                            BrowserStorageKeys.USER_IS_IN_ACCOUNT_CREATION_FLOW
                        );
                    } else {
                        sessionStorage.removeItem(BrowserStorageKeys.USER_IS_IN_ACCOUNT_CREATION_FLOW);
                    }
                    updateState(draft => void (draft.inAccountCreationFlow = isInCreationFlow));
                },
                updateCurrentUserProp: async ({ prop, value }) => {
                    await state.document?.update({ [prop]: value });
                    updateState(draft => {
                        draft.document!.data = { ...draft.document!.data, [prop]: value };
                    });
                },
            }}
        >
            {state.loading ? <LoadingSpinner type="page" /> : children}
        </CurrentUserContext.Provider>
    );
}

export function deriveViewContextFromClaims(claims: Claims): CurrentUserState['viewContext'] {
    if (claims.isProviderInCurrentOrganization() && claims.isOrgAdminInCurrentOrganization()) {
        const lastViewContext = localStorage.getItem(BrowserStorageKeys.VIEW_CONTEXT)
            ? Number(localStorage.getItem(BrowserStorageKeys.VIEW_CONTEXT))
            : null;
        return lastViewContext ?? UserRoles.provider;
    } else {
        // if the user is a super admin, or just an org admin or provider (not both)
        return null;
    }
}
