import React, { useEffect, useRef, useState } from 'react';
import IconManager, { IconType } from './IconManager';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../redux/store';
import { selectToastAlert } from '../redux/currentSession/currentSessionSelectors';
import { clearToastAlerts } from '../redux/currentSession/currentSessionActions';
import { globalHistory } from '@reach/router';

interface Props {
    message: string;
    duration?: number;
}

function ToastAlert(props: Props) {
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const inputRef = useRef<HTMLInputElement>(null);
    const [urlWhereDispatchOccurred, setUrlWhereDispatchOccurred] = useState<string>(window.location.href);
    const dispatch = useDispatch();

    useEffect(() => {
        //subscribe to changes to url to ensure active tab is in sync with routing
        return globalHistory.listen(({ location }) => {
            if (location.href !== urlWhereDispatchOccurred) {
                dispatch(clearToastAlerts());
            }
            setUrlWhereDispatchOccurred(window.location.href);
        });
    }, []);

    useEffect(() => {
        //only auto dismiss if toast alert is not an error
        if (!toastAlert.isError) {
            setTimeout(() => {
                inputRef.current!.checked = !inputRef.current!.checked;
            }, props.duration ?? 3000);
        }
    }, []);

    return (
        <ToastContainer className="fixed top-0 z-40 mt-20 right-0 m-8 w-5/6 md:w-full max-w-sm">
            <input
                ref={inputRef}
                type="checkbox"
                className="hidden"
                id="toastAlert"
                onChange={() => dispatch(clearToastAlerts())}
            />
            <label
                className={`close cursor-pointer flex items-start justify-between w-full p-2 border border-${
                    toastAlert.isError ? 'red' : 'green'
                }-600 bg-white bg-${toastAlert.isError ? 'red' : 'green'}-100 text-${
                    toastAlert.isError ? 'red' : 'green'
                }-600 rounded-md shadow-lg`}
                title="close"
                htmlFor="toastAlert"
            >
                <div className="flex flex-row items-center justify-start">
                    <IconManager
                        type={toastAlert.isError ? IconType.X_CIRCLE : IconType.SUCCESS}
                        fill={toastAlert.isError ? '#C5302F' : '#37A269'}
                        stroke="#fff"
                        size={toastAlert.isError ? 35 : 30}
                    />
                    <span className="text-lg ml-2 block">{props.message}</span>
                </div>
                <IconManager
                    type={IconType.CLOSE}
                    className="mt-2"
                    size={20}
                    fill={toastAlert.isError ? '#C5302F' : '#37A269'}
                />
            </label>
        </ToastContainer>
    );
}

export default React.memo(ToastAlert);

const ToastContainer = styled.div`
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    
    input:checked ~ * {
      -webkit-animation: fade-out-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: fade-out-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      
    @-webkit-keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }
    @keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }
    @-webkit-keyframes fade-out-right {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: translateX(50px);
            transform: translateX(50px);
            opacity: 0;
        }
    }
    @keyframes fade-out-right {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: translateX(50px);
            transform: translateX(50px);
            opacity: 0;
        }
    }
`;
