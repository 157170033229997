import React from 'react';
import styled from 'styled-components';

interface Props {
    className?: string;
}

export default function LoadingDots({ className }: Props) {
    return (
        <div className={className}>
            <DotOne />
            <DotTwo />
            <DotThree />
        </div>
    );
}

const Dot = styled.div`
    @-webkit-keyframes bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
        }
        40% {
            -webkit-transform: scale(1);
        }
    }

    @keyframes bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        40% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
    width: 5px;
    height: 5px;
    margin: 0 2px;
    background-color: #1a1d67;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
    animation: bouncedelay 1.4s infinite ease-in-out both;
`;

const DotOne = styled(Dot)`
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
`;

const DotTwo = styled(Dot)`
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
`;

const DotThree = styled(Dot)``;
