import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import {
    initialSelectedPatientReduxState,
    SelectedPatientReduxState,
} from './selectedPatient/selectedPatientReducer';
import rootReducer from './rootReducer';
import {
    initialSelectedOrganizationReduxState,
    SelectedOrganizationReduxState,
} from './selectedOrganization/selectedOrganizationReducer';
import { CurrentSessionReduxState, initialCurrentSessionState } from './currentSession/currentSessionReducer';

export interface ReduxState {
    selectedPatient: SelectedPatientReduxState;
    selectedOrganization: SelectedOrganizationReduxState;
    currentSession: CurrentSessionReduxState;
}

const initialState: ReduxState = {
    selectedPatient: initialSelectedPatientReduxState,
    selectedOrganization: initialSelectedOrganizationReduxState,
    currentSession: initialCurrentSessionState,
};

export default createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...[thunk])));
