import React, { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    pageHeader?: string;
}

export default function SplashPageContainer({ children, pageHeader }: Props) {
    return (
        <div className="w-screen mx-auto flex min-h-screen splash-background">
            <div className="w-full xs:w-4/5 mx-auto mt-10 md:mt-40 max-w-sm">
                {pageHeader && (
                    <h2 className="text-white text-2xl font-bold text-center mx-auto mb-3">{pageHeader}</h2>
                )}
                {children}
            </div>
        </div>
    );
}
