import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../redux/store';
import {
    selectSelectedPatientDocument,
    selectSelectedPatientUpdates,
} from '../../redux/selectedPatient/selectedPatientSelectors';
import ActionButton from '../../components/ActionButton';
import {
    cancelChangesToPatient,
    saveChangesToPatient,
} from '../../redux/selectedPatient/selectedPatientActions';
import LoadingDots from '../../components/LoadingDots';
import { PatientDocument } from '../../database/documents/PatientDocument';
import { Theme } from '../../theme';
import { useImmer } from 'use-immer';
import ConfirmationModal from '../../components/ConfirmationModal';
import IconManager, { IconType } from '../../components/IconManager';
import PageContainer from '../../components/PageContainer';
import { Tab } from '../../components/Tabulation';
import { navigate } from '@reach/router';
import { AuthRoutes } from '../../routes';
import useNavigation from '../../hooks/useNavigation';

interface Props {
    //passed as props to update parent loading state
    loading: boolean;
}

enum ModalScenarios {
    CANCELING_PATIENT_UPDATES = 1,
}

interface State {
    modalScenario?: ModalScenarios;
}

export default function PatientDetailsHeader(props: Props) {
    const selectedPatient = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const selectedPatientUpdates = useSelector((state: ReduxState) => selectSelectedPatientUpdates(state));
    const [state, updateState] = useImmer<State>({});
    const navigation = useNavigation();

    const derivePatientHeaderInfo = (patient: PatientDocument | null): string | null => {
        if (!patient?.data) return null;
        return `${patient.data.lastName}, ${patient.data.firstName} ${
            patient.data.mrn ? `(#${patient.data.mrn})` : ''
        }`;
    };

    const toggleModal = (modalScenario?: ModalScenarios) =>
        updateState(draft => void (draft.modalScenario = modalScenario));

    return (
        <div className="w-full flex flex-col mb-2">
            <div className="w-full my-3 flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center w-1/2 relative">
                    <h3 className="block text-2xl text-gray-800 mr-2">
                        <button
                            className={`underline text-${Theme.lightBlue} hover:text-${Theme.lightBlueHover}`}
                            onClick={() =>
                                !!selectedPatientUpdates.length
                                    ? toggleModal(ModalScenarios.CANCELING_PATIENT_UPDATES)
                                    : navigate(navigation.getPatientsUrl(selectedPatient?.organizationId!))
                            }
                        >
                            Patient List
                        </button>
                        <IconManager className="inline ml-1 mr-1" type={IconType.CHEVRON_RIGHT} size={18} />
                        <span className="font-semibold">Patient Details</span>
                    </h3>
                </div>
            </div>
            <div className="h-10">
                {props.loading ? (
                    <LoadingDots className="mb-3 ml-2" />
                ) : (
                    <h1 className="text-3xl text-black">{derivePatientHeaderInfo(selectedPatient)}</h1>
                )}
            </div>
            <ConfirmationModal
                isOpen={state.modalScenario !== undefined}
                closeModal={() => toggleModal()}
                onConfirm={() => navigate(navigation.getPatientsUrl(selectedPatient?.organizationId!))}
            >
                <p>
                    Are you sure you want to discard your unsaved change(s) to{' '}
                    {selectedPatient?.data.firstName}'s record?
                </p>
            </ConfirmationModal>
        </div>
    );
}
