import React from 'react';
import TextInput from '../../../../../components/TextInput';
import { MedicationFormKeys } from '../medicationTypes';
import { isOpioidPrescription, toPascalCase } from '../../../../../utils';
import { DeliveryType } from '../../../../../database/schemas/Medication';
import { opioidTypeLabels, renderDoseText, renderPillSizeText } from '../medicationFormLabels';
import { UserMedicationDocument } from '../../../../../database/documents/UserMedicationDocument';
import dateFNSFormat from 'date-fns/format';

interface Props {
    prescription: UserMedicationDocument;
}

export default function ArchivedPrescriptionRow(props: Props) {
    return (
        <div className="w-full p-3">
            <div className="flex flex-row items-center justify-start mb-6">
                <div className="mr-1 w-1/6">
                    <label className="block">
                        <span className="text-gray-700 font-semibold">Name</span>
                        <TextInput disabled={true} className="py-1" value={props.prescription.data.name} />
                    </label>
                </div>
                <div style={{ width: '8%' }} className="px-2">
                    <label className="block" htmlFor={MedicationFormKeys.delivery}>
                        <span className="text-gray-700 font-semibold">Method</span>
                        <div className="flex flex-row relative">
                            <TextInput
                                name={MedicationFormKeys.delivery}
                                value={toPascalCase(DeliveryType[props.prescription.data.delivery]) ?? ''}
                                disabled={true}
                                className="py-1"
                            />
                        </div>
                    </label>
                </div>
                <div style={{ width: '10%' }} className="mr-4">
                    <span className="text-gray-700 font-semibold">Opioid Type</span>
                    <TextInput
                        name={MedicationFormKeys.prescriptionOpioid}
                        value={
                            props.prescription.data.prescription &&
                            isOpioidPrescription(props.prescription.data.prescription)
                                ? opioidTypeLabels[props.prescription.data.prescription!.opioid!]
                                : 'Non-Opioid'
                        }
                        disabled={true}
                        className="py-1"
                    />
                </div>
                <div className="mr-4" style={{ width: '11.5%' }}>
                    <label className="block" htmlFor={MedicationFormKeys.unitAmount}>
                        <span className="text-gray-700 font-semibold">
                            {renderPillSizeText(props.prescription.data.delivery)}
                        </span>
                        <span className="block flex flex-1 items-center leading-normal p-1 whitespace-no-wrap">
                            {String(props.prescription.data.unit?.amount ?? '')}
                            {props.prescription.data.unit?.measure}
                        </span>
                    </label>
                </div>
                <div
                    className="relative mr-4"
                    style={{
                        width: props.prescription.data.delivery === DeliveryType.patch ? '13.5%' : '11.5%',
                    }}
                >
                    <label className="block" htmlFor={MedicationFormKeys.dose}>
                        <span className="text-gray-700 font-semibold">
                            {renderDoseText(props.prescription.data.delivery)}
                        </span>
                        <div className="flex flex-row relative">
                            <span>
                                {props.prescription.data.prescription?.dose.min ===
                                props.prescription.data.prescription?.dose.max
                                    ? props.prescription.data.prescription?.dose.min
                                    : `${props.prescription.data.prescription?.dose.min}-${props.prescription.data.prescription?.dose.max}`}
                            </span>
                            <div
                                className={`flex flex-row items-stretch ${
                                    props.prescription.data.delivery === DeliveryType.patch
                                        ? 'w-5/6 mx-auto'
                                        : 'w-full'
                                } relative`}
                            >
                                {props.prescription.data.delivery === DeliveryType.liquid && (
                                    <span className="flex items-center leading-normal font-semibold p-1 whitespace-no-wrap text-gray-700 text-sm">
                                        mL
                                    </span>
                                )}
                            </div>
                        </div>
                    </label>
                </div>
                <div className="text-left mr-4" style={{ width: '11.5%' }}>
                    <label htmlFor={MedicationFormKeys.prescriptionFrequency} className="block">
                        <span className="text-gray-700 font-semibold">Removal Date</span>
                        <div className="w-full flex flex-row items-center justify-start">
                            <span>
                                {props.prescription.data.archivedAt
                                    ? dateFNSFormat(
                                          props.prescription.data.archivedAt?.toDate(),
                                          'MM-dd-yyyy'
                                      )
                                    : 'Not specified'}
                            </span>
                        </div>
                    </label>
                </div>
                <div className="text-left mr-4" style={{ width: '20%' }}>
                    <label htmlFor={MedicationFormKeys.prescriptionFrequency} className="block">
                        <span className="text-gray-700 font-semibold">Reason for Removal</span>
                        <div className="w-full flex flex-row items-center justify-start">
                            <span>{props.prescription.data.archivedReason ?? 'Not specified'}</span>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    );
}
