import React, { useCallback, useContext, useEffect } from 'react';
import IconManager, { IconType } from '../../components/IconManager';
import ActionButton from '../../components/ActionButton';
import { useImmer } from 'use-immer';
import Modal from '../../components/Modal';
import ConfirmationModal from '../../components/ConfirmationModal';
import { CurrentUserContext } from '../../context/CurrentUserContextProvider';
import { ProviderDocument } from '../../database/documents/ProviderDocument';
import { FetchRequest, FormSubmission, ReactSelectState, SelectOption } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../redux/store';
import { selectSelectedOrganizationProviders } from '../../redux/selectedOrganization/selectedOrganizationSelectors';
import { setToastError } from '../../redux/currentSession/currentSessionActions';
import LoadingSpinner from '../../components/LoadingSpinner';
import Select from '../../components/Select';
import ToolTip from '../../components/ToolTip';
import SubmitButton from '../../components/SubmitButton';
import FunctionsManager from '../../functions/FunctionsManager';

type Delegates = ProviderDocument[];

enum ModalScenarios {
    ADDING_DELEGATING,
    REMOVING_DELEGATE,
    REMOVING_SELF_AS_DELEGATE,
}

interface State {
    modalScenario?: ModalScenarios;
    delegateToRemove?: ProviderDocument;
    delegateToAdd?: ProviderDocument;
    delegateData: FetchRequest<{ delegates: Delegates; delegators: Delegates }>;
    toolTipVisible: boolean;
    loading?: boolean;
}

const initialState: State = {
    delegateData: { fetching: true, data: { delegates: [], delegators: [] }, error: null },
    toolTipVisible: false,
};

const deriveDelegateName = (delegate?: ProviderDocument) =>
    `${delegate?.data.firstName} ${delegate?.data.lastName}`;

export default function Delegation() {
    const [state, updateState] = useImmer<State>(initialState);
    const currentUser = useContext(CurrentUserContext);
    const selectedOrganizationProviders = useSelector((state: ReduxState) =>
        selectSelectedOrganizationProviders(state)
    );
    const dispatch = useDispatch();
    const toggleToolTipVisible = useCallback(() => {
        updateState(draft => void (draft.toolTipVisible = !draft.toolTipVisible));
    }, []);
    //at this point, we can be certain that this current user is a provider or else this component would not be rendered
    const providerDocument = currentUser.document as ProviderDocument;

    useEffect(() => {
        (async () => {
            try {
                if (currentUser.claims?.currentOrgId) {
                    const { delegates, delegators } = await providerDocument.getDelegationData(
                        currentUser.claims.currentOrgId
                    );
                    updateState(draft => {
                        draft.delegateData.data.delegates = delegates;
                        draft.delegateData.data.delegators = delegators;
                    });
                }
            } catch (error) {
                console.log(error);
                updateState(draft => void (draft.delegateData.error = error));
                dispatch(setToastError('An error occurred while gathering delegate data'));
            }
            setTimeout(() => {
                updateState(draft => void (draft.delegateData.fetching = false));
            }, 700);
        })();
    }, [currentUser.claims?.currentOrgId]);

    const toggleModal = useCallback((scenario?: State['modalScenario']) => {
        updateState(draft => {
            if (!scenario) {
                draft.delegateToRemove = undefined;
                draft.delegateToAdd = undefined;
            }
            draft.modalScenario = scenario;
        });
    }, []);

    const presentDelegateRemovalModal = async (delegateId: string, isDelegator?: boolean) => {
        updateState(draft => {
            const targetGroup = isDelegator
                ? draft.delegateData.data.delegators
                : draft.delegateData.data.delegates;
            draft.delegateToRemove = targetGroup.find(({ id }) => id === delegateId);
        });
        toggleModal(
            isDelegator ? ModalScenarios.REMOVING_SELF_AS_DELEGATE : ModalScenarios.REMOVING_DELEGATE
        );
    };

    const setDelegateToAdd = delegateId => {
        updateState(draft => {
            draft.delegateToAdd = selectedOrganizationProviders.find(({ id }) => id === delegateId);
        });
    };

    const addDelegate = async (): Promise<void> => {
        if (currentUser.claims?.currentOrgId && state.delegateToAdd) {
            const addedDelegate = await providerDocument.addDelegate(
                currentUser.claims.currentOrgId,
                state.delegateToAdd.id
            );

            if (addedDelegate) {
                updateState(draft => void draft.delegateData.data.delegates.push(addedDelegate));
            }
        }
    };

    const removeDelegate = async (): Promise<void> => {
        if (currentUser.claims?.currentOrgId && state.delegateToRemove) {
            await providerDocument.removeDelegate(
                currentUser.claims!.currentOrgId!,
                state.delegateToRemove.id
            );
            updateState(draft => {
                const { delegates } = draft.delegateData.data;
                delegates.splice(
                    delegates.findIndex(({ id }) => id === state.delegateToRemove?.id),
                    1
                );
            });
        }
    };

    const removeSelfAsDelegate = async (): Promise<void> => {
        if (state.delegateToRemove) {
            await FunctionsManager.user.removeSelfAsDelegate({ providerId: state.delegateToRemove.id });
            updateState(draft => {
                const { delegators } = draft.delegateData.data;
                delegators.splice(
                    delegators.findIndex(({ id }) => id === state.delegateToRemove?.id),
                    1
                );
            });
        }
    };

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => void (draft.loading = true));
        try {
            switch (state.modalScenario) {
                case ModalScenarios.ADDING_DELEGATING:
                    await addDelegate();
                    break;
                case ModalScenarios.REMOVING_DELEGATE:
                    await removeDelegate();
                    break;
                case ModalScenarios.REMOVING_SELF_AS_DELEGATE:
                    await removeSelfAsDelegate();
                    break;
            }
        } catch (error) {
            console.log(error);
        }
        updateState(draft => void (draft.loading = false));
        toggleModal();
    };
    const { delegates, delegators } = state.delegateData.data;
    return (
        <>
            {state.delegateData.fetching ? (
                <LoadingSpinner type="page" />
            ) : (
                <>
                    <div className="flex flex-col justify-start items-start p-3 my-2">
                        <div className="flex flex-row justify-start items-center w-full relative">
                            <h4 className="font-semibold text-gray-700">Your Delegates</h4>
                            <span
                                onMouseEnter={toggleToolTipVisible}
                                onMouseLeave={toggleToolTipVisible}
                                className="cursor-pointer p-1"
                            >
                                <IconManager type={IconType.INFO_CIRCLE} size={18} stroke="#3B98CF" />
                            </span>
                            <ToolTip visible={state.toolTipVisible} width="40rem">
                                <p className="text-gray-600 block px-2">
                                    Adding another provider as a delegate allows them to help you monitor the
                                    patients for which you are the assigned prescriber. Providers which you
                                    designate as your delegate will see patients assigned to you when
                                    filtering the Patient List by "My Patients". Additionally, each provider
                                    you add as a delegate will get a copy of the email when an alert is
                                    created for a patient assigned to you.
                                </p>
                            </ToolTip>
                        </div>
                        {!delegates.length ? (
                            <div className="text-gray-600 text-left ml-1 my-2 w-full md:w-3/5">
                                You do not have any delegates
                            </div>
                        ) : (
                            <ul className="py-2 text-left lg:w-2/3 w-full">
                                {delegates.map(provider => (
                                    <li key={provider.id}>
                                        <Delegate
                                            delegateName={deriveDelegateName(provider)}
                                            presentRemoveModal={() =>
                                                presentDelegateRemovalModal(provider.id)
                                            }
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                        <ActionButton
                            className="mt-1 w-1/2 lg:w-1/3"
                            onClick={() => toggleModal(ModalScenarios.ADDING_DELEGATING)}
                        >
                            Add Delegate
                        </ActionButton>
                    </div>
                    <div className="flex flex-col justify-start items-start p-3 my-2">
                        <h4 className="font-semibold text-gray-700">Delegating to You</h4>
                        {!delegators.length ? (
                            <div className="text-gray-600 text-left ml-1 my-2 w-full md:w-3/5">
                                You do not have any delegators
                            </div>
                        ) : (
                            <ul className="py-2 text-left lg:w-2/3 w-full">
                                {delegators.map(provider => (
                                    <li key={provider.id}>
                                        <Delegate
                                            delegateName={deriveDelegateName(provider)}
                                            presentRemoveModal={() =>
                                                presentDelegateRemovalModal(provider.id, true)
                                            }
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <DelegateModal
                        submittingForm={state.loading}
                        delegateSelectOptions={selectedOrganizationProviders.filter(
                            ({ id }) =>
                                id !== currentUser.document?.id &&
                                !delegates.find(provider => provider.id === id)
                        )}
                        onSubmit={handleSubmit}
                        delegateToAdd={state.delegateToAdd}
                        delegateToRemove={state.delegateToRemove}
                        setDelegateToAdd={setDelegateToAdd}
                        modalScenario={state.modalScenario}
                        toggleModal={() => toggleModal()}
                    />
                </>
            )}
        </>
    );
}

interface DelegateProps {
    delegateName?: string;
    presentRemoveModal: () => void;
}

function Delegate(props: DelegateProps): JSX.Element {
    return (
        <div
            className="bg-white border border-gray-400 w-full text-gray-800 mb-2 px-4 py-3 rounded-md relative"
            role="alert"
        >
            <strong className="font-bold">{props.delegateName}</strong>
            <button onClick={props.presentRemoveModal} className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <IconManager
                    type={IconType.CLOSE}
                    size={20}
                    strokeWidth={3}
                    fill="#3B98CF"
                    className="cursor-pointer"
                />
            </button>
        </div>
    );
}

interface DelegateModalProps {
    modalScenario: State['modalScenario'];
    toggleModal: () => void;
    delegateToAdd?: State['delegateToAdd'];
    delegateToRemove?: State['delegateToRemove'];
    setDelegateToAdd: (delegateId: string) => void;
    delegateSelectOptions: ProviderDocument[];
    onSubmit: (e: FormSubmission) => void;
    submittingForm: State['loading'];
}

function DelegateModal(props: DelegateModalProps): JSX.Element {
    const isRemovingDelegateOrSelf =
        props.modalScenario === ModalScenarios.REMOVING_SELF_AS_DELEGATE ||
        props.modalScenario === ModalScenarios.REMOVING_DELEGATE;
    const ActiveModal = isRemovingDelegateOrSelf ? ConfirmationModal : Modal;
    const delegateSelect: ReactSelectState = {
        options: props.delegateSelectOptions.map(provider => ({
            label: deriveDelegateName(provider),
            value: provider.id,
        })),
        get selectedOption() {
            return this.options.find(({ value }) => value === props.delegateToAdd?.id);
        },
        handleSelect(option) {
            props.setDelegateToAdd((option as SelectOption).value);
        },
    };

    return (
        <ActiveModal
            onConfirm={props.onSubmit}
            isOpen={props.modalScenario !== undefined}
            closeModal={props.toggleModal}
        >
            {isRemovingDelegateOrSelf ? (
                <p>
                    {props.modalScenario === ModalScenarios.REMOVING_DELEGATE ? (
                        <span>
                            Are you sure you want to remove{' '}
                            <span className="font-semibold inline">
                                {deriveDelegateName(props.delegateToRemove)}
                            </span>{' '}
                            as one of your delegates?
                        </span>
                    ) : (
                        <span>
                            Are you sure you want to remove yourself as one of{' '}
                            <span className="font-semibold inline">
                                {deriveDelegateName(props.delegateToRemove)}
                            </span>{' '}
                            delegates?
                        </span>
                    )}
                </p>
            ) : (
                <form className="w-full my-2 p-3 md:w-11/12 mx-auto" onSubmit={props.onSubmit}>
                    <h4 className="text-lg text-gray-800 text-center mb-2 block">
                        Select a fellow provider to add as a delegate
                    </h4>
                    <Select
                        options={delegateSelect.options}
                        selectedOption={delegateSelect.selectedOption}
                        placeholder={delegateSelect.selectedOption ? undefined : 'Select prescriber'}
                        onChange={delegateSelect.handleSelect}
                    />
                    <p className="text-xs text-gray-600 px-3 text-center block my-2">
                        The provider you select will receive an email notifying them that they've been added
                        as a delegate for your patients
                    </p>
                    <SubmitButton loading={props.submittingForm} className="w-full mt-3">
                        Add Delegate
                    </SubmitButton>
                </form>
            )}
        </ActiveModal>
    );
}
