import { Dispatch } from '../../types';
import {
    GroupedOpioidAgreements,
    OrganizationUser,
    SelectedOrganizationActions,
    SelectedOrganizationActionTypes,
} from './selectedOrganizationReducer';
import { Thunk } from '../reduxTypes';
import DatabaseManager from '../../database/DatabaseManager';
import { OpioidAgreementDocument } from '../../database/documents/OpioidAgreementDocument';
import { UserRoles } from '../../database/schemas/User';
import { Claims } from '../../Claims';
import { clearSelectedPatient } from '../selectedPatient/selectedPatientActions';
import Navigator from '../../routes';
import { OrgAdminTabKeys } from '../../pages/orgAdmin/OrganizationDetails';

type DispatchSelectedOrganizationActions = Dispatch<SelectedOrganizationActionTypes>;
type SelectedOrganizationThunk = Thunk<SelectedOrganizationActions>;

export const getSelectedOrganizationDataForUser = ({
    currentUserClaims,
    organizationId = currentUserClaims.currentOrgId!,
}: {
    currentUserClaims: Claims;
    organizationId?: string;
}): SelectedOrganizationThunk => async (dispatch: DispatchSelectedOrganizationActions): Promise<void> => {
    const currentOrganization = await DatabaseManager.OrganizationModel.get(organizationId);
    const [providers, groupedAndSortedOpioidAgreements, orgAdmins] = await Promise.all([
        await currentOrganization.getProviders(),
        groupAndSortOpioidAgreements(await currentOrganization.getAllOpioidAgreements()),
        currentUserClaims.currentRoles?.[UserRoles.orgAdmin] ? await currentOrganization.getOrgAdmins() : [],
    ]);
    dispatch({
        type: SelectedOrganizationActions.SET_SELECTED_ORGANIZATION_DATA_FOR_USER,
        payload: {
            document: currentOrganization,
            providers,
            opioidAgreements: groupedAndSortedOpioidAgreements,
            orgAdmins,
            activeTab: OrgAdminTabKeys.PROVIDERS,
        },
    });
};

export const changeCurrentUserSelectedOrganization = ({
    currentUserClaims,
    newOrganizationId,
}: {
    currentUserClaims: Claims;
    newOrganizationId: string;
}): SelectedOrganizationThunk => async (dispatch): Promise<void> => {
    await Promise.all([dispatch(clearSelectedOrganization()), dispatch(clearSelectedPatient())]);
    dispatch(
        getSelectedOrganizationDataForUser({
            currentUserClaims,
            organizationId: newOrganizationId,
        })
    );
};

export const clearSelectedOrganization = (): SelectedOrganizationThunk => async (
    dispatch: DispatchSelectedOrganizationActions
): Promise<void> => {
    dispatch({ type: SelectedOrganizationActions.CLEAR_SELECTED_ORGANIZATION });
};

export const addOpioidAgreement = (
    opioidAgreement: OpioidAgreementDocument
): SelectedOrganizationThunk => async (dispatch): Promise<void> => {
    dispatch({ type: SelectedOrganizationActions.ADD_OPIOID_AGREEMENT, payload: opioidAgreement });
};

export const updateOpioidAgreementTitle = ({
    opioidAgreementId,
    title,
}: {
    opioidAgreementId: string;
    title: string;
}): SelectedOrganizationThunk => async (dispatch): Promise<void> => {
    dispatch({
        type: SelectedOrganizationActions.UPDATE_OPIOID_AGREEMENT_TITLE,
        payload: { title, opioidAgreementId },
    });
};

export const toggleOpioidAgreementArchivedStatus = (
    opioidAgreement: OpioidAgreementDocument
): SelectedOrganizationThunk => async (dispatch): Promise<void> => {
    dispatch({
        type: SelectedOrganizationActions.TOGGLE_OPIOID_AGREEMENT_ARCHIVED_STATUS,
        payload: opioidAgreement,
    });
    await opioidAgreement.toggleArchivedStatus();
};

export const updateUserInSelectedOrganization = (
    updatedUser: OrganizationUser
): SelectedOrganizationThunk => async (dispatch: DispatchSelectedOrganizationActions): Promise<void> => {
    dispatch({
        type: SelectedOrganizationActions.UPDATE_USER_IN_SELECTED_ORGANIZATION,
        payload: updatedUser,
    });
};

export const updateUserRoleInSelectedOrganization = (
    updatedUser: OrganizationUser
): SelectedOrganizationThunk => async (dispatch: DispatchSelectedOrganizationActions): Promise<void> => {
    dispatch({
        type: SelectedOrganizationActions.UPDATE_USER_ROLE_IN_SELECTED_ORGANIZATION,
        payload: updatedUser,
    });
};

export const removeUserFromSelectedOrganization = (userId: string): SelectedOrganizationThunk => async (
    dispatch: DispatchSelectedOrganizationActions
): Promise<void> => {
    dispatch({
        type: SelectedOrganizationActions.REMOVE_USER_FROM_SELECTED_ORGANIZATION,
        payload: { userId },
    });
};

export const setLoadingSelectedOrganization = (loading: boolean): SelectedOrganizationThunk => async (
    dispatch: DispatchSelectedOrganizationActions
): Promise<void> => {
    dispatch({ type: SelectedOrganizationActions.SET_LOADING_SELECTED_ORGANIZATION, payload: loading });
};

export const updateActiveTab = (activeTab: OrgAdminTabKeys): SelectedOrganizationThunk => async (
    dispatch: DispatchSelectedOrganizationActions
): Promise<void> => {
    dispatch({ type: SelectedOrganizationActions.SET_ACTIVE_TAB, payload: activeTab });
};

//UTIL
function groupAndSortOpioidAgreements(opioidAgreements: OpioidAgreementDocument[]): GroupedOpioidAgreements {
    const groupedOpioidAgreements = opioidAgreements.reduce(
        (groupedAgreements: GroupedOpioidAgreements, agreement) => {
            agreement.data.archivedAt
                ? groupedAgreements.archived.push(agreement)
                : groupedAgreements.active.push(agreement);
            return groupedAgreements;
        },
        { active: [], archived: [] }
    );
    //sort by createdAt
    groupedOpioidAgreements.active.sort((a, b) => a.data.createdAt.seconds - b.data.createdAt.seconds);
    //sort by archivedAt
    groupedOpioidAgreements.archived.sort((a, b) => a.data.archivedAt!.seconds - b.data.archivedAt!.seconds);
    return groupedOpioidAgreements;
}
