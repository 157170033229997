import FirebaseApp from '../firestore';
import firebase from 'firebase';
import { logDistinctiveMessage, unformatPhoneNumber } from './utils';
import { BrowserStorageKeys } from './types';

export type Credentials = {
    email: string;
    password: string;
};

export enum AuthErrorCodes {
    phoneNumberAlreadyExists = 'auth/phone-number-already-exists',
    emailAlreadyExists = 'auth/email-already-exists',
    insufficientPermissions = 'auth/insufficient-permission',
    internalError = 'auth/internal-error',
    invalidEmail = 'auth/invalid-email',
    invalidPassword = 'auth/invalid-password',
    wrongPassword = 'auth/wrong-password',
    invalidPhoneNumber = 'auth/invalid-phone-number',
    userNotFound = 'auth/user-not-found',
    invalidVerificationCode = 'auth/invalid-verification-code',
    multiFactorAuthRequired = 'auth/multi-factor-auth-required',
}

class AuthManager {
    recaptchaVerifierId = 'recaptcha-verifier';
    recaptchaVerifier?: firebase.auth.RecaptchaVerifier;
    constructor(private auth: firebase.auth.Auth) {}
    get currentUserEmail(): string | null {
        return this.currentUser?.email;
    }

    get currentUserPhoneNumberDisplayName() {
        if (this.currentUser.multiFactor.enrolledFactors) {
            return this.currentUser.multiFactor.enrolledFactors[0]?.displayName;
        }
        return null;
    }

    private get currentUser(): firebase.User {
        const { currentUser } = this.auth;
        if (!currentUser) {
            throw new Error('Current user not set in firebase auth');
        }
        return currentUser;
    }

    async login({
        email,
        password,
    }: //if the user has MFA enable, a callback to resolve their sign in process will be returned
    Credentials): Promise<void | ((verificationCode: string) => Promise<void>)> {
        try {
            await this.auth.signInWithEmailAndPassword(email, password);
            localStorage.setItem(BrowserStorageKeys.USER_IS_AUTHED, BrowserStorageKeys.USER_IS_AUTHED);
        } catch (error) {
            logDistinctiveMessage(`error logging in user -- ${error}`);
            if (error.code === AuthErrorCodes.multiFactorAuthRequired) {
                this.createRecaptcha();
                const phoneInfoOptions: firebase.auth.PhoneInfoOptions = {
                    multiFactorHint: error.resolver.hints[0],
                    session: error.resolver.session,
                };
                const verificationId = await new firebase.auth.PhoneAuthProvider().verifyPhoneNumber(
                    phoneInfoOptions,
                    this.recaptchaVerifier!
                );
                return async (verificationCode: string) => {
                    const phoneAuthCredential = firebase.auth.PhoneAuthProvider.credential(
                        verificationId!,
                        verificationCode
                    );
                    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
                        phoneAuthCredential
                    );
                    await error.resolver.resolveSignIn(multiFactorAssertion);
                    localStorage.setItem(
                        BrowserStorageKeys.USER_IS_AUTHED,
                        BrowserStorageKeys.USER_IS_AUTHED
                    );
                };
            } else {
                throw error;
            }
        }
    }

    async logout(): Promise<void> {
        await this.auth.signOut();
        localStorage.removeItem(BrowserStorageKeys.USER_IS_AUTHED);
    }

    async refreshUserSession(): Promise<void> {
        await this.auth.currentUser?.getIdTokenResult(true);
    }

    async createUserAccount({ email, password }: { email: string; password: string }) {
        await this.auth.createUserWithEmailAndPassword(email, password);
    }

    async confirmPasswordReset({ code, newPassword }: { code: string; newPassword: string }) {
        await this.auth.confirmPasswordReset(code, newPassword);
    }

    async changePassword({
        email,
        password,
        newPassword,
    }: { newPassword: string } & Credentials): Promise<void> {
        await this.login({ email, password });
        await this.auth.currentUser?.updatePassword(newPassword);
    }

    async changeEmail({ email, password, newEmail }: { newEmail: string } & Credentials): Promise<void> {
        await this.login({ email, password });
        await this.auth.currentUser?.verifyBeforeUpdateEmail(newEmail);
        await this.logout();
    }

    createRecaptcha() {
        if (this.recaptchaVerifier) {
            this.recaptchaVerifier.clear();
        }
        const recaptchaElement = window.document.createElement('div');
        recaptchaElement.id = this.recaptchaVerifierId;
        if (window.document.getElementById(this.recaptchaVerifierId)) {
            window.document.getElementById(this.recaptchaVerifierId)!.replaceWith(recaptchaElement);
        } else {
            window.document.body.appendChild(recaptchaElement);
        }
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptchaVerifierId, {
            size: 'invisible',
        });
    }

    //makes the assumption that the phone number is the only MFA method for the user
    //todo: need to remove phoneNumber from user auth record
    async removePhoneNumberAsMultiFactorAuth(): Promise<void> {
        if (!this.currentUser.multiFactor.enrolledFactors.length) {
            throw new Error('User does not have MFA method to remove');
        }
        const [phoneNumberMFAMethod] = this.currentUser.multiFactor.enrolledFactors;
        await this.currentUser.multiFactor.unenroll(phoneNumberMFAMethod).catch(error => {
            console.error(
                `error un-enrolling phone number ${JSON.stringify(phoneNumberMFAMethod)}: ${error}`
            );
        });
    }

    async togglePhoneNumberAsMFA(phoneNumber: string) {
        const session = await this.currentUser.multiFactor.getSession();
        const phoneInfo: firebase.auth.PhoneInfoOptions = {
            phoneNumber: `+1${unformatPhoneNumber(phoneNumber)}`,
            session,
        };
        this.createRecaptcha();
        const verificationId = await new firebase.auth.PhoneAuthProvider()
            .verifyPhoneNumber(phoneInfo, this.recaptchaVerifier!)
            .catch(error => {
                console.error(`error verifying phone number: ${error}`);
            });
        if (verificationId) {
            return async (verificationCode: string): Promise<void> => {
                const phoneAuthCredential = firebase.auth.PhoneAuthProvider.credential(
                    verificationId,
                    verificationCode
                );
                const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
                    phoneAuthCredential
                );
                const maskedPhonedNumber = `+1${'*'.repeat(6)}${phoneNumber.slice(6)}`;
                await this.currentUser.multiFactor
                    .enroll(multiFactorAssertion, maskedPhonedNumber)
                    .catch(error => {
                        console.error(`error enrolling phone number as MFA method for user: ${error}`);
                        if (error.code === AuthErrorCodes.invalidVerificationCode) {
                            throw error;
                        }
                    });
            };
        }
    }

    translateError(error: unknown): string | undefined {
        function isAuthError(error: any): error is { code: AuthErrorCodes } {
            return 'code' in error;
        }
        if (isAuthError(error)) {
            logDistinctiveMessage(error.code);
            switch (error.code) {
                case AuthErrorCodes.emailAlreadyExists:
                    return 'This email is already associated with a user account.';
                case AuthErrorCodes.insufficientPermissions:
                    return 'You are not permitted to perform this action.';
                case AuthErrorCodes.internalError:
                    return 'An internal server error has occurred. Please check your internet connection & try again.';
                case AuthErrorCodes.invalidEmail:
                case AuthErrorCodes.invalidPassword:
                case AuthErrorCodes.wrongPassword:
                case AuthErrorCodes.userNotFound:
                    return 'The provided email or password is invalid';
                case AuthErrorCodes.invalidPhoneNumber:
                    return 'The provided phone number is invalid';
                case AuthErrorCodes.phoneNumberAlreadyExists:
                    return 'There is already an account associated with the provided phone number';
                case AuthErrorCodes.invalidVerificationCode:
                    return 'The provided verification code is invalid';
                default:
                    return 'An unknown error has occurred. Please check your internet connection & try again.';
            }
        }
    }
}

export default new AuthManager(FirebaseApp.auth());
