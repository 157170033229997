import React from 'react';
import { RouteProps } from '../types';
import SplashPageContainer from '../components/SplashPageContainer';
import { Theme } from '../theme';
//@ts-ignore
import Logo from '../img/logo-white.png';

export default function RedirectPatient(_: RouteProps) {
    return (
        <SplashPageContainer>
            <div className="mx-auto text-center">
                <img src={Logo} alt="Symmetry logo" height={200} className="mx-auto mb-4" />
            </div>
            <h3 className={`text-${Theme.darkBlue} text-xl mb-4 text-center font-semibold`}>
                Welcome to Symmetry
            </h3>
            <div className="mx-auto mt-3 text-center bg-white border border-gray-400 rounded-md shadow-md p-5">
                <p className="text-lg text-gray-600">
                    This link is meant to be opened in the Symmetry Mobile App. You can either click the link
                    again on your mobile device or download the Symmetry Mobile App from Google Play or the
                    Apple App Store and login to continue.
                </p>
            </div>
        </SplashPageContainer>
    );
}
