import React, { useEffect, useRef } from 'react';
import { FetchRequest, FormSubmission, RouteProps } from '../../../../types';
import ToastAlert from '../../../../components/ToastAlert';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import { selectToastAlert } from '../../../../redux/currentSession/currentSessionSelectors';
import { UrlParams } from '../../../../routes';
import { OpioidAgreementDocument } from '../../../../database/documents/OpioidAgreementDocument';
import { FormState, OpioidAgreementInputChange } from './opioidAgreementTypes';
import { useImmer } from 'use-immer';
import { selectSelectedOrganization } from '../../../../redux/selectedOrganization/selectedOrganizationSelectors';
import { setToastAlert, setToastError } from '../../../../redux/currentSession/currentSessionActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import OpioidAgreementForm from './OpioidAgreementForm';
import {
    toggleOpioidAgreementArchivedStatus,
    updateOpioidAgreementTitle,
} from '../../../../redux/selectedOrganization/selectedOrganizationActions';
import isAuthed from '../../../../hooks/isAuthed';
import { UserRoles } from '../../../../database/schemas/User';
import { AuthRedirection } from '../../../../components/AuthRedirection';
import { useSynchronizeOrgId } from '../../../../hooks/useSynchronizeOrgId';
import useNavigation from '../../../../hooks/useNavigation';

interface Props extends RouteProps {
    opioidAgreementId?: UrlParams.opioidAgreementId;
}

interface State {
    selectedOpioidAgreement: FetchRequest<OpioidAgreementDocument | undefined>;
    form: FormState;
}

const initialState: State = {
    form: { text: '', title: '' },
    selectedOpioidAgreement: { fetching: true, data: undefined },
};

export default function OpioidAgreementDetails(props: Props) {
    const navigation = useNavigation();
    const { synchronized } = useSynchronizeOrgId((orgId: string) =>
        navigation.getOpioidAgreementDetailsUrl(orgId, props.opioidAgreementId!)
    );
    const [state, updateState] = useImmer<State>(initialState);
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const dispatch = useDispatch();
    const isMounted = useRef<boolean>(false);

    useEffect(() => {
        (async () => {
            isMounted.current = true;
            if (props.opioidAgreementId && selectedOrganization && isMounted.current) {
                try {
                    const selectedOpioidAgreement = await selectedOrganization.getOpioidAgreement(
                        props.opioidAgreementId
                    );
                    updateState(draft => {
                        draft.selectedOpioidAgreement.data = selectedOpioidAgreement;
                        draft.form.title = selectedOpioidAgreement.data.title;
                        draft.form.text = selectedOpioidAgreement.data.text;
                        draft.form.isValid = true;
                    });
                } catch (error) {
                    console.log(error);
                    dispatch(
                        setToastError(
                            "An error occurred while trying to get retrieve the agreement's details"
                        )
                    );
                }
                updateState(draft => void (draft.selectedOpioidAgreement.fetching = false));
            }

            return () => {
                isMounted.current = false;
            };
        })();
    }, [props.opioidAgreementId, selectedOrganization]);

    const toggleArchivedStatus = async (e: FormSubmission) => {
        e.preventDefault();
        // await state.selectedOpioidAgreement.data?.toggleArchivedStatus();
        dispatch(toggleOpioidAgreementArchivedStatus(state.selectedOpioidAgreement.data!));
        dispatch(
            setToastAlert(
                `This agreement has been ${
                    !state.selectedOpioidAgreement.data!.data.archivedAt ? 'unarchived' : 'archived'
                }`
            )
        );
    };

    const handleTitleInput = (e: OpioidAgreementInputChange) => {
        e.persist();
        updateState(draft => void (draft.form.title = e.target.value));
    };

    const updateTitle = async (): Promise<void> => {
        await state.selectedOpioidAgreement.data?.update({ title: state.form.title });
        dispatch(
            updateOpioidAgreementTitle({
                title: state.form.title,
                opioidAgreementId: state.selectedOpioidAgreement.data!.id,
            })
        );
        dispatch(setToastAlert('Agreement title has been updated'));
    };

    const { data: selectedOpioidAgreement } = state.selectedOpioidAgreement;
    return (
        <AuthRedirection requiredRoles={[UserRoles.orgAdmin]} synchronized={synchronized}>
            <div className="w-full flex flex-row bg-gray-100 p-2">
                {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
                <div className="mx-4 mt-2 p-4 flex flex-col w-full">
                    <h2 className="text-blue-900 text-xl font-semibold">View Opioid Agreement</h2>
                    {state.selectedOpioidAgreement.fetching ? (
                        <LoadingSpinner type="page" />
                    ) : (
                        <OpioidAgreementForm
                            updateTitle={updateTitle}
                            scenario="viewing"
                            isArchived={!!selectedOpioidAgreement?.data.archivedAt}
                            timeStamp={
                                !!selectedOpioidAgreement?.data.archivedAt
                                    ? selectedOpioidAgreement.data.archivedAt!
                                    : selectedOpioidAgreement!.data.createdAt
                            }
                            form={state.form}
                            handleSubmit={toggleArchivedStatus}
                            handleInput={handleTitleInput}
                        />
                    )}
                </div>
            </div>
        </AuthRedirection>
    );
}
