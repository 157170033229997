import { getOrganizationsAndRoles } from '../../utils';
import { DocumentSnapshot } from '../FirestoreTypes';
import { UserAccess } from '../UserAccess';
import { BaseDocument } from './BaseDocument';
import { UserSchema } from '../schemas/User';
import { OrganizationRoles } from '../schemas/OrganizationRoles';

export class UserDocument<Schema extends UserSchema = UserSchema> extends BaseDocument<Schema> {
    access: UserAccess = new UserAccess(
        getOrganizationsAndRoles(this.data.organizations),
        this.data.isAppAdmin
    );

    constructor(snapshot: DocumentSnapshot<Schema>, documentType: string = 'User') {
        super(snapshot, documentType);
    }

    async updateUserRoles({
        organizationId,
        orgAdmin,
        provider,
    }: {
        organizationId: string;
        orgAdmin: boolean;
        provider: boolean;
    }): Promise<UserDocument> {
        // call updateOrganizationRole method, if successful, update local copy
        this.data.organizations[organizationId].roles = {
            0: orgAdmin,
            1: provider,
            2: false,
        };
        this.data.isOrgAdmin = Object.values(this.data.organizations).some(organization => {
            return organization.roles[OrganizationRoles.orgAdmin];
        });
        this.data.isProvider = Object.values(this.data.organizations).some(organization => {
            return organization.roles[OrganizationRoles.provider];
        });
        this.access = new UserAccess(getOrganizationsAndRoles(this.data.organizations), this.data.isAppAdmin);
        return this;
    }
}
