import { OrganizationRoles } from './schemas/OrganizationRoles';
import { OrganizationRolesObject, OrganizationsAndRoles } from './schemas/User';
import { immerable } from 'immer';

export class UserAccess {
    constructor(private organizations: OrganizationsAndRoles, private _isAppAdmin: boolean) {
        this[immerable] = true;
    }

    isAppAdmin(): boolean {
        return this._isAppAdmin;
    }

    isOrgAdminIn(organizationId: string): boolean {
        return this.isInOrganizationWithRoles(organizationId, [OrganizationRoles.orgAdmin]);
    }

    isProviderIn(organizationId: string): boolean {
        return this.isInOrganizationWithRoles(organizationId, [OrganizationRoles.provider]);
    }

    isPatient(): boolean {
        return !!Object.values(this.organizations).find(roles => roles[OrganizationRoles.patient]);
    }

    isPatientIn(organizationId: string): boolean {
        return this.isInOrganizationWithRoles(organizationId, [OrganizationRoles.patient]);
    }

    isInMaxNumberOfOrganizations(): boolean {
        return Object.keys(this.organizations).length >= 10;
    }

    isInOneOrganization(): boolean {
        return Object.keys(this.organizations).length == 1;
    }

    isInMultipleOrganizations(): boolean {
        return Object.keys(this.organizations).length > 1;
    }

    isInOrganization(organizationId: string): boolean {
        return !!this.organizations[organizationId];
    }

    getOrganizationIds(): string[] {
        return Object.keys(this.organizations);
    }

    getOrganizationRoles(organizationId: string): OrganizationRolesObject | undefined {
        return this.organizations[organizationId];
    }

    isInOrganizationWithRoles(organizationId: string, roles: OrganizationRoles[]): boolean {
        const organizationRoles = this.organizations[organizationId];
        return !!organizationRoles && roles.find(role => organizationRoles[role]) != undefined;
    }

    hasOrganizationRole(roles: OrganizationRoles[]): boolean {
        return !!Object.values(this.organizations)?.find(organizationRoles => {
            return roles.find(role => organizationRoles[role]) != undefined;
        });
    }
}
