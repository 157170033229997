import React, { useCallback, useEffect } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Label,
    Customized,
    Dot,
} from 'recharts';
import styled from 'styled-components';
import { InputChange } from '../../../../types';
import { useImmer } from 'use-immer';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import {
    selectSelectedPatientDocument,
    selectSelectedPatientEntries,
} from '../../../../redux/selectedPatient/selectedPatientSelectors';
import SegmentedControl from '../../../../components/SegmentedControl';
import { DataPoint, derivePatientPainPillHistory, deriveShortestInterval } from './historyTabFormatters';
import { EntryDocument } from '../../../../database/documents/EntryDocument';
import dateFNSSubDays from 'date-fns/subDays';
import { Theme } from '../../../../theme';

export enum HistoryIntervalKeys {
    lastSevenDays = 7,
    lastFourteenDays = 14,
}

enum DataKeys {
    shortActing = 'shortActing',
    averagePain = 'averagePain',
    date = 'date',
}

interface State {
    weekInterval: HistoryIntervalKeys;
    loading: boolean;
    painPillHistory: { [key in HistoryIntervalKeys]: DataPoint[] };
    shortestInterval: number;
}

export default function PainPillHistory() {
    const selectedPatientDocument = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const selectedPatientEntries = useSelector((state: ReduxState) => selectSelectedPatientEntries(state));
    const [state, updateState] = useImmer<State>({
        weekInterval: HistoryIntervalKeys.lastSevenDays,
        loading: true,
        painPillHistory: {
            [HistoryIntervalKeys.lastSevenDays]: [],
            [HistoryIntervalKeys.lastFourteenDays]: [],
        },
        shortestInterval: 1,
    });

    const handleIntervalChange = (e: InputChange) => {
        e.persist();
        updateState(draft => void (draft.weekInterval = Number(e.target.value)));
    };

    const filterEntriesByRange = useCallback(
        (entries: EntryDocument[]) => {
            const comparisonDate = dateFNSSubDays(new Date(), state.weekInterval);
            return entries.filter(({ data }) => data.createdAt.seconds >= comparisonDate.getSeconds());
        },
        [state.weekInterval]
    );

    useEffect(() => {
        (async () => {
            if (selectedPatientDocument && selectedPatientEntries) {
                try {
                    const allPatientMedications = await selectedPatientDocument.getAllMedications();
                    const filteredEntries = filterEntriesByRange(selectedPatientEntries);
                    const painPillHistory = derivePatientPainPillHistory({
                        entries: filteredEntries,
                        medications: allPatientMedications,
                        maxInterval: HistoryIntervalKeys.lastFourteenDays,
                    });
                    const shortestInterval = deriveShortestInterval({
                        entries: filteredEntries,
                        medications: allPatientMedications,
                    });
                    updateState(draft => {
                        draft.painPillHistory = {
                            [HistoryIntervalKeys.lastSevenDays]: painPillHistory.slice(
                                HistoryIntervalKeys.lastSevenDays
                            ),
                            [HistoryIntervalKeys.lastFourteenDays]: painPillHistory,
                        };
                        draft.shortestInterval = shortestInterval;
                        draft.loading = false;
                    });
                } catch (error) {
                    console.log(error);
                }
                updateState(draft => void (draft.loading = false));
            }
        })();
    }, [selectedPatientDocument, selectedPatientEntries]);

    return (
        <ReChartsContainer className="border border-gray-400 rounded-md bg-white text-gray-700 px-3 py-5 my-2">
            {state.loading ? (
                <LoadingSpinner type="page" />
            ) : (
                <>
                    <SegmentedControl
                        options={[
                            {
                                label: '1 week',
                                value: HistoryIntervalKeys.lastSevenDays,
                                checked: state.weekInterval === HistoryIntervalKeys.lastSevenDays,
                            },
                            {
                                label: '2 weeks',
                                value: HistoryIntervalKeys.lastFourteenDays,
                                checked: state.weekInterval === HistoryIntervalKeys.lastFourteenDays,
                            },
                        ]}
                        handleInput={handleIntervalChange}
                    />
                    <LineChart
                        width={500}
                        height={400}
                        data={state.painPillHistory[state.weekInterval]}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 35,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey={DataKeys.date}
                            name="Date"
                            allowDuplicatedCategory={true}
                            interval={state.weekInterval === HistoryIntervalKeys.lastSevenDays ? 0 : 1}
                            tick={<XAxisTick />}
                        />
                        <YAxis
                            type="number"
                            // @ts-ignore - allowed by the library but not built into the typing: https://github.com/recharts/recharts/issues/720
                            label={
                                <Label
                                    angle={270}
                                    position="insideLeft"
                                    offset={10}
                                    value="Average Pain"
                                    style={{ textAnchor: 'middle', fill: 'rgb(113,128,150)' }}
                                ></Label>
                            }
                            yAxisId="left"
                            orientation="left"
                            domain={[0, 10]}
                            tickCount={10}
                        />
                        <YAxis
                            type="number"
                            // @ts-ignore - allowed by the library but not built into the typing: https://github.com/recharts/recharts/issues/720
                            label={
                                <Label
                                    angle={270}
                                    position="insideRight"
                                    offset={10}
                                    value="Short Acting Doses"
                                    style={{ textAnchor: 'middle', fill: 'rgba(113, 128, 150, 1)' }}
                                ></Label>
                            }
                            yAxisId="right"
                            orientation="right"
                            domain={[0, 12]}
                        />
                        <Tooltip />
                        <Legend
                            formatter={value => {
                                const formattedLegendText = value.replace(/([A-Z])/g, ' $1');
                                return (
                                    formattedLegendText.charAt(0).toUpperCase() + formattedLegendText.slice(1)
                                );
                            }}
                        />
                        <Line
                            isAnimationActive={false}
                            type="linear"
                            yAxisId="left"
                            shapeRendering="crispEdges"
                            name="Average Pain"
                            dataKey={DataKeys.averagePain}
                            stroke="#303DA8"
                            strokeWidth={5}
                            connectNulls={true}
                        />
                        <Line
                            type="linear"
                            strokeWidth={5}
                            yAxisId="right"
                            shapeRendering="crispEdges"
                            isAnimationActive={false}
                            name="Short Acting"
                            dataKey={DataKeys.shortActing}
                            stroke="#00ABDC"
                            connectNulls={true}
                            dot={renderCustomDot}
                        />
                    </LineChart>
                </>
            )}
        </ReChartsContainer>
    );
}

function XAxisTick(props: any) {
    return (
        <g transform={`translate(${props.x},${props.y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                {props.payload.value}
            </text>
        </g>
    );
}

const ReChartsContainer = styled.div`
    .recharts-legend-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .recharts-legend-wrapper .recharts-default-legend {
        cursor: pointer;
        position: absolute;
        top: 25px;
    }
`;

const renderCustomDot = props => {
    const { cx, cy, stroke, payload, value } = props;

    console.log(props);

    if (value > 12) {
        return (
            // @ts-ignore - passing a fill works but isn't typed correctly
            <Dot cx={cx} cy={cy + 5} r={10} fill="red" />
        );
    }

    return (
        // @ts-ignore - passing a fill works but isn't typed correctly
        <Dot cx={cx} cy={cy} r={5} fill="#00ABDC" />
    );
};
