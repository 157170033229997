import React from 'react';
import { UserFormKeys } from '../../../database/schemas/User';
import { FormSubmission, InputChange } from '../../../types';
import TextInput from '../../../components/TextInput';
import { State } from './AccountSetup';
import MaskablePasswordInput from '../../../components/MaskablePasswordInput';
import SubmitButton from '../../../components/SubmitButton';

export interface SignUpFormProps extends Pick<State, 'form' | 'createAccountError' | 'fetchEmailError'> {
    handleInput: (e: InputChange) => void;
    isProvider?: boolean;
    userEmail?: string;
    handlePasswordBlur: () => void;
    handlePhoneNumberBlur: () => void;
    createAccountAndContinueToMFAEncouragement: (e: FormSubmission) => Promise<void>;
}

export default function SignUpForm(props: SignUpFormProps) {
    return (
        <form onSubmit={props.createAccountAndContinueToMFAEncouragement}>
            <div className="block font-semibold">
                <span className="text-gray-700 block">Email</span>
                <span className="text-gray-600 block font-normal text-sm">{props.userEmail}</span>
            </div>
            <div className="mb-4">
                <div className="w-full my-2">
                    <label className="block font-semibold mb-4" htmlFor={UserFormKeys.firstName}>
                        <span className="text-gray-700">First Name</span>
                        <TextInput
                            name={UserFormKeys.firstName}
                            value={props.form.firstName}
                            onChange={props.handleInput}
                            className="py-1"
                        />
                    </label>
                    <label className="block font-semibold mb-4" htmlFor={UserFormKeys.lastName}>
                        <span className="text-gray-700">Last Name</span>
                        <TextInput
                            name={UserFormKeys.lastName}
                            value={props.form.lastName}
                            onChange={props.handleInput}
                            className="py-1"
                        />
                    </label>
                    <label className="block font-semibold mb-4 relative" htmlFor={UserFormKeys.password}>
                        <span className="text-gray-700">Password</span>
                        <MaskablePasswordInput
                            onChange={props.handleInput}
                            value={props.form.password.value}
                            name={UserFormKeys.password}
                            onBlur={props.handlePasswordBlur}
                            type="selfControlled"
                            className={
                                props.form.password.showError || props.form.password.confirmedStrong === false
                                    ? 'border border-red-500'
                                    : ''
                            }
                        />
                        <InvalidPasswordMessage {...props.form.password} />
                    </label>
                    <PhoneNumberInput
                        {...props.form.phoneNumber!}
                        handleInput={props.handleInput}
                        handleBlur={props.handlePhoneNumberBlur}
                        isProvider={props.isProvider}
                    />
                </div>
            </div>
            <div className="w-full flex mt-12 flex-row justify-center items-center">
                <SubmitButton className="w-full" disabled={!props.form.valid} loading={props.form.submitting}>
                    Continue
                </SubmitButton>
            </div>
        </form>
    );
}

type InvalidPasswordMessageProps = Pick<State, 'form'>['form']['password'];

function InvalidPasswordMessage(props: InvalidPasswordMessageProps): JSX.Element | null {
    const classNames = 'text-xs text-center block w-full font-normal text-red-500 block';

    if (props.confirmedStrong === false) {
        return <p className={classNames}>The password chosen is too common. Please use another.</p>;
    } else if (props.showError && props.isValid === false) {
        return <p className={classNames}>Password must be at least 8 characters</p>;
    }
    return null;
}

type PhoneNumberInputProps = Pick<State, 'form'>['form']['phoneNumber'] & {
    isProvider?: boolean;
    handleInput: (e: InputChange) => void;
    handleBlur: () => void;
};

function PhoneNumberInput(props: PhoneNumberInputProps): JSX.Element | null {
    if (props.isProvider) {
        const classNames = `text-xs text-center font-normal block w-full absolute text-${
            props.showError ? 'red-500' : 'gray-600'
        } block mt-1`;

        const message = props.showError
            ? 'Please enter a valid phone number'
            : 'This will be the number patients will contact during an emergency';
        return (
            <label className="block font-semibold mb-2 relative" htmlFor={UserFormKeys.phoneNumber}>
                <span className="text-gray-700">Contact Phone Number</span>
                <span className="text-gray-600 text-xs ml-1 font-normal">(optional)</span>
                <TextInput
                    onChange={props.handleInput}
                    value={props.value}
                    name={UserFormKeys.phoneNumber}
                    onBlur={props.handleBlur}
                    className={props.showError ? 'border border-red-500 py-1' : 'py-1'}
                />
                <p className={classNames}>{message}</p>
            </label>
        );
    }
    return null;
}
