import React, { useCallback, useContext, useEffect } from 'react';
import { FetchRequest, ReactSelectState, RouteProps, SelectOption } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../redux/store';
import {
    selectSelectedOrganization,
    selectSelectedOrganizationActiveOpioidAgreements,
} from '../redux/selectedOrganization/selectedOrganizationSelectors';
import ToastAlert from '../components/ToastAlert';
import { selectToastAlert } from '../redux/currentSession/currentSessionSelectors';
import { CurrentUserContext } from '../context/CurrentUserContextProvider';
import { UserRoles } from '../database/schemas/User';
import { useImmer } from 'use-immer';
import { PatientDocument } from '../database/documents/PatientDocument';
import { setToastAlert, setToastError } from '../redux/currentSession/currentSessionActions';
import { OpioidAgreementDocument } from '../database/documents/OpioidAgreementDocument';
import { ProviderDocument } from '../database/documents/ProviderDocument';
import { useSynchronizeOrgId } from '../hooks/useSynchronizeOrgId';
import { AuthRedirection } from '../components/AuthRedirection';
import LoadingSpinner from '../components/LoadingSpinner';
import { UserOpioidAgreement } from '../database/schemas/Patient';
import { Theme } from '../theme';
import { getDateFromDateString } from '../utils';
import Select from '../components/Select';
import ActionButton from '../components/ActionButton';
import { format as dateFNSFormat } from 'date-fns';
import useNavigation from '../hooks/useNavigation';

interface State {
    patientsWithRenewals: FetchRequest<PatientDocument[]>;
}

export default function OpioidAgreementRenewals(_: RouteProps) {
    const navigation = useNavigation();
    const { synchronized } = useSynchronizeOrgId(navigation.getOpioidAgreementRenewalsUrl);
    const [state, updateState] = useImmer<State>({ patientsWithRenewals: { fetching: true, data: [] } });
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const selectedOrganizationActiveOpioidAgreements = useSelector((state: ReduxState) =>
        selectSelectedOrganizationActiveOpioidAgreements(state)
    );
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const dispatch = useDispatch();
    const currentUser = useContext(CurrentUserContext);

    useEffect(() => {
        (async () => {
            if (selectedOrganization && currentUser.document?.id) {
                try {
                    const delegatorAndCurrentUserIds = [currentUser.document.id];
                    if (currentUser.claims?.isProviderInCurrentOrganization()) {
                        const delegatorIds = await (currentUser.document as ProviderDocument).getDelegatorsIds(
                            selectedOrganization.id
                        );
                        delegatorAndCurrentUserIds.push(...delegatorIds);
                    }
                    const patientsWithRenewals = await selectedOrganization.getUpcomingOpioidAgreementRenewals(
                        delegatorAndCurrentUserIds
                    );
                    updateState(draft => void (draft.patientsWithRenewals.data = patientsWithRenewals));
                } catch (error) {
                    console.log(error);
                    dispatch(
                        setToastError(
                            'An error occurred while trying to gather the list of opioid agreement renewals'
                        )
                    );
                }
                updateState(draft => void (draft.patientsWithRenewals.fetching = false));
            }
        })();
    }, [selectedOrganization, currentUser.document?.id]);

    const handleOpioidAgreementSelect = async ({
        opioidAgreement,
        selectedPatientId,
    }: {
        opioidAgreement?: OpioidAgreementDocument;
        selectedPatientId: string;
    }) => {
        if (opioidAgreement) {
            updateState(draft => {
                draft.patientsWithRenewals.data[
                    draft.patientsWithRenewals.data.findIndex(({ id }) => selectedPatientId === id)
                ].data.opioidAgreement!.assignedOpioidAgreementId = opioidAgreement.id;
            });
        }
    };

    const saveChanges = useCallback(async (patient: PatientDocument) => {
        await patient.save();
        dispatch(
            setToastAlert(
                `${patient.data.firstName} ${patient.data.lastName}'s Opioid Agreement has been updated`
            )
        );
    }, []);

    return (
        <AuthRedirection requiredRoles={[UserRoles.provider]} synchronized={synchronized}>
            <div className="w-full bg-white p-4">
                {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
                <div className="mt-5 ml-5">
                    <h2 className="text-2xl text-gray-800 block ml-5 mb-3 font-semibold">
                        Upcoming Opioid Agreement Renewals
                    </h2>
                    <div className="border border-gray-400 p-4 flex ml-5 flex-col w-3/4 rounded-md bg-gray-100">
                        <h2 className="text-blue-900 block text-xl font-semibold">Patient List</h2>
                        {!!state.patientsWithRenewals.data.length && (
                            <div className="w-full flex flex-row items-center justify-start mt-2 px-2">
                                <h3 className="text-lg block text-blue-700 ml-3 w-1/4">Name</h3>
                                <h3 className="text-lg block text-blue-700 w-3/4 pl-10">Renewal Date</h3>
                            </div>
                        )}
                        {state.patientsWithRenewals.fetching ? (
                            <LoadingSpinner type="page" />
                        ) : (
                            <ul className="list-none pb-1">
                                {!state.patientsWithRenewals.data.length ? (
                                    <div className="text-gray-600 py-4">
                                        You have no assigned or delegated patients with upcoming renewals to
                                        display.
                                    </div>
                                ) : (
                                    state.patientsWithRenewals.data.map(patient => (
                                        <PatientListItem
                                            key={patient.id}
                                            opioidAgreements={selectedOrganizationActiveOpioidAgreements}
                                            patient={patient}
                                            selectedOpioidAgreement={patient.data.opioidAgreement}
                                            setSelectedOpioidAgreement={handleOpioidAgreementSelect}
                                            saveChanges={() => saveChanges(patient)}
                                        />
                                    ))
                                )}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </AuthRedirection>
    );
}

interface PatientListItemProps {
    opioidAgreements: OpioidAgreementDocument[];
    selectedOpioidAgreement?: UserOpioidAgreement;
    setSelectedOpioidAgreement: (args: {
        opioidAgreement?: OpioidAgreementDocument;
        selectedPatientId: string;
    }) => void;
    patient: PatientDocument;
    saveChanges: () => void;
}

function PatientListItem(props: PatientListItemProps): JSX.Element {
    const opioidAgreementSelect: ReactSelectState = {
        options: props.opioidAgreements.map(({ data, id }) => ({
            label: data.title,
            value: id,
        })),
        get selectedOption() {
            return this.options.find(
                ({ value }) => value === props.patient.data.opioidAgreement?.assignedOpioidAgreementId
            );
        },
        handleSelect(option) {
            const selectedOpioidAgreement = props.opioidAgreements.find(
                ({ id }) => (option as SelectOption).value === id
            );
            props.setSelectedOpioidAgreement({
                opioidAgreement: selectedOpioidAgreement,
                selectedPatientId: props.patient.id,
            });
        },
    };

    return (
        <div
            key={props.patient.id}
            className={`round-md bg-white hover:bg-${Theme.offWhite} border border-gray-400 px-3 py-4 my-3 rounded-md flex flex-1 flex-row justify-start items-center`}
        >
            <h5 className="block flex-1">
                {props.patient.data.lastName}, {props.patient.data.firstName}
            </h5>
            <h5 className="block flex-1">
                {dateFNSFormat(
                    getDateFromDateString(props.selectedOpioidAgreement?.expirationDate ?? ''),
                    'MMMM do, yyyy'
                )}
            </h5>{' '}
            <div className="block flex flex-row w-2/5">
                <Select
                    options={opioidAgreementSelect.options}
                    onChange={opioidAgreementSelect.handleSelect}
                    selectedOption={opioidAgreementSelect.selectedOption}
                    placeholder={opioidAgreementSelect.selectedOption ? undefined : 'Select an agreement'}
                />
                <ActionButton className="w-1/5 ml-3" onClick={props.saveChanges}>
                    Save
                </ActionButton>
            </div>
        </div>
    );
}
