import { navigate } from '@reach/router';
import { UserRoles } from './database/schemas/User';
import { Claims } from './Claims';
import FunctionsManager from './functions/FunctionsManager';

export enum UrlParams {
    organizationId = ':organizationId',
    opioidAgreementId = ':opioidAgreementId',
    userId = ':userId',
}

export enum QueryParams {
    organizationId = 'organizationId',
    actionCode = 'actionCode',
}

export enum UrlStateKeys {
    patientInviteSuccess,
}

export enum NonAuthRoutes {
    LOGIN = '/',
    RESET_PASSWORD = '/reset-password',
    ACCOUNT_SETUP = '/createAccount',
    UNAUTHORIZED = '/unauthorized',
    DOWNLOAD_MOBILE_APP = '/download',
    CONFIRM_PASSWORD_RESET = '/confirm-password-reset',
    OFFLINE = '/offline',
}

export const AuthRoutes = {
    ACCOUNT: '/account',

    PATIENTS: `/organizations/${UrlParams.organizationId}/patients`,
    ADD_PATIENT: `/organizations/${UrlParams.organizationId}/patients/add`,
    VIEW_PATIENT: `/organizations/${UrlParams.organizationId}/patients/${UrlParams.userId}`,

    ORGANIZATIONS: '/organizations',
    OPIOID_AGREEMENT_RENEWALS: `/organizations/${UrlParams.organizationId}/opioid-agreement-renewals`,

    ADMIN_ORGANIZATIONS: '/admin/organizations',
    ADMIN_ORGANIZATIONS_VIEW_ORGANIZATION: `/admin/organizations/${UrlParams.organizationId}/org-admins`,
    ADMIN_ORGANIZATIONS_VIEW_ADMIN: `/admin/organizations/${UrlParams.organizationId}/org-admins/${UrlParams.userId}`,

    VIEW_ORGANIZATION: `/organizations/${UrlParams.organizationId}`,

    VIEW_ORG_ADMIN: `/organizations/${UrlParams.organizationId}/org-admins/${UrlParams.userId}`,
    ADD_PROVIDER: `/organizations/${UrlParams.organizationId}/providers/add`,
    VIEW_PROVIDER: `/organizations/${UrlParams.organizationId}/providers/${UrlParams.userId}`,

    ADD_OPIOID_AGREEMENT: `/organizations/${UrlParams.organizationId}/opioid-agreements/add`,
    VIEW_OPIOID_AGREEMENT: `/organizations/${UrlParams.organizationId}/opioid-agreements/${UrlParams.opioidAgreementId}`,
};

export default class Navigator {
    //NON-AUTH
    get loginUrl() {
        return NonAuthRoutes.LOGIN;
    }

    get resetPasswordUrl() {
        return NonAuthRoutes.RESET_PASSWORD;
    }

    get unauthorizedUrl() {
        return NonAuthRoutes.UNAUTHORIZED;
    }

    //AUTH
    get accountUrl() {
        return AuthRoutes.ACCOUNT;
    }

    get adminOrganizationsUrl() {
        return AuthRoutes.ADMIN_ORGANIZATIONS;
    }

    getAdminViewOrganizationUrl(orgId: string) {
        return `/admin/organizations/${orgId}/org-admins`;
    }

    getAdminViewOrganizationAdminUrl(orgId: string, userId: string) {
        return `/admin/organizations/${orgId}/org-admins/${userId}`;
    }

    getPatientsUrl(orgId: string) {
        return `/organizations/${orgId}/patients`;
    }

    getPatientsAddUrl(orgId: string) {
        return `/organizations/${orgId}/patients/add`;
    }

    getOrganizationAdminDetailsUrl(orgId: string, adminId: string) {
        return `/organizations/${orgId}/org-admins/${adminId}`;
    }

    getPatientDetailsUrl(orgId: string, patientId: string) {
        return `/organizations/${orgId}/patients/${patientId}`;
    }

    getOpioidAgreementDetailsUrl(orgId: string, opioidAgreementId: string) {
        return `/organizations/${orgId}/opioid-agreements/${opioidAgreementId}`;
    }

    getOpioidAgreementRenewalsUrl(orgId: string) {
        return `/organizations/${orgId}/opioid-agreement-renewals`;
    }

    getOrganizationDetailsUrl(orgId: string) {
        return `/organizations/${orgId}`;
    }

    getProviderDetailsUrl(orgId: string, providerId: string) {
        return `/organizations/${orgId}/providers/${providerId}`;
    }

    getOrgAdminDetailsUrl(orgId: string, orgAdminId: string) {
        return `/organizations/${orgId}/org-admins/${orgAdminId}`;
    }

    getOrganizationOpioidAgreementAddUrl(orgId: string) {
        return `/organizations/${orgId}/opioid-agreements/add`;
    }

    async directUserBasedOnRole({
        claims,
        viewContext,
        organizationId = claims.currentOrgId!,
    }: {
        claims: Claims;
        viewContext: UserRoles | null;
        organizationId?: string;
    }) {
        const providerHomeRoute = this.getPatientsUrl(organizationId);
        const orgAdminHomeRoute = this.getOrganizationDetailsUrl(organizationId);
        let userIsMultiRole = false;
        if (!claims.currentOrgId) {
            await FunctionsManager.user.setCurrentOrganization({ organizationId });
        }
        if (claims.access?.isProviderIn(organizationId) && claims.access?.isOrgAdminIn(organizationId)) {
            userIsMultiRole = true;
        }
        if (claims.access?.isProviderIn(organizationId)) {
            if (viewContext === null || !userIsMultiRole) {
                await navigate(providerHomeRoute);
            } else {
                await navigate(viewContext === UserRoles.provider ? providerHomeRoute : orgAdminHomeRoute);
            }
        } else if (claims.access?.isOrgAdminIn(organizationId)) {
            if (viewContext === null || !userIsMultiRole) {
                await navigate(orgAdminHomeRoute);
            } else {
                await navigate(viewContext === UserRoles.provider ? providerHomeRoute : orgAdminHomeRoute);
            }
        } else {
            await navigate(orgAdminHomeRoute);
        }
    }
}
