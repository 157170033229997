import React from 'react';
import { FormSubmission, InputChange, RouteProps, ValidatablePassword } from '../types';
import useURLQueryParams from '../hooks/useURLQueryParams';
import SplashPageForm from '../components/SplashPageForm';
import SplashPageContainer from '../components/SplashPageContainer';
import { Theme } from '../theme';
import MaskablePasswordInput from '../components/MaskablePasswordInput';
import { useImmer } from 'use-immer';
import FunctionsManager from '../functions/FunctionsManager';
import AuthManager from '../AuthManager';
import { QueryParams } from '../routes';
import SubmitButton from '../components/SubmitButton';
import useNavigation from '../hooks/useNavigation';
import { navigate } from '@reach/router';

interface State {
    form: {
        password: ValidatablePassword;
        submitting?: boolean;
    };
    confirmPasswordStrengthError: unknown;
    confirmPasswordResetError: unknown;
}

const initialState: State = {
    form: {
        password: { value: '' },
    },
    confirmPasswordStrengthError: null,
    confirmPasswordResetError: null,
};

export default function ConfirmPasswordReset(_: RouteProps) {
    const [state, updateState] = useImmer<State>(initialState);
    const { actionCode } = useURLQueryParams<{ actionCode: QueryParams.actionCode }>();
    const navigation = useNavigation();

    const handleInput = (e: InputChange) => {
        e.persist();
        updateState(draft => {
            draft.form.password.showError = false;
            draft.form.password.value = e.target.value;
            draft.form.password.isValid = e.target.value.length >= 8;
        });
    };

    const handleBlur = () => {
        updateState(draft => {
            draft.form.password.showError = draft.form.password.isValid === false;
        });
    };

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => void (draft.form.submitting = true));
        if (actionCode) {
            const passwordConfirmedStrong = await FunctionsManager.user
                .confirmStrongPassword(state.form.password.value.trim())
                .catch(err => updateState(draft => void (draft.confirmPasswordStrengthError = err)));
            if (!passwordConfirmedStrong) {
                updateState(draft => void (draft.form.password.confirmedStrong = false));
            } else {
                await AuthManager.confirmPasswordReset({
                    code: actionCode,
                    newPassword: state.form.password.value.trim(),
                }).catch(err => updateState(draft => void (draft.confirmPasswordResetError = err)));
                await navigate(navigation.loginUrl);
            }
        }
        updateState(draft => void (draft.form.submitting = false));
    };

    const invalidPasswordMessage = (() => {
        if (state.form.password.isValid === false) {
            return 'Your password must be at least 8 characters';
        } else if (state.form.password.confirmedStrong === false) {
            return 'The password chosen is too common. Please use another.';
        }
        return null;
    })();

    return (
        <SplashPageContainer>
            <SplashPageForm onSubmit={handleSubmit} className="mt-32">
                <h3 className={`text-${Theme.darkBlue} text-xl mb-4 text-center font-semibold`}>
                    Set Password
                </h3>
                <div className="mb-1 text-center relative">
                    <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                        Please enter the new password to be associated with your account
                    </label>
                    <MaskablePasswordInput
                        onChange={handleInput}
                        onBlur={handleBlur}
                        value={state.form.password.value}
                        type="selfControlled"
                        name="password"
                        className={
                            state.form.password.showError || state.form.password.confirmedStrong === false
                                ? 'border border-red-500'
                                : ''
                        }
                    />
                    {invalidPasswordMessage && (
                        <p className="text-xs text-center block w-full absolute text-red-500 block mt-1">
                            {invalidPasswordMessage}
                        </p>
                    )}
                </div>
                <div className="pb-2 mt-10 flex flex-row w-full justify-center">
                    <SubmitButton
                        className="w-full mt-1"
                        loading={state.form.submitting}
                        disabled={!state.form.password.isValid}
                    >
                        Submit New Password
                    </SubmitButton>
                </div>
            </SplashPageForm>
        </SplashPageContainer>
    );
}
