import React from 'react';
import { RouteProps } from '../../types';
import PageContainer from '../../components/PageContainer';
import PatientForm from './tabs/patient/PatientForm';
import { AuthRedirection } from '../../components/AuthRedirection';
import { UserRoles } from '../../database/schemas/User';
import { useSynchronizeOrgId } from '../../hooks/useSynchronizeOrgId';
import useNavigation from '../../hooks/useNavigation';

export default function AddPatient(_: RouteProps) {
    const navigation = useNavigation();
    const { synchronized } = useSynchronizeOrgId(navigation.getPatientsAddUrl);
    return (
        <AuthRedirection requiredRoles={[UserRoles.provider]} synchronized={synchronized}>
            <PageContainer>
                <div className="max-w-2xl p-4 border mt-5 shadow border-gray-400 bg-gray-100 rounded-md">
                    <PatientForm addingPatient={true} />
                </div>
            </PageContainer>
        </AuthRedirection>
    );
}
