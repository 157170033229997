import React, { useContext, useEffect } from 'react';
import { isValidEmail } from '../utils';
import { FirestoreError, FormSubmission, InputChange, RouteProps } from '../types';
import SubmitButton from '../components/SubmitButton';
import ErrorMessage from '../components/ErrorMessage';
import { Theme } from '../theme';
import { Link, navigate } from '@reach/router';
import SplashPageContainer from '../components/SplashPageContainer';
import SplashPageForm from '../components/SplashPageForm';
import { useImmer } from 'use-immer';
import { CurrentUserContext, MultiFactorAuthState } from '../context/CurrentUserContextProvider';
import OrganizationSelect from './OrganizationSelect';
import useNavigation from '../hooks/useNavigation';
import MaskablePasswordInput from '../components/MaskablePasswordInput';
import { UserFormKeys, UserRoles } from '../database/schemas/User';
import { Environments } from '../../firebaseConfig';
import TextInput from '../components/TextInput';
import AuthManager, { AuthErrorCodes } from '../AuthManager';
// @ts-ignore
import Logo from '../img/logo-white.png';

const minPasswordLength = 8;

interface State {
    form: {
        email: string;
        password: string;
        submitting: boolean;
        error: FirestoreError | null;
        valid: boolean;
    };
    emailStatus: Credential;
    multiFactorAuth: MultiFactorAuthState;
    passwordStatus: Credential;
    userMustSelectOrganization?: boolean;
}

const credentials = {
    [UserRoles.provider]: { email: '', password: '' },
    [UserRoles.orgAdmin]: { email: '', password: '' },
    [UserRoles.appAdmin]: { email: '', password: '' },
};

const initialFormState: State['form'] = {
    [UserFormKeys.email]:
        process.env.REACT_APP_ENV === Environments.INTERNAL ? credentials[UserRoles.provider].email : '',
    [UserFormKeys.password]:
        process.env.REACT_APP_ENV === Environments.INTERNAL ? credentials[UserRoles.provider].password : '',
    submitting: false,
    valid: false,
    error: null,
};

type Credential = { missing: boolean | null; valid: boolean | null };
const initialCredentialState: Credential = { missing: null, valid: null };

const initialLoginState: State = {
    multiFactorAuth: {
        enabled: false,
        verificationCode: '',
        enableMFARequest: { fetching: false, data: undefined },
        personalPhoneNumber: { value: '' },
    },
    form: initialFormState,
    emailStatus: initialCredentialState,
    passwordStatus: initialCredentialState,
};

export default function Login(_: RouteProps) {
    const [state, updateState] = useImmer<State>(initialLoginState);
    const currentUser = useContext(CurrentUserContext);
    const navigation = useNavigation();

    const _setDevCredentials = (role: UserRoles) => {
        updateState(draft => {
            draft.form.email = credentials[role].email;
            draft.form.password = credentials[role].password;
        });
    };

    const handleInput = (e: InputChange) => {
        e.persist();
        updateState(draft => {
            draft.form.error = null;
            draft.emailStatus = initialCredentialState;
            draft.passwordStatus = initialCredentialState;
            draft.form[e.target.name] = e.target.value;
        });
    };

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        //if enabled, this will handle verification code submission
        //otherwise proceed with standard login
        if (state.multiFactorAuth.resolveSignIn) {
            updateState(draft => void (draft.multiFactorAuth.submittingVerificationCode = true));
            await state.multiFactorAuth.resolveSignIn(state.multiFactorAuth.verificationCode).catch(error => {
                console.log(`error during MFA verification code submission -- ${error.code}`);
                updateState(draft => {
                    draft.multiFactorAuth.submittingVerificationCode = false;
                    draft.multiFactorAuth.submissionError = error;
                });
            });
        } else {
            updateState(draft => void (draft.form.submitting = true));
            const { email, password } = state.form;
            const resolveSignInForMFAUser = await AuthManager.login({
                email: email.trim(),
                password: password.trim(),
            }).catch(error => {
                console.log(`error during login not relating to MFA -- ${error}`);
                updateState(draft => void ((draft.form.submitting = false), (draft.form.error = error)));
            });
            if (resolveSignInForMFAUser) {
                updateState(draft => {
                    draft.multiFactorAuth.resolveSignIn = resolveSignInForMFAUser;
                    draft.multiFactorAuth.awaitingCodeConfirmation = true;
                });
            }
        }
    };

    useEffect(() => {
        updateState(draft => {
            draft.form.valid =
                !!state.form.email &&
                isValidEmail(state.form.email) &&
                !!state.form.password &&
                state.form.password.length >= minPasswordLength;
        });
    }, [state.form.email, state.form.password]);

    useEffect(() => {
        (async () => {
            if (!currentUser.claims || state.multiFactorAuth.enabled) {
                return;
            }
            if (currentUser.claims?.isAppAdmin) {
                await navigate(navigation.adminOrganizationsUrl);
            } else if (!currentUser.claims.isInOrganizations()) {
                // user is not in any organizations, which means they were removed from their last org and now are
                // orphaned. they can still be invited to a new org and use the same email/pw so we maintain the
                // account.
                await navigate(navigation.accountUrl);
            } else if (currentUser.claims && !currentUser.claims.currentOrgId) {
                // user is logged in but does not have an org set
                updateState(draft => void (draft.userMustSelectOrganization = true));
                // the user has an org set, so check their view context before setting their home route
            } else if (currentUser.claims?.currentOrgId && !currentUser.claims.isPatient) {
                await navigation.directUserBasedOnRole({
                    claims: currentUser.claims,
                    viewContext: currentUser.viewContext,
                });
            } else {
                await navigate(navigation.unauthorizedUrl);
            }
            updateState(draft => void (draft.form.submitting = false));
        })();
    }, [currentUser.claims?.currentOrgId, state.multiFactorAuth.enabled]);

    const handleVerificationCodeInput = (e: InputChange) => {
        e.persist();
        if (e.target.value.length <= 6) {
            updateState(draft => {
                draft.multiFactorAuth.verificationCode = e.target.value;
                draft.multiFactorAuth.submissionError = undefined;
            });
        }
    };

    return (
        <>
            {/*{process.env.REACT_APP_ENV === Environments.INTERNAL && !state.userMustSelectOrganization && (*/}
            {/*    <div className="flex bg-white flex-row justify-center items-center mx-auto border-b border-gray-400 rounded-md p-3">*/}
            {/*        <code className="block text-lg text-blue-600 mr-4">INTERNAL ENVIRONMENT</code>*/}
            {/*        <span className="block font-mono">Set role-based credentials 👉</span>*/}
            {/*        <button*/}
            {/*            className={`mx-2 text-blue-600 px-1 ${*/}
            {/*                credentials[UserRoles.provider].email === state.form.email*/}
            {/*                    ? 'font-bold'*/}
            {/*                    : 'font-normal'*/}
            {/*            }`}*/}
            {/*            onClick={() => _setDevCredentials(UserRoles.provider)}*/}
            {/*        >*/}
            {/*            <code>provider</code>*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            className={`mx-2 text-blue-600 px-1 ${*/}
            {/*                credentials[UserRoles.orgAdmin].email === state.form.email*/}
            {/*                    ? 'font-bold'*/}
            {/*                    : 'font-normal'*/}
            {/*            }`}*/}
            {/*            onClick={() => _setDevCredentials(UserRoles.orgAdmin)}*/}
            {/*        >*/}
            {/*            <code>orgAdmin</code>*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            className={`mx-2 text-blue-600 px-1 ${*/}
            {/*                credentials[UserRoles.appAdmin].email === state.form.email*/}
            {/*                    ? 'font-bold'*/}
            {/*                    : 'font-normal'*/}
            {/*            }`}*/}
            {/*            onClick={() => _setDevCredentials(UserRoles.appAdmin)}*/}
            {/*        >*/}
            {/*            <code>appAdmin</code>*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*)}*/}
            <SplashPageContainer>
                <div className="mx-auto text-center">
                    <img src={Logo} alt="Symmetry logo" height={200} className="mx-auto mb-4" />
                </div>
                {state.userMustSelectOrganization ? (
                    <OrganizationSelect />
                ) : (
                    <SplashPageForm onSubmit={handleSubmit}>
                        {state.multiFactorAuth.awaitingCodeConfirmation ? (
                            <div className="mb-4">
                                <span className="text-gray-700 mb-3 block">
                                    Please enter the verification code sent via SMS to your provided phone
                                    number
                                </span>
                                <TextInput
                                    value={state.multiFactorAuth.verificationCode}
                                    onChange={handleVerificationCodeInput}
                                    className={`py-1 ${
                                        (state.multiFactorAuth.submissionError as { code: AuthErrorCodes })
                                            ?.code === AuthErrorCodes.invalidVerificationCode
                                            ? 'border border-red-500'
                                            : ''
                                    }`}
                                />
                                {!!state.multiFactorAuth.submissionError && (
                                    <ErrorMessage>
                                        {AuthManager.translateError(state.multiFactorAuth.submissionError)}
                                    </ErrorMessage>
                                )}
                                <div className="mt-5 w-full">
                                    <SubmitButton
                                        className="w-full"
                                        loading={state.multiFactorAuth.submittingVerificationCode}
                                        disabled={
                                            !state.multiFactorAuth.verificationCode ||
                                            state.multiFactorAuth.verificationCode.length < 6
                                        }
                                    >
                                        Submit Code
                                    </SubmitButton>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="mb-4">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor={UserFormKeys.email}
                                    >
                                        Email
                                    </label>
                                    <TextInput
                                        className={`py-1 ${
                                            state.emailStatus.missing ||
                                            (state.emailStatus.missing === false &&
                                                !isValidEmail(state.form.email))
                                                ? 'border-red-500'
                                                : 'input-border'
                                        }`}
                                        type="email"
                                        name={UserFormKeys.email}
                                        value={state.form.email}
                                        onChange={handleInput}
                                        onBlur={() => {
                                            let emailStatus: Credential;
                                            if (!state.form.email) {
                                                emailStatus = { missing: true, valid: null };
                                            } else if (state.form.email && !isValidEmail(state.form.email)) {
                                                emailStatus = { missing: null, valid: false };
                                            } else {
                                                emailStatus = initialCredentialState;
                                            }
                                            updateState(draft => void (draft.emailStatus = emailStatus));
                                        }}
                                    />
                                    {state.emailStatus.missing && (
                                        <p className="text-red-500 text-xs italic">
                                            Please enter your email.
                                        </p>
                                    )}
                                    {state.emailStatus.missing === null &&
                                        state.emailStatus.valid === false && (
                                            <p className="text-red-500 text-xs italic">
                                                Please enter a valid email address.
                                            </p>
                                        )}
                                </div>
                                <div className="mb-6">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor={UserFormKeys.password}
                                    >
                                        Password
                                    </label>
                                    <MaskablePasswordInput
                                        className={
                                            !state.passwordStatus.missing &&
                                            state.passwordStatus.valid === false
                                                ? 'border-red-500'
                                                : 'input-border'
                                        }
                                        name={UserFormKeys.password}
                                        value={state.form.password}
                                        tooShort={state.passwordStatus.valid === false}
                                        onChange={handleInput}
                                        type="selfControlled"
                                    />
                                    {state.passwordStatus.missing && (
                                        <p className="text-red-500 text-xs italic">
                                            Please enter a password.
                                        </p>
                                    )}
                                    {state.passwordStatus.missing === null &&
                                        state.passwordStatus.valid === false && (
                                            <p className="text-red-500 text-xs italic">
                                                Your password must be at least 8 characters
                                            </p>
                                        )}
                                    {!!state.form.error && (
                                        <ErrorMessage className="mt-3">
                                            {AuthManager.translateError(state.form.error)}
                                        </ErrorMessage>
                                    )}
                                </div>
                                <div className="flex items-center justify-between">
                                    <SubmitButton
                                        loading={state.form.submitting}
                                        className={`bg-${Theme.darkBlue} hover:bg-${Theme.darkBlueHover} text-white font-bold py-1 px-2 md:py-2 md:px-4 rounded focus:outline-none focus:shadow-outline`}
                                        disabled={!state.form.valid}
                                    >
                                        Login
                                    </SubmitButton>
                                    <Link
                                        className={`inline-block align-baseline font-bold text-xs md:text-sm text-${Theme.darkBlue} hover:text-${Theme.darkBlueHover} ml-auto pt-5`}
                                        to={navigation.resetPasswordUrl}
                                    >
                                        Forgot Password?
                                    </Link>
                                </div>
                            </>
                        )}
                    </SplashPageForm>
                )}
                <p className="text-center text-white text-xs">&copy;2020 Symmetry. All rights reserved.</p>
            </SplashPageContainer>
        </>
    );
}
