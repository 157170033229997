import React, { useCallback, useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import MedicationTab from './tabs/medication/MedicationTab';
import HistoryTab from './tabs/history/HistoryTab';
import { RouteProps } from '../../types';
import PageContainer from './../../components/PageContainer';
import PatientTab from './../../pages/patients/tabs/patient/PatientTab';
import { useImmer } from 'use-immer';
import { useDispatch, useSelector } from 'react-redux';
import {
    cancelChangesToPatient,
    getPatientById,
    saveChangesToPatient,
} from '../../redux/selectedPatient/selectedPatientActions';
import PatientDetailsHeader from './PatientDetailsHeader';
import { ReduxState } from '../../redux/store';
import {
    selectLoadingSelectedPatient,
    selectSelectedPatientActiveAlerts,
    selectSelectedPatientDocument,
    selectSelectedPatientUpdates,
} from '../../redux/selectedPatient/selectedPatientSelectors';
import Alert from '../../components/Alert';
import { setToastError } from '../../redux/currentSession/currentSessionActions';
import Tabulation, { Tab } from '../../components/Tabulation';
import { AuthRoutes, UrlParams } from '../../routes';
import { UserRoles } from '../../database/schemas/User';
import { Theme } from '../../theme';
import IconManager, { IconType } from '../../components/IconManager';
import ActionButton from '../../components/ActionButton';
import ConfirmationModal from '../../components/ConfirmationModal';
import { AuthRedirection } from '../../components/AuthRedirection';
import { useSynchronizeOrgId } from '../../hooks/useSynchronizeOrgId';
import useNavigation from '../../hooks/useNavigation';
import useBeforeUnload from 'use-before-unload';
import { navigate } from '@reach/router';

export enum TabKeys {
    PATIENT = 'PATIENT',
    MEDICATIONS = 'MEDICATIONS',
    HISTORY = 'HISTORY',
}

interface Props extends RouteProps {
    userId?: UrlParams.userId;
}

interface State {
    error: unknown;
    tabs: Tab[];
    savingChanges: boolean;
    modalScenario?: ModalScenarios;
}

enum ModalScenarios {
    CANCELING_PATIENT_UPDATES,
    POPPING_STATE_WTIH_UPDATES,
}

const initialTabState: Tab[] = [
    { key: TabKeys.PATIENT, active: true, Component: PatientTab, title: 'Patient', type: 'component' },
    {
        key: TabKeys.MEDICATIONS,
        active: false,
        Component: MedicationTab,
        title: 'Medications',
        type: 'component',
    },
    { key: TabKeys.HISTORY, active: false, Component: HistoryTab, title: 'History', type: 'component' },
];

export default function PatientDetails(props: Props) {
    const navigation = useNavigation();
    const { synchronized } = useSynchronizeOrgId((orgId: string) =>
        navigation.getPatientDetailsUrl(orgId, props.userId!)
    );
    const [state, updateState] = useImmer<State>({
        error: null,
        savingChanges: false,
        tabs: initialTabState,
    });
    const selectedPatientUpdates = useSelector((state: ReduxState) => selectSelectedPatientUpdates(state));
    const selectedPatient = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const selectedPatientActiveAlerts = useSelector((state: ReduxState) =>
        selectSelectedPatientActiveAlerts(state)
    );
    const loadingSelectedPatient = useSelector((state: ReduxState) => selectLoadingSelectedPatient(state));
    const dispatch = useDispatch();
    const patientChangesRef = useRef(selectedPatientUpdates);

    // Subscribe the ref to changes of selected patient updates so that we can access it inside bound event listeners
    useEffect(() => {
        patientChangesRef.current = selectedPatientUpdates;
    }, [selectedPatientUpdates]);

    const saveChanges = useCallback(() => {
        updateState(draft => void (draft.savingChanges = true));
        dispatch(saveChangesToPatient());
    }, []);

    const cancelChanges = useCallback(() => {
        updateState(draft => void (draft.savingChanges = true));
        dispatch(cancelChangesToPatient());
        toggleModal();
    }, []);

    const cancelChangesAndPop = () => {
        updateState(draft => void (draft.savingChanges = true));
        dispatch(cancelChangesToPatient());
        toggleModal();
        navigate(navigation.getPatientsUrl(selectedPatient?.organizationId!));
    };

    const handlePopState = (event: PopStateEvent) => {
        if (!!patientChangesRef.current.length) {
            event.stopImmediatePropagation();
            toggleModal(ModalScenarios.POPPING_STATE_WTIH_UPDATES);
        }
    };

    useBeforeUnload(evt => {
        if (!!patientChangesRef.current.length) {
            evt.preventDefault();
            return true; // Suppress reload
        }
    });

    useEffect(() => {
        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, []);

    useEffect(() => {
        (async () => {
            if (props.userId) {
                try {
                    dispatch(getPatientById(props.userId));
                } catch (error) {
                    console.log(error);
                    updateState(draft => void (draft.error = error));
                    dispatch(setToastError('Error occurred while fetching patient'));
                }
            }
        })();
    }, [props.userId]);

    useEffect(() => {
        if ((state.savingChanges = true)) {
            updateState(draft => void (draft.savingChanges = false));
        }
    }, [selectedPatientUpdates.length]);

    const switchTab = useCallback((tabKey: string) => {
        updateState(draft => void draft.tabs.map(tab => (tab.active = tab.key === tabKey)));
    }, []);

    const toggleModal = (modalScenario?: ModalScenarios) =>
        updateState(draft => void (draft.modalScenario = modalScenario));

    return (
        <AuthRedirection requiredRoles={[UserRoles.provider]} synchronized={synchronized}>
            <>
                <div
                    className={`flex flex-row justify-between items-center sticky top-0 left-0 bg-white w-screen z-30 py-4 border-b border-t border-blue-600`}
                >
                    {!!selectedPatientUpdates.length ? (
                        <span
                            className={`text-${Theme.mediumBlue} text-sm flex flex-row items-center justify-start ml-32`}
                        >
                            <IconManager
                                type={IconType.INFO_CIRCLE}
                                size={20}
                                stroke="#2f3da8"
                                className="mr-1"
                            />
                            You have unsaved change(s)
                        </span>
                    ) : (
                        <span
                            className={`text-${Theme.mediumBlue} text-sm flex flex-row items-center justify-start ml-32`}
                        >
                            No unsaved changes to patient
                        </span>
                    )}
                    <div className={`flex flex-row`}>
                        <ActionButton
                            className="px-4"
                            disabled={!selectedPatientUpdates.length || state.savingChanges}
                            onClick={() => toggleModal(ModalScenarios.CANCELING_PATIENT_UPDATES)}
                            color={Theme.mediumBlue}
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            className="block mx-2 px-4 mr-20"
                            onClick={saveChanges}
                            color={Theme.mediumBlue}
                            disabled={!selectedPatientUpdates.length || state.savingChanges}
                        >
                            Save
                        </ActionButton>
                    </div>
                </div>
                <PageContainer>
                    <PatientDetailsHeader loading={loadingSelectedPatient} />
                    {selectedPatientActiveAlerts
                        .sort((a, b) => (a.data.createdAt < b.data.createdAt ? 1 : -1))
                        .map(alert => (
                            <Alert key={alert.id} alert={alert} />
                        ))}
                    <Tabulation tabs={state.tabs} switchTab={switchTab} loading={loadingSelectedPatient} />
                    <ConfirmationModal
                        isOpen={state.modalScenario === ModalScenarios.CANCELING_PATIENT_UPDATES}
                        closeModal={() => toggleModal()}
                        onConfirm={cancelChanges}
                    >
                        <p>
                            Are you sure you want to discard your unsaved change(s) to{' '}
                            {selectedPatient?.data.firstName}'s record?
                        </p>
                    </ConfirmationModal>
                    <ConfirmationModal
                        isOpen={state.modalScenario === ModalScenarios.POPPING_STATE_WTIH_UPDATES}
                        closeModal={() => toggleModal()}
                        onConfirm={cancelChangesAndPop}
                    >
                        <p>
                            Are you sure you want to discard your unsaved change(s) to{' '}
                            {selectedPatient?.data.firstName}'s record?
                        </p>
                    </ConfirmationModal>
                </PageContainer>
            </>
        </AuthRedirection>
    );
}
