import React, { useCallback, useEffect, useRef } from 'react';
import ToastAlert from '../../components/ToastAlert';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../redux/store';
import { selectToastAlert } from '../../redux/currentSession/currentSessionSelectors';
import Navigator, { UrlParams } from '../../routes';
import { FetchRequest, FormSubmission, InputChange, RouteProps, ValidatableString } from '../../types';
import { useImmer } from 'use-immer';
import { ProviderDocument } from '../../database/documents/ProviderDocument';
import { UserDocument } from '../../database/documents/UserDocument';
import DatabaseManager from '../../database/DatabaseManager';
import TextInput from '../../components/TextInput';
import ActionButton from '../../components/ActionButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Link } from '@reach/router';
import useNavigation from '../../hooks/useNavigation';
import { Theme } from '../../theme';
import { setToastAlert, setToastError } from '../../redux/currentSession/currentSessionActions';
import Modal from '../../components/Modal';
import SubmitButton from '../../components/SubmitButton';
import ErrorMessage from '../../components/ErrorMessage';
import { isValidEmail } from '../../utils';
import { OrganizationDocument } from '../../database/documents/OrganizationDocument';
import FunctionsManager from '../../functions/FunctionsManager';
import { OrganizationUser } from '../../redux/selectedOrganization/selectedOrganizationReducer';
import LoadMoreButton from '../../components/LoadMoreButton';
import { UserRoles } from '../../database/schemas/User';
import { InviteStatus } from '../../functions/userTypes';
import { AuthRedirection } from '../../components/AuthRedirection';

interface Props extends RouteProps {
    organizationId?: UrlParams.organizationId;
}

type OrganizationAdmin =
    | { isProvider: true; document: ProviderDocument }
    | { isProvider: false; document: UserDocument };

enum FormKeys {
    name = 'name',
}

interface State {
    organizationAdmins: FetchRequest<OrganizationAdmin[]>;
    paginatedOrgAdmins: OrganizationAdmin[];
    activePage: number;
    selectedOrganization?: OrganizationDocument;
    initialOrgAdminIdWithPendingInvite?: boolean;
    name: string;
    orgAdminToAdd: { email: ValidatableString; submitting: boolean };
    inviteStatus: InviteStatus;
    addingOrgAdmin?: boolean;
}

const initialOrgAdminToAdd = { email: { value: '' }, submitting: false };

export default function OrganizationAdminList(props: Props) {
    const pageIncrement = 8;
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const [state, updateState] = useImmer<State>({
        organizationAdmins: { data: [], fetching: true, error: null },
        name: '',
        inviteStatus: { fetching: false, data: undefined },
        orgAdminToAdd: initialOrgAdminToAdd,
        paginatedOrgAdmins: [],
        activePage: 1,
    });
    const dispatch = useDispatch();
    const isMounted = useRef<boolean>(false);
    const navigation = useNavigation();

    useEffect(() => {
        (async () => {
            isMounted.current = true;
            if (props.organizationId && isMounted.current) {
                try {
                    const selectedOrganization = await DatabaseManager.OrganizationModel.get(
                        props.organizationId
                    );
                    updateState(draft => {
                        draft.name = selectedOrganization.data.name;
                        draft.selectedOrganization = selectedOrganization;
                    });
                    const organizationAdmins = deriveProviderStatus(
                        await selectedOrganization.getOrgAdmins()
                    );
                    updateState(draft => {
                        draft.organizationAdmins.data = organizationAdmins;
                        draft.paginatedOrgAdmins = organizationAdmins.slice(0, pageIncrement);
                    });
                } catch (error) {
                    console.log(error);
                    dispatch(setToastError("An error occurred while gathering the organization's admins"));
                }

                updateState(draft => void (draft.organizationAdmins.fetching = false));

                return () => {
                    isMounted.current = false;
                };
            }
        })();
    }, [props.organizationId]);

    const deriveProviderStatus = (orgAdmins: OrganizationUser[]): OrganizationAdmin[] => {
        return orgAdmins.map(user =>
            user.data.isProvider
                ? { document: user as ProviderDocument, isProvider: true }
                : { document: user as UserDocument, isProvider: false }
        );
    };

    const loadMoreOrgAdmins = async () => {
        updateState(draft => {
            draft.paginatedOrgAdmins.push(
                ...draft.organizationAdmins.data.slice(draft.activePage * pageIncrement)
            );
        });
    };

    const inviteOrgAdmin = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        if (!isValidEmail(state.orgAdminToAdd.email.value)) {
            return updateState(draft => void (draft.orgAdminToAdd.email.isValid = false));
        }
        updateState(draft => void (draft.orgAdminToAdd.submitting = true));
        const emailIsEligible = await FunctionsManager.invite.isEligible({
            email: state.orgAdminToAdd.email.value,
            role: UserRoles.orgAdmin,
            organizationId: props.organizationId!,
        });
        if (emailIsEligible) {
            try {
                const { userId } = await FunctionsManager.invite.organizationAdmin({
                    organizationId: props.organizationId!,
                    email: state.orgAdminToAdd.email.value.trim(),
                    isAlsoProvider: false,
                });
                const invitedOrgAdmin = await DatabaseManager.UserModel.get(userId);
                updateState(draft => {
                    if (draft.paginatedOrgAdmins.length < 10) {
                        draft.paginatedOrgAdmins.push({ document: invitedOrgAdmin, isProvider: false });
                    } else {
                        draft.organizationAdmins.data.push({ document: invitedOrgAdmin, isProvider: false });
                    }
                    draft.addingOrgAdmin = false;
                    draft.orgAdminToAdd = initialOrgAdminToAdd;
                });
                dispatch(
                    setToastAlert(
                        'An organization admin invitation has been sent to the provided email address'
                    )
                );
            } catch (error) {
                console.log(error);
                updateState(draft => void (draft.addingOrgAdmin = false));
                dispatch(setToastError('An error occurred while processing your invite request'));
            }
        } else {
            updateState(draft => void (draft.inviteStatus.error = { emailAlreadyInUse: true }));
        }
        updateState(draft => void (draft.orgAdminToAdd.submitting = false));
    };

    const handleEmailInput = (e: InputChange) => {
        e.persist();
        updateState(draft => {
            draft.inviteStatus.error = undefined;
            draft.orgAdminToAdd.email.value = e.target.value;
        });
    };

    const handleOrganizationNameInput = (e: InputChange) => {
        e.persist();
        updateState(draft => void (draft.name = e.target.value));
    };

    const updateOrganizationName = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        try {
            state.selectedOrganization?.update({ name: state.name });
            dispatch(setToastAlert('Organization name has been updated'));
        } catch (error) {
            console.log(error);
            dispatch(setToastError("An error occurred while attempting to update the organization's name"));
        }
    };

    const toggleModal = useCallback(() => {
        updateState(draft => {
            draft.addingOrgAdmin = !draft.addingOrgAdmin;
            draft.orgAdminToAdd.email.value = '';
        });
    }, []);

    const {
        paginatedOrgAdmins,
        organizationAdmins: { data: organizationAdmins, fetching },
    } = state;

    return (
        <AuthRedirection requiredRoles={[UserRoles.appAdmin]} synchronized={true}>
            <div className="w-full flex flex-row p-2">
                {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
                <div className="mt-5 border bg-gray-100 border-gray-400 p-4 flex ml-5 flex-col w-2/3 rounded-md">
                    <form
                        className="text-center mb-5 flex flex-row justify-start items-end lg:w-1/2 w-full"
                        onSubmit={updateOrganizationName}
                    >
                        <label
                            className="block flex mt-2 flex-col justify-start items-start w-full"
                            htmlFor={FormKeys.name}
                        >
                            <span className="font-semibold text-gray-700">Name</span>
                            <TextInput
                                value={state.name}
                                name={FormKeys.name}
                                onChange={handleOrganizationNameInput}
                                className="py-1"
                            />
                        </label>
                        <ActionButton type="submit" className="ml-2">
                            Update
                        </ActionButton>
                    </form>
                    <div className="mt-5">
                        <h2 className="text-blue-900 block text-xl font-semibold">Organization Admins</h2>
                    </div>
                    <AdminList
                        paginatedOrgAdmins={paginatedOrgAdmins}
                        navigation={navigation}
                        organizationId={props.organizationId}
                        loading={fetching}
                    />
                    <ActionButton onClick={toggleModal} className="mt-1 w-1/2 lg:w-1/3">
                        Invite Organization Admin
                    </ActionButton>
                    {paginatedOrgAdmins.length < organizationAdmins.length && (
                        <div className="mx-auto">
                            <LoadMoreButton
                                onClick={loadMoreOrgAdmins}
                                remainingCount={
                                    organizationAdmins.length - paginatedOrgAdmins.length < 10
                                        ? organizationAdmins.length - paginatedOrgAdmins.length
                                        : 10
                                }
                            />
                        </div>
                    )}
                </div>
                <Modal isOpen={!!state.addingOrgAdmin} closeModal={toggleModal}>
                    <form onSubmit={inviteOrgAdmin} className="pb-3">
                        <div className="w-full mx-auto px-2">
                            <h2 className="text-center block mb-2">
                                Please enter the email for the organization admin you'd like to invite
                            </h2>
                            <div className="relative pb-3">
                                <TextInput
                                    className={`py-1 mb-3 ${
                                        state.orgAdminToAdd.email.isValid === false ||
                                        state.inviteStatus.error?.emailAlreadyInUse
                                            ? 'border border-red-500'
                                            : ''
                                    }`}
                                    value={state.orgAdminToAdd.email.value}
                                    onChange={handleEmailInput}
                                />
                                {(state.orgAdminToAdd.email.isValid === false ||
                                    state.inviteStatus.error?.emailAlreadyInUse) && (
                                    <p className="w-full absolute bottom-0 left-0 text-xs text-center text-red-500 block">
                                        {state.inviteStatus.error?.emailAlreadyInUse
                                            ? `There is already an org admin account associated with this email`
                                            : 'Please enter a valid email address'}
                                    </p>
                                )}
                            </div>
                            <SubmitButton
                                className="w-full mt-3"
                                loading={state.orgAdminToAdd.submitting}
                                disabled={!state.orgAdminToAdd.email}
                            >
                                Send Invite
                            </SubmitButton>
                            {state.organizationAdmins.error && (
                                <ErrorMessage className="mt-2">
                                    An error occurred while attempting to add the organization admin. Please
                                    check your internet connection & try again.
                                </ErrorMessage>
                            )}
                        </div>
                    </form>
                </Modal>
            </div>
        </AuthRedirection>
    );
}

// interface EmailInputProps {}
//
// function EmailInput(): JSX.Element {}

type AdminListProps = Pick<State, 'paginatedOrgAdmins'> &
    Pick<Props, 'organizationId'> & { navigation: Navigator; loading?: boolean };

function AdminList(props: AdminListProps) {
    if (props.loading) {
        return <LoadingSpinner type="page" />;
    } else if (!props.paginatedOrgAdmins.length) {
        return <div className="text-gray-600 py-4">There are no admins for this organization to display</div>;
    } else {
        const listItemClassNames = `round-md bg-white hover:bg-${Theme.offWhite} border border-gray-400 px-3 py-4 my-3 rounded-md flex flex-row justify-start items-center`;
        return (
            <ul className="list-none w-full md:w-2/3 py-1">
                {props.paginatedOrgAdmins.map(orgAdmin =>
                    orgAdmin.document.data.authId ? (
                        <Link
                            key={orgAdmin.document.id}
                            to={props.navigation.getAdminViewOrganizationAdminUrl(
                                props.organizationId!,
                                orgAdmin.document.id
                            )}
                        >
                            <div className={listItemClassNames}>
                                <h5 className="block">
                                    {!orgAdmin.document.data.lastName || !orgAdmin.document.data.firstName ? (
                                        <span className="text-gray-600">User does not have name set</span>
                                    ) : (
                                        `${orgAdmin.document.data.lastName}, ${orgAdmin.document.data.firstName}`
                                    )}
                                </h5>
                                {orgAdmin.isProvider &&
                                    ((orgAdmin.document.access.isProviderIn(
                                        props.organizationId!
                                    ) as unknown) as ProviderDocument) && (
                                        <span className={`block text-sm ml-3 text-${Theme.lightBlue}`}>
                                            Provider
                                        </span>
                                    )}
                            </div>
                        </Link>
                    ) : (
                        <div className={`${listItemClassNames} text-gray-600`}>
                            Invite pending for{' '}
                            <span className={`ml-1 text-${Theme.lightBlue} font-semibold`}>
                                {orgAdmin.document.data.email}
                            </span>
                        </div>
                    )
                )}
            </ul>
        );
    }
}
