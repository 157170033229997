import React, { useEffect, useRef } from 'react';
import { FetchRequest, RouteProps } from '../../../../types';
import { UserRoles } from '../../../../database/schemas/User';
import UserProfile from '../UserProfile';
import { UserDocument } from '../../../../database/documents/UserDocument';
import { useImmer } from 'use-immer';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import DatabaseManager from '../../../../database/DatabaseManager';
import { selectToastAlert } from '../../../../redux/currentSession/currentSessionSelectors';
import { setToastError } from '../../../../redux/currentSession/currentSessionActions';
import ToastAlert from '../../../../components/ToastAlert';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { AuthRedirection } from '../../../../components/AuthRedirection';
import { useParams } from '@reach/router';

interface Props extends RouteProps {}

interface State {
    selectedOrgAdmin: FetchRequest<UserDocument | undefined>;
}

export default function OrganizationAdminDetails(props: Props) {
    const [state, updateState] = useImmer<State>({ selectedOrgAdmin: { fetching: true, data: undefined } });
    const isMounted = useRef<boolean>(false);
    const dispatch = useDispatch();
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const { userId } = useParams();

    useEffect(() => {
        (async () => {
            isMounted.current = true;
            if (userId && isMounted.current) {
                try {
                    const selectedOrgAdmin = await DatabaseManager.UserModel.get(userId);
                    updateState(draft => void (draft.selectedOrgAdmin.data = selectedOrgAdmin));
                } catch (error) {
                    console.log(error);
                    dispatch(
                        setToastError(
                            "An error occurred while trying to get the organization admin's information"
                        )
                    );
                }
                updateState(draft => void (draft.selectedOrgAdmin.fetching = false));
                return () => {
                    isMounted.current = false;
                };
            }
        })();
    }, [userId]);

    return (
        <AuthRedirection requiredRoles={[UserRoles.appAdmin]} synchronized={true}>
            <>
                {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
                <div className="px-5 pt-3 w-full flex flex-row justify-start">
                    <h4 className="ml-4 text-lg font-semibold text-gray-900">Organization Admin Details</h4>
                </div>
                <div className="w-full bg-gray-100 p-2">
                    {state.selectedOrgAdmin.fetching ? (
                        <LoadingSpinner type="page" />
                    ) : (
                        <div className="rounded-md bg-white mx-4 border border-gray-300 mt-2 p-4 flex flex-col w-1/2">
                            <UserProfile
                                userRole={UserRoles.orgAdmin}
                                selectedUser={state.selectedOrgAdmin.data}
                            />
                        </div>
                    )}
                </div>
            </>
        </AuthRedirection>
    );
}
