import { ReduxState } from '../store';
import { CurrentSessionReduxState } from './currentSessionReducer';

export function selectRequestedPathname(state: ReduxState): CurrentSessionReduxState['requestedPathname'] {
    return state.currentSession.requestedPathname;
}

export function selectPendingAccountCreationInfo(
    state: ReduxState
): CurrentSessionReduxState['pendingAccountCreationInfo'] {
    return state.currentSession.pendingAccountCreationInfo;
}

export function selectToastAlert(state: ReduxState): CurrentSessionReduxState['toastAlert'] {
    return state.currentSession.toastAlert;
}
