import React from 'react';
import styled, { keyframes } from 'styled-components';

type PageSpinnerProps = {
    type: 'page';
    className?: string;
};
type ButtonSpinnerProps = {
    type: 'button';
    loading: number;
    color?: string;
};

export default function LoadingSpinner(props: PageSpinnerProps | ButtonSpinnerProps) {
    return props.type === 'button' ? (
        <ButtonSpinner loading={props.loading ? 1 : 0} color={props.color ?? '#CBD5E0'} />
    ) : (
        <PageSpinner className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32" />
    );
}

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }`;

const ButtonSpinner = styled.div<Pick<ButtonSpinnerProps, 'loading' | 'color'>>`
    border: 4px solid ${({ color = 'white' }) => color};
    border-radius: 50%;
    border-top-color: white;
    opacity: ${({ loading }) => (!!loading ? 1 : 0)};
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    width: 24px;
    height: 24px;
    transition: opacity 200ms;
    animation: ${rotate} 1s linear;
    animation-iteration-count: infinite;
    transition-delay: ${({ loading }) => (!!loading ? '200ms' : '0ms')};
`;

const PageSpinner = styled.div`
    border-top-color: #3498db;
    -webkit-animation: spinner 1s linear infinite;
    animation: spinner 1s linear infinite;
    margin: 40px auto;
    @-webkit-keyframes spinner {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
