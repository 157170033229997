import { FirestoreOptions } from '../DatabaseTypes';
import { DocumentSnapshot } from '../FirestoreTypes';
import { AlertDocument } from './AlertDocument';
import { EntryDocument } from './EntryDocument';
import { UserDocument } from './UserDocument';
import { UserMedicationDocument } from './UserMedicationDocument';
import { ResourceDocument } from './ResourceDocument';
import { AlertSchema, EntrySchema, PatientSchema, UserMedicationSchema } from '../schemas/Patient';
import { PatientTableRow } from '../../pages/patients/patientList/PatientsList';

export class PatientDocument extends UserDocument<PatientSchema> {
    private readonly entriesCollectionPath = `${this.path}/entries`;
    private readonly alertsCollectionPath = `${this.path}/alerts`;
    private readonly medicationsCollectionPath = `${this.path}/medications`;
    // Patients can only be a part of one organization, so we know the first index key exists and is their sole org
    organizationId: string = Object.keys(this.data.organizations)[0];
    constructor(snapshot: DocumentSnapshot<PatientSchema>) {
        super(snapshot, 'Patient');
    }

    createMedication(data: UserMedicationSchema): Promise<UserMedicationDocument> {
        try {
            return this.createDocument(UserMedicationDocument, this.medicationsCollectionPath, data);
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'PatientDocument.createMedication()',
                error,
                path: this.path,
                collectionPath: this.medicationsCollectionPath,
                requestPayload: data,
                organizationId: this.organizationId,
            });
            throw error;
        }
    }

    async getActiveMedications(): Promise<UserMedicationDocument[]> {
        try {
            //return all, filter in the component
            return await this.queryDocuments(UserMedicationDocument, this.medicationsCollectionPath, {
                queries: [['archived', '==', false]],
            });
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'PatientDocument.getActiveMedication()',
                error,
                path: this.path,
                collectionPath: this.medicationsCollectionPath,
                organizationId: this.organizationId,
            });
            throw error;
        }
    }

    async getArchivedMedications(): Promise<UserMedicationDocument[]> {
        try {
            return await this.queryDocuments(UserMedicationDocument, this.medicationsCollectionPath, {
                queries: [['archived', '==', true]],
            });
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'PatientDocument.getActiveMedication()',
                error,
                path: this.path,
                collectionPath: this.medicationsCollectionPath,
                organizationId: this.organizationId,
            });
            throw error;
        }
    }

    getAllMedications(): Promise<UserMedicationDocument[]> {
        try {
            return this.getAllDocuments(UserMedicationDocument, this.medicationsCollectionPath);
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'PatientDocument.getAllMedications()',
                error,
                path: this.path,
                collectionPath: this.medicationsCollectionPath,
                organizationId: this.organizationId,
            });
            throw error;
        }
    }

    async getMedicationById(medicationId: string): Promise<UserMedicationDocument> {
        try {
            return await this.getDocument(
                UserMedicationDocument,
                `${this.medicationsCollectionPath}/${medicationId}`
            );
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'PatientDocument.getMedicationById()',
                error,
                path: this.path,
                collectionPath: this.medicationsCollectionPath,
                organizationId: this.organizationId,
            });
            throw error;
        }
    }

    getResources(): Promise<ResourceDocument[]> {
        const resourceIds = this.data.resources.map(({ resourceId }) => resourceId);
        try {
            return this.db.ResourceModel.getByIds(resourceIds);
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'PatientDocument.getResources()',
                error,
                path: this.path,
                resourceIds,
                organizationId: this.organizationId,
            });
            throw error;
        }
    }

    get tableRowData(): PatientTableRow {
        return {
            assignedPrescriberId: this.data.assignedPrescriber,
            patientId: this.id,
            firstName: this.data.firstName,
            lastName: this.data.lastName,
            lastAverageDayPainLevel: this.data.lastAverageDayPainLevel,
            averageDayPainSparkline: this.data.averageDayPainSparkline,
            lastWorstDayPainLevel: this.data.lastWorstDayPainLevel,
            worstDayPainSparkline: this.data.worstDayPainSparkline,
            lowestRefillPercentage: this.data.lowestRefillPercentage,
            lastEntryDate: this.data.lastEntryDate,
            shortActingOpioidUsage: this.data.shortActingOpioidUsage,
            lastTierThreeOrInformationalAlert: this.data.lastTierThreeOrInformationalAlert,
            monitoringLevel: this.data.monitoringLevel,
            lastBowelMovementDate: this.data.lastBowelMovementDate,
            accountCreated: !!this.data.authId,
        };
    }

    getAllEntries(options: FirestoreOptions<EntrySchema> = {}): Promise<EntryDocument[]> {
        try {
            return this.getAllDocuments(EntryDocument, this.entriesCollectionPath, {
                ...options,
                orderBy: options.orderBy ?? [['createdAt', false]],
            });
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'PatientDocument.getAllEntries()',
                error,
                path: this.path,
                options,
                organizationId: this.organizationId,
            });
            throw error;
        }
    }

    async getAlertsAboveTierOne(
        options: FirestoreOptions<AlertSchema> = {}
    ): Promise<{
        active: AlertDocument[];
        resolved: AlertDocument[];
    }> {
        try {
            const alerts = await this.queryDocuments(AlertDocument, this.alertsCollectionPath, {
                ...options,
                queries: [
                    ['tier', '>', 1],
                    ['organizationId', '==', this.organizationId],
                ],
            });

            return {
                active: alerts.filter(alert => alert.isActive()),
                resolved: alerts.filter(alert => !alert.isActive()),
            };
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'PatientDocument.getAlertsAboveTierOne()',
                error,
                path: this.path,
                collectionPath: this.alertsCollectionPath,
                organizationId: this.organizationId,
                options,
            });
            throw error;
        }
    }
}
