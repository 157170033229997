export enum Environments {
    INTERNAL = 'internal',
    CLIENT = 'client',
    PROD = 'prod',
}

export const firebaseConfig: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
} = (() => {
    switch (process.env.REACT_APP_ENV as Environments) {
        case Environments.INTERNAL:
            return {
                apiKey: 'AIzaSyCF8JC2HqnrMA4vmU3iZIDtqWZ8Ib3H9sY',
                authDomain: 'symmetryapp-internal.firebaseapp.com',
                databaseURL: 'https://symmetryapp-internal.firebaseio.com',
                projectId: 'symmetryapp-internal',
                storageBucket: 'symmetryapp-internal.appspot.com',
                messagingSenderId: '87596755654',
                appId: '1:87596755654:web:b7e7db321e0d607a21c5c7',
            };
        case Environments.CLIENT:
            return {
                apiKey: 'AIzaSyDlmf_aasTDrp7OdLCxxt8SHldm4kUEkMU',
                authDomain: 'symmetryapp-client.firebaseapp.com',
                databaseURL: 'https://symmetryapp-client.firebaseio.com',
                projectId: 'symmetryapp-client',
                storageBucket: 'symmetryapp-client.appspot.com',
                messagingSenderId: '1031455394429',
                appId: '1:87596755654:web:b7e7db321e0d607a21c5c7',
            };
        case Environments.PROD:
            return {
                apiKey: 'AIzaSyCmbiN8zinjeKUEFN6QZIp173lYSJNY8Dg',
                authDomain: 'symmetryapp-prod.firebaseapp.com',
                databaseURL: 'https://symmetryapp-prod.firebaseio.com',
                projectId: 'symmetryapp-prod',
                storageBucket: 'symmetryapp-prod.appspot.com',
                messagingSenderId: '373944133743',
                appId: '1:373944133743:web:7fa3a510065d1eb4b3859a',
            };
        default:
            return {
                apiKey: 'AIzaSyCYwJT9l_HRBjpuQMCtGJzzB9otfpEEODQ',
                authDomain: 'duke-symtest-internal.firebaseapp.com',
                databaseURL: 'https://duke-symtest-internal.firebaseio.com',
                projectId: 'duke-symtest-internal',
                storageBucket: 'duke-symtest-internal.appspot.com',
                messagingSenderId: '864456503543',
                appId: '1:864456503543:web:a4a947ec4c9bc4146963f2',
            };
    }
})();
