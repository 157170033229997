import React, { useCallback, useContext, useEffect } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { AlertDocument } from '../../../database/documents/AlertDocument';
import {
    BrowserStorageKeys,
    ButtonClick,
    FetchRequest,
    FormSubmission,
    InputChange,
    RouteProps,
} from '../../../types';
import { navigate, useLocation } from '@reach/router';
import { useImmer } from 'use-immer';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux/store';
import * as TableComponents from './patientTableDataComponents';
import { selectSelectedPatientDocument } from '../../../redux/selectedPatient/selectedPatientSelectors';
import { clearSelectedPatient } from '../../../redux/selectedPatient/selectedPatientActions';
import {
    deriveAndFormatTierThreeAndInfoAlerts,
    deriveTableRowDataFromPatients,
} from './patientTableFormatters';
import {
    selectLoadingSelectedOrganization,
    selectSelectedOrganization,
} from '../../../redux/selectedOrganization/selectedOrganizationSelectors';
import { AlertSchema, MonitoringLevel, PatientDashboardRow } from '../../../database/schemas/Patient';
import SegmentedControl from '../../../components/SegmentedControl';
import { CurrentUserContext } from '../../../context/CurrentUserContextProvider';
import { setToastAlert, setToastError } from '../../../redux/currentSession/currentSessionActions';
import { selectToastAlert } from '../../../redux/currentSession/currentSessionSelectors';
import ToastAlert from '../../../components/ToastAlert';
import { PatientSortOrderBy } from '../../../database/DatabaseTypes';
import useNavigation from '../../../hooks/useNavigation';
import { ProviderDocument } from '../../../database/documents/ProviderDocument';
import { parseSearchQuery } from './patientQueryFormatters';
import { PatientDocument } from '../../../database/documents/PatientDocument';
import { PatientQueryRequest } from '../../../functions/userTypes';
import Encouragement from '../../../components/Encouragement';
import useBrowserStorage from '../../../hooks/useBrowserStorage';
import { UrlParams, UrlStateKeys } from '../../../routes';
import InfiniteScroll from 'react-infinite-scroll-component';
import useURLQueryParams from '../../../hooks/useURLQueryParams';
import DatabaseManager from '../../../database/DatabaseManager';
import { OrganizationDocument } from '../../../database/documents/OrganizationDocument';
import { useSynchronizeOrgId } from '../../../hooks/useSynchronizeOrgId';
import { AuthRedirection } from '../../../components/AuthRedirection';
import { UserRoles } from '../../../database/schemas/User';
import AlertDashboard from './AlertDashboard';
import { Theme } from '../../../theme';
import TextInput from '../../../components/TextInput';
import SubmitButton from '../../../components/SubmitButton';
import IconManager, { IconType } from '../../../components/IconManager';
import ActionButton from '../../../components/ActionButton';
import { toPascalCase } from '../../../utils';

type TableRow = {
    assignedPrescriberId: string;
    patientId: string;
};

export type AlertDashboardData = { tierThree: AlertTableRow[]; informational: AlertTableRow[] };
export type PatientTableRow = PatientDashboardRow & TableRow;
export type AlertTableRow = Omit<AlertSchema, 'updatedAt' | 'cause' | 'tier' | 'date' | 'organizationId'> &
    TableRow & {
        alerts: AlertDocument[];
        patientPhoneNumber: string;
        patientName: string;
    };
export enum SearchFilters {
    constipation = 'constipation',
    lastentry = 'lastentry',
    monitoring = 'monitoring',
    pain = 'pain',
    shortacting = 'shortacting',
}

export type SearchError = {
    type:
        | 'invalidFilterField'
        | 'invalidMonitoringLevel'
        | 'invalidPain'
        | 'invalidShortActing'
        | 'invalidConstipation'
        | 'invalidLastEntry';
};

const SearchErrorStrings = {
    invalidFilterField:
        'Invalid filter. Valid filters are monitoring:, pain:, shortacting:, constipation:, and lastentry:',
    invalidMonitoringLevel:
        'Invalid monitoring level filter. Valid filters are monitoring:none, monitoring:daily, and monitoring:hourly.',
    invalidPain: 'Invalid pain level filter. Valid filters are pain:0 through pain:10',
    invalidShortActing:
        'Invalid short acting filter. Valid filters are shortacting:none, shortacting:low, shortacting:moderate, and shortacting:high.',
    invalidConstipation:
        'Invalid constipation filter. Valid filters are constipation:1, constipation:2, etc.',
    invalidLastEntry: 'Invalid lastentry filter. Valid filters are lastentry:1, lastentry:2, etc.',
};

export interface Search {
    fetching?: boolean;
    searchString: string;
    suggestion?: SearchFilters;
    error?: SearchError;
    remainingPatientCount?: number; //number of patients that match a search that aren't in view
}
export type SortField = keyof PatientDashboardRow;
export type SortOrder = 'ascending' | 'descending';

export interface SortStatus {
    order: SortOrder;
    isActive: boolean;
    priority?: number;
}

const defaultSort: PatientSortOrderBy[] = [
    {
        field: 'lastBowelMovementDate',
        descending: true,
        active: true,
    },
    { field: 'lastAverageDayPainLevel', descending: true, active: true },
    { field: 'shortActingOpioidUsage', descending: true, active: true },
];

const initialSearchState: Search = { searchString: '' };

const initialSortState: PatientSortOrderBy[] = [
    ...defaultSort,
    { field: 'lastEntryDate', descending: true, active: false },
    { field: 'monitoringLevel', descending: true, active: false },
    { field: 'lastWorstDayPainLevel', descending: true, active: false },
    { field: 'lastName', descending: true, active: false },
    { field: 'firstName', descending: true, active: false },
];

type ListData<T> = {
    patients: T[];
    alerts: AlertTableRow[];
    search: Search;
    sort: PatientSortOrderBy[];
    loadingMore?: boolean;
};

interface Props extends RouteProps {
    organizationId?: UrlParams;
}

export type CurrentUserPatientsData = {
    onlyForCurrentUserPatients: boolean;
    currentUserIdAndDelegatorsIds: string[];
    isDefaultSort: boolean;
};

export interface State {
    providerDashboardData: FetchRequest<Pick<ListData<PatientDocument>, 'patients' | 'alerts'>>;
    currentUserDelegatorIds: string[];
    patientTableRows: ListData<PatientTableRow> & { filtering?: boolean };
    patientsWithAlerts: PatientDocument[];
    patientFilter: TargetPatients;
    toolTipVisible: boolean;
    encourageUserToSetContactNumber?: boolean;
    fetchingDueToOrgChange: boolean;
}

export enum TargetPatients {
    CURRENT_ORGANIZATION_PATIENTS,
    CURRENT_USER_PATIENTS,
}

export const initialPatientListState: State = {
    providerDashboardData: {
        fetching: true,
        data: { patients: [], alerts: [] },
        error: null,
    },
    currentUserDelegatorIds: [],
    patientFilter: sessionStorage.getItem(
        BrowserStorageKeys.PROVIDER_HAS_SELECTED_THEIR_PATIENTS_IN_PATIENT_LIST
    )
        ? TargetPatients.CURRENT_USER_PATIENTS
        : TargetPatients.CURRENT_ORGANIZATION_PATIENTS,
    patientTableRows: {
        patients: [],
        alerts: [],
        sort: initialSortState,
        search: initialSearchState,
    },
    patientsWithAlerts: [],
    toolTipVisible: false,
    fetchingDueToOrgChange: false,
};

export default function PatientsList(props: Props) {
    const navigation = useNavigation();
    const { synchronized } = useSynchronizeOrgId(navigation.getPatientsUrl);
    const currentUser = useContext(CurrentUserContext);
    const [state, updateState] = useImmer<State>(initialPatientListState);
    const selectedPatient = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const dispatch = useDispatch();
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const location = useLocation();
    const { alertId } = useURLQueryParams();
    const loadingSelectedOrganization = useSelector((state: ReduxState) =>
        selectLoadingSelectedOrganization(state)
    );

    //session set variables for user settings
    const encouragementToSetContactNumber = useBrowserStorage({
        type: 'session',
        key: BrowserStorageKeys.PROVIDER_HAS_DISMISSED_CONTACT_NUMBER_ENCOURAGEMENT,
    });
    const providerSelectedTheirPatients = useBrowserStorage({
        type: 'session',
        key: BrowserStorageKeys.PROVIDER_HAS_SELECTED_THEIR_PATIENTS_IN_PATIENT_LIST,
    });

    const encourageProviderToSetContactNumber =
        currentUser.claims?.isProviderInCurrentOrganization() &&
        !(currentUser.document as ProviderDocument).data.phoneNumber &&
        !encouragementToSetContactNumber.itemExistsInStorage;

    const initialSetOfData = async () => {
        // make sure our synchronize hook has completed processing
        if (
            currentUser.claims?.currentOrgId &&
            currentUser.claims.currentOrgId === selectedOrganization?.id &&
            currentUser.claims.currentOrgId === props.organizationId
        ) {
            // If we have an alert ID, we've come from an alert email, so go straight to patient details page for that alert
            if (alertId) {
                const patientId = await DatabaseManager.PatientModel.getPatientIdFromAlertId({
                    alertId,
                    organizationId: currentUser.claims.currentOrgId,
                });
                if (patientId) {
                    await navigate(
                        navigation.getPatientDetailsUrl(currentUser.claims.currentOrgId, patientId)
                    );
                }
            }
            // If no alert ID, then we can just get the data now that all the org ids are up to date
            if (currentUser.claims.isProviderInCurrentOrganization()) {
                const delegatorIds = await (currentUser.document as ProviderDocument).getDelegatorsIds(
                    currentUser.claims.currentOrgId
                );
                const [patientPageInfo, { patientsWithAlerts, alertTableRows }] = await deriveDashboardData(
                    {
                        onlyForCurrentUserPatients:
                            state.patientFilter === TargetPatients.CURRENT_USER_PATIENTS,
                        currentUserIdAndDelegatorsIds: [currentUser.document?.id, ...delegatorIds],
                        isDefaultSort: true,
                    },
                    selectedOrganization
                );
                updateState(draft => {
                    draft.currentUserDelegatorIds = delegatorIds;
                    draft.encourageUserToSetContactNumber = encourageProviderToSetContactNumber;
                    draft.patientTableRows.search.remainingPatientCount = patientPageInfo.remaining;
                    draft.providerDashboardData.data.patients = patientPageInfo.patients;
                    draft.patientsWithAlerts = patientsWithAlerts;
                    draft.providerDashboardData.data.alerts = alertTableRows;
                    draft.patientTableRows.patients = deriveTableRowDataFromPatients(
                        patientPageInfo.patients
                    );
                    draft.patientTableRows.alerts = alertTableRows;
                });
            }
        }
    };

    /*
     * USE EFFECTS
     * */

    // fetch initial page data on mount/selected organization change
    useEffect(() => {
        (async () => {
            try {
                updateState(draft => void (draft.providerDashboardData.fetching = true));
                await initialSetOfData();
            } catch (error) {
                console.log(error);
                dispatch(setToastError('An error occurred while gathering patient data'));
            }

            updateState(draft => void (draft.providerDashboardData.fetching = false));
        })();
    }, [currentUser.claims?.currentOrgId]);

    useEffect(() => {
        (async () => {
            try {
                updateState(draft => void (draft.fetchingDueToOrgChange = true));
                await initialSetOfData();
            } catch (error) {
                console.log(error);
                dispatch(setToastError('An error occurred while gathering patient data'));
            }
            updateState(draft => void (draft.fetchingDueToOrgChange = false));
        })();
    }, [selectedOrganization]);

    // Make sure that the toast alert for successful patient invite is only shown once by replacing the state in the current location
    useEffect(() => {
        if (location.state?.[UrlStateKeys.patientInviteSuccess] && props.organizationId) {
            dispatch(setToastAlert('An invite email has been sent to the patient'));
            navigate(navigation.getPatientsUrl(props.organizationId), { state: {}, replace: true });
        }
    }, [location.state]);

    // make sure there is not a selected patient in redux state
    useEffect(() => {
        (async () => {
            if (selectedPatient?.data) {
                dispatch(clearSelectedPatient());
            }
        })();
    }, [selectedPatient?.data]);

    useEffect(() => {
        (async () => {
            if (selectedOrganization && currentUser.document && !Sort.isDefaultSort) {
                try {
                    //check to make sure selected sort is not default sort before firing query
                    const { queries, search } = parseSearchQuery(state.patientTableRows.search.searchString);
                    const query: PatientQueryRequest & CurrentUserPatientsData = {
                        orderBy: Sort.queryOrderBy,
                        ...Search.currentUserPatientsData,
                        isDefaultSort: false,
                    };
                    if (!!queries?.length) {
                        query.queries = queries;
                    }
                    if (!!search?.[0]) {
                        query.search = search;
                    }
                    if (selectedOrganization) {
                        const { patients, remaining } = await selectedOrganization.queryPatients(query);
                        updateState(draft => {
                            draft.patientTableRows.patients = deriveTableRowDataFromPatients(patients);
                            draft.patientTableRows.search.remainingPatientCount = remaining;
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
                updateState(draft => {
                    const activeSort = draft.patientTableRows.sort.find(
                        ({ active, loading }) => active && loading
                    );
                    if (activeSort) {
                        activeSort.loading = false;
                    }
                });
            }
        })();
    }, [state.patientTableRows.sort]);

    /*
     * END USE EFFECTS
     * */

    const deriveDashboardData = async (
        currentUserPatientsData: CurrentUserPatientsData = Search.currentUserPatientsData,
        selectedOrganization: OrganizationDocument
    ) => {
        return await Promise.all([
            selectedOrganization.queryPatients({
                orderBy: Sort.queryOrderBy,
                ...currentUserPatientsData,
            }),
            deriveAndFormatTierThreeAndInfoAlerts({
                selectedOrganization,
                ...currentUserPatientsData,
            }),
        ]);
    };

    const toggleToolTipVisible = useCallback(() => {
        updateState(draft => void (draft.toolTipVisible = !draft.toolTipVisible));
    }, []);

    const Search = {
        get searchString() {
            return state.patientTableRows.search.searchString;
        },
        get remainingPatientCount() {
            return state.patientTableRows.search.remainingPatientCount;
        },
        get currentUserPatientsData(): CurrentUserPatientsData {
            return {
                currentUserIdAndDelegatorsIds: [currentUser.document!.id, ...state.currentUserDelegatorIds],
                onlyForCurrentUserPatients: state.patientFilter === TargetPatients.CURRENT_USER_PATIENTS,
                isDefaultSort: Sort.isDefaultSort,
            };
        },
        async fireQuery(e: FormSubmission): Promise<void> {
            e.preventDefault();
            try {
                updateState(draft => void (draft.patientTableRows.search.fetching = true));
                const { queries, search } = parseSearchQuery(state.patientTableRows.search.searchString);
                const { patients, remaining } = await selectedOrganization!.queryPatients({
                    queries,
                    search,
                    orderBy: Sort.queryOrderBy,
                    ...Search.currentUserPatientsData,
                });
                updateState(draft => {
                    draft.patientTableRows.search.remainingPatientCount = remaining;
                    draft.patientTableRows.patients = deriveTableRowDataFromPatients(patients);
                });
            } catch (error) {
                console.log(error);
                updateState(draft => void (draft.patientTableRows.search.error = error));
            }
            updateState(draft => void (draft.patientTableRows.search.fetching = false));
        },
        async clear(e: ButtonClick): Promise<void> {
            e.preventDefault();
            if (selectedOrganization) {
                const { patients, remaining } = await selectedOrganization.queryPatients({
                    orderBy: Sort.queryOrderBy,
                    ...Search.currentUserPatientsData,
                });
                updateState(draft => {
                    draft.patientTableRows.search = { searchString: '', remainingPatientCount: remaining };
                    draft.patientTableRows.patients = deriveTableRowDataFromPatients(patients);
                });
            }
        },
        handleInput(e: InputChange) {
            e.persist();
            updateState(draft => {
                for (const key in SearchFilters) {
                    if (!!e.target.value && SearchFilters[key].startsWith(e.target.value)) {
                        draft.patientTableRows.search.suggestion = SearchFilters[key];
                    }
                }
                draft.patientTableRows.search.error = undefined;
                draft.patientTableRows.search.searchString = e.target.value;
            });
        },
        async filterPatients(e: InputChange): Promise<void> {
            e.persist();
            updateState(draft => void (draft.patientTableRows.filtering = true));
            const filter = Number(e.target.value) as TargetPatients;
            filter === TargetPatients.CURRENT_USER_PATIENTS
                ? providerSelectedTheirPatients.setInStorage()
                : providerSelectedTheirPatients.clearFromStorage();
            if (selectedOrganization) {
                const [patientPageInfo, { patientsWithAlerts, alertTableRows }] = await deriveDashboardData(
                    {
                        onlyForCurrentUserPatients: filter === TargetPatients.CURRENT_USER_PATIENTS,
                        currentUserIdAndDelegatorsIds:
                            Search.currentUserPatientsData.currentUserIdAndDelegatorsIds,
                        isDefaultSort: Sort.isDefaultSort,
                    },
                    selectedOrganization
                );
                updateState(draft => {
                    draft.patientFilter = filter;
                    draft.patientTableRows.patients = deriveTableRowDataFromPatients(
                        patientPageInfo.patients
                    );
                    draft.patientTableRows.search.remainingPatientCount = patientPageInfo.remaining;
                    draft.patientsWithAlerts = patientsWithAlerts;
                    draft.patientTableRows.alerts = alertTableRows;
                    draft.patientTableRows.filtering = false;
                });
            }
        },
    };

    const Sort = {
        get sort() {
            return state.patientTableRows.sort;
        },
        get isDefaultSort() {
            return (
                this.isFieldActive('lastBowelMovementDate') &&
                this.isFieldActive('lastAverageDayPainLevel') &&
                this.isFieldActive('shortActingOpioidUsage')
            );
        },
        get queryOrderBy(): PatientQueryRequest['orderBy'] {
            return state.patientTableRows.sort
                .filter(({ active }) => active)
                .map(({ field, descending }) => [field, descending ? 'descending' : 'ascending']);
        },
        async reset() {
            const { queries, search } = parseSearchQuery(state.patientTableRows.search.searchString);
            const query: PatientQueryRequest & CurrentUserPatientsData = {
                orderBy: Sort.queryOrderBy,
                ...Search.currentUserPatientsData,
                isDefaultSort: true,
            };
            if (!!queries?.length) {
                query.queries = queries;
            }
            if (!!search?.[0]) {
                query.search = search;
            }
            const { patients, remaining } = await selectedOrganization!.queryPatients(query);
            updateState(draft => {
                draft.patientTableRows.sort = initialSortState;
                draft.patientTableRows.search.remainingPatientCount = remaining;
                draft.patientTableRows.patients = deriveTableRowDataFromPatients(patients);
            });
        },
        orderForField(sortField: SortField): SortOrder {
            return this.sort.find(({ field }) => field === sortField)?.descending
                ? 'descending'
                : 'ascending';
        },
        isFieldActive(sortField: SortField): boolean {
            return this.sort.find(({ field }) => field === sortField)?.active ?? false;
        },
        isFieldLoading(sortField: SortField): boolean {
            return this.sort.find(({ field }) => field === sortField)?.loading ?? false;
        },
        priorityForField(sortField: SortField): number | undefined {
            if (this.isFieldActive(sortField)) {
                switch (sortField) {
                    case 'lastBowelMovementDate':
                        return this.isDefaultSort ? 1 : undefined;
                    case 'lastAverageDayPainLevel':
                        return this.isDefaultSort ? 2 : undefined;
                    case 'shortActingOpioidUsage':
                        return this.isDefaultSort ? 3 : undefined;
                }
            }
        },
        toggleOrder(sortField: SortField): void {
            updateState(draft => {
                draft.patientTableRows.sort.map(sort => {
                    if (sort.field === sortField) {
                        draft.patientTableRows.sort.find(({ field }) => field === sortField)!.loading = true;
                        sort.descending = !sort.active || Sort.isDefaultSort ? true : !sort.descending;
                        sort.active = true;
                    } else {
                        sort.active = false;
                        sort.descending = true;
                    }
                });
            });
        },
        getStatusForField(sortField: SortField): SortStatus {
            return {
                isActive: this.isFieldActive(sortField),
                order: this.orderForField(sortField),
                priority: this.priorityForField(sortField),
            };
        },
    };

    const loadMorePatients = async () => {
        updateState(draft => void (draft.patientTableRows.loadingMore = true));
        const lastPatientTableRow =
            state.patientTableRows.patients[state.patientTableRows.patients.length - 1];
        try {
            const { patients, remaining } = await selectedOrganization!.queryPatients({
                orderBy: Sort.queryOrderBy,
                startAfter: lastPatientTableRow.patientId,
                ...Search.currentUserPatientsData,
            });
            updateState(draft => {
                draft.patientTableRows.search.remainingPatientCount = remaining;
                draft.providerDashboardData.data.patients.push(...patients);
                draft.patientTableRows.patients.push(...deriveTableRowDataFromPatients(patients));
            });
        } catch (error) {
            console.log(error);
            dispatch(setToastError('An error occurred while trying to gather more patients'));
        }
        updateState(draft => void (draft.patientTableRows.loadingMore = false));
    };

    const dismissContactNumberEncouragement = (e: ButtonClick) => {
        e.preventDefault();
        updateState(draft => void (draft.encourageUserToSetContactNumber = undefined));
        encouragementToSetContactNumber.setInStorage();
    };

    const { remainingPatientCount } = state.patientTableRows.search;

    return (
        <>
            {state.providerDashboardData.fetching ||
            state.fetchingDueToOrgChange ||
            loadingSelectedOrganization ? (
                <LoadingSpinner type="page" />
            ) : (
                <AuthRedirection requiredRoles={[UserRoles.provider]} synchronized={synchronized}>
                    <InfiniteScroll
                        next={loadMorePatients}
                        hasMore={!!remainingPatientCount}
                        loader={<LoadingSpinner type="page" />}
                        dataLength={state.patientTableRows.patients.length}
                    >
                        {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
                        <div className="pt-6 w-11/12 mx-auto flex flex-row justify-end items-center">
                            {state.encourageUserToSetContactNumber && (
                                <div className="w-1/2 self-center mr-auto">
                                    <Encouragement
                                        dismiss={dismissContactNumberEncouragement}
                                        title="Set Contact Number"
                                        message="This will be the number your patients will be prompted to contact in the case of an emergency"
                                        link={{
                                            to: navigation.accountUrl,
                                            label: 'Edit Your Account',
                                        }}
                                    />
                                </div>
                            )}
                            <SegmentedControl
                                size="xl"
                                options={[
                                    {
                                        label: 'All Patients',
                                        value: TargetPatients.CURRENT_ORGANIZATION_PATIENTS,
                                        checked:
                                            state.patientFilter ===
                                            TargetPatients.CURRENT_ORGANIZATION_PATIENTS,
                                    },
                                    {
                                        label: 'My Patients',
                                        value: TargetPatients.CURRENT_USER_PATIENTS,
                                        checked: state.patientFilter === TargetPatients.CURRENT_USER_PATIENTS,
                                    },
                                ]}
                                handleInput={Search.filterPatients}
                            />
                        </div>
                        {state.patientTableRows.filtering ? (
                            <LoadingSpinner type="page" />
                        ) : (
                            <>
                                <div style={{ maxWidth: '68rem' }} className="w-11/12 mx-auto mb-16">
                                    {!!state.patientTableRows.alerts.length && (
                                        <AlertDashboard
                                            patientFilter={state.patientFilter}
                                            alertTableRows={state.patientTableRows.alerts}
                                            patientsWithAlerts={state.patientsWithAlerts}
                                        />
                                    )}
                                </div>
                                <div className="mx-auto mb-4" style={{ width: '95%', minHeight: '500px' }}>
                                    <div className="w-full mb-4 flex flex-row justify-between items-center">
                                        <div className="relative w-auto">
                                            <h2 className="block text-3xl text-gray-900 mr-2">
                                                Patient List
                                            </h2>
                                            {!Sort.isDefaultSort && (
                                                <button
                                                    onClick={Sort.reset}
                                                    style={{ right: '-60px' }}
                                                    className={`absolute top-0 mt-4 text-xs text-${Theme.lightBlue} hover:text-${Theme.lightBlueHover}`}
                                                >
                                                    Reset sort
                                                </button>
                                            )}
                                        </div>
                                        <form
                                            className="flex flex-row w-1/3 justify-center items-center self-center relative"
                                            onSubmit={Search.fireQuery}
                                        >
                                            <div className="relative w-2/3">
                                                <TextInput
                                                    value={Search.searchString}
                                                    onChange={Search.handleInput}
                                                    suggestion={state.patientTableRows.search.suggestion}
                                                    setSuggestionAsValue={() =>
                                                        updateState(draft => {
                                                            draft.patientTableRows.search.searchString = String(
                                                                draft.patientTableRows.search.suggestion
                                                            );
                                                        })
                                                    }
                                                    className={`py-1 ${
                                                        state.patientTableRows.search.error
                                                            ? 'border border-red-500'
                                                            : ''
                                                    }`}
                                                    clearable={true}
                                                    clear={Search.clear}
                                                />
                                                {state.patientTableRows.search.error && (
                                                    <div className="w-full absolute left-0 mt-2 text-center bg-white rounded-md shadow-md z-50 p-2 border border-dashed border-red-500">
                                                        <p className="text-xs text-red-500 block">
                                                            {
                                                                SearchErrorStrings[
                                                                    state.patientTableRows.search.error.type
                                                                ]
                                                            }
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <SubmitButton
                                                loading={state.patientTableRows.search.fetching}
                                                className="mx-2"
                                            >
                                                Search
                                            </SubmitButton>
                                            <span
                                                onMouseEnter={toggleToolTipVisible}
                                                onMouseLeave={toggleToolTipVisible}
                                                className="cursor-pointer p-1"
                                            >
                                                <IconManager
                                                    type={IconType.INFO_CIRCLE}
                                                    size={20}
                                                    stroke="#3B98CF"
                                                />
                                            </span>
                                            <TableComponents.PatientSearchToolTip
                                                visible={state.toolTipVisible}
                                                belowIcon={!state.patientTableRows.alerts.length}
                                            />
                                        </form>
                                        <ActionButton
                                            linkTo={
                                                currentUser.claims?.currentOrgId
                                                    ? navigation.getPatientsAddUrl(
                                                          currentUser.claims!.currentOrgId
                                                      )
                                                    : ''
                                            }
                                            className="block"
                                        >
                                            Add Patient
                                        </ActionButton>
                                    </div>
                                    <div className="w-full">
                                        <table className="table-auto w-full">
                                            <thead>
                                                <tr className="bg-gray-200">
                                                    <TableComponents.SortableColumn
                                                        loading={Sort.isFieldLoading('lastName')}
                                                        title="Name"
                                                        toggleSortOrder={() => Sort.toggleOrder('lastName')}
                                                        sortStatus={Sort.getStatusForField('lastName')}
                                                    />
                                                    <TableComponents.SortableColumn
                                                        loading={Sort.isFieldLoading(
                                                            'lastAverageDayPainLevel'
                                                        )}
                                                        title="Average Daily Pain"
                                                        toggleSortOrder={() =>
                                                            Sort.toggleOrder('lastAverageDayPainLevel')
                                                        }
                                                        sortStatus={Sort.getStatusForField(
                                                            'lastAverageDayPainLevel'
                                                        )}
                                                    />
                                                    <TableComponents.SortableColumn
                                                        title={`Avg Pain Sparkline (7 days)`}
                                                    />
                                                    <TableComponents.SortableColumn
                                                        loading={Sort.isFieldLoading('lastWorstDayPainLevel')}
                                                        title="Worst Daily Pain"
                                                        toggleSortOrder={() =>
                                                            Sort.toggleOrder('lastWorstDayPainLevel')
                                                        }
                                                        sortStatus={Sort.getStatusForField(
                                                            'lastWorstDayPainLevel'
                                                        )}
                                                    />
                                                    <TableComponents.SortableColumn
                                                        title={`Worst Pain Sparkline (7 days)`}
                                                    />
                                                    <TableComponents.SortableColumn
                                                        loading={Sort.isFieldLoading(
                                                            'shortActingOpioidUsage'
                                                        )}
                                                        title="Short Acting Medication Usage"
                                                        toggleSortOrder={() =>
                                                            Sort.toggleOrder('shortActingOpioidUsage')
                                                        }
                                                        sortStatus={Sort.getStatusForField(
                                                            'shortActingOpioidUsage'
                                                        )}
                                                    />
                                                    <TableComponents.SortableColumn
                                                        loading={Sort.isFieldLoading('lastBowelMovementDate')}
                                                        title="Last Bowel Movement (days ago)"
                                                        toggleSortOrder={() =>
                                                            Sort.toggleOrder('lastBowelMovementDate')
                                                        }
                                                        sortStatus={Sort.getStatusForField(
                                                            'lastBowelMovementDate'
                                                        )}
                                                    />
                                                    <TableComponents.SortableColumn
                                                        loading={Sort.isFieldLoading('lastEntryDate')}
                                                        title="Day of Last Entry"
                                                        toggleSortOrder={() =>
                                                            Sort.toggleOrder('lastEntryDate')
                                                        }
                                                        sortStatus={Sort.getStatusForField('lastEntryDate')}
                                                    />
                                                    <TableComponents.SortableColumn
                                                        loading={Sort.isFieldLoading('monitoringLevel')}
                                                        title="Monitoring Level"
                                                        toggleSortOrder={() =>
                                                            Sort.toggleOrder('monitoringLevel')
                                                        }
                                                        sortStatus={Sort.getStatusForField('monitoringLevel')}
                                                    />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!state.patientTableRows.patients.length ? (
                                                    <tr className="w-full text-gray-600 text-center">
                                                        <td
                                                            className="border w-full font-medium px-4 py-3"
                                                            colSpan={9}
                                                        >
                                                            {state.patientFilter ===
                                                            TargetPatients.CURRENT_USER_PATIENTS
                                                                ? 'You are not the primary provider for any patients'
                                                                : 'No patients found'}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    state.patientTableRows.patients.map(tableData => (
                                                        <tr
                                                            key={tableData.patientId}
                                                            className="cursor-pointer border-blue-600"
                                                            onClick={() =>
                                                                currentUser.claims?.currentOrgId
                                                                    ? navigate(
                                                                          navigation.getPatientDetailsUrl(
                                                                              currentUser.claims!
                                                                                  .currentOrgId,
                                                                              tableData.patientId
                                                                          )
                                                                      )
                                                                    : {}
                                                            }
                                                        >
                                                            <td className="border px-4 py-2">
                                                                {tableData.lastName}, {tableData.firstName}
                                                            </td>
                                                            <td className="border px-4 py-2">
                                                                <TableComponents.PainLevel
                                                                    type="average"
                                                                    lastAverageDayPainLevel={
                                                                        tableData.lastAverageDayPainLevel
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="border px-2 py-2">
                                                                <TableComponents.PainLevelSparkLine
                                                                    type="average"
                                                                    averageDayPainSparkline={
                                                                        tableData.averageDayPainSparkline
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="border px-4 py-2">
                                                                <TableComponents.PainLevel
                                                                    type="worst"
                                                                    lastWorstDayPainLevel={
                                                                        tableData.lastWorstDayPainLevel
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="border px-2 py-2">
                                                                <TableComponents.PainLevelSparkLine
                                                                    type="worst"
                                                                    worstDayPainSparkline={
                                                                        tableData.worstDayPainSparkline
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="border px-4 py-2">
                                                                <TableComponents.ShortActingUsage
                                                                    shortActingUsage={
                                                                        tableData.shortActingOpioidUsage
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="border px-4 py-2">
                                                                <TableComponents.ConstipationDays
                                                                    lastBowelMovementDate={
                                                                        tableData.lastBowelMovementDate
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="border px-4 py-2">
                                                                <TableComponents.DayOfLastEntry
                                                                    dayOfLastEntry={tableData.lastEntryDate}
                                                                    accountCreated={tableData.accountCreated}
                                                                />
                                                            </td>
                                                            <td className="border px-4 py-2">
                                                                {toPascalCase(
                                                                    MonitoringLevel[tableData.monitoringLevel]
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        )}
                    </InfiniteScroll>
                </AuthRedirection>
            )}
        </>
    );
}
