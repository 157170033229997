import { Firestore } from '../FirestoreTypes';
import { OrganizationRoles } from '../schemas/OrganizationRoles';
import { ProviderDocument } from '../documents/ProviderDocument';
import { UserModel } from './UserModel';
import { ProviderSchema } from '../schemas/Provider';

export class ProviderModel extends UserModel<ProviderSchema, ProviderDocument> {
    constructor(firestore: Firestore) {
        super(firestore, ProviderDocument, 'Provider');
    }

    getAllByOrganization(organizationId: string): Promise<ProviderDocument[]> {
        return this.query({
            queries: [
                [`organizations.${organizationId}.roles.${OrganizationRoles.provider}` as any, '==', true],
            ],
        });
    }
}
