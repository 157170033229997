import React, { useEffect, useRef, useState } from 'react';
import { AlertDocument } from '../database/documents/AlertDocument';
import { useDispatch } from 'react-redux';
import { toggleSelectedPatientAlertResolved } from '../redux/selectedPatient/selectedPatientActions';
import { AlertTier, AlertType } from '../database/schemas/Patient';
import { translateCause } from '../utils';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

interface Props {
    className?: string;
    alert: AlertDocument;
}

export default function Alert(props: Props) {
    const { color, title } = (() => {
        const { tier } = props.alert.data;
        if (tier === AlertTier.tierTwo) {
            return { color: 'yellow', title: 'Moderate Alert' };
        } else if (tier === AlertTier.tierThree) {
            return { color: 'red', title: 'Severe Alert' };
        } else {
            return { color: 'blue', title: 'Informational Alert' };
        }
    })();
    const alertRef = useRef<HTMLDivElement>(null);
    const baseClassNames = `mr-3 font-semibold rounded-md border border-${color}-700 px-2 py-1`;
    const unresolvedClassName = `${baseClassNames} hover:text-white text-${color}-700 bg-${alert}-400 hover:bg-${color}-500`;
    const resolvedClassNames = `${baseClassNames} text-white bg-${color}-600 hover:bg-${color}-700`;
    const dispatch = useDispatch();
    const [cause, setCause] = useState<string>('');

    // Get the cause
    useEffect(() => {
        (async () => {
            const causeString =
                props.alert.data.type === AlertType.medication
                    ? await translateCause(props.alert.data.cause, {
                          medicationId: props.alert.data.details.medicationId,
                          userId: props.alert.ref.parent.parent?.id ?? '',
                      })
                    : await translateCause(props.alert.data.cause);
            setCause(causeString);
        })();
    }, []);

    const toggleResolved = () => {
        dispatch(toggleSelectedPatientAlertResolved(props.alert.id));
    };

    return (
        <div
            className={`bg-${color}-100
             border border-${color}-400 mb-2 text-${color}-900 px-4 py-3 rounded relative flex flex-row ${props.className}`}
            role="alert"
            ref={alertRef}
            style={{ height: '64px' }}
        >
            <div className={`flex flex-col`}>
                <b className={`font-bold mr-3 text-${color}-800 w-32`}>{title}</b>
                <span className={`text-xs text-${color}-800 ml-4`}>
                    {formatDistanceToNow(props.alert.data.createdAt.toDate())} ago
                </span>
            </div>
            <span className="block sm:inline flex-wrap mr-40">{cause}</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3 flex flex-row items-center">
                <button
                    className={props.alert.data.acknowledgedAt ? resolvedClassNames : unresolvedClassName}
                    onClick={toggleResolved}
                >
                    {props.alert.data.acknowledgedAt ? 'Mark unresolved' : 'Mark resolved'}
                </button>
            </span>
        </div>
    );
}
