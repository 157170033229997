import FirebaseApp from '../../firestore';
import { UserDocument } from './documents/UserDocument';
import { Firestore } from './FirestoreTypes';
import { OrganizationModel } from './models/OrganizationModel';
import { PatientModel } from './models/PatientModel';
import { ProviderModel } from './models/ProviderModel';
import { ResourceModel } from './models/ResourceModel';
import { UserModel } from './models/UserModel';
import { MedicationModel } from './models/MedicationModel';

export class DatabaseManager {
    private constructor(private firestore: Firestore) {}

    //-- ROOT COLLECTIONS --//
    public OrganizationModel = new OrganizationModel(this.firestore);
    public UserModel = new UserModel(this.firestore, UserDocument);
    public PatientModel = new PatientModel(this.firestore);
    public ProviderModel = new ProviderModel(this.firestore);
    public ResourceModel = new ResourceModel(this.firestore);
    public MedicationModel = new MedicationModel(this.firestore);

    private static instance: DatabaseManager;

    static getInstance(firestore: Firestore): DatabaseManager {
        if (!this.instance) {
            this.instance = new DatabaseManager(firestore);
        }
        return this.instance;
    }
}

export default DatabaseManager.getInstance(FirebaseApp.firestore());
