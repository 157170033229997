import firebase from 'firebase';
import { BaseDocument } from './database/documents/BaseDocument';
import { Timestamp } from './database/FirestoreTypes';
import { Timestamps } from './database/schemas/Timestamps';
import isEqual from 'lodash/isequal';
import * as dateFNS from 'date-fns';
import { AlertCause, BasePrescription, OpioidPrescription } from './database/schemas/Patient';
import { OrganizationsAndRoles, UserOrganizations } from './database/schemas/User';
import { CustomError, CustomErrorCodes } from './types';
import DatabaseManager from './database/DatabaseManager';
import { UserMedicationDocument } from './database/documents/UserMedicationDocument';

export function isValidEmail(email: string): boolean {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLocaleLowerCase());
}

export function truncate(str: string, maxChar: number): string {
    return str.length > maxChar ? `${str.substr(0, maxChar)}...` : str;
}

export function isFirestoreDocument<T>(object: any): object is T {
    return object instanceof BaseDocument;
}

export function convertDocToSchema<T extends BaseDocument<S>, S extends Timestamps>(doc: any): S {
    return isFirestoreDocument<T>(doc) ? doc.data : doc;
}

export function scrollPageTo(position: 'top' | 'bottom'): void {
    window.scrollTo({
        top: position === 'bottom' ? document.body.scrollHeight : 0,
        left: 0,
        behavior: 'smooth',
    });
}

export async function downloadFile(url: string, name: string): Promise<void> {
    const res = await fetch(url);
    const blob = await res.blob();
    const link = document.createElement('a');
    const blobUrl = URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = name;
    link.click();
    URL.revokeObjectURL(blobUrl);
}

export function getTimestamp(date: Date = new Date()): Timestamp {
    return firebase.firestore.Timestamp.fromDate(date);
}

export function diffObjects<T, R>(object1: T, object2: T): R {
    return (Object.keys(object1).reduce((result, key) => {
        if (!(object2 as Object).hasOwnProperty(key)) {
            result.push(key);
        } else if (isEqual(object1[key], object2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(object2)) as any) as R;
}

export function renderRelativeDateFromTimeStamp(date: Date): string {
    if (dateFNS.isToday(date)) {
        return 'Today';
    } else if (dateFNS.isYesterday(date)) {
        return 'Yesterday';
    } else {
        const differenceInCalendarDays = dateFNS.differenceInCalendarDays(new Date(), date);
        return `${differenceInCalendarDays} days ago`;
    }
}

export function toPascalCase(str?: string): string | undefined {
    return str
        ?.toLocaleLowerCase()
        .replace(
            /\w\S*/g,
            string => string.charAt(0).toLocaleUpperCase() + string.substr(1).toLocaleLowerCase()
        );
}

export function formatPhoneNumber(phoneNumber: string | number): string | undefined {
    const cleaned = ('' + String(phoneNumber)).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
}

export function unformatPhoneNumber(phoneNumber: string): string {
    return phoneNumber
        .split('')
        .filter(char => /\d/g.test(char))
        .join('');
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
    return /^1?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phoneNumber);
}

export async function translateCause(
    cause: AlertCause,
    medicationInfo?: { medicationId: string; userId: string }
) {
    let medicationDocument: UserMedicationDocument | null = null;

    if (medicationInfo) {
        const patientDocument = await DatabaseManager.PatientModel.get(medicationInfo.userId);
        medicationDocument = await patientDocument.getMedicationById(medicationInfo.medicationId);
    }

    switch (cause) {
        case AlertCause.scoredHighOnOpioidRiskTool:
            return 'Patient scored high on opioid risk tool';
        case AlertCause.didNotRegisterAfterThreeNotifications:
            return 'Patient did not register after 3 notifications';
        case AlertCause.didNotCompleteOpioidAgreementAfterThreeDays:
            return 'Patient did not complete opioid agreement after 3 days';
        case AlertCause.didNotReportMedsTakenForFiveDays:
            return 'Patient did not report medications taken for 5 days';
        case AlertCause.tookMoreThanTwelveShortActingOpioidsInOneDay:
            return 'Patient took more than 12 short acting opioids in 1 day';
        case AlertCause.painScoreGteSevenforThreeDaysWithNoPainMeds:
            return 'Patient pain score was more than 7 for 3 days with pain medications';
        case AlertCause.painScoreOfTenForThreeDays:
            return 'Patient had pain score of 10 for 3 consecutive days';
        case AlertCause.noBowelMovementForFiveDays:
            return 'Patient has not had a bowel movement in 5 days';
        case AlertCause.tookTooManyPillsSingleEntry:
            return medicationDocument
                ? `Patient has taken more than the maximum prescribed amount of ${medicationDocument.data.name}.`
                : 'Patient has taken too many pills';
        case AlertCause.transitionedFromHourlyToDailyMonitoring:
            return 'Patient transitioned from hourly to daily monitoring';
        case AlertCause.exceededNonPrnMedPrescribedMaxDose:
            return medicationDocument
                ? `Patient exceeded the max dose of a non-PRN medication: ${medicationDocument.data.name}`
                : 'Patient exceeded the max dose of a non-PRN medication';
        case AlertCause.matchedOrExceededPrnMedPrescribedMaxDose:
            return medicationDocument
                ? `Patient matched or exceeded the max dose of a PRN medication: ${medicationDocument.data.name}`
                : 'Patient matched or exceeded the max dose of a PRN medication';
        case AlertCause.exceededNonPrnMedPrescribedMaxEntries:
            return medicationDocument
                ? `Patient exceeded the max daily entries of a non-PRN medication: ${medicationDocument.data.name}`
                : 'Patient exceeded the max daily entries of a non-PRN medication';
        case AlertCause.exceededPrnMedPrescribedMaxEntries:
            return medicationDocument
                ? `Patient exceeded the max daily entries of a PRN medication: ${medicationDocument.data.name}`
                : 'Patient exceeded the max daily entries of a PRN medication';
        case AlertCause.notTakenLongActingOpioidInOver24Hours:
            return 'Patient is actively making entries, but has not made an entry with their prescribed long-acting opioid in over 24 hours.';
        case AlertCause.notTakenLongActingOpioidInOver72Hours:
            return 'Patient is actively making entries, but has not made an entry with their prescribed long-acting opioid in over 72 hours.';
    }
}

function isCustomError(error: any): error is CustomError {
    return 'error' in error;
}

export function translateCustomError(error: unknown): string {
    if (isCustomError(error)) {
        const { type } = error.error.details;
        switch (type) {
            case CustomErrorCodes.DATA_VALIDATION_ERROR:
                return 'Something about your request is invalid. Please ensure all required fields are filled out.';
            case CustomErrorCodes.INTERNAL_ERROR:
                return 'Oops, an internal server error has occurred. Try refreshing your browser. If the issue persists, contact an application admin.';
            case CustomErrorCodes.INVITE_ERROR:
                return 'An error occurred while trying to process your invite request.';
            case CustomErrorCodes.INVITE_EXPIRED:
                return 'It looks like your invite has expired. Please ask the inviter to send you another and accept it within 14 days.';
            case CustomErrorCodes.MAX_ORGANIZATIONS:
                return 'This user has reached their limit for the number of organizations they can be a part of.';
            case CustomErrorCodes.NOT_FOUND:
                return "We weren't able to find any results matching your request.";
            case CustomErrorCodes.UNAUTHENTICATED:
                return 'Your authentication session has expired. Please log back in to continue.';
            case CustomErrorCodes.UNAUTHORIZED:
                return 'You are not authorized to perform this action.';
        }
    }
    return 'An unknown error has occurred. Please check your internet connection & try again.';
}

export function getOrganizationsAndRoles(organizations: UserOrganizations): OrganizationsAndRoles {
    const organizationsAndRoles = {};

    Object.keys(organizations).forEach(organizationId => {
        organizationsAndRoles[organizationId] = organizations[organizationId].roles;
    });

    return organizationsAndRoles;
}

export function getDateFromDateString(date: string) {
    return dateFNS.parse(date, 'yyyy-MM-dd', new Date());
}

//return a formatted date string "YYYY-MM-DD"
export function getDateString(date: Date | Timestamp = new Date()): string {
    if (date instanceof firebase.firestore.Timestamp) {
        date = date.toDate();
    }
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${date.getFullYear()}-${month}-${day}`;
}

export function generateId(length: number = 8): string {
    const options = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let random = () => Math.floor(Math.random() * options.length);
    let id: string = '';
    for (let i = 0; i < length; i++) {
        id += options[random()];
    }
    return id;
}

export function logDistinctiveMessage(message: string, color: string = 'hsl(0, 85%, 55%)') {
    const style = `
  color: ${color};
  font-size: 14px;
  font-weight: bold;
  `;
    console.log(`%c${message}`, style);
}

export function isOpioidPrescription(
    prescription: BasePrescription | OpioidPrescription
): prescription is OpioidPrescription {
    return (prescription as OpioidPrescription).opioid !== undefined;
}
