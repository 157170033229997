import React from 'react';
import TextInput from './TextInput';
import { UserFormKeys } from '../database/schemas/User';
import SubmitButton from './SubmitButton';
import { MultiFactorAuthState } from '../context/CurrentUserContextProvider';
import { FormSubmission, InputChange } from '../types';
import ErrorMessage from './ErrorMessage';
import AuthManager, { AuthErrorCodes } from '../AuthManager';

interface Props {
    multiFactorAuth: MultiFactorAuthState;
    sendVerificationCodeToUserDevice: (e: FormSubmission) => Promise<void>;
    submitVerificationCodeToToggleMFA: (e: FormSubmission) => Promise<void>;
    handlePersonalPhoneNumberInput: (e: InputChange) => void;
    handleVerificationCodeInput: (e: InputChange) => void;
    handlePersonalPhoneNumberBlur: () => void;
}

export default function MFAVerificationForm(props: Props) {
    return (
        <div className="w-full my-2">
            {!props.multiFactorAuth.awaitingCodeConfirmation ? (
                <form onSubmit={props.sendVerificationCodeToUserDevice}>
                    <label className="block font-semibold mb-6 relative">
                        <span className="text-gray-700">Personal Phone Number</span>
                        <TextInput
                            name={UserFormKeys.personalPhoneNumber}
                            value={props.multiFactorAuth.personalPhoneNumber.value}
                            onChange={props.handlePersonalPhoneNumberInput}
                            onBlur={props.handlePersonalPhoneNumberBlur}
                            className={`py-1 ${
                                props.multiFactorAuth.personalPhoneNumber.showError
                                    ? 'border border-red-500'
                                    : ''
                            }`}
                        />
                        <p
                            className={`text-${
                                props.multiFactorAuth.personalPhoneNumber.showError ? 'red-500' : 'gray-600'
                            } mt-2 text-xs text-center font-normal block w-full absolute`}
                        >
                            {props.multiFactorAuth.personalPhoneNumber.showError
                                ? 'Please enter a valid phone number.'
                                : 'This will be the number verification codes will be sent to when you login.'}
                        </p>
                    </label>
                    <div className="w-full pt-10">
                        {!!props.multiFactorAuth.submissionError && (
                            <ErrorMessage className="font-normal">
                                {AuthManager.translateError(props.multiFactorAuth.submissionError)}
                            </ErrorMessage>
                        )}
                        <SubmitButton
                            className="w-full"
                            loading={props.multiFactorAuth.submittingVerificationCode}
                            disabled={
                                !props.multiFactorAuth.personalPhoneNumber.value ||
                                props.multiFactorAuth.personalPhoneNumber.isValid === false
                            }
                        >
                            Send SMS Verification Code
                        </SubmitButton>
                    </div>
                </form>
            ) : (
                <form onSubmit={props.submitVerificationCodeToToggleMFA}>
                    <div className="block font-semibold">
                        <span className="text-gray-700 mb-3 text-center block">
                            Please enter the verification code sent via SMS to your provided phone number.
                        </span>
                        <TextInput
                            value={props.multiFactorAuth.verificationCode}
                            onChange={props.handleVerificationCodeInput}
                            className={`py-1 ${
                                props.multiFactorAuth.submissionError &&
                                (props.multiFactorAuth.submissionError as { code: AuthErrorCodes }).code ===
                                    AuthErrorCodes.invalidVerificationCode
                                    ? 'border border-red-500'
                                    : ''
                            }`}
                        />
                        {!!props.multiFactorAuth.submissionError && (
                            <ErrorMessage className="font-normal">
                                {AuthManager.translateError(props.multiFactorAuth.submissionError)}
                            </ErrorMessage>
                        )}
                        <SubmitButton
                            loading={props.multiFactorAuth.enableMFARequest.fetching}
                            disabled={
                                !props.multiFactorAuth.verificationCode ||
                                props.multiFactorAuth.verificationCode.length !== 6
                            }
                            className={`w-full ${props.multiFactorAuth.submissionError ? 'mt-4' : 'mt-8'}`}
                        >
                            Submit Code
                        </SubmitButton>
                    </div>
                </form>
            )}
        </div>
    );
}
