import { MedicationDocument } from '../documents/MedicationDocument';
import { Firestore } from '../FirestoreTypes';
import { BaseModel } from './BaseModel';
import { MedicationSchema } from '../schemas/Medication';

export class MedicationModel extends BaseModel<MedicationSchema, MedicationDocument> {
    constructor(firestore: Firestore) {
        super(firestore, 'Medication', 'medications', MedicationDocument);
    }
}
