import React, { useContext } from 'react';
import { FormSubmission, RouteProps } from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import { selectToastAlert } from '../../../../redux/currentSession/currentSessionSelectors';
import ToastAlert from '../../../../components/ToastAlert';
import Asterisk from '../../../../components/Asterisk';
import { selectSelectedOrganization } from '../../../../redux/selectedOrganization/selectedOrganizationSelectors';
import OpioidAgreementForm from './OpioidAgreementForm';
import { useImmer } from 'use-immer';
import { setToastAlert, setToastError } from '../../../../redux/currentSession/currentSessionActions';
import { navigate } from '@reach/router';
import useNavigation from '../../../../hooks/useNavigation';
import { CurrentUserContext } from '../../../../context/CurrentUserContextProvider';
import { FormState, OpioidAgreementInputChange } from './opioidAgreementTypes';
import { addOpioidAgreement } from '../../../../redux/selectedOrganization/selectedOrganizationActions';
import isAuthed from '../../../../hooks/isAuthed';
import { UserRoles } from '../../../../database/schemas/User';
import { useSynchronizeOrgId } from '../../../../hooks/useSynchronizeOrgId';
import { AuthRedirection } from '../../../../components/AuthRedirection';

interface State {
    form: FormState;
}

export enum FormKeys {
    title = 'title',
    text = 'text',
}

const initialFormState: State['form'] = {
    title: '',
    text: '',
};

export default function AddOpioidAgreement(_: RouteProps) {
    const navigation = useNavigation();
    const { synchronized } = useSynchronizeOrgId(navigation.getOrganizationOpioidAgreementAddUrl);
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const dispatch = useDispatch();
    const currentUser = useContext(CurrentUserContext);
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const [state, updateState] = useImmer<State>({
        form: initialFormState,
    });

    const handleInput = (e: OpioidAgreementInputChange) => {
        e.persist();
        updateState(draft => void (draft.form[e.target.name] = e.target.value));
    };
    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        const { title, text } = state.form;
        if (!!title && !!text) {
            try {
                updateState(draft => void (draft.form.submitting = true));
                const addedOpioidAgreement = await selectedOrganization!.createOpioidAgreement({
                    title,
                    text,
                });
                dispatch(addOpioidAgreement(addedOpioidAgreement));
                await navigate(
                    navigation.getOpioidAgreementDetailsUrl(
                        currentUser.claims?.currentOrgId!,
                        addedOpioidAgreement.id
                    )
                );
            } catch (error) {
                console.log(error);
                dispatch(setToastError('An error occurred while adding your Opioid Agreement'));
            }
            dispatch(
                setToastAlert(
                    `You've successfully add a new Opioid Agreement to ${selectedOrganization?.data.name}`
                )
            );
            updateState(draft => void (draft.form.submitting = false));
        } else {
            updateState(draft => void (draft.form.isValid = false));
        }
    };

    return (
        <AuthRedirection requiredRoles={[UserRoles.orgAdmin]} synchronized={synchronized}>
            <div className="w-full flex flex-row bg-gray-100 p-2">
                {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
                <div className="mx-4 mt-2 p-4 flex flex-col w-full">
                    <div className="flex flex-row justify-between items-center w-full mb-2 md:w-2/3 pr-12">
                        <div>
                            <h2 className="text-blue-900 text-xl font-semibold">Add Opioid Agreement</h2>
                            <p className="text-sm text-gray-600">
                                This agreement will be added to the{' '}
                                <span className="font-semibold">{selectedOrganization?.data.name}</span>{' '}
                                organization
                            </p>
                        </div>

                        <span className="block text-xs text-gray-600 mr-5">
                            <Asterisk /> indicates required fields
                        </span>
                    </div>
                    <OpioidAgreementForm
                        scenario="adding"
                        form={state.form}
                        handleSubmit={handleSubmit}
                        handleInput={handleInput}
                    />
                </div>
            </div>
        </AuthRedirection>
    );
}
