import { DeliveryType } from '../../../../database/schemas/Medication';
import { BasePrescription } from '../../../../database/schemas/Patient';

class PillsPerDose {
    decimalRegex = /^\d*(\.([0|5]))?$/;
    wholeNumberRegex = /^\d+$/;

    isValid({ value, deliveryType }: { value: string; deliveryType: DeliveryType }): boolean {
        const [min, max] = value.split('-');

        const regexToTest = deliveryType === DeliveryType.patch ? this.wholeNumberRegex : this.decimalRegex;
        if (!max) {
            return regexToTest.test(value);
        } else {
            return regexToTest.test(min) && regexToTest.test(max) && Number(min) < Number(max);
        }
    }

    format(dose: BasePrescription['dose']): string {
        if (!dose || (dose && isNaN(dose.min)) || isNaN(dose.max)) {
            return '';
        }
        return dose.min === dose.max ? String(dose.min) : `${dose.min}-${dose.max}`;
    }

    convertToMinMax(dose: string): BasePrescription['dose'] {
        let doseMin: number, doseMax: number;
        if (dose.includes('-')) {
            const [min, max] = dose.split('-');
            doseMin = parseFloat(min);
            doseMax = parseFloat(max);
        } else {
            doseMin = parseFloat(dose);
            doseMax = parseFloat(dose);
        }
        return { min: doseMin, max: doseMax };
    }
}

export const pillsPerDoseValidator = new PillsPerDose();
