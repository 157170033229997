import { ReduxState } from '../store';
import { SelectedOrganizationReduxState } from './selectedOrganizationReducer';

export function selectSelectedOrganization(state: ReduxState): SelectedOrganizationReduxState['document'] {
    return state.selectedOrganization.document;
}

export function selectSelectedOrganizationProviders(
    state: ReduxState
): SelectedOrganizationReduxState['providers'] {
    return state.selectedOrganization.providers.filter(({ data }) => !!data.authId);
}

export function selectSelectedOrganizationOrgAdmins(
    state: ReduxState
): SelectedOrganizationReduxState['orgAdmins'] {
    return state.selectedOrganization.orgAdmins;
}

export function selectLoadingSelectedOrganization(
    state: ReduxState
): SelectedOrganizationReduxState['loading'] {
    return state.selectedOrganization.loading;
}

export function selectSelectedOrganizationOpioidAgreements(
    state: ReduxState
): SelectedOrganizationReduxState['opioidAgreements'] {
    return state.selectedOrganization.opioidAgreements;
}

export function selectSelectedOrganizationActiveOpioidAgreements(
    state: ReduxState
): SelectedOrganizationReduxState['opioidAgreements']['active'] {
    return state.selectedOrganization.opioidAgreements.active;
}

export function selectSelectedOrganizationArchivedOpioidAgreements(
    state: ReduxState
): SelectedOrganizationReduxState['opioidAgreements']['archived'] {
    return state.selectedOrganization.opioidAgreements.archived;
}

export function selectActiveTab(state: ReduxState): SelectedOrganizationReduxState['activeTab'] {
    return state.selectedOrganization.activeTab;
}
