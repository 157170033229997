import React from 'react';
import { FormSubmission, InputChange, RouteProps, ValidatableString } from '../types';
import SplashPageContainer from '../components/SplashPageContainer';
import SplashPageForm from '../components/SplashPageForm';
import { Theme } from '../theme';
import { isValidEmail } from '../utils';
import { useImmer } from 'use-immer';
import SubmitButton from '../components/SubmitButton';
import IconManager, { IconType } from '../components/IconManager';
import { Link } from '@reach/router';
import { NonAuthRoutes } from '../routes';
import FunctionsManager from '../functions/FunctionsManager';

enum StateKeys {
    email = 'email',
}

interface State {
    email: ValidatableString;
    submitting: boolean;
    submissionError: unknown;
    formSubmitted: boolean;
}

const initialResetPasswordState: State = {
    email: { value: '' },
    submitting: false,
    submissionError: null,
    formSubmitted: false,
};

export default function ResetPassword(_: RouteProps) {
    const [state, updateState] = useImmer(initialResetPasswordState);

    const handleInput = (e: InputChange) => {
        e.persist();
        updateState(draft => {
            draft.email.showError = false;
            draft.email.value = e.target.value;
            draft.email.isValid = isValidEmail(e.target.value);
        });
    };

    const handleBlur = () => {
        updateState(draft => void (draft.email.showError = draft.email.isValid === false));
    };

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => void (draft.submitting = true));
        try {
            await FunctionsManager.user.sendResetPasswordEmail({ email: state.email.value.trim() });
        } catch (error) {
            console.log(error);
            updateState(draft => void (draft.submissionError = error));
        }
        updateState(draft => {
            draft.submitting = false;
            draft.formSubmitted = true;
        });
    };
    return (
        <SplashPageContainer>
            <SplashPageForm onSubmit={handleSubmit} className="mt-32">
                {state.formSubmitted ? (
                    <div className="w-full px-4 py-2 flex flex-col mx-auto justify-center">
                        <h3 className="text-lg text-center block mb-3">
                            Thank you, your request has been submitted. If the provided email is associated to
                            an account an email will be sent to the provided address with a link to reset your
                            password
                        </h3>
                        <IconManager
                            type={IconType.CHECK_CIRCLE}
                            size={125}
                            stroke="#34AC6F"
                            className="mx-auto"
                        />
                        <Link
                            to={NonAuthRoutes.LOGIN}
                            className="text-blue-600 hover:text-blue-700 mx-auto mt-2"
                        >
                            Return to login
                        </Link>
                    </div>
                ) : (
                    <>
                        <h3 className={`text-${Theme.darkBlue} text-xl mb-4 text-center font-semibold`}>
                            Reset your password
                        </h3>
                        <div className="mb-1 text-center">
                            <label
                                htmlFor={StateKeys.email}
                                className="block text-gray-700 text-sm font-bold mb-2"
                            >
                                Please enter the email associated with your account
                            </label>
                            <input
                                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline ${
                                    state.email.showError ? 'border-red-500' : 'input-border'
                                }`}
                                onBlur={handleBlur}
                                type="email"
                                name={StateKeys.email}
                                value={state.email.value}
                                onChange={handleInput}
                            />
                        </div>
                        <span
                            className={`text-xs text-center block text-${
                                state.email.showError ? 'red' : 'gray'
                            }-600 mb-2`}
                        >
                            {state.email.showError
                                ? 'Please enter a valid email address'
                                : "We'll send you an email with instructions for setting a new password"}
                        </span>
                        <SubmitButton
                            loading={state.submitting}
                            disabled={!state.email.isValid}
                            className="w-full mt-3"
                        >
                            Request Password Reset
                        </SubmitButton>
                        <div className="flex flex-row justify-center items-center mt-2">
                            <Link
                                to={NonAuthRoutes.LOGIN}
                                className={`text-${Theme.darkBlue} hover-${Theme.darkBlueHover}`}
                            >
                                Return to login
                            </Link>
                        </div>
                    </>
                )}
            </SplashPageForm>
        </SplashPageContainer>
    );
}
