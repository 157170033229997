import produce from 'immer';
import { UserSchema } from '../../database/schemas/User';

export enum CurrentSessionActions {
    SET_LOADING_CURRENT_USER = 'SET_LOADING_CURRENT_USER',
    SET_REQUESTED_PATHNAME = 'SET_REQUESTED_PATHNAME',
    RESET_REQUESTED_PATHNAME = 'RESET_REQUESTED_PATHNAME',
    SET_TOAST_ALERT = 'SET_TOAST_ALERT',
    DISMISS_TOAST_ALERT = 'DISMISS_TOAST_ALERT',
    CLEAR_TOAST_ALERT = 'CLEAR_TOAST_ALERT',
    SET_PENDING_ACCOUNT_CREATION_INFO = 'SET_PENDING_ACCOUNT_CREATION_INFO',
    CLEAR_PENDING_ACCOUNT_CREATION_INFO = 'CLEAR_PENDING_ACCOUNT_CREATION_INFO',
}

export type ToastAlert = { visible: boolean; message: string; isError: boolean };

export type AccountCreationInfo = Pick<UserSchema, 'firstName' | 'lastName'> & { phoneNumber?: string };

export interface CurrentSessionReduxState {
    requestedPathname: string;
    toastAlert: ToastAlert;
    pendingAccountCreationInfo?: AccountCreationInfo;
}

const initialToastAlertState: ToastAlert = {
    visible: false,
    message: '',
    isError: false,
};

export const initialCurrentSessionState: CurrentSessionReduxState = {
    requestedPathname: window.location.pathname.concat(window.location.search),
    toastAlert: initialToastAlertState,
};

export type CurrentSessionActionTypes =
    | { type: CurrentSessionActions.RESET_REQUESTED_PATHNAME }
    | { type: CurrentSessionActions.SET_REQUESTED_PATHNAME; payload: string }
    | { type: CurrentSessionActions.SET_TOAST_ALERT; payload: { message: string; isError?: boolean } }
    | { type: CurrentSessionActions.DISMISS_TOAST_ALERT }
    | { type: CurrentSessionActions.CLEAR_TOAST_ALERT }
    | { type: CurrentSessionActions.SET_PENDING_ACCOUNT_CREATION_INFO; payload: AccountCreationInfo }
    | { type: CurrentSessionActions.CLEAR_PENDING_ACCOUNT_CREATION_INFO };

export default function currentSessionReducer(
    state: CurrentSessionReduxState = initialCurrentSessionState,
    action: CurrentSessionActionTypes
) {
    switch (action.type) {
        case CurrentSessionActions.RESET_REQUESTED_PATHNAME:
            return produce(state, draft => {
                draft.requestedPathname = '/';
            });
        case CurrentSessionActions.SET_REQUESTED_PATHNAME:
            return produce(state, draft => {
                draft.requestedPathname = action.payload;
            });
        case CurrentSessionActions.SET_TOAST_ALERT:
            return produce(state, draft => {
                draft.toastAlert.message = action.payload.message;
                draft.toastAlert.isError = !!action.payload.isError;
                draft.toastAlert.visible = true;
            });
        case CurrentSessionActions.CLEAR_TOAST_ALERT:
            return produce(state, draft => {
                draft.toastAlert = initialToastAlertState;
            });
        case CurrentSessionActions.DISMISS_TOAST_ALERT:
            return produce(state, draft => void (draft.toastAlert = initialToastAlertState));
        case CurrentSessionActions.SET_PENDING_ACCOUNT_CREATION_INFO:
            return produce(state, draft => void (draft.pendingAccountCreationInfo = action.payload));
        case CurrentSessionActions.CLEAR_PENDING_ACCOUNT_CREATION_INFO:
            return produce(state, draft => void (draft.pendingAccountCreationInfo = undefined));
        default:
            return state;
    }
}
