import FunctionsManager from '../../functions/FunctionsManager';
import { DocumentSnapshot } from '../FirestoreTypes';
import { UserDocument } from './UserDocument';
import { ProviderSchema } from '../schemas/Provider';
import { OrganizationRoles } from '../schemas/OrganizationRoles';

export class ProviderDocument extends UserDocument<ProviderSchema> {
    constructor(snapshot: DocumentSnapshot<ProviderSchema>) {
        super(snapshot, 'Provider');
    }

    async addDelegate(organizationId: string, providerId: string): Promise<ProviderDocument> {
        try {
            if (!this.data.organizations[organizationId].delegates) {
                this.data.organizations[organizationId].delegates = [];
            }

            if (!this.data.organizations[organizationId].delegates.includes(providerId)) {
                this.data.organizations[organizationId].delegates.push(providerId);

                await FunctionsManager.user.updateDelegates({
                    providerId: this.id,
                    delegates: this.data.organizations[organizationId].delegates,
                });
            }

            return this.db.ProviderModel.get(providerId);
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'ProviderDocument.addDelegate()',
                error,
                path: this.path,
                organizationId,
                providerId,
            });
            throw error;
        }
    }

    async removeDelegate(organizationId: string, providerId: string): Promise<void> {
        try {
            const index = this.data.organizations[organizationId].delegates?.indexOf(providerId);

            if (index >= 0) {
                this.data.organizations[organizationId].delegates.splice(index, 1);
            }

            await FunctionsManager.user.updateDelegates({
                providerId: this.id,
                delegates: this.data.organizations[organizationId].delegates,
            });
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'ProviderDocument.removeDelegate()',
                error,
                path: this.path,
                organizationId,
                providerId,
            });
            throw error;
        }
    }

    getDelegates(organizationId: string): Promise<ProviderDocument[]> {
        //error will be caught in getDelegationData
        return this.db.ProviderModel.getByIds(this.data.organizations[organizationId].delegates ?? []);
    }

    getDelegators(organizationId: string): Promise<ProviderDocument[]> {
        //error will be caught in getDelegationData
        return this.db.ProviderModel.query({
            queries: [
                [`organizations.${organizationId}.roles.${OrganizationRoles.provider}` as any, '==', true],
                [`organizations.${organizationId}.delegates`, 'array-contains', this.id],
            ],
        });
    }

    async getDelegatorsIds(organizationId: string): Promise<string[]> {
        return (await this.getDelegators(organizationId)).map(({ id }) => id);
    }

    async getDelegationData(
        organizationId: string
    ): Promise<{ delegates: ProviderDocument[]; delegators: ProviderDocument[] }> {
        try {
            const [delegates, delegators] = await Promise.all([
                this.getDelegates(organizationId),
                this.getDelegators(organizationId),
            ]);
            return { delegates, delegators };
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'ProviderDocument.getDelegationData()',
                error,
                path: this.path,
                organizationId,
            });
            throw error;
        }
    }
}
