import { useContext, useEffect, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { CurrentUserContext } from '../context/CurrentUserContextProvider';
import useNavigation from './useNavigation';
import { ReduxState } from '../redux/store';
import { selectSelectedOrganization } from '../redux/selectedOrganization/selectedOrganizationSelectors';
import { NonAuthRoutes } from '../routes';
import {
    changeCurrentUserSelectedOrganization,
    getSelectedOrganizationDataForUser,
    setLoadingSelectedOrganization,
} from '../redux/selectedOrganization/selectedOrganizationActions';
import FunctionsManager from '../functions/FunctionsManager';
import { UserRoles } from '../database/schemas/User';

export function useSynchronizeOrgId(
    navigationFunction: (orgId: string) => string
): { synchronized: boolean } {
    const currentUser = useContext(CurrentUserContext);
    const navigation = useNavigation();
    const { organizationId } = useParams();
    const dispatch = useDispatch();
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const [synchronized, setSynchronized] = useState<boolean>(false);

    // if user is not authorized, then redirect them to login
    if (!currentUser.claims) {
        navigate(NonAuthRoutes.LOGIN);
    }

    // 2 options:
    // 1) the ID in the path dictates the currentOrgId and we need to update the selected organization
    // 2) the selected organization dictates the currentOrgId and we need to update the ID in the path
    // To solve this, we just update the path when the user selects a new organization

    // First, make sure the user is authorized to view that org
    if (
        currentUser?.claims?.organizations &&
        !Object.keys(currentUser.claims.organizations).includes(organizationId)
    ) {
        navigate(navigation.unauthorizedUrl);
    }

    if (selectedOrganization && selectedOrganization.id !== organizationId) {
        dispatch(setLoadingSelectedOrganization(true));
        FunctionsManager.user.setCurrentOrganization({ organizationId: organizationId });
    }

    // once the new organization is correctly set on the claims, then we want to make sure it's good in Redux in case we were coming from a path
    useEffect(() => {
        if (
            currentUser.claims?.currentOrgId &&
            currentUser.claims?.currentOrgId !== selectedOrganization?.id
        ) {
            console.log('setting it here');
            setSynchronized(false);
            dispatch(
                changeCurrentUserSelectedOrganization({
                    currentUserClaims: currentUser.claims,
                    newOrganizationId: currentUser.claims.currentOrgId,
                })
            );
        }
    }, [currentUser.claims?.currentOrgId]);

    // Then, once all 3 org IDs are in sync, we can fetch the data and navigate appropriately
    useEffect(() => {
        (async () => {
            if (
                selectedOrganization?.id === currentUser.claims?.currentOrgId &&
                organizationId === currentUser.claims?.currentOrgId &&
                !synchronized
            ) {
                dispatch(
                    getSelectedOrganizationDataForUser({
                        currentUserClaims: currentUser.claims!,
                        organizationId,
                    })
                );
                dispatch(setLoadingSelectedOrganization(false));
                // Currently a provider, not a provider in new org
                if (
                    !currentUser.claims?.access?.isProviderIn(organizationId) &&
                    currentUser.viewContext === UserRoles.provider
                ) {
                    await navigate(navigation.getOrganizationDetailsUrl(organizationId));
                    // Currently an org admin, not an org admin in new org
                } else if (
                    !currentUser.claims?.access?.isOrgAdminIn(organizationId) &&
                    currentUser.viewContext === UserRoles.orgAdmin
                ) {
                    await navigate(navigation.getPatientsUrl(organizationId));
                } else {
                    setSynchronized(true);
                    await navigate(navigationFunction(organizationId));
                }
            }
        })();
    }, [selectedOrganization?.id, currentUser.claims?.currentOrgId, organizationId]);

    // Return this value so we know when it's appropriate to check the required roles against the route (using the AuthRedirection component)
    return {
        synchronized,
    };
}
