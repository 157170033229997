export enum Theme {
    lightBlue = 'lightBlue',
    lightBlueHover = 'lightBlueHover',
    mediumBlue = 'mediumBlue',
    mediumBlueHover = 'mediumBlueHover',
    darkBlue = 'darkBlue',
    darkBlueHover = 'darkBlueHover',
    offWhite = 'offWhite',
    softGray = 'softGray',
}

export type BlueVariants = Theme.lightBlue | Theme.mediumBlue | Theme.darkBlue;

export const buttonStyles = {
    colors(variant: BlueVariants): { primary: BlueVariants; hover: Theme } {
        if (variant === Theme.mediumBlue) {
            return { primary: Theme.mediumBlue, hover: Theme.mediumBlueHover };
        } else if (variant === Theme.lightBlue) {
            return { primary: Theme.lightBlue, hover: Theme.lightBlueHover };
        } else {
            return { primary: Theme.darkBlue, hover: Theme.darkBlueHover };
        }
    },
    classNames({
        variant,
        additionalClassNames,
        isDisabled,
    }: {
        variant: BlueVariants;
        additionalClassNames?: string;
        isDisabled?: boolean;
    }): string {
        const colors = this.colors(variant);
        return `rounded-md font-bold cursor-pointer text-base px-2 py-1 flex flex-row justify-center items-center text-white bg-${
            colors.primary
        } hover:bg-${colors.hover} ${
            isDisabled ? 'opacity-50 cursor-not-allowed' : ''
        } ${additionalClassNames ?? ''}`;
    },
};
