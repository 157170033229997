import { AlertDocument } from '../../../../database/documents/AlertDocument';
import { EntryDocument } from '../../../../database/documents/EntryDocument';
import dateFNSFormat from 'date-fns/format';
import dateFNSIsAfter from 'date-fns/isAfter';
import { Timestamp } from '../../../../database/FirestoreTypes';
import { PatientDocument } from '../../../../database/documents/PatientDocument';
import { isOpioidPrescription, toPascalCase, translateCause } from '../../../../utils';
import { UserMedicationDocument } from '../../../../database/documents/UserMedicationDocument';
import { OpioidType } from '../../../../database/schemas/Medication';
import dateFNSSubDays from 'date-fns/subDays';
import {
    AlertType,
    EntryMedicationTaken,
    MedicationEntryType,
    PatchMedicationEntryEvent,
} from '../../../../database/schemas/Patient';

export type ActivityLog = { [date: string]: ActivityLogItem[] };
type ActivityLogFormatterArgs = { previousActivityLog?: ActivityLog };
export type ActivityLogFeed = [string, { activity: string[]; timeStamp: string }[]][];
export type ActivityLogItem = { activity: string[]; timeStamp: Timestamp };

function formatDateFromTimeStamp(dateString: string): string {
    return dateFNSFormat(new Date(dateString), 'MMMM do');
}

function formatTimeFromTimeStamp(timeStamp: Timestamp): string {
    return dateFNSFormat(timeStamp.toDate(), 'h:mm a');
}

/*
 * PATIENT ACTIVITY
 * */

function deriveMedicationLog({
    medicationDocument,
    medicationTaken,
}: {
    medicationDocument: UserMedicationDocument;
    medicationTaken: EntryMedicationTaken;
}): string {
    switch (medicationTaken.type) {
        case MedicationEntryType.pill:
        case MedicationEntryType.liquid:
            return `${medicationTaken.count} x ${medicationDocument.data.unit.amount}${medicationDocument.data.unit.measure} ${medicationDocument.data.name}`;
        case MedicationEntryType.patch:
            let activity;
            if (medicationTaken.event === PatchMedicationEntryEvent.wearing) {
                activity = `Patient is ${PatchMedicationEntryEvent[medicationTaken.event]}`;
            } else {
                activity = `Patient has ${PatchMedicationEntryEvent[medicationTaken.event]}`;
            }
            return `${activity} ${medicationDocument.data.name} patch`;
    }
}

function sortActivitiesByTime(activityLog) {
    Object.keys(activityLog).forEach(
        key =>
            (activityLog[key] = activityLog[key].sort((a, b) =>
                dateFNSIsAfter(a.timeStamp.toDate(), b.timeStamp.toDate()) ? -1 : 1
            ))
    );
}

export async function formatPatientEntries({
    entries,
    patient,
    previousActivityLog = {},
}: {
    patient: PatientDocument;
    entries: EntryDocument[];
} & ActivityLogFormatterArgs): Promise<ActivityLog> {
    const medications = await patient.getAllMedications();
    return entries.reduce((activityLog: ActivityLog, entry) => {
        const entryDateString = entry.data.createdAt.toDate().toDateString();
        const activityLogMedication = entry.data.medicationTaken?.map(medicationTaken => ({
            medicationDocument: medications.find(({ id }) => id === medicationTaken.medicationId),
            medicationTaken,
        }));
        const activities: string[] = [];
        const { averageDayPainLevel, worstDayPainLevel, painLevel } = entry.data;
        if (averageDayPainLevel === undefined && worstDayPainLevel === undefined && painLevel !== undefined) {
            activities.push(`Pain Rating: ${painLevel}/10`);
        }
        if (worstDayPainLevel !== undefined) {
            activities.push(`Worst Pain Rating: ${worstDayPainLevel}/10`);
        }
        if (averageDayPainLevel !== undefined) {
            activities.push(`Average Pain Rating: ${averageDayPainLevel}/10`);
        }
        if (entry.data.bowelMovement) {
            activities.push('Patient indicates a bowel movement for today.');
        } else if (entry.data.bowelMovement === false) {
            activities.push('Patient indicates no bowel movement for today.');
        }

        activityLogMedication?.forEach(({ medicationDocument, medicationTaken }) => {
            if (!!medicationDocument) {
                activities.push(
                    deriveMedicationLog({
                        medicationDocument,
                        medicationTaken,
                    })
                );
            }
        });

        const activityLogItem = {
            activity: activities,
            timeStamp: entry.data.createdAt,
        };

        if (!!activityLogItem.activity.length) {
            activityLog[entryDateString]
                ? activityLog[entryDateString].push(activityLogItem)
                : (activityLog[entryDateString] = [activityLogItem]);
        }
        sortActivitiesByTime(activityLog);
        return activityLog;
    }, previousActivityLog);
}

export async function formatPatientResources({
    patient,
    previousActivityLog = {},
}: { patient: PatientDocument } & ActivityLogFormatterArgs): Promise<ActivityLog> {
    const resourceDocuments = await patient.getResources();
    return patient.data.resources.reduce((activityLog: ActivityLog, resource) => {
        const activityLogResource = resourceDocuments.find(document => document.id === resource.resourceId)
            ?.data;
        if (resource.assignedTime) {
            const resourceAssignedDateString = resource.assignedTime.toDate().toDateString();
            const activityLogItem: ActivityLogItem = {
                activity: [
                    `${toPascalCase(activityLogResource?.type)} Assigned: "${activityLogResource?.title}"`,
                ],
                timeStamp: resource.assignedTime,
            };
            activityLog[resourceAssignedDateString]
                ? activityLog[resourceAssignedDateString].push(activityLogItem)
                : (activityLog[resourceAssignedDateString] = [activityLogItem]);
        }
        if (resource.completedTime) {
            const resourceCompletedDateString = resource.completedTime.toDate().toDateString();
            const activityLogItem: ActivityLogItem = {
                activity: [
                    `${toPascalCase(activityLogResource?.type)} Completed: "${activityLogResource?.title}"`,
                ],
                timeStamp: resource.completedTime,
            };
            activityLog[resourceCompletedDateString]
                ? activityLog[resourceCompletedDateString].push(activityLogItem)
                : (activityLog[resourceCompletedDateString] = [activityLogItem]);
        }
        sortActivitiesByTime(activityLog);
        return activityLog;
    }, previousActivityLog);
}

export async function formatPatientAlerts({
    alerts,
    previousActivityLog = {},
    userId,
}: {
    alerts: AlertDocument[];
    userId: string;
} & ActivityLogFormatterArgs): Promise<ActivityLog> {
    const causes = await Promise.all(
        alerts.map(async alert => {
            return alert.data.type === AlertType.medication
                ? await translateCause(alert.data.cause, {
                      medicationId: alert.data.details.medicationId,
                      userId,
                  })
                : await translateCause(alert.data.cause);
        })
    );
    return alerts.reduce((activityLog: ActivityLog, alert, index) => {
        //alerts passed will be resolved and will therefore have acknowledgedAt timestamp defined
        const alertDateString = alert.data.acknowledgedAt!.toDate().toDateString();
        const activityLogItem: ActivityLogItem = {
            timeStamp: alert.data.acknowledgedAt!,
            activity: [`Tier ${alert.data.tier.toString()} Alert Resolved: ${causes[index]}`],
        };
        activityLog[alertDateString]
            ? activityLog[alertDateString].push(activityLogItem)
            : (activityLog[alertDateString] = [activityLogItem]);
        sortActivitiesByTime(activityLog);
        return activityLog;
    }, previousActivityLog);
}

export async function derivePatientActivityLog({
    entries,
    patient,
    alerts,
}: {
    entries: EntryDocument[];
    patient: PatientDocument;
    alerts: AlertDocument[];
}): Promise<ActivityLogFeed> {
    let activityLog = await formatPatientEntries({ entries, patient });
    activityLog = await formatPatientResources({ patient, previousActivityLog: activityLog });
    activityLog = await formatPatientAlerts({ alerts, previousActivityLog: activityLog, userId: patient.id });
    return Object.entries(activityLog)
        .sort(([aDate], [bDate]) => (dateFNSIsAfter(new Date(aDate), new Date(bDate)) ? -1 : 1))
        .map(([date, log]) => [
            formatDateFromTimeStamp(date),
            log.map(item => ({ ...item, timeStamp: formatTimeFromTimeStamp(item.timeStamp) })),
        ]);
}

/*
 * PATIENT PILL HISTORY
 * */

export type DataPoint = { date: string; shortActing: number; averagePain: number | null };

export function deriveShortestInterval({
    medications,
    entries,
}: {
    medications: UserMedicationDocument[];
    entries: EntryDocument[];
}): number {
    const uniqueMedicationIds = entries.reduce((acc: Set<string>, val) => {
        val.data.medicationTaken?.forEach(({ medicationId }) => acc.add(medicationId));
        return acc;
    }, new Set<string>());
    const shortActingOpioids = [...uniqueMedicationIds]
        .map(id => medications.find(medication => medication.id === id))
        .filter(
            medication =>
                medication?.data.prescription &&
                isOpioidPrescription(medication.data.prescription) &&
                medication?.data.prescription.opioid === OpioidType.shortActing
        );
    return Math.min(...shortActingOpioids.map(opioid => opioid!.data.prescription!.frequency));
}

export function derivePatientPainPillHistory({
    medications,
    entries,
    maxInterval,
}: {
    medications: UserMedicationDocument[];
    entries: EntryDocument[];
    maxInterval: number;
}): DataPoint[] {
    const dataPoints = generateDaysForInterval(maxInterval - 1).map(date => ({
        date,
        averagePain: null,
        shortActing: 0,
    }));
    return entries.reduce((data: DataPoint[], { data: entry }) => {
        const entryDate = dateFNSFormat(entry.createdAt.toDate(), 'MMM do');
        const dataPoint = data.find(({ date }) => date === entryDate)! ?? { shortActing: 0 };
        if (entry.averageDayPainLevel && dataPoint) {
            dataPoint.averagePain = entry.averageDayPainLevel;
        }
        if (entry.medicationTaken) {
            entry.medicationTaken.forEach(medicationTaken => {
                const medication = medications.find(
                    medication => medication.id === medicationTaken.medicationId
                );
                // The medication a short acting prescription?
                if (
                    medication?.data.prescription &&
                    isOpioidPrescription(medication.data.prescription) &&
                    medication.data.prescription.opioid === OpioidType.shortActing
                ) {
                    // Calculate based on the minimum dose value for that medication
                    const medicationDose = medication.data.prescription.dose.min;

                    // If it's a pill or liquid, the number taken is the count value. There won't be short acting patches, but just in case, we'll call it 1 dose.
                    const numberTaken =
                        medicationTaken.type !== MedicationEntryType.patch
                            ? medicationTaken.count
                            : medicationTaken.event !== PatchMedicationEntryEvent.removed
                            ? 1
                            : 0;
                    dataPoint.shortActing += numberTaken / medicationDose;
                }
            });
        }
        return data;
    }, dataPoints);
    // Example data for testing the chart
    // return [
    //     { date: 'Sep 1st', averagePain: null, shortActing: 4 },
    //     { date: 'Sep 2nd', averagePain: 3, shortActing: 0 },
    //     { date: 'Sep 3rd', averagePain: null, shortActing: 0 },
    //     { date: 'Sep 4th', averagePain: 6, shortActing: 1 },
    //     { date: 'Sep 5th', averagePain: 2, shortActing: 0 },
    //     { date: 'Sep 6th', averagePain: 10, shortActing: 0 },
    //     { date: 'Sep 7th', averagePain: 8, shortActing: 2 },
    //     { date: 'Sep 8th', averagePain: 0, shortActing: 0 },
    //     { date: 'Sep 9th', averagePain: 1, shortActing: 0 },
    //     { date: 'Sep 10th', averagePain: 2, shortActing: 1 },
    //     { date: 'Sep 11th', averagePain: 5, shortActing: 0 },
    //     { date: 'Sep 12th', averagePain: null, shortActing: 0 },
    //     { date: 'Sep 13th', averagePain: null, shortActing: 10 },
    //     { date: 'Sep 14th', averagePain: 3, shortActing: 0 },
    // ];
}

function generateDaysForInterval(interval: number): string[] {
    const days: string[] = [];
    for (let i = interval; i >= 0; i--) {
        days.push(dateFNSFormat(dateFNSSubDays(new Date(), i), 'MMM do'));
    }
    return days;
}
