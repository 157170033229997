import { DeliveryType, OpioidType } from '../../../../database/schemas/Medication';

export const renderDoseText = (deliveryType: DeliveryType) => {
    return deliveryType === DeliveryType.liquid
        ? 'Dose'
        : deliveryType === DeliveryType.patch
        ? 'Patches per dose'
        : 'Pills per dose';
};

export const renderPillSizeText = (deliveryType: DeliveryType) => {
    return deliveryType === DeliveryType.liquid
        ? 'Concentration'
        : deliveryType === DeliveryType.patch
        ? 'Patch dose'
        : 'Pill Size';
};

export const getMeasure = (delivery: DeliveryType) => {
    if (delivery === DeliveryType.patch) {
        return 'mcg/hr';
    } else if (delivery === DeliveryType.liquid) {
        return 'mg/mL';
    } else {
        return 'mg';
    }
};

export const opioidTypeLabels = {
    [OpioidType.shortActing]: 'Short-acting',
    [OpioidType.longActing]: 'Long-acting',
    [OpioidType.nonOpioid]: 'Non-Opioid',
};
