import React, { useEffect } from 'react';
import TextInput from '../../../../../components/TextInput';
import { MedicationFormKeys } from '../medicationTypes';
import { isOpioidPrescription, toPascalCase } from '../../../../../utils';
import { DeliveryType } from '../../../../../database/schemas/Medication';
import { opioidTypeLabels, renderDoseText, renderPillSizeText } from '../medicationFormLabels';
import { Theme } from '../../../../../theme';
import { getMedLabel } from '../../../patientList/patientTableFormatters';
import { InputChange, ValidatableString } from '../../../../../types';
import { useImmer } from 'use-immer';
import { pillsPerDoseValidator } from '../medicationFormValidation';
import { UserMedicationDocument } from '../../../../../database/documents/UserMedicationDocument';

interface Props {
    handleInput: (e: InputChange) => void;
    prescription: UserMedicationDocument;
    isEditing: boolean;
    toggleArchivePrescriptionModal: () => void;
    cancelUpdatingPrescription: () => void;
    updatePrescriptionInRedux: () => void;
    preventInput?: boolean;
}

interface State {
    pillsPerDose: ValidatableString & { showError?: boolean };
    formValid: boolean;
}

export default function PrescriptionForm(props: Props) {
    const [state, updateState] = useImmer<State>({
        pillsPerDose: {
            value:
                props.prescription.data.prescription?.dose?.min !== undefined
                    ? pillsPerDoseValidator.format(props.prescription.data.prescription.dose)
                    : '',
            isValid: props.prescription.data.prescription?.dose ? true : undefined,
        },
        formValid: true, //form is valid by default because it's a pre-existing prescription
    });
    useEffect(() => {
        const { prescription } = props.prescription.data;
        updateState(draft => {
            draft.formValid = !!(
                state.pillsPerDose.isValid &&
                !!prescription?.frequency &&
                ((isOpioidPrescription(prescription) && !!prescription.count.prescribed) ||
                    !isOpioidPrescription(prescription))
            );
        });
    }, [
        state.pillsPerDose.isValid,
        props.prescription.data.prescription?.frequency,
        props.prescription.data.prescription &&
            isOpioidPrescription(props.prescription.data.prescription) &&
            props.prescription.data.prescription?.count.prescribed,
    ]);

    useEffect(() => {
        //because we are storing and formatting a local version of the prescription.dose, if changes are discarded
        //in the parent MedicationTab, we need to update this component's local state accordingly
        if (props.isEditing) {
            return;
        }
        const { dose } = props.prescription.data.prescription!;
        const { min, max } = pillsPerDoseValidator.convertToMinMax(state.pillsPerDose.value);
        if (min !== dose.min || max !== dose.max) {
            updateState(draft => {
                draft.pillsPerDose.value = pillsPerDoseValidator.format(dose);
            });
        }
    }, [
        props.prescription.data.prescription?.dose.min,
        props.prescription.data.prescription?.dose.max,
        props.isEditing,
    ]);

    const validatePillsPerDoseOnBlur = () => {
        const isValid = pillsPerDoseValidator.isValid({
            value: state.pillsPerDose.value,
            deliveryType: props.prescription.data.delivery,
        });
        updateState(draft => {
            draft.pillsPerDose.isValid = isValid;
            draft.pillsPerDose.showError = !isValid;
        });
    };

    const handlePillsPerDoseInput = (e: InputChange) => {
        e.persist();
        props.handleInput(e);
        if (props.preventInput) {
            return;
        }
        updateState(draft => {
            draft.pillsPerDose.showError = false;
            draft.pillsPerDose.value = e.target.value;
            draft.pillsPerDose.isValid = pillsPerDoseValidator.isValid({
                value: e.target.value,
                deliveryType: props.prescription.data.delivery,
            });
        });
    };

    const {
        prescription: { data: prescriptionInfo },
    } = props;

    return (
        <div className="w-full p-3">
            <div className="flex flex-row w-full items-center justify-between">
                <div className="mr-4" style={{ width: '14%' }}>
                    <label className="block">
                        <span className="text-gray-700 font-semibold">Name</span>
                        <TextInput
                            disabled={true}
                            className="py-1 word-wrap"
                            multiline
                            value={prescriptionInfo.name}
                        />
                    </label>
                </div>
                <div style={{ width: '10%' }}>
                    <label className="block" htmlFor={MedicationFormKeys.delivery}>
                        <span className="text-gray-700 font-semibold">Method</span>
                        <TextInput
                            name={MedicationFormKeys.delivery}
                            value={toPascalCase(DeliveryType[prescriptionInfo.delivery]) ?? ''}
                            disabled={true}
                            className="py-1"
                        />
                    </label>
                </div>
                <div style={{ width: '10%' }} className="mr-4">
                    <span className="text-gray-700 font-semibold">Opioid Type</span>
                    <TextInput
                        name={MedicationFormKeys.prescriptionOpioid}
                        value={
                            prescriptionInfo.prescription &&
                            isOpioidPrescription(prescriptionInfo.prescription)
                                ? opioidTypeLabels[prescriptionInfo.prescription!.opioid!]
                                : 'Non-Opioid'
                        }
                        disabled={true}
                        className="py-1"
                    />
                </div>
                <div className="mr-4" style={{ width: '11.5%' }}>
                    <label className="block" htmlFor={MedicationFormKeys.unitAmount}>
                        <span className="text-gray-700 font-semibold">
                            {renderPillSizeText(prescriptionInfo.delivery)}
                        </span>
                        <span className="block flex flex-1 items-center leading-normal p-1 whitespace-no-wrap">
                            {String(prescriptionInfo.unit?.amount ?? '')}
                            {prescriptionInfo.unit?.measure}
                        </span>
                    </label>
                </div>
                <div
                    className="relative mr-4"
                    style={{ width: prescriptionInfo.delivery === DeliveryType.patch ? '13.5%' : '11.5%' }}
                >
                    <label className="block" htmlFor={MedicationFormKeys.dose}>
                        <span className="text-gray-700 font-semibold">
                            {renderDoseText(prescriptionInfo.delivery)}
                        </span>
                        <div
                            className={`flex flex-row items-stretch ${
                                prescriptionInfo.delivery === DeliveryType.patch ? 'w-5/6 mx-auto' : 'w-full'
                            } relative`}
                        >
                            <TextInput
                                name={MedicationFormKeys.dose}
                                value={state.pillsPerDose.value}
                                onChange={handlePillsPerDoseInput}
                                onBlur={validatePillsPerDoseOnBlur}
                                inValid={state.pillsPerDose.showError}
                                className="py-1"
                            />
                            {prescriptionInfo.delivery === DeliveryType.liquid && (
                                <span className="flex items-center leading-normal font-semibold p-1 whitespace-no-wrap text-gray-700 text-sm">
                                    mL
                                </span>
                            )}
                        </div>
                    </label>
                    {state.pillsPerDose.showError && (
                        <span
                            className="text-xs w-full text-red-500 absolute text-center bg-white rounded-md shadow-md z-50 p-2 border border-dashed border-red-500"
                            style={{ bottom: '-85px' }}
                        >
                            {prescriptionInfo.delivery === DeliveryType.patch
                                ? 'Please enter a valid range with only whole values'
                                : 'Please enter a valid range with only whole or half values.'}
                        </span>
                    )}
                </div>
                <div className="text-left mr-4" style={{ width: '11.5%' }}>
                    <label htmlFor={MedicationFormKeys.prescriptionFrequency} className="block">
                        <span className="text-gray-700 font-semibold">Interval</span>
                        <div className="w-full flex flex-row items-center justify-start">
                            <TextInput
                                className={`form-input focus:outline-none border py-1 border-gray-400 w-1/12 block`}
                                value={String(prescriptionInfo.prescription?.frequency ?? '') ?? ''}
                                name={MedicationFormKeys.prescriptionFrequency}
                                onChange={props.handleInput}
                            />
                            <span className="text-gray-700 font-semibold text-sm ml-1">hr</span>
                        </div>
                    </label>
                </div>
                <div className="mr-4" style={{ width: '6%' }}>
                    {prescriptionInfo.prescription && isOpioidPrescription(prescriptionInfo.prescription) && (
                        <label className="block" htmlFor={MedicationFormKeys.prescriptionCountPrescribed}>
                            <span className="text-gray-700 font-semibold">Qty</span>
                            <TextInput
                                name={MedicationFormKeys.prescriptionCountPrescribed}
                                value={String(prescriptionInfo?.prescription?.count?.prescribed ?? '') ?? ''}
                                onChange={props.handleInput}
                                className="py-1"
                            />
                        </label>
                    )}
                </div>
                <div className="mt-6 text-xs" style={{ width: '8%' }}>
                    {prescriptionInfo.prescription && isOpioidPrescription(prescriptionInfo.prescription) && (
                        <label className="block" htmlFor={MedicationFormKeys.prescriptionCountPrescribed}>
                            <span className="py-1 break-words">
                                {prescriptionInfo.prescription?.count?.remaining !== undefined
                                    ? prescriptionInfo.prescription.count.remaining === null
                                        ? `--- ${getMedLabel({
                                              count: prescriptionInfo.prescription.count.remaining,
                                              delivery: prescriptionInfo.delivery,
                                          })} remaining`
                                        : `${prescriptionInfo.prescription.count.remaining} ${getMedLabel({
                                              count: prescriptionInfo.prescription.count.remaining,
                                              delivery: prescriptionInfo.delivery,
                                          })} remaining`
                                    : ''}
                            </span>
                        </label>
                    )}
                </div>
            </div>
            <div className="w-full flex flex-row items-center justify-end">
                {props.isEditing ? (
                    <div className="flex flex-col justify-center pt-5">
                        <button
                            onClick={e => {
                                e.stopPropagation();
                                props.updatePrescriptionInRedux();
                            }}
                            disabled={!state.formValid}
                            className={`px-2 py-1 rounded-md focus:outline-none bg-${
                                Theme.lightBlue
                            } hover:bg-${Theme.lightBlueHover} text-white ${
                                !state.formValid ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        >
                            Update
                        </button>
                        <button
                            onClick={e => {
                                e.stopPropagation();
                                props.cancelUpdatingPrescription();
                            }}
                            className={`px-2 py-1 rounded-md focus:outline-none font-normal text-gray-600 hover:text-gray-600`}
                        >
                            Cancel
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={props.toggleArchivePrescriptionModal}
                        className={`px-2 py-1 rounded-md focus:outline-none font-semibold mt-5 text-white bg-${Theme.darkBlue} hover:bg-${Theme.darkBlueHover}`}
                    >
                        Remove
                    </button>
                )}
            </div>
        </div>
    );
}
