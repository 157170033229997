import React from 'react';
import { Theme } from '../theme';
import { InputChange } from '../types';

interface Props {
    options: { label: string; value: any; checked: boolean }[];
    handleInput: (e: InputChange) => void;
    size?: 'sm' | 'base' | 'xl';
}

export default function SegmentedControl(props: Props) {
    const baseClassNames = `inline-block text-${props.size ??
        'base'} rounded-md px-2 font-medium cursor-pointer`;
    const activeClassNames = `${baseClassNames} border border-${Theme.lightBlue} bg-${Theme.lightBlue} text-white`;
    const renderWeekIntervalClassNames = (active: boolean) =>
        active
            ? activeClassNames
            : `${baseClassNames} bg-white text-blue-500 border hover:border-${Theme.lightBlueHover}`;
    return (
        <div className="flex flex-row justify-end mb-3">
            <div className="border rounded-md">
                {props.options.map((option, i) => (
                    <label
                        key={i}
                        className={`${
                            i === 0
                                ? 'rounded-r-none'
                                : i === props.options.length - 1
                                ? 'rounded-l-none'
                                : 'rounded-none'
                        } ${renderWeekIntervalClassNames(option.checked)}`}
                    >
                        <input
                            type="checkbox"
                            hidden={true}
                            className="hidden"
                            value={option.value}
                            onChange={props.handleInput}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
        </div>
    );
}
