/**
 * Claims to be used when the user is either an App Admin or unknown if they have an OrganizationRole
 */
import firebase from 'firebase/app';
import { OrganizationRoles } from './database/schemas/OrganizationRoles';
import { UserAccess } from './database/UserAccess';
import { OrganizationRolesObject, UserOrganizations } from './database/schemas/User';

export class Claims {
    firestoreId: string | undefined;
    isAppAdmin: boolean | undefined;
    isPatient: boolean | undefined;
    organizations: UserOrganizations | undefined;
    currentOrgId: string | undefined;
    currentRoles: OrganizationRolesObject | undefined;
    access: UserAccess | undefined;

    protected token: any;

    constructor(token: firebase.auth.IdTokenResult) {
        const {
            organizations,
            isAppAdmin,
            isPatient,
            currentOrgId,
            currentRoles,
            firestoreId,
        } = token.claims;

        this.firestoreId = firestoreId;
        this.isAppAdmin = isAppAdmin;
        this.isPatient = isPatient;
        this.organizations = organizations;
        this.currentOrgId = currentOrgId;
        this.currentRoles = currentRoles;
        this.access = organizations ? new UserAccess(organizations, !!isAppAdmin) : undefined;
    }

    isProviderInCurrentOrganization(): boolean {
        return !!this.currentRoles?.[OrganizationRoles.provider];
    }

    isOrgAdminInCurrentOrganization(): boolean {
        return !!this.currentRoles?.[OrganizationRoles.orgAdmin];
    }

    getOrgIds(): string[] {
        return Object.keys(this.organizations ?? {});
    }

    isInOrganizations(): boolean {
        return Object.keys(this.organizations ?? {}).length > 0;
    }

    isInMultipleOrganizations(): boolean {
        return Object.keys(this.organizations ?? {}).length > 1;
    }
}
