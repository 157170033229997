import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import { selectToastAlert } from '../../../../redux/currentSession/currentSessionSelectors';
import ToastAlert from '../../../../components/ToastAlert';
import PatientActivity from './PatientActivity';
import PainPillHistory from './PainPillHistory';

export default function HistoryTab() {
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));

    return (
        <div className="w-full md:p-3 flex flex-row">
            {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
            <div className="rounded-md bg-gray-100 border-gray-200 p-3 flex flex-col flex-1">
                <h4 className="text-lg font-semibold text-blue-900">Activity Log</h4>
                <PatientActivity />
            </div>
            <div className="flex-1 p-3">
                <h4 className="text-lg font-semibold text-blue-900">Pain/Medication History</h4>
                <PainPillHistory />
            </div>
        </div>
    );
}
