import {
    GroupedAlerts,
    LocalUserMedicationSchema,
    SelectedPatientActions,
    SelectedPatientActionTypes,
    SelectedPatientState,
    UpdatePatientArgs,
} from './selectedPatientReducer';
import DatabaseManager from '../../database/DatabaseManager';
import { Dispatch } from '../../types';
import { Thunk } from '../reduxTypes';
import { UserMedicationDocument } from '../../database/documents/UserMedicationDocument';
import { formatPhoneNumber, isFirestoreDocument, isOpioidPrescription } from '../../utils';
import { ReduxState } from '../store';
import {
    selectSelectedPatientActiveAlerts,
    selectSelectedPatientDocument,
    selectSelectedPatientUpdates,
    selectUpdatedSelectedPatient,
} from './selectedPatientSelectors';
import { InvitePatientRequest } from '../../functions/inviteTypes';
import { PatientKeys, UserMedicationSchema } from '../../database/schemas/Patient';
import { setToastAlert, setToastError } from '../currentSession/currentSessionActions';
import FunctionsManager from '../../functions/FunctionsManager';

type DispatchSelectedPatientActions = Dispatch<SelectedPatientActionTypes>;
type SelectedPatientThunk = Thunk<SelectedPatientActionTypes>;

export const getPatientById = (patientId: string): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    const selectedPatient = await DatabaseManager.PatientModel.get(patientId);
    const [medications, entries, archivedMedications] = await Promise.all([
        selectedPatient.getActiveMedications(),
        selectedPatient.getAllEntries(),
        selectedPatient.getArchivedMedications(),
    ]);
    const patientAddedMedications: UserMedicationDocument[] = [];
    const providerAddedPrescriptions: UserMedicationDocument[] = [];
    medications.forEach(medication => {
        //if it's still a schema we know it's provider added
        if (
            !isFirestoreDocument<UserMedicationDocument>(medication) ||
            (isFirestoreDocument<UserMedicationDocument>(medication) &&
                medication.data.prescription !== null &&
                medication.data.archived === false)
        ) {
            providerAddedPrescriptions.push(medication);
        } else {
            patientAddedMedications.push(medication as UserMedicationDocument);
        }
    });
    const { active, resolved } = await selectedPatient.getAlertsAboveTierOne();
    const form: InvitePatientRequest | {} = {};

    for (const prop in selectedPatient.data) {
        if (prop in PatientKeys) {
            form[prop] = selectedPatient.data[prop];
        }
    }

    const archivedPrescriptions = archivedMedications.filter(
        medication => medication.data.prescription !== null
    );

    dispatch({
        type: SelectedPatientActions.SET_SELECTED_PATIENT,
        payload: {
            form: {
                ...form,
                emergencyPhoneNumber:
                    formatPhoneNumber((form as InvitePatientRequest).emergencyPhoneNumber) ?? '',
            } as InvitePatientRequest,
            prescriptions: { providerAdded: providerAddedPrescriptions, pending: [] },
            archivedPrescriptions,
            medications: patientAddedMedications,
            document: selectedPatient,
            entries,
            alerts: { active, resolved },
        } as SelectedPatientState,
    });
};

export const saveChangesToPatient = (): SelectedPatientThunk => async (
    dispatch, //will ultimately dispatch another thunk
    getState: () => ReduxState
): Promise<void> => {
    const reduxState = getState();
    const patientDocument = selectSelectedPatientDocument(reduxState);
    const updatedPatientFields = selectSelectedPatientUpdates(reduxState);
    const updatedPatient = selectUpdatedSelectedPatient(reduxState);
    const activeAlerts = selectSelectedPatientActiveAlerts(reduxState);

    if (!updatedPatientFields.length) {
        return;
    }

    try {
        if (patientDocument) {
            if (updatedPatientFields.includes('document')) {
                await patientDocument.save();
            }
            if (updatedPatientFields.includes('form')) {
                const { email, ...patientForm } = updatedPatient.form;

                if (Object.keys(patientForm).includes('assignedPrescriber')) {
                    await FunctionsManager.user.clearPatientQueryCache({
                        assignedPrescriberIds: [
                            patientForm.assignedPrescriber,
                            patientDocument.data.assignedPrescriber,
                        ],
                    });
                }

                await patientDocument.update({ ...patientForm });
                if (patientDocument.data.email !== email) {
                    await FunctionsManager.invite.changeInvitedPatientEmail({
                        patientId: patientDocument.id,
                        newEmail: email,
                    });
                }
            }
            if (updatedPatientFields.includes('prescriptions')) {
                const { providerAdded, pending } = updatedPatient.prescriptions;
                providerAdded.forEach(prescription => prescription.save());
                //store all created prescriptions in variable to pass to move them from pending to providerAdded in redux state
                if (!!pending.length) {
                    const createdPrescriptions = await Promise.all(
                        pending.map(({ id, ...prescription }) => {
                            if (
                                prescription.prescription &&
                                isOpioidPrescription(prescription.prescription)
                            ) {
                                prescription.prescription.count.prescribed = Number(
                                    prescription.prescription.count.prescribed
                                );
                            }
                            prescription.unit.amount = Number(prescription.unit.amount);
                            if (prescription.prescription) {
                                prescription.prescription.frequency = Number(
                                    prescription.prescription?.frequency
                                );
                            }
                            return patientDocument.createMedication(prescription);
                        })
                    );
                    dispatch(mergeChangesToPatientPrescriptionsToOriginal(createdPrescriptions));
                }
            }
            if (updatedPatientFields.includes('alerts')) {
                await Promise.all(activeAlerts.map(alert => alert.save()));
                const { active, resolved } = await patientDocument.getAlertsAboveTierOne();
                dispatch(
                    updateGroupedAlerts({
                        active,
                        resolved,
                    })
                );
            }
        }
        dispatch(setToastAlert('Changes saved for patient'));
        dispatch(mergePatientChangesToOriginal());
    } catch (error) {
        dispatch(setToastError('An error occurred while saving changes for patient'));
    }
};

export const updateGroupedAlerts = (groupedAlerts: GroupedAlerts): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.SET_SELECTED_PATIENT_ALERTS, payload: groupedAlerts });
};

const mergePatientChangesToOriginal = (): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.MERGE_UPDATED_PATIENT_FIELDS });
};

const mergeChangesToPatientPrescriptionsToOriginal = (
    createdPrescriptions: UserMedicationDocument[]
): SelectedPatientThunk => async (dispatch: DispatchSelectedPatientActions): Promise<void> => {
    dispatch({
        type: SelectedPatientActions.MERGE_UPDATED_PATIENT_PRESCRIPTIONS,
        payload: createdPrescriptions,
    });
};

export const cancelChangesToPatient = (): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.CANCEL_CHANGES_FOR_PATIENT });
};

export const handlePatientFormInput = (formState: UpdatePatientArgs): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.UPDATE_SELECTED_PATIENT_FORM_PROP, payload: formState });
};

export const updatePatientDocumentProp = (formState: UpdatePatientArgs): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.UPDATE_SELECTED_PATIENT_DOCUMENT_FIELD, payload: formState });
};

export const clearSelectedPatient = (): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.CLEAR_SELECTED_PATIENT });
};

export const clearSelectedPatientForm = (): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.CLEAR_SELECTED_PATIENT_FORM });
};

/*
 * MEDICATIONS / PRESCRIPTIONS
 *  */

export const addPendingPrescriptionForSelectedPatient = (
    pendingPrescription: LocalUserMedicationSchema
): SelectedPatientThunk => async (dispatch: DispatchSelectedPatientActions): Promise<void> => {
    dispatch({
        type: SelectedPatientActions.ADD_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT,
        payload: pendingPrescription,
    });
};

export const removePendingPrescriptionForSelectedPatient = (
    prescriptionId: string
): SelectedPatientThunk => async (dispatch: DispatchSelectedPatientActions): Promise<void> => {
    dispatch({
        type: SelectedPatientActions.REMOVE_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT,
        payload: prescriptionId,
    });
};

export const removeProviderAddedPrescriptionForSelectedPatient = (
    updatedPrescription: UserMedicationDocument
): SelectedPatientThunk => async (dispatch: DispatchSelectedPatientActions): Promise<void> => {
    dispatch({
        type: SelectedPatientActions.REMOVE_PROVIDER_ADDED_PRESCRIPTION_FOR_SELECTED_PATIENT,
        payload: updatedPrescription,
    });
};

export const removePatientAddedMedicationForSelectedPatient = (
    medicationId: string
): SelectedPatientThunk => async (dispatch: DispatchSelectedPatientActions): Promise<void> => {
    dispatch({
        type: SelectedPatientActions.REMOVE_PATIENT_ADDED_MEDICATION_FOR_SELECTED_PATIENT,
        payload: medicationId,
    });
};

export const updateProviderAddedPrescriptionForSelectedPatient = (
    updatedPrescription: UserMedicationDocument
): SelectedPatientThunk => async (dispatch: DispatchSelectedPatientActions): Promise<void> => {
    dispatch({
        type: SelectedPatientActions.UPDATE_PROVIDER_ADDED_PRESCRIPTION_FOR_SELECTED_PATIENT,
        payload: updatedPrescription,
    });
};

export const updatePendingPrescriptionForSelectedPatient = (
    updatedPrescription: LocalUserMedicationSchema
): SelectedPatientThunk => async (dispatch: DispatchSelectedPatientActions): Promise<void> => {
    dispatch({
        type: SelectedPatientActions.UPDATE_PENDING_PRESCRIPTION_FOR_SELECTED_PATIENT,
        payload: updatedPrescription,
    });
};

export const getArchivedPrescriptionsForSelectedPatient = (
    archivedPrescriptions: UserMedicationDocument[]
): SelectedPatientThunk => async (dispatch: DispatchSelectedPatientActions): Promise<void> => {
    dispatch({
        type: SelectedPatientActions.SET_SELECTED_PATIENT_ARCHIVED_PRESCRIPTIONS,
        payload: archivedPrescriptions,
    });
};

/*
 * ALERTS
 * */

export const toggleSelectedPatientAlertResolved = (alertId: string): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.TOGGLE_ALERT_RESOLVED, payload: alertId });
};
