import React from 'react';
import { render } from 'react-dom';
import './styles/index.css';
import App from './App';
import CurrentUserContextProvider from './context/CurrentUserContextProvider';
import { Provider } from 'react-redux';
import store from './redux/store';
import { setAutoFreeze } from 'immer';
setAutoFreeze(false);

render(
    <Provider store={store}>
        <CurrentUserContextProvider>
            <App />
        </CurrentUserContextProvider>
    </Provider>,
    document.getElementById('app')
);
